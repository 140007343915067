import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Modal } from "./Modal";

import { HeaderModal } from "./Header";
import { FooterModal } from "./Footer";

import close from "assets/images/close-icon.svg";

import { Body } from "./Body";
import allImgPaths from "assets/images";
import Toast from "../Toast";
import { createFolder, editFolder } from "services/portfolio";
import { InputTextField } from "components/InputField";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  setRefreshData: Dispatch<SetStateAction<boolean>>;
  portfolioId: string;
  currentFolderId?: string | null;
  isEditMode?: boolean;
  existingFolderName?: string;
  folderIdToEdit?: string | null;
}

const AddNewFolderModal = ({
  isShow,
  setIsShow,
  setRefreshData,
  portfolioId,
  currentFolderId = null,
  isEditMode = false,
  existingFolderName = "",
  folderIdToEdit = null,
}: Iprops) => {
  const [folderName, setFolderName] = useState(
    isEditMode ? existingFolderName : ""
  );

  // Validation check for folder name
  const isFolderNameValid = useMemo(() => {
    return folderName.trim().length >= 1;
  }, [folderName]);

  const onCloseModal = () => {
    setIsShow(false);
  };

  const handleFolderNameChange = (e: any) => {
    setFolderName(e.target.value);
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();

    if (!isFolderNameValid) {
      return;
    }

    let payload: any = {
      name: folderName.trim(),
    };

    if (currentFolderId && !isEditMode) {
      payload.parent_folder_id = currentFolderId;
    }

    if (isEditMode && folderIdToEdit) {
      await editFolder(portfolioId, folderIdToEdit, payload);
      Toast.success("Success", "Folder updated successfully");
    } else {
      await createFolder(portfolioId, payload);
      Toast.success("Success", "Folder created successfully");
    }

    setFolderName("");
    setIsShow(false);
    setRefreshData((prev) => !prev);
  };

  return (
    <>
      <Modal className={"modal-wrapper max-w-[480px]"} isShow={isShow}>
        <form onSubmit={handleSubmit}>
          <HeaderModal
            title={isEditMode ? "Edit Folder" : "Add New Folder"}
            isShowHeader={true}
            className="modal-header pt-6 px-6"
          >
            <div className="flex items-center justify-between pb-7">
              {isEditMode ? (
                <>
                  <img
                    className="h-10 w-10"
                    src={allImgPaths.editFolderIcon}
                    alt="Edit Folder"
                  />
                </>
              ) : (
                <>
                  <img
                    className="h-10 w-10"
                    src={allImgPaths.addFolderIcon}
                    alt="Add Folder"
                  />
                </>
              )}
              {/* Close Icon */}
              <img
                className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4"
                id="modal-toggle"
                src={close}
                onClick={onCloseModal}
                alt="close icon"
              />
            </div>

            <h2 className="text-base text-[#0A090B] font-interSemiBold pb-1">
              {isEditMode ? "Edit Folder" : "Add New Folder"}
            </h2>
            <p className="text-sm text-[#4F4D55] font-interRegular">
              {isEditMode
                ? "Change the folder properties to fit your requirements."
                : "Create a folder to organize your portfolio"}
            </p>
          </HeaderModal>

          <Body title="" isShowBody={true} className="modal-body px-6 pt-7">
            <div className="pb-5">
              <label
                htmlFor=""
                className="text-[#2D2B32] text-sm font-interRegular pb-2 flex"
              >
                Folder Name
                <img src={allImgPaths.questionCircle} alt="help" />
              </label>
              <InputTextField
                className="placeholder:text-[#7F7D83] placeholder:text-[15px] text-base text-[#344054] w-full"
                name={"name"}
                placeholder={"Enter Folder Name"}
                onChange={handleFolderNameChange}
                value={folderName}
              />
            </div>
          </Body>

          <FooterModal
            title=""
            isShowFooter={true}
            className="modal-footer pt-5 px-6 pb-6"
          >
            <div className="flex items-center gap-x-3 justify-center">
              <button
                onClick={onCloseModal}
                className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]"
              >
                Cancel
              </button>
              <button
                className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${
                  isFolderNameValid ? "" : "button-disabled"
                }`}
                type="submit"
                disabled={!isFolderNameValid}
              >
                {isEditMode ? "Update" : "Create"}
              </button>
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
};

export default AddNewFolderModal;
