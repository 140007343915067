import allImgPaths from "assets/images";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import React,{ useState } from 'react';


const AddFileBlue = ({
    addDropdownRef,
    setOpenModal,
    type = 'project',
    setOpenFolderModal = {}
}: { addDropdownRef: React.MutableRefObject<null>; setOpenModal: any ; type: string; setOpenFolderModal? : any }) => {
    const [isShowAddDropdown,setIsShowAddDropdown] = useState<boolean>(false);

    useOnClickOutside(addDropdownRef,() => setIsShowAddDropdown(false));

    const projectListItems =[{ title : "With patent #", isEnabled : false}, { title : "From Portfolio", isEnabled : false}, { title : "External Upload", isEnabled : true}];
    const portfolioListItems =[ { title : "New Folder", isEnabled : true}, { title : "External Upload", isEnabled : true}];


    return (
        <div ref={addDropdownRef} className="relative">
            <button
                className={`!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg px-3.5 py-2.5 mx-auto`}
                onClick={() => setIsShowAddDropdown(!isShowAddDropdown)}
            >
                <img className="w-4" src={allImgPaths.plusIconWhite} alt="" />
                Add New
                <img
                    className={`-ml-1 transform ${isShowAddDropdown ? "rotate-180" : "rotate-0"}`}
                    src={allImgPaths.downIconWhite}
                    alt=""
                />
            </button>
            <div className={`absolute bg-white w-36 top-full left-1/2 -translate-x-1/2 shadow-customShadow14 rounded-lg mt-1 z-20 block ${isShowAddDropdown ? "block" : "hidden"}`}>
                <ul>
                    {type === 'project' && projectListItems.map((item, index) => (
                        <li key={index + item.title} className={`${item.isEnabled ? "flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]": "cursor-default pointer-events-none opacity-50 flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD]  text-sm font-interMedium text-[#0A090B]"}`} onClick={() => { index === 2 && setOpenModal(true)}} >
                        {item.title}
                    </li>
                    ))}

{type === 'portfolio' && portfolioListItems.map((item, index) => (
                        <li key={index + item.title} className={`${item.isEnabled ? "flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]": "cursor-default pointer-events-none opacity-50 flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD]  text-sm font-interMedium text-[#0A090B]"}`} onClick={() => { index === 0 && setOpenFolderModal(true); index === 1 && setOpenModal(true); }} >
                        {item.title}
                    </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AddFileBlue;