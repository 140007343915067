import {
  TeamMemberListRequestPayload,
  UpdateTeamFields,
  SendInvitationPayload,
  UpdateUserProfileFields,
  ChangePasswordFields,
} from "../types";
import { axiosInstance } from "./axios";

export const selectTeam = async (team_id: string) => {
  try {
    return await axiosInstance.post(`user/select-team/${team_id}`);
  } catch (error) {
    throw error;
  }
};

export const getProjectInvitations = async (team_id: string) => {
  try {
    return await axiosInstance.post(`team/project-invitation-list`, {
      team_id,
    });
  } catch (error) {
    throw error;
  }
};

export const getPortfolioInvitations = async (team_id: string) => {
  try {
    return await axiosInstance.post(`team/portfolio-invitation-list`, {
      team_id,
    });
  } catch (error) {
    throw error;
  }
};

export const updateApprovalStatus = async (
  collaboratorId: string,
  payload: any
) => {
  try {
    return await axiosInstance.put(`team/approve/${collaboratorId}`, payload);
  } catch (error) {
    throw error;
  }
};

export const sendInvitation = async (payLoad: SendInvitationPayload) => {
  try {
    return await axiosInstance.post(`user/send-invitation`, payLoad);
  } catch (error) {
    throw error;
  }
};

export const updateUserProfile = async (data: UpdateUserProfileFields) => {
  try {
    return await axiosInstance.put(`user/profile`, data);
  } catch (error) {
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    return await axiosInstance.get(`user/profile`);
  } catch (error) {
    throw error;
  }
};

export const createUserPreSignedUrl = async (payload: any) => {
  try {
    return await axiosInstance.post("user/create-user-presigned-url", payload);
  } catch (error) {
    throw error;
  }
};

export const getUserProfileImage = async (payload: any) => {
  try {
    return await axiosInstance.post(`/user/user-profile-image`, payload);
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (payload: ChangePasswordFields) => {
  try {
    return await axiosInstance.post(`/user/change-password`, payload);
  } catch (error) {
    throw error;
  }
};
