import { lazy } from 'react';
import { Navigate,Outlet,useRoutes } from 'react-router-dom';
// import ForgotPassword from "./pages/auth/ForgotPassword";
// import TeamSetup from "./pages/auth/TeamSetup";
// import SelectTeam from "./pages/auth/SelectTeam";
// import Dashboard from "./pages/dashboard/Dashboard";
// import PageNotFound from "./pages/pageNotFound";
// import ContactUs from "./pages/ContactUs";
// import PrivacyPolicy from "./pages/PrivacyPolicy";
// import Successful from "./pages/Successful";
// import Settings from "./pages/dashboard/Settings";
// import Explore from "./pages/dashboard/Explore";
// import MailSent from "./pages/auth/MailSent";
// import PlanningBoard from "./pages/dashboard/PlanningBoard";
// import NewFete from "pages/dashboard/NewFete";
// import Prompts from "pages/prompts";
// import Favourite from "pages/dashboard/Favourite";
// import Vendor from "components/Vendor";

import Projects from 'pages/dashboard/Projects';
import Portfolio from 'pages/dashboard/Portfolios';
import Login from 'pages/auth/Login';
import SelectTeam from 'pages/auth/SelectTeam';
import FindCoveredProducts from 'pages/dashboard/FindCoveredProducts';
import ClaimChart from 'pages/dashboard/ClaimChart';
import ClaimAnalysis from 'pages/dashboard/ClaimAnalysis';
import ClaimAnalysisCite from 'pages/dashboard/ClaimAnalysisCite';
import ClaimAnalysisVijilize from 'pages/dashboard/ClaimAnalysisVijilize';
import PageNotFound from 'pages/PageNotFound';
import TeamSetup from 'pages/auth/TeamSetup';
import MailSent from 'pages/auth/MailSent';
import ForgotPassword from 'pages/auth/ForgotPassword';
import SignUp from 'pages/auth/SignUp';
import VerifyUserScreen from 'pages/auth/VerifyUserScreen';
import ResetPassword from 'pages/auth/ResetPassword';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ContactUs from 'pages/ContactUs';
import PortfolioDetails from "pages/dashboard/PortfolioDetails";
import ClaimAnalysisHeatmap from 'pages/dashboard/ClaimAnalysisHeatmap';
import DownloadClaimchart from 'pages/dashboard/DownloadClaimchart';
import Settings from 'pages/dashboard/Settings';
import Prompts from 'pages/prompts';

// Lazy-loaded components
// const Login = lazy(() => import("./pages/auth/Login"));
// const SignUp = lazy(() => import("./pages/auth/SignUp"));
// const VerifyUserScreen = lazy(() => import("./pages/auth/VerifyUserScreen"));
// const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));

// Helper function to get logged-in user type
const getUserType = () => {
    const userData = localStorage.getItem('userData');

    try {
        if (userData && JSON.parse(userData).accessToken) {
            return 'user';
        }
    } catch (error) {
        console.error('Error parsing user data:',error);
        return null; // Fallback to null if there's a problem with parsing.
    }

    return null; // Return null if no valid user or vendor token exists.
};

// Route Configurations
const Routing = () => {
    const userType = getUserType();
    // const userType = '';


    const routes = [
        // Public routes, accessible by anyone (no auth required)
        {
            path: '/login',
            element: userType ? <Navigate to='/' /> : <Login />,
        },
        {
            path: '/signup',
            element: userType ? <Navigate to='/' /> : <SignUp />,
        },
        {
            path: '/forgotpassword',
            element: userType ? <Navigate to='/' /> : <ForgotPassword />,
        },
        {
            path: '/verifyuser',
            element: userType ? <Navigate to='/' /> : <VerifyUserScreen />,
        },
        {
            path: '/reset-password',
            element: userType ? <Navigate to='/' /> : <ResetPassword />,
        },
        {
            path: '/privacy-policy',
            element: <PrivacyPolicy />,
        },
        {
            path: '/contact-us',
            element: <ContactUs />,
        },
        

        // User routes, accessible only to users
        {
            path: '/',
            element: userType === 'user' ? <Outlet /> : <Navigate to='/login' />,
            children: [
                {
                    path: '/',
                    element: <Navigate to='/project' replace />,
                },
                { path: '/project',element: <Projects /> },
                { path: '/portfolio',element: <Portfolio /> },
                { path: '/portfolio/:id/details',element: <PortfolioDetails /> },
                { path: '/select-team',element: <SelectTeam /> },
                { path: '/team-setup',element: <TeamSetup /> },
                { path: '/mail-sent',element: <MailSent /> },
                { path: '/project/:id/find-covered-product',element: <FindCoveredProducts /> },
                { path: '/project/:projectId/claim-chart/:claimChartId',element: <ClaimChart /> },
                { path: '/project/:id/patent/:patentId/claim-analysis',element: <ClaimAnalysis /> },
                { path: '/project/:id/patent/:patentId/claim-analysis/:claimId/cite',element: <ClaimAnalysisCite /> },
                // { path: '/project/:id/patent/:patentId/claim-analysis/:claimId/vijilize',element: <ClaimAnalysisVijilize /> },
                { path: '/project/:id/vijilize/:vijilizeId',element: <ClaimAnalysisVijilize /> },
                { path: '/project/:id/vijilize/:vijilizeId/heatmap',element: <ClaimAnalysisHeatmap /> },
                { path: '/project/download-claimchart',element: <DownloadClaimchart /> },
                { path: "/settings", element: <Settings /> },
                { path: "/prompt-store", element: <Prompts /> },

            ],
        },
        // Catch-all for unknown routes
        { path: '*',element: <PageNotFound /> },
        
    ];

    return useRoutes(routes);
};

export default Routing;
