import axiosInstance from "./axios";

export const fetchPortfolioList = async (team_id: string, search: string) => {
  try {
    return await axiosInstance.get(
      `/portfolio/list?team_id=${team_id}&search=${search}`
    );
  } catch (error) {
    throw error;
  }
};

export const createPortfolio = async (data: any) => {
  try {
    return await axiosInstance.post(`/portfolio/`, data);
  } catch (error) {
    throw error;
  }
};

export const deletePortfolio = async (portfolioId: string) => {
  try {
    return await axiosInstance.delete(`/portfolio/${portfolioId}`);
  } catch (error) {
    throw error;
  }
};

export const getPortfolioById = async (portfolioId: string) => {
  try {
    return await axiosInstance.get(`/portfolio/${portfolioId}`);
  } catch (error) {
    throw error;
  }
};

export const createFilePresignedUrl = async (data: any) => {
  try {
    return await axiosInstance.post(
      `/portfolio/create-file-presigned-url`,
      data
    );
  } catch (error) {
    throw error;
  }
};

export const s3PutUrlWithProgressPortfolioFile = async (
  imageData: any,
  onProgress?: (percentage: number) => void
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // Upload progress event
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable && onProgress) {
        const percentage = Math.round((event.loaded / event.total) * 100);
        onProgress(percentage);
      }
    };

    // Load completion event
    xhr.onload = () => {
      // Create a response object similar to fetch Response
      const response = {
        ok: xhr.status >= 200 && xhr.status < 300,
        status: xhr.status,
        statusText: xhr.statusText,
        headers: xhr
          .getAllResponseHeaders()
          .split("\r\n")
          .reduce((acc: any, current) => {
            const [name, value] = current.split(": ");
            if (name) acc[name.toLowerCase()] = value;
            return acc;
          }, {}),
        body: xhr.response,
        text: () => Promise.resolve(xhr.responseText),
        json: () => Promise.resolve(JSON.parse(xhr.responseText)),
        xhr: xhr, // Include original XHR object if needed
      };

      if (response.ok) {
        resolve(response);
      } else {
        reject(new Error(`Upload failed with status: ${xhr.status}`));
      }
    };

    // Error event
    xhr.onerror = () => {
      reject(new Error("Network error occurred during upload"));
    };

    try {
      // Open and send the request
      xhr.open("PUT", imageData.url);
      xhr.setRequestHeader("Content-Type", imageData.type);
      xhr.send(imageData.file);
    } catch (error) {
      console.log("error while uploading to s3 =>", error);
      reject(error);
    }
  });
};

export const fileUploadStatus = async (payload: any) => {
  try {
    return await axiosInstance.post(`/portfolio/file-upload-status`, payload);
  } catch (error) {
    throw error;
  }
};

export const createFolder = async (portfolioId: string, data: any) => {
  try {
    return await axiosInstance.post(`/portfolio/${portfolioId}/folders`, data);
  } catch (error) {
    throw error;
  }
};

export const attachToProject = async (data: any) => {
  try {
    return await axiosInstance.post(`/portfolio/attach-to-project`, data);
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async (portfolioId: any, fileId: string) => {
  try {
    return await axiosInstance.delete(
      `/portfolio/${portfolioId}/files/${fileId}`
    );
  } catch (error) {
    throw error;
  }
};

export const deleteMultipleFiles = async (portfolioId: any, data: any) => {
  try {
    return await axiosInstance.post(
      `/portfolio/${portfolioId}/files/delete-multiple`,
      data
    );
  } catch (error) {
    throw error;
  }
};

export const getFolder = async (portfolioId: any, folderId: any) => {
  try {
    return await axiosInstance.get(
      `/portfolio/${portfolioId}/folders/${folderId}`
    );
  } catch (error) {
    throw error;
  }
};

export const deleteFolder = async (portfolioId: any, folderId: string) => {
  try {
    return await axiosInstance.delete(
      `/portfolio/${portfolioId}/folders/${folderId}`
    );
  } catch (error) {
    throw error;
  }
};

export const editFolder = async (
  portfolioId: any,
  folderId: string,
  payload: any
) => {
  try {
    return await axiosInstance.put(
      `/portfolio/${portfolioId}/folders/${folderId}`,
      payload
    );
  } catch (error) {
    throw error;
  }
};

export const getCollaboratorsForPortfolio = async (
  id: any,
  searchTerm: string
) => {
  try {
    return await axiosInstance.post(`/portfolio/${id}/collaborators`, {
      search: searchTerm,
    });
  } catch (error) {
    throw error;
  }
};

export const getInviteTeamMemberListForPortfolio = async (
  portfolioId: string,
  teamId: string
) => {
  try {
    return await axiosInstance.get(
      `/portfolio/${portfolioId}/frequent-team-members/${teamId}`
    );
  } catch (error) {
    throw error;
  }
};

export const getPortfolioInviteRoles = async () => {
  try {
    return await axiosInstance.get(`/portfolio/roles`);
  } catch (error) {
    throw error;
  }
};

export const addInviteCollaboratorForPortfolio = async (
  id: string,
  data: any
) => {
  try {
    return await axiosInstance.post(`/portfolio/${id}/invite`, data);
  } catch (error) {
    throw error;
  }
};
