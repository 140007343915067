import allImgPaths from "assets/images";
import React, { useState } from "react";

const ClaimGraphics = ({ claim, setClaimFiguresTotal = () => {} }: any) => {
    const figures = claim?.figures || [];
    const elements = claim?.elements || [];
    const [loadingStates, setLoadingStates] = useState<boolean[]>(Array(figures.length + elements.reduce((acc:any, element:any) => acc + (element.figures?.length || 0), 0)).fill(true));

    const transformLegend = (figure: string) => {
        const legendData = claim?.legends.find((legend: any) => Object.keys(legend)[0] === figure);
        
        if (legendData) {
            return legendData[figure].map((item: any) => {
                const key = Object.keys(item)[0];
                return {
                    number: key,
                    text: item[key],
                };
            });
        } 

        for (const element of elements) {
            const elementLegends = element.legends || [];
            const found = elementLegends.find((legend: any) => Object.keys(legend)[0] === figure);
            if (found) {
                return found[figure].map((item: any) => {
                    const key = Object.keys(item)[0];
                    return {
                        number: key,
                        text: item[key],
                    };
                });
            }
        }

        return [];
    };

    const handleImageLoad = (index: number) => {
        setLoadingStates((prev) => {
            const newLoadingStates = [...prev];
            newLoadingStates[index] = false;
            return newLoadingStates;
        });
    };

    // Function to deduplicate images and legends
    const getUniqueFiguresAndLegends = () => {
        const uniqueFigures = new Set();
        
        const uniqueEntries:any[] = [];

        figures.forEach((figure:any, index:any) => {
            const legendItems = transformLegend(figure);
            const imageUrl = claim?.figurePreSignedUrls[index];

            if (!uniqueFigures.has(figure)) {
                uniqueFigures.add(figure); 
                uniqueEntries.push({ figure, legendItems, imageUrl });
            }
        });

        elements.forEach((element:any) => {
            const elementFigures = element.figures || [];
            elementFigures.forEach((figure:any, figureIndex:any) => {
                const legendItems = transformLegend(figure);
                const imageUrl = element.figurePreSignedUrls?.[figureIndex];

                if (!uniqueFigures.has(figure)) {
                    uniqueFigures.add(figure); 
                    uniqueEntries.push({ figure, legendItems, imageUrl });
                }
            });
        });

        return uniqueEntries;
    };

    if (figures.length === 0 && elements.length === 0) {
        return null;
    }

    // Get unique figures and their corresponding legends
    const uniqueEntries = getUniqueFiguresAndLegends();

    setClaimFiguresTotal(uniqueEntries.length);

    return (
        <div className="mb-5 space-y-8">
            {/* Loop through unique entries to display their images and legends */}
            {uniqueEntries.map(({ figure, legendItems, imageUrl }, entryIndex) => (
                <div key={figure} className="w-full">
                    <div className="flex gap-x-8">
                        {/* Left side - Image */}
                        <div className="w-1/2">
                            <div className="flex justify-center">
                                {loadingStates[entryIndex] && (
                                    <div className="w-[348px] border rounded-lg p-4 animate-pulse">
                                        <div className="space-y-4">
                                            <div className="h-56 bg-gray-200 rounded"></div>
                                        </div>
                                    </div>
                                )}
                                <img
                                    src={imageUrl}
                                    onLoad={() => handleImageLoad(entryIndex)}
                                    onError={(e) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = allImgPaths.claimFigureNotFound; // Fallback image
                                    }}
                                    alt={`Patent figure ${figure}`}
                                    className={`w-full max-w-sm object-contain ${loadingStates[entryIndex] ? "hidden" : "block"}`}
                                />
                            </div>
                        </div>

                        {/* Right side - Legend */}
                        <div className="w-1/2">
                            <ul className="space-y-2">
                                {legendItems.map((item: any) => (
                                    <li key={item.number} className="text-sm text-gray-900 font-interRegular">
                                        {item.number}. {item.text}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    {/* Figure caption below */}
                    <div className="mt-4 bg-[#F9FAFB] py-2 px-3 text-center w-11/12 mx-auto">
                        <span className="text-sm text-[#272E35] font-interMedium">
                            Fig. {figure.replace("FIG_", "")}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ClaimGraphics;