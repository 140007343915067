/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Sidebar from "components/common/Sidebar";
import Header from "components/common/Header";
import allImgPaths from "assets/images";

import ClaimTable from "./ClaimTable";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddSource from "components/common/modals/AddSource";
import AddComparisonData from "components/common/modals/AddComparisonData";
import SearchBar from "components/common/SearchBar";

import { useLocation } from "react-router-dom";
import {
  deletePatentById,
  deleteProject,
  deleteUrlById,
  getClaimChartByProjectId,
  getPatentsStatusByProjectId,
  getProjectDetails,
  getVijilization,
  startVijilize,
  updatePatentStatus,
} from "services/project";
import Toast from "components/common/Toast";
import {
  ProjectDetailShimmerLoader,
  VijilizeShimmerLoader,
} from "pages/loaders";
import VijilizeModal from "components/common/modals/VijiliseModal";
import useAppState from "context/useAppState";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import AddFileWhite from "components/common/AddFileWhite";
import AddFileBlue from "components/common/AddFileBlue";
import DeleteModal from "components/common/modals/DeleteModal";
import EditProject from "components/common/modals/EditProject";
import { formatDate } from "utils/function";
import { DELETE_MODAL_DATA, PATENT_FILE_CONSTANTS } from "utils/constant";

function FindCoveredProducts() {
  var toggle = 1;
  var comparisonCount = 0;
  var claimCount = 0;

  const location = useLocation();
  const { id: projectId } = useParams();
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState("");
  const [searchVijilizations, setSearchVijilizations] = useState("");
  const [searchVijilCharts, setSearchVijilCharts] = useState("");

  const [sortOrder, setSortOrder] = useState("asc");
  const [arrowUp, setArrowUp] = useState(false);
  const [columnCount, setColumnCount] = useState({
    patents: 0,
    files: 0,
    urls: 0,
    publications: 0,
  });
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
  const [isShowEdit, setIsShowEdit] = useState<boolean>(false);
  const columns = [
    { name: "Patents", count: columnCount.patents },
    { name: "Files", count: columnCount.files },
    { name: "URLs", count: columnCount.urls },
    // { name: 'Publications',count: columnCount.publications },
  ];

  // Patent table

  const [sourcePatents, setSourcePatents] = useState<any[]>([]);
  const [comparePatents, setComparePatents] = useState<any[]>([]);

  const [vijilizedData, setVijilizedData] = useState<any[]>([]);
  const [claimChartData, setClaimChartData] = useState<any[]>([]);

  const [isLoader, setIsLoader] = useState<boolean>(true);
  const [compareFiles, setCompareFiles] = useState<any[]>([]);
  const [compareURLs, setCompareURLs] = useState<any[]>([]);
  const [srcRefData, setSrcRefData] = useState<any>({});
  // const [comparePublications,setComparePublications] = useState<any[]>([]);
  const [refreshData, setRefreshData] = useState(false);
  const {
    setVijilizeParamsData,
    isActiveSection,
    setIsActiveSection,
    setHeaderDataLocal,
  } = useAppState("vijilize");
  const [isShowSideBar, setIsShowSideBar] = useState(false);
  const [toggledDataUsed, setToggledDataUsed] = useState<any>(0);
  // Patent table
  const patentData: any[] = [];
  let patentColumns: any[] = [
    {
      name: "PatentId",
      ref: "file_name",
      hide: false,
      position: 1,
    },
  ];

  // claim charts

  const [isActiveColumn, setIsActiveColumn] = useState<number>(0);
  const [showMenu, setSowMenu] = useState(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedCompareFiles, setSelectedCompareFiles] = useState<any[]>([]);
  const [selectedComparePatents, setSelectedComparePatents] = useState<any[]>(
    []
  );
  const [selectedCompareUrls, setSelectedCompareUrls] = useState<any[]>([]);

  const [selectAll, setSelectAll] = useState(false);
  const [dataLength, setDataLength] = useState(1);
  const [projectDetail, setProjectDetail] = useState<any>();
  // set state for selected source
  const [selectedSource, setSelectedSource] = useState<any[]>([]);

  const [vijilizeModalStatus, setVijilizeModalStatus] =
    useState<boolean>(false);
  const [vijilizationsCount, setVijilizationsCount] = useState(0);
  const [isShowDropdown, setisShowDropdown] = useState<any>(0);
  const [isShowAddDropdown, setisShowAddDropdown] = useState<any>(false);
  const [isShowAddBlueDropdown, setisShowAddBlueDropdown] =
    useState<any>(false);

  const [vijilizeLoader, setVijilizeLoader] = useState<boolean>(false);
  const [isClaimTableLoading, setIsClaimTableLoading] =
    useState<boolean>(false);
  const [tabCount, setTabCount] = useState({
    comparisonLib: 0,
    vijilizations: 0,
    claimCharts: 0,
  });
  const [selectAllSource, setSelectAllSource] = useState(false);
  const [selectAllComparePatents, setSelectAllComparePatents] = useState(false);
  const [selectAllCompareFiles, setSelectAllCompareFiles] = useState(false);

  const [selectAllCompareUrls, setSelectAllCompareUrls] = useState(false);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setisShowDropdown(false));

  const addDropdownSrcRef = useRef(null);
  const addDropdownCmpRef = useRef(null);
  // useOnClickOutside(addDropdownRef,() => setisShowAddDropdown(false));

  const addDropdownBlueRef = useRef(null);
  useOnClickOutside(addDropdownBlueRef, () => setisShowAddBlueDropdown(false));

  const navigate = useNavigate();

  // const [sourceSearch, setSourceSearch] = useState();
  // const [patentsSearch, setPatentsSearch] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openComparisonFileModal, setOpenComparisonFileModal] = useState(false);
  const [openComparisonPatentModal, setOpenComparisonPatentModal] =
    useState(false);
  const [openComparisonUrlModal, setOpenComparisonUrlModal] = useState(false);

  const [paginatedData, setPaginatedData] = useState<any[]>([]);
  const [toast, setToast] = useState({
    show: false,
    heading: "",
    message: "",
  });

  const initialSortState: { [tab: string]: { [key: string]: string } } = {
    compareWithPatents: {
      file_name: "asc",
      uploaded_at: "asc",
    },
    compareWithFiles: {
      file_name: "asc",
      uploaded_at: "asc",
    },
    compareWithUrls: {
      url: "asc",
      updatedAt: "asc",
    },
    vijilizations: {
      name: "asc",
      updatedAt: "asc",
      status: "asc",
    },
    vijilChart: {
      name: "asc",
      createdAt: "asc",
      updatedAt: "asc",
    },
  };

  const [sortConfig, setSortConfig] = useState(initialSortState);
  const [isVijilizationPolling, setIsVijilizationPolling] =
    useState<boolean>(false);
  const [isSourcePatentPolling, setIsSourcePatentPolling] = useState(false);
  const [isComparePatentPolling, setIsComparePatentPolling] = useState(false);
  const sourcePatentIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const comparePatentIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const totalItems = patentData.length;
  const itemsPerPage = 5;

  // const { currentPage,totalPages,startItem,endItem,nextPage,prevPage,currentData } = usePagination({
  //     totalItems: dataLength,
  //     itemsPerPage,
  //     initialPage: 1,
  // });

  const resetSelectionStates = () => {
    setSelectedItems([]);
    setSelectedSource([]);
    setSelectedCompareFiles([]);
    setSelectedComparePatents([]);
    setSelectedCompareUrls([]);
    setSelectAll(false);
    setSelectAllComparePatents(false);
    setSelectAllCompareFiles(false);
    setSelectAllCompareUrls(false);
    setSelectAllSource(false);
  };

  const updateTabCount = (detailResponse: any) => {
    setTabCount((prevState) => ({
      ...prevState,
      vijilizations: detailResponse?.vijilizations,
      claimCharts: detailResponse?.claim_charts,
    }));
  };

  const updateGrandTotal = async (compareData: any) => {
    const grandTotal = Object.values(compareData).reduce(
      (sum: number, items: any) => sum + items.length,
      0
    );
    setTabCount((prevState) => ({
      ...prevState,
      comparisonLib: grandTotal,
    }));
  };

  const callVijilizationList = () => {
    setVijilizeLoader(true);
    (async () => {
      try {
        const data: any = await getVijilization(
          projectId || "0",
          searchVijilizations
        );
        setVijilizedData([...data.data]);
        setVijilizeLoader(false);
      } catch (error) {
        throw error;
      }
    })();
  };
  const callClaimChartList = () => {
    setIsClaimTableLoading(true);
    (async () => {
      try {
        const data: any = await getClaimChartByProjectId(
          projectId || "0",
          searchVijilCharts
        );
        setClaimChartData([...data.data]);
        setIsClaimTableLoading(false);
      } catch (error) {
        throw error;
      }
    })();
  };

  const fetchProjectDetails = async () => {
    try {
      setIsLoader(true);
      setDataLength(patentData.length);
      resetSelectionStates();

      const detailResponse: any = await getProjectDetails(
        projectId || "0",
        search
      );

      updateTabCount(detailResponse);
      setProjectDetail({
        title: detailResponse.name,
        description: detailResponse.description,
        id: detailResponse._id || projectId,
      });

      setHeaderDataLocal({
        projectDetail: {
          title: detailResponse.name,
          description: detailResponse.description,
          id: detailResponse._id || projectId,
        },
        claimAnalysis: { title: "", id: "" },
        vijilize: { title: "", id: "" },
        claimChart: { title: "", id: "" },
        portfolioDetail: { title: "", description: "", id: "" },
      });

      setSourcePatents([
        ...detailResponse.source.external,
        ...detailResponse.source.patent,
      ]);
      setCompareFiles([...detailResponse.compare.external]);
      setComparePatents([...detailResponse.compare.patent]);

      setColumnCount({
        ...columnCount,
        patents: detailResponse.compare.patent?.length || 0,
        files: detailResponse.compare.external?.length || 0,
        urls: detailResponse.compare.url?.length || 0,
      });
      setCompareURLs([...detailResponse.compare.url]);

      await updateGrandTotal(detailResponse.compare);
      setIsLoader(false);
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  useEffect(() => {
    fetchProjectDetails();
    callVijilizationList();
    callClaimChartList();
  }, [refreshData]);

  useEffect(() => {
    if (isActiveSection === 0) {
      setSearchVijilizations("");
      setSearchVijilCharts("");
      fetchProjectDetails();
    } else if (isActiveSection === 1) {
      setSearch("");
      setSearchVijilCharts("");
      callVijilizationList();
    } else if (isActiveSection === 2) {
      setSearch("");
      setSearchVijilizations("");
      callClaimChartList();
    }
  }, [isActiveSection, search, searchVijilizations, searchVijilCharts]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("tab") === "vijilizations") {
      setIsActiveSection(1);
      callVijilizationList();
    }
  }, [location]);

  useEffect(() => {
    if (!isVijilizationPolling && vijilizedData.length > 0) {
      startVijilizationPolling(vijilizedData);
    }
  }, [vijilizedData]);

  useEffect(() => {
    if (isActiveSection !== 0) {
      stopSourcePatentPolling();
      stopComparePatentPolling();
    } else {
      if (
        !isSourcePatentPolling &&
        sourcePatents.some((patent) => patent.patent_status === PATENT_FILE_CONSTANTS.IN_PROGRESS)
      ) {
        startSourcePatentPolling(sourcePatents);
      }
      if (
        !isComparePatentPolling &&
        comparePatents.some((patent) => patent.patent_status === PATENT_FILE_CONSTANTS.IN_PROGRESS)
      ) {
        startComparePatentPolling(comparePatents);
      }
    }
  }, [sourcePatents, comparePatents, isActiveSection]);

  const checkPendingSourcePatents = async () => {
    try {
      if (!sourcePatentIntervalRef.current || isActiveSection !== 0) return;

      const newData: any = await getPatentsStatusByProjectId(projectId);

      const hasPending = newData.source.external
        .concat(newData.source.patent)
        .some(
          (item: any) =>
            item.patent_status === PATENT_FILE_CONSTANTS.IN_PROGRESS
        );

      setSourcePatents((prev) => {
        const hasChanges = newData.source.external
          .concat(newData.source.patent)
          .some((newItem: any) => {
            const oldItem = prev.find(
              (old) => old.patent_id === newItem.patent_id
            );
            return !oldItem || oldItem.patent_status !== newItem.patent_status;
          });
        return hasChanges
          ? [...newData.source.external, ...newData.source.patent]
          : prev;
      });

      if (!hasPending) {
        console.log("🛑 Stopping polling - no pending source patents");
        stopSourcePatentPolling();
      }
    } catch (error) {
      console.error("🔴 Polling error:", error);
      stopSourcePatentPolling();
    }
  };

  const startSourcePatentPolling = (data: any) => {
    if (sourcePatentIntervalRef.current) {
      clearInterval(sourcePatentIntervalRef.current);
      sourcePatentIntervalRef.current = null;
    }

    const hasPending = data.some(
      (item: any) => item.patent_status === PATENT_FILE_CONSTANTS.IN_PROGRESS
    );

    if (isActiveSection === 0 && hasPending) {
      console.log("🟢 Starting source patent polling");
      setIsSourcePatentPolling(true);
      sourcePatentIntervalRef.current = setInterval(
        checkPendingSourcePatents,
        5000
      );
      checkPendingSourcePatents();
    }
  };

  const stopSourcePatentPolling = () => {
    if (sourcePatentIntervalRef.current) {
      clearInterval(sourcePatentIntervalRef.current);
      sourcePatentIntervalRef.current = null;
    }
    setIsSourcePatentPolling(false);
  };

  const checkPendingComparePatents = async () => {
    try {
      if (!comparePatentIntervalRef.current || isActiveSection !== 0) return;

      const newData: any = await getPatentsStatusByProjectId(projectId);

      const hasPending = newData.compare.patent.some(
        (item: any) => item.patent_status === PATENT_FILE_CONSTANTS.IN_PROGRESS
      );

      setComparePatents((prev) => {
        const hasChanges = newData.compare.patent.some((newItem: any) => {
          const oldItem = prev.find(
            (old) => old.patent_id === newItem.patent_id
          );
          return !oldItem || oldItem.patent_status !== newItem.patent_status;
        });
        return hasChanges ? [...newData.compare.patent] : prev;
      });

      if (!hasPending) {
        console.log("🛑 Stopping polling - no pending compare patents");
        stopComparePatentPolling();
      }
    } catch (error) {
      console.error("🔴 Polling error:", error);
      stopComparePatentPolling();
    }
  };

  const startComparePatentPolling = (data: any) => {
    if (comparePatentIntervalRef.current) {
      clearInterval(comparePatentIntervalRef.current);
      comparePatentIntervalRef.current = null;
    }

    const hasPending = data.some(
      (item: any) => item.patent_status === PATENT_FILE_CONSTANTS.IN_PROGRESS
    );

    if (isActiveSection === 0 && hasPending) {
      console.log("🟢 Starting compare patent polling");
      setIsComparePatentPolling(true);
      comparePatentIntervalRef.current = setInterval(
        checkPendingComparePatents,
        5000
      );
      checkPendingComparePatents();
    }
  };

  const stopComparePatentPolling = () => {
    if (comparePatentIntervalRef.current) {
      clearInterval(comparePatentIntervalRef.current);
      comparePatentIntervalRef.current = null;
    }
    setIsComparePatentPolling(false);
  };

  const checkPendingItems = async () => {
    try {
      if (!intervalRef.current || isActiveSection !== 1) return;

      const newData: any = await getVijilization(
        projectId || "0",
        searchVijilizations
      );

      const hasPending = newData.data.some(
        (item: any) => item.status === PATENT_FILE_CONSTANTS.IN_PROGRESS
      );

      setVijilizedData((prev) => {
        const hasChanges = newData.data.some((newItem: any) => {
          const oldItem = prev.find((old) => old.id === newItem.id);
          return !oldItem || oldItem.status !== newItem.status;
        });
        return hasChanges ? [...newData.data] : prev;
      });

      if (!hasPending) {
        console.log("🛑 Stopping polling - no pending items");
        stopPolling();
      }
    } catch (error) {
      console.error("🔴 Polling error:", error);
      stopPolling();
    }
  };

  const startVijilizationPolling = (data: any) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    const hasPending = data?.some(
      (item: any) => item.status === PATENT_FILE_CONSTANTS.IN_PROGRESS
    );

    if (isActiveSection === 1 && hasPending) {
      console.log("🟢 Starting polling");
      setIsVijilizationPolling(true);
      intervalRef.current = setInterval(checkPendingItems, 5000);
      checkPendingItems();
    }
  };

  const stopPolling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    setIsVijilizationPolling(false);
  };

  useEffect(() => {
    return () => {
      stopPolling()
      stopSourcePatentPolling();
      stopComparePatentPolling();
    };
  }, []);

  const handleSelectItem = useCallback(
    (index: number) => {
      setSelectedItems((prev) => {
        const newSelected = prev.includes(index)
          ? prev.filter((i) => i !== index)
          : [...prev, index];

        // Update selectAll state based on if all items in current page are selected
        setSelectAll(newSelected.length === paginatedData.length);

        return newSelected;
      });
    },
    [paginatedData]
  );

  // Select All Handler for Source
  const handleSelectAllSource = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectAllSource(e.target.checked);
      if (e.target.checked) {
        const allSourceIds = sourcePatents.map((source) => source.patent_id);
        setSelectedSource(allSourceIds);
      } else {
        setSelectedSource([]);
      }
    },
    [sourcePatents]
  );

  // Individual Source Select Handler
  const handleSourceSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    const isChecked = event.target.checked;
    setSelectedSource((prev) => {
      const updatedSelection = isChecked
        ? [...prev, data.patent_id]
        : prev.filter((item) => item !== data.patent_id);

      setSelectAllSource(updatedSelection.length === sourcePatents.length);

      return updatedSelection;
    });
  };

  // Select All Handler for Compare Patents
  const handleSelectAllComparePatents = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectAllComparePatents(e.target.checked);
      if (e.target.checked) {
        const allComparisonIds = comparePatents.map(
          (compare) => compare.patent_id
        );
        setSelectedComparePatents(allComparisonIds);
      } else {
        setSelectedComparePatents([]);
      }
    },
    [comparePatents]
  );

  // Select All Handler for Compare Files
  const handleSelectAllCompareFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectAllCompareFiles(e.target.checked);
      if (e.target.checked) {
        const allComparisonIds = compareFiles.map(
          (compare) => compare.patent_id
        );
        setSelectedCompareFiles(allComparisonIds);
      } else {
        setSelectedCompareFiles([]);
      }
    },
    [compareFiles]
  );

  // Select All Handler for Compare Urls
  const handleSelectAllCompareUrls = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectAllCompareUrls(e.target.checked);
      if (e.target.checked) {
        const allComparisonIds = compareURLs.map((compare) => compare._id);
        setSelectedCompareUrls(allComparisonIds);
      } else {
        setSelectedCompareUrls([]);
      }
    },
    [compareURLs]
  );

  // Individual Compare patent Select Handler
  const handleSelectedComparePatents = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    const isChecked = event.target.checked;
    setSelectedComparePatents((prev) => {
      const updatedSelection = isChecked
        ? [...prev, data.patent_id]
        : prev.filter((item) => item !== data.patent_id);

      setSelectAllComparePatents(
        updatedSelection.length === comparePatents.length
      );

      return updatedSelection;
    });
  };

  // Individual Compare file Select Handler
  const handleSelectedCompareFiles = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    const isChecked = event.target.checked;
    setSelectedCompareFiles((prev) => {
      const updatedSelection = isChecked
        ? [...prev, data.patent_id]
        : prev.filter((item) => item !== data.patent_id);

      setSelectAllCompareFiles(updatedSelection.length === compareFiles.length);

      return updatedSelection;
    });
  };

  // Individual Compare url Select Handler
  const handleSelectedCompareUrls = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    const isChecked = event.target.checked;
    setSelectedCompareUrls((prev) => {
      const updatedSelection = isChecked
        ? [...prev, data._id]
        : prev.filter((item) => item !== data._id);

      setSelectAllCompareUrls(updatedSelection.length === compareURLs.length);

      return updatedSelection;
    });
  };

  const selectColumn = async (index: number) => {
    setIsActiveColumn(index);
  };

  const handleMenuClick = () => {
    setSowMenu(!showMenu);
  };
  const createColumnClassName = (field: string) => {
    if (field == "file_name") {
      return "table-col w-full flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0";
    }
    // if (field == 'patentTitle') {
    //     return 'table-col w-[50%]  flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
    // }
    // if (field == 'publication_date') {
    //     return 'table-col w-[20%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
    // }
    // if (field == 'action') {
    //     return 'table-col w-[10%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
    // }
  };

  const handleSort = (key: string, tab: string) => {
    setSortConfig((prevSortConfig) => {
      const currentDirection = prevSortConfig[tab]?.[key] || "asc";
      const newDirection = currentDirection === "asc" ? "desc" : "asc";

      let data: any[] = [];
      if (tab === "vijilizations") {
        data = vijilizedData;
      } else if (tab === "vijilChart") {
        data = claimChartData;
      } else if (tab === "compareWithPatents") {
        data = comparePatents;
      } else if (tab === "compareWithFiles") {
        data = compareFiles;
      } else if (tab === "compareWithUrls") {
        data = compareURLs;
      }

      data.sort((a: any, b: any) => {
        if (["name", "status", "file_name", "url"].includes(key)) {
          return newDirection === "asc"
            ? a[key].localeCompare(b[key])
            : b[key].localeCompare(a[key]);
        } else if (["updatedAt", "createdAt", "uploaded_at"].includes(key)) {
          return newDirection === "asc"
            ? new Date(a[key]).getTime() - new Date(b[key]).getTime()
            : new Date(b[key]).getTime() - new Date(a[key]).getTime();
        }
        return 0;
      });

      return {
        ...prevSortConfig,
        [tab]: {
          ...prevSortConfig[tab],
          [key]: newDirection,
        },
      };
    });
  };

  const handlePatentClick = (patentObj: any) => {
    navigate(
      `/project/${projectId}/patent/${patentObj.patent_id}/claim-analysis`
    );
  };
  const transformPatentDataArray = (
    inputDataArray: any[],
    additionalData: any
  ): any[] => {
    try {
      // Map through the array of input data and transform each object
      const transformedArray = inputDataArray.map((inputData) => {
        // Extract the necessary parts from each input data object
        const { patent_id, file_id, s3_file_path, key } = inputData;
        const { team_id, project_id } = additionalData;

        // Construct the transformed object
        return {
          team_id,
          project_id,
          file_id,
          patent_id,
          key,
          s3_file_path,
        };
      });

      return transformedArray;
    } catch (error) {
      console.error("Error transforming patent data array:", error);
      throw new Error("Failed to transform patent data array");
    }
  };

  const startVijilizeProcess = async () => {
    let selectedTeam: any = JSON.parse(
      localStorage.getItem("selectedTeam") as string
    );

    let filteredSourcePatents = sourcePatents.filter((item: any) =>
      selectedSource.includes(item.patent_id)
    );
    let filteredComparePatents = comparePatents.filter((item: any) =>
      selectedComparePatents.includes(item.patent_id)
    );

    let filteredCompareFiles = compareFiles.filter((item: any) =>
      selectedCompareFiles.includes(item.patent_id)
    );

    let filteredCompareUrls = compareURLs.filter((item: any) =>
      selectedCompareUrls.includes(item._id)
    );

    let sourcePayload = [...filteredSourcePatents];
    let comparePayload = [
      ...filteredComparePatents,
      ...filteredCompareFiles,
      ...filteredCompareUrls,
    ];

    let sourcevijilizeArr: any[];
    let compareVijilizeObj: any;
    let payload: any;

    let team_id: string = selectedTeam.currentTeamId;
    const project_id = projectId;
    if (comparePayload?.length) {
      setVijilizeModalStatus(true);
    } else {
      sourcevijilizeArr = transformPatentDataArray([...sourcePayload], {
        team_id,
        project_id,
      });
      payload = {
        type: "analyzer", // if both source and compare then 'comparer', if compare is empty 'analyzer'
        data: sourcevijilizeArr,
      };
      try {
        let response = await startVijilize(payload);
        Toast.success("Success", "Vijilization started");
        setRefreshData((prev) => !prev);
      } catch (error) {
        throw error;
      }
    }
  };

  const checkBoxdata = [
    {
      title: "US4211445",
      value: "US4211445",
      date: "1980-07-08",
      content: "Soap bar holder",
      selected: false,
    },
    {
      title: "US5396399",
      value: "US4211445",
      date: "1995-03-07",
      content:
        "Multi-purpose handle/prop for pen-based computer having overhanging front surface",
      selected: false,
    },
    {
      title: "US4211445",
      value: "US6016248A",
      date: "1998-02-18",
      content: "A collapsible sound conduit for mobile ",
      selected: false,
    },
  ];

  const setVijilizeData = (data: any) => {
    const patent_ids = data?.source.map((item: any) => item.patent_id);
    const compare_id = data?._id;
    setVijilizeParamsData({ patent_ids, compare_id });
  };

  const startIndivijualAnalyzation = async (data: any) => {
    let selectedTeam: any = JSON.parse(
      localStorage.getItem("selectedTeam") as string
    );
    let team_id: string = selectedTeam.currentTeamId;
    const project_id = projectId;
    const payload = {
      type: "analyzer", // if both source and compare then 'comparer', if compare is empty 'analyzer'
      data: transformPatentDataArray([{ ...data }], { team_id, project_id }),
    };
    try {
      let response = await startVijilize(payload);
      Toast.success("Success", "Analysis started");
      setRefreshData((prev) => !prev);
    } catch (error) {
      throw error;
    }
  };

  const deletePatent = async (id: string) => {
    try {
      await deletePatentById(id);
      let successMessage: any;
      if (isActiveColumn === 0) {
        successMessage = `Patent deleted successfully`;
      } else if (isActiveColumn === 1) {
        successMessage = `File deleted successfully`;
      }
      setRefreshData((prev) => !prev);
      Toast.success("Success", successMessage);
    } catch (error) {
      console.log("Error while deleting patent:", error);
      Toast.error(
        "Error",
        `Error while deleting ${
          isActiveColumn === 0 ? "patent" : "file"
        } please try again later`
      );
    }
  };

  const deleteUrl = async (id: string) => {
    try {
      let res: any = await deleteUrlById(id);
      Toast.success("Success", res.message);
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.log("Error while deleting url:", error);
      Toast.error("Error", "Error while deleting url please try again later");
    }
  };

  const handleSearchChange = (value: any) => {
    isActiveSection === 0 && setSearch(value);
    isActiveSection === 1 && setSearchVijilizations(value);
    isActiveSection === 2 && setSearchVijilCharts(value);
  };

  const handleDeleteProject = async () => {
    try {
      await deleteProject(projectId);
      Toast.success("Success", "File deleted successfully");
      setRefreshData((prev) => !prev);
      navigate("/");
    } catch (error) {
      console.log("Error while deleting project:", error);
      Toast.error(
        "Error",
        "Error while deleting project please try again later"
      );
    }
  };

  const handleUpdatePatentStatus = async (patentId: string) => {
    try {
      await updatePatentStatus(patentId);
      Toast.success("Success", "Patent status updated successfully");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.log("Error while updating patent status:", error);
      Toast.error(
        "Error",
        "Error while updating patent status please try again later"
      );
    }
  };

  return (
    <div className="dashboard-wrapper flex">
      {/* Sidebar */}
      <Sidebar />

      {/* dashboard main */}
      <div className="w-[calc(100%_-_72px)] ml-auto">
        {/* Hedaer */}
        <Header
          title="Project Details"
          count={0}
          isChildEle={true}
          type="project"
        />
        <div className="bg-[#f9fafb]">
          {/* Page Header Part */}

          <div className="flex justify-between py-4 px-4">
            <div className="flex items-start gap-x-2 pl-1">
              <img
                className="inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1"
                src={allImgPaths.goBack}
                alt="return"
                onClick={() => {
                  navigate(-1);
                  setIsActiveSection(0);
                }}
              />
              <div className="">
                <h2 className="text-[#0A090B] text-xl font-interSemiBold">
                  {projectDetail?.title}
                </h2>
                <p
                  title={projectDetail?.description}
                  className="text-sm text-[#7F7D83] font-interRegular truncate max-w-[calc(100vw_-_1100px)]"
                >
                  {projectDetail?.description}
                </p>
              </div>
            </div>

            <div className="flex items-center gap-x-4 h-12">
              {isActiveSection == 0 && (
                // TODO: add condition of compare patent afterwards
                <button
                  onClick={() => startVijilizeProcess()}
                  disabled={
                    !(
                      selectedSource.length > 0 &&
                      (selectedComparePatents.length > 0 ||
                        selectedCompareFiles.length > 0 ||
                        selectedCompareUrls.length > 0)
                    )
                  }
                  className={`!text-sm text-white outline-none flex items-center gap-1.5 font-interMedium rounded-lg  ${
                    !(
                      selectedSource.length > 0 &&
                      (selectedComparePatents.length > 0 ||
                        selectedCompareFiles.length > 0 ||
                        selectedCompareUrls.length > 0)
                    )
                      ? "button-disabled bg-[#1751D0] px-3.5 py-2.5"
                      : ""
                  }`}
                >
                  {!(
                    selectedSource.length > 0 &&
                    (selectedComparePatents.length > 0 ||
                      selectedCompareFiles.length > 0 ||
                      selectedCompareUrls.length > 0)
                  ) ? (
                    <>
                      <img
                        className="outline-none"
                        src={allImgPaths.disabledVijiliseIcon}
                        alt=""
                      />{" "}
                      {"Vijilize"}
                    </>
                  ) : (
                    <img
                      className="cursor-pointer outline-none"
                      onClick={() => startVijilizeProcess()}
                      src={allImgPaths.enabledVijiliseIcon2}
                      alt=""
                    />
                  )}
                </button>
              )}

              {isActiveSection === 0 && (
                <SearchBar
                  img_url={allImgPaths.searchIcon}
                  value={search}
                  onChange={handleSearchChange}
                  width={"w-64"}
                  placeholder={"Search"}
                />
              )}

              {isActiveSection === 1 && (
                <SearchBar
                  img_url={allImgPaths.searchIcon}
                  value={searchVijilizations}
                  onChange={handleSearchChange}
                  width={"w-64"}
                  placeholder={"Search"}
                />
              )}

              {isActiveSection === 2 && (
                <SearchBar
                  img_url={allImgPaths.searchIcon}
                  value={searchVijilCharts}
                  onChange={handleSearchChange}
                  width={"w-64"}
                  placeholder={"Search"}
                />
              )}

              <ul className="flex items-center">
                <li
                  onClick={() => setIsActiveSection(0)}
                  className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] rounded-tl-lg rounded-bl-lg flex items-center gap-x-2 cursor-pointer  ${
                    isActiveSection === 0
                      ? "bg-[#EBF0FB] pointer-none text-[#113B98]"
                      : "bg-[#fff] text-[#4F4D55]"
                  }`}
                >
                  <span className="text-sm font-interMedium">
                    Comparison Library ({tabCount.comparisonLib})
                  </span>
                </li>

                <li
                  onClick={() => {
                    setIsActiveSection(1);
                    callVijilizationList();
                  }}
                  className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] flex items-center gap-x-2 cursor-pointer  ${
                    isActiveSection === 1
                      ? "bg-[#EBF0FB] pointer-none text-[#113B98]"
                      : "bg-[#fff] text-[#4F4D55]"
                  }`}
                >
                  <span className="text-sm font-interMedium">
                    Vijilizations ({tabCount.vijilizations || 0})
                  </span>
                </li>

                <li
                  onClick={() => {
                    setIsActiveSection(2);
                    callClaimChartList();
                  }}
                  className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] flex items-center gap-x-2 cursor-pointer rounded-tr-lg rounded-br-lg ${
                    isActiveSection === 2
                      ? "bg-[#EBF0FB] pointer-none text-[#113B98]"
                      : "bg-[#fff] text-[#4F4D55]"
                  }`}
                >
                  <span className="text-sm font-interMedium">
                    Vijil Charts ({tabCount.claimCharts})
                  </span>
                </li>
              </ul>
              {/* Dropdown 1 */}
              <div ref={dropdownRef} className="relative">
                <img
                  onClick={() => setisShowDropdown(!isShowDropdown)}
                  className="cursor-pointer on-hover-bg-grey w-6 h-6 p-1 rounded"
                  src={allImgPaths.threeVerticleDotsIcon}
                  alt=""
                />
                <div
                  className={`absolute bg-white w-36 top-0 right-full shadow-customShadow14 rounded-lg mt-8 -mr-5 z-[100] ${
                    isShowDropdown ? "block" : "hidden"
                  }`}
                >
                  <ul>
                    <li
                      className="flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer"
                      onClick={() => setIsShowEdit(true)}
                    >
                      <img
                        className="w-[18px]"
                        src={allImgPaths.EditIcon2}
                        alt=""
                      />
                      <span className="text-sm font-interMedium text-[#0A090B]">
                        Edit Project
                      </span>
                    </li>
                    <li
                      className="flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer"
                      onClick={() => setIsShowDelete(true)}
                    >
                      <img src={allImgPaths.deleteicon2} alt="" />
                      <span className="text-sm font-interMedium text-[#C53434]">
                        Delete Project
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Comparison Library */}
          {isActiveSection == 0 && (
            <div className="">
              {" "}
              {/*flex gap-x-5 px-6 h-[calc(100vh_-_170px)] */}
              {isLoader ? <ProjectDetailShimmerLoader /> : <></>}
              {!isLoader ? (
                <div className="flex px-4 h-[calc(100vh_-_149px)] pb-4">
                  <div className=" bg-white border-r border-[#E6E6E6] w-[332px] flex-shrink-0 rounded-tl-lg rounded-bl-lg">
                    <div className="flex items-center justify-between py-2.5 px-5 border-b border-[#E6E6E6]">
                      <div className="flex items-center gap-x-2">
                        {sourcePatents.length > 0 && (
                          <div className="relative leading-none">
                            <input
                              type="checkbox"
                              id={"selectAllSource"}
                              name="checkbox-group"
                              checked={selectAllSource}
                              onChange={handleSelectAllSource}
                              className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                    checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                    transition-colors cursor-pointer"
                            />
                            <svg
                              className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                              viewBox="0 0 16 16"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <polyline points="4 8 7 11 12 5"></polyline>
                            </svg>
                          </div>
                        )}

                        <h2 className="text-sm font-interMedium text-black">
                          Source
                        </h2>
                      </div>
                      {/* <img className='inline-block cursor-pointer on-hover-bg-grey w-7 h-7 p-1 rounded '   src={allImgPaths.circleBlack} alt='' /> */}

                      {/* <div ref={addDropdownRef} className="relative">
                                            <div onClick={() => setisShowAddDropdown(!isShowAddDropdown)} className={`flex items-center gap-x-2 border rounded-md px-2 py-2 cursor-pointer hover:border-[#1751D0] ${isShowAddDropdown ? "border-[#1751D0]" : "border-[#E6E6E6]"}`}>
                                                <img src={allImgPaths.plusIcon2} alt="" />

                                                <span className='text-sm font-interMedium text-[#4F4D55]'>Add</span>
                                                <img className={`-ml-1 transform ${isShowAddDropdown ? "rotate-180" : "rotate-0"}`} src={allImgPaths.downIcon3} alt="" />
                                            </div>
                                            <div className={`absolute bg-white w-36 top-full left-1/2 -translate-x-1/2 shadow-customShadow14 rounded-lg mt-1 z-20 block ${isShowAddDropdown ? "block" : "hidden"}`}>
                                                <ul>
                                                    <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>With patent #</li>
                                                    <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>From Portfolio</li>
                                                    <li onClick={() => setOpenModal(true)} className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>External Upload</li>
                                                    <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>Paste URLs</li>
                                                </ul>
                                            </div>
                                        </div> */}
                      <AddFileWhite
                        addDropdownRef={addDropdownSrcRef}
                        setOpenFileModal={(flag: any) => {
                          setOpenModal(true);
                        }}
                        type="source"
                      />
                    </div>

                    {/* No Source Patent  */}
                    {!sourcePatents.length && (
                      <div className="pt-14 text-center">
                        <h2 className="text-base text-[#101828] font-interSemiBold pb-0.5">
                          No source patents
                        </h2>
                        <p className="text-sm text-[#475467] pb-4">
                          Add your source patents
                        </p>
                        <AddFileBlue
                          addDropdownRef={addDropdownBlueRef}
                          setOpenModal={() => setOpenModal(true)}
                          type="project"
                        />
                        {/* <div className="relative">
                                                    <button ref={addDropdownBlueRef} className={`!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5 mx-auto`} onClick={() => {
                                                        setisShowAddBlueDropdown(!isShowAddBlueDropdown);
                                                    }}>
                                                        <img className='w-4' src={allImgPaths.plusIconWhite} alt="" />
                                                        Add New
                                                        <img className={`-ml-1 transform ${isShowAddBlueDropdown ? "rotate-180" : "rotate-0"}`} src={allImgPaths.downIconWhite} alt="" />
                                                    </button>
                                                    <div className={`absolute bg-white w-36 top-full left-1/2 -translate-x-1/2 shadow-customShadow14 rounded-lg mt-1 z-20 block ${isShowAddBlueDropdown ? "block" : "hidden"}`}>
                                                        <ul>
                                                            <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>With patent #</li>
                                                            <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>From Portfolio</li>
                                                            <li onClick={() => setOpenModal(true)} className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>External Upload</li>
                                                            <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>Paste URLs</li>
                                                        </ul>
                                                    </div>
                                                </div> */}
                      </div>
                    )}
                    <div className="overflow-y-auto h-[calc(100vh_-_240px)]">
                      {sourcePatents?.length > 0 &&
                        sourcePatents.map((dt, index) => (
                          <li
                            className="px-5 border-b border-[#F1F1F1] flex justify-between py-3 last:border-b-0"
                            key={index}
                          >
                            <div className="flex">
                              <div className="relative">
                                <input
                                  type="checkbox"
                                  id={dt.value}
                                  name="checkbox-group"
                                  checked={selectedSource.includes(
                                    dt.patent_id
                                  )}
                                  onChange={(e) => handleSourceSelect(e, dt)}
                                  className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer"
                                />
                                <svg
                                  className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <polyline points="4 8 7 11 12 5"></polyline>
                                </svg>
                              </div>
                              <label
                                htmlFor={dt.value}
                                className="ml-2 flex-1 cursor-pointer w-[202px]"
                              >
                                <div className="flex flex-col">
                                  <div className="flex items-center justify-between">
                                    <Link
                                      to={dt.file_url}
                                      target="_blank"
                                      className=""
                                    >
                                      <p
                                        title={dt.file_name}
                                        className="text-sm font-interSemiBold text-[#1751D0] pb-1 truncate max-w-[180px]"
                                      >
                                        {dt.file_name}
                                      </p>
                                    </Link>
                                    {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{dt.date}</span> */}
                                  </div>
                                  {/* <p title={dt.content} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2 max-w-60'>{dt.content}</p> */}
                                </div>
                              </label>
                            </div>
                            <div className="flex items-center justify-center gap-x-1.5">
                              {dt.patent_status === "not_started" && (
                                <div className="relative group">
                                  <img
                                    onClick={() =>
                                      startIndivijualAnalyzation(dt)
                                    }
                                    className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                    src={allImgPaths.analyzeIcon}
                                    alt=""
                                  />

                                  <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                    <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                    <ul className="text-center">
                                      <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                        Analyze
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}

                              {dt.patent_status === "failed" && (
                                <div className="relative group">
                                  <img
                                    className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                    src={allImgPaths.alertRedIcon2}
                                    alt=""
                                  />

                                  <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-max absolute z-10 mb-3 transform right-[28px] top-0 cursor-default hidden group-hover:block">
                                    <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                    <ul className="text-center">
                                      <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                        Last analysis failed
                                        <Link
                                          to={`#`}
                                          onClick={() =>
                                            startIndivijualAnalyzation(dt)
                                          }
                                          className="uppercase underline text-white font-interMedium text-xs inline-block ml-2"
                                        >
                                          Retry
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}

                              {dt.patent_status === "completed" && (
                                <div className="relative group">
                                  <img
                                    onClick={() => handlePatentClick(dt)}
                                    className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                    src={allImgPaths.viewAnalysis}
                                    alt=""
                                  />
                                  <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                    <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                    <ul className="text-center">
                                      <li className="text-xs font-interMedium text-white pb-1 last:pb-0 whitespace-nowrap">
                                        View Analysis
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                              {dt.patent_status === "in_progress" && (
                                <div className="relative group">
                                  <img
                                    className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                    src={allImgPaths.analyzingIcon1}
                                    alt=""
                                  />
                                  <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-max absolute z-10 mb-3 transform right-[28px] top-0 cursor-default hidden group-hover:block">
                                    <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                    <ul className="text-center">
                                      <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                        Analyzing...
                                        <Link
                                          onClick={() =>
                                            handleUpdatePatentStatus(
                                              dt.patent_id
                                            )
                                          }
                                          to={`#`}
                                          className="uppercase underline text-white font-interMedium text-xs inline-block ml-2"
                                        >
                                          Cancel
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}

                              <div className="relative group">
                                <img
                                  className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                  src={allImgPaths.threedotsVerticles2}
                                  alt=""
                                />
                                <div className="bg-white shadow-customShadow14 rounded-lg absolute z-[100] -right-3 transform cursor-default hidden group-hover:block">
                                  <li
                                    className="flex items-center gap-x-2 w-max cursor-pointer hover:bg-[#F5F8FD] py-2 px-2 "
                                    onClick={() => deletePatent(dt.patent_id)}
                                  >
                                    <img
                                      className="w-3 h-3"
                                      src={allImgPaths.closeIconRed}
                                      alt=""
                                    />
                                    <span className="text-sm font-interMedium text-[#C53434]">
                                      Remove
                                    </span>
                                  </li>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </div>
                  </div>

                  <div className="bg-white rounded-tr-lg rounded-br-lg w-[calc(100%_-_332px)]">
                    <div className="flex px-3 py-2.5 justify-between items-center border-b border-[#E6E6E6]">
                      <span className="text-sm font-interMedium text-black">
                        Compare with
                      </span>

                      <div className="flex gap-x-4 items-center">
                        {/* <button className='!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5' onClick={() => setOpenComparisonModal(true)}>
                                                Add
                                            </button> */}
                        <AddFileWhite
                          addDropdownRef={addDropdownCmpRef}
                          setOpenFileModal={(flag: any) => {
                            setOpenComparisonFileModal(true);
                          }}
                          setOpenPatentModal={(flag: any) => {
                            setOpenComparisonPatentModal(true);
                          }}
                          type="compare"
                          setOpenUrlModal={() => {
                            setOpenComparisonUrlModal(true);
                          }}
                        />
                        {/* <SearchBar
                                                value={searchCompareWith}
                                                onChange={handleSearchChangeCompareWith}
                                                img_url={allImgPaths.searchIcon}
                                                width={'w-full'}
                                                placeholder={'Search'}
                                            /> */}
                      </div>
                      {/* <div className="text-sm">
                                            Dropdown
                                        </div> */}
                    </div>
                    {/* Tabs */}
                    <div className="bg-[#FFF] border-b border-[#EAECF0]">
                      <div className="flex items-center justify-center w-full">
                        <div
                          id="scrollBoardContainer"
                          ref={scrollContainerRef}
                          className="flex items-center no-scrollbar gap-[16px] overflow-x-auto h-[50px] w-[calc(100vw_-_225px)]"
                        >
                          {columns?.length > 0 &&
                            columns.map((column: any, cIndex: any) => (
                              <div
                                key={cIndex}
                                className="w-1/3 text-center relative"
                              >
                                <span
                                  key={cIndex}
                                  onClick={() => {
                                    selectColumn(cIndex);
                                  }}
                                  className={`${
                                    isActiveColumn === cIndex
                                      ? "text-[#1751D0] planning-board-border-bottom after:bg-[#1751D0]"
                                      : "text-[#7F7D83]"
                                  }  text-sm font-interMedium pointer-cursor mx-10 flex-shrink-0 hover:text-[#1751D0]`}
                                >
                                  {column.name}
                                  <span className="text-sm font-interMedium ml-1">
                                    ({column.count})
                                  </span>
                                </span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="bg-white">
                        {/* Tabs Head */}
                        {/* <div className='flex flex-nowrap sticky top-0 z-10 checkbox-alignment2'>
                                                    {isActiveColumn === 0 &&
                                                        patentColumns?.length > 0 &&
                                                        comparePatents.length > 0 &&
                                                        patentColumns.map((column: any, columnIndex: number) => (
                                                            <React.Fragment key={columnIndex}>
                                                                {
                                                                    <div className={createColumnClassName(column.ref)}>
                                                                        
                                                                        {column.name === 'PatentId' && (
                                                                            <span className=' font-interMedium text-sm text-[#4F4D55] flex items-center justify-normal gap-x-2 w-[100%]'>
                                                                                <div className='relative inline-block'>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        id={'select-all-' + columnIndex}
                                                                                        name='checkbox-group'
                                                                                        checked={selectAll}
                                                                                        onChange={handleSelectAll}
                                                                                        className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                                checked:bg-indigo-600 checked:border-0 
                                                                                transition-colors cursor-pointer'
                                                                                    />
                                                                                    <svg
                                                                                        className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                                          inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                                        viewBox='0 0 16 16'
                                                                                        fill='none'
                                                                                        stroke='currentColor'
                                                                                        strokeWidth='2'
                                                                                        strokeLinecap='round'
                                                                                        strokeLinejoin='round'>
                                                                                        <polyline points='4 8 7 11 12 5'></polyline>
                                                                                    </svg>
                                                                                </div>
                                                                                {column.name}
                                                                                <img className={`w-4 cursor-pointer transform ${sortOrder == 'asc' ? 'rotate-0' : 'rotate-180'}`} src={allImgPaths.arrowUp3} alt='' onClick={() => handleSort(column.ref)} />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        ))}

                                                </div > */}

                        {/* Body */}
                        {/* Patents */}
                        {isActiveColumn === 0 && (
                          <>
                            <div className={"mt-4"}>
                              {comparePatents && comparePatents.length > 0 && (
                                <div className="rounded-bl-lg rounded-br-lg overflow-hidden">
                                  {/* Table Head */}
                                  {
                                    <div className="flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1]">
                                      <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <div className="relative leading-none">
                                            <input
                                              type="checkbox"
                                              id={"selectAllComparePatents"}
                                              name="checkbox-group"
                                              checked={selectAllComparePatents}
                                              onChange={
                                                handleSelectAllComparePatents
                                              }
                                              className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer"
                                            />
                                            <svg
                                              className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            >
                                              <polyline points="4 8 7 11 12 5"></polyline>
                                            </svg>
                                          </div>
                                          <div className="flex items-center gap-x-1.5">
                                            <span className="font-interMedium text-sm text-[#0A090B]">
                                              File Name
                                            </span>
                                            <img
                                              onClick={() =>
                                                handleSort(
                                                  "file_name",
                                                  "compareWithPatents"
                                                )
                                              }
                                              className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                                sortConfig["compareWithPatents"]
                                                  ?.file_name === "asc"
                                                  ? "rotate-0"
                                                  : "rotate-180"
                                              }`}
                                              src={allImgPaths.arrowUp3}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <span className="font-interMedium text-sm text-[#0A090B]">
                                            Last Update
                                          </span>
                                          <img
                                            onClick={() =>
                                              handleSort(
                                                "uploaded_at",
                                                "compareWithPatents"
                                              )
                                            }
                                            className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                              sortConfig["compareWithPatents"]
                                                ?.uploaded_at === "asc"
                                                ? "rotate-0"
                                                : "rotate-180"
                                            }`}
                                            src={allImgPaths.arrowUp3}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <span className="font-interMedium text-sm text-[#0A090B]">
                                            Action
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  {/* Table body */}
                                  <div className="max-h-[calc(100vh_-_332px)] overflow-y-auto rounded-bl-lg rounded-br-lg">
                                    {comparePatents.map(
                                      (row: any, rowIndex: number) => (
                                        <div className="h-auto" key={rowIndex}>
                                          <div className="flex">
                                            <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex">
                                              <div className="flex items-center gap-x-2 cursor-pointer">
                                                <div className="relative">
                                                  <input
                                                    type="checkbox"
                                                    id={"item" + rowIndex}
                                                    name="checkbox-group"
                                                    checked={selectedComparePatents.includes(
                                                      row.patent_id
                                                    )}
                                                    onChange={(e) =>
                                                      handleSelectedComparePatents(
                                                        e,
                                                        row
                                                      )
                                                    }
                                                    className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                                                                    checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                                                                    transition-colors cursor-pointer"
                                                  />
                                                  <svg
                                                    className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  >
                                                    <polyline points="4 8 7 11 12 5"></polyline>
                                                  </svg>
                                                </div>
                                                <Link
                                                  to={row.file_url}
                                                  target="_blank"
                                                  className="font-interMedium inline-block"
                                                >
                                                  <div className=" font-interMedium text-sm pb-0.5 hover:text-[#113B98] text-[#1751D0] underline">
                                                    {row.file_name}
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>

                                            <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                              <div>
                                                <p className="text-sm text-[#4F4D55] font-interRegular">
                                                  {" "}
                                                  {formatDate(row.uploaded_at)}
                                                </p>
                                                <p className="text-sm text-[#ADACB0] font-interRegular">
                                                  {" "}
                                                  {
                                                    row?.uploaded_by?.user_name
                                                  }{" "}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                              <div className="flex items-center gap-x-2 justify-center">
                                                {row.patent_status ===
                                                  "not_started" && (
                                                  <div className="relative group">
                                                    <img
                                                      onClick={() =>
                                                        startIndivijualAnalyzation(
                                                          row
                                                        )
                                                      }
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.analyzeIcon
                                                      }
                                                      alt=""
                                                    />

                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                          Analyze
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}

                                                {row.patent_status ===
                                                  "failed" && (
                                                  <div className="relative group">
                                                    <img
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.alertRedIcon2
                                                      }
                                                      alt=""
                                                    />

                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-max absolute z-10 mb-3 transform right-[28px] top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                          Last analysis failed
                                                          <Link
                                                            to={`#`}
                                                            onClick={() =>
                                                              startIndivijualAnalyzation(
                                                                row
                                                              )
                                                            }
                                                            className="uppercase underline text-white font-interMedium text-xs inline-block ml-2"
                                                          >
                                                            Retry
                                                          </Link>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}

                                                {row.patent_status ===
                                                  "completed" && (
                                                  <div className="relative group">
                                                    <img
                                                      onClick={() =>
                                                        handlePatentClick(row)
                                                      }
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.viewAnalysis
                                                      }
                                                      alt=""
                                                    />
                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0 whitespace-nowrap">
                                                          View Analysis
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}
                                                {row.patent_status ===
                                                  "in_progress" && (
                                                  <div className="relative group">
                                                    <img
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.analyzingIcon1
                                                      }
                                                      alt=""
                                                    />
                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-max absolute z-10 mb-3 transform right-[28px] top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                          Analyzing...
                                                          <Link
                                                            onClick={() =>
                                                              handleUpdatePatentStatus(
                                                                row.patent_id
                                                              )
                                                            }
                                                            to={`#`}
                                                            className="uppercase underline text-white font-interMedium text-xs inline-block ml-2"
                                                          >
                                                            Cancel
                                                          </Link>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}
                                                <img
                                                  className="cursor-pointer on-hover-bg-grey w-[28px] h-[28px] p-1 rounded"
                                                  src={allImgPaths.deleteIcon3}
                                                  alt=""
                                                  onClick={() =>
                                                    deletePatent(row.patent_id)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}

                              {/* Empty State */}
                              {comparePatents.length === 0 && (
                                <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                                  <div className="text-center">
                                    <img
                                      className="mb-2.5 ml-auto mr-auto"
                                      src={allImgPaths.searchIcon3}
                                      alt=""
                                    />
                                    <p className="font-interSemiBold text-base text-[#101828]">
                                      No Patents found
                                    </p>
                                    <p className="font-interRegular text-sm text-[#475467]">
                                      Please add patents to compare with <br />{" "}
                                      your source patent
                                    </p>
                                  </div>
                                </div>
                              )}

                              {/* Pagi */}
                              {/* <div className="flex items-center justify-center w-full px-5 py-6">
                                                                    <div className="text-base font-interRegular text-[#4F4D55]">
                                                                        Showing <span className="text-[#0A090B] font-interMedium">1</span> to{" "}
                                                                        <span className="text-[#0A090B] font-interMedium">4</span> of{" "}
                                                                        <span className="text-[#0A090B] font-interMedium">4</span> Results
                                                                    </div>
                                                                </div> */}
                            </div>
                          </>
                        )}
                        {/* Files */}
                        {isActiveColumn === 1 && (
                          <>
                            <div className={"mt-4"}>
                              {compareFiles && compareFiles.length > 0 && (
                                <div className="rounded-bl-lg rounded-br-lg overflow-hidden">
                                  {/* Table Head */}
                                  {
                                    <div className="flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1]">
                                      <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <div className="relative leading-none">
                                            <input
                                              type="checkbox"
                                              id={"selectAllCompareFiles"}
                                              name="checkbox-group"
                                              checked={selectAllCompareFiles}
                                              onChange={
                                                handleSelectAllCompareFiles
                                              }
                                              className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer"
                                            />
                                            <svg
                                              className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            >
                                              <polyline points="4 8 7 11 12 5"></polyline>
                                            </svg>
                                          </div>
                                          <div className="flex items-center gap-x-1.5">
                                            <span className="font-interMedium text-sm text-[#0A090B]">
                                              File Name
                                            </span>
                                            <img
                                              onClick={() =>
                                                handleSort(
                                                  "file_name",
                                                  "compareWithFiles"
                                                )
                                              }
                                              className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                                sortConfig["compareWithFiles"]
                                                  ?.file_name === "asc"
                                                  ? "rotate-0"
                                                  : "rotate-180"
                                              }`}
                                              src={allImgPaths.arrowUp3}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <span className="font-interMedium text-sm text-[#0A090B]">
                                            Last Update
                                          </span>
                                          <img
                                            onClick={() =>
                                              handleSort(
                                                "uploaded_at",
                                                "compareWithFiles"
                                              )
                                            }
                                            className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                              sortConfig["compareWithFiles"]
                                                ?.uploaded_at === "asc"
                                                ? "rotate-0"
                                                : "rotate-180"
                                            }`}
                                            src={allImgPaths.arrowUp3}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <span className="font-interMedium text-sm text-[#0A090B]">
                                            Action
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  {/* Table body */}
                                  <div className="max-h-[calc(100vh_-_332px)] overflow-y-auto rounded-bl-lg rounded-br-lg">
                                    {compareFiles.map(
                                      (row: any, rowIndex: number) => (
                                        <div key={rowIndex} className="h-auto">
                                          <div className="flex">
                                            <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex">
                                              <div className="flex items-center gap-x-2 cursor-pointer">
                                                <div className="relative">
                                                  <input
                                                    type="checkbox"
                                                    id={"item" + rowIndex}
                                                    name="checkbox-group"
                                                    checked={selectedCompareFiles.includes(
                                                      row.patent_id
                                                    )}
                                                    onChange={(e) =>
                                                      handleSelectedCompareFiles(
                                                        e,
                                                        row
                                                      )
                                                    }
                                                    className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                                                                    checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                                                                    transition-colors cursor-pointer"
                                                  />
                                                  <svg
                                                    className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  >
                                                    <polyline points="4 8 7 11 12 5"></polyline>
                                                  </svg>
                                                </div>
                                                <Link
                                                  to={row.file_url}
                                                  target="_blank"
                                                  className="font-interMedium inline-block"
                                                >
                                                  <div className=" font-interMedium text-sm pb-0.5 hover:text-[#113B98] text-[#1751D0] underline">
                                                    {row.file_name}
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>

                                            <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                              <div>
                                                <p className="text-sm text-[#4F4D55] font-interRegular">
                                                  {" "}
                                                  {formatDate(row.uploaded_at)}
                                                </p>
                                                <p className="text-sm text-[#ADACB0] font-interRegular">
                                                  {" "}
                                                  {
                                                    row?.uploaded_by?.user_name
                                                  }{" "}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                              <div className="flex items-center gap-x-2 justify-center">
                                                {/* {row.patent_status ===
                                                  "not_started" && (
                                                  <div className="relative group">
                                                    <img
                                                      onClick={() =>
                                                        startIndivijualAnalyzation(
                                                          row
                                                        )
                                                      }
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.analyzeIcon
                                                      }
                                                      alt=""
                                                    />

                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                          Analyze
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}

                                                {row.patent_status ===
                                                  "failed" && (
                                                  <div className="relative group">
                                                    <img
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.alertRedIcon2
                                                      }
                                                      alt=""
                                                    />

                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-max absolute z-10 mb-3 transform right-[28px] top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                          Last analysis failed
                                                          <Link
                                                            to={`#`}
                                                            onClick={() =>
                                                              startIndivijualAnalyzation(
                                                                row
                                                              )
                                                            }
                                                            className="uppercase underline text-white font-interMedium text-xs inline-block ml-2"
                                                          >
                                                            Retry
                                                          </Link>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}

                                                {row.patent_status ===
                                                  "completed" && (
                                                  <div className="relative group">
                                                    <img
                                                      onClick={() =>
                                                        handlePatentClick(row)
                                                      }
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.viewAnalysis
                                                      }
                                                      alt=""
                                                    />
                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0 whitespace-nowrap">
                                                          View Analysis
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}
                                                {row.patent_status ===
                                                  "in_progress" && (
                                                  <div className="relative group">
                                                    <img
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.analyzingIcon1
                                                      }
                                                      alt=""
                                                    />
                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-max absolute z-10 mb-3 transform right-[28px] top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                          Analyzing...
                                                          <Link
                                                            onClick={() =>
                                                              handleUpdatePatentStatus(
                                                                row.patent_id
                                                              )
                                                            }
                                                            to={`#`}
                                                            className="uppercase underline text-white font-interMedium text-xs inline-block ml-2"
                                                          >
                                                            Cancel
                                                          </Link>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )} */}
                                                <img
                                                  className="cursor-pointer on-hover-bg-grey w-[28px] h-[28px] p-1 rounded"
                                                  src={allImgPaths.deleteIcon3}
                                                  alt=""
                                                  onClick={() =>
                                                    deletePatent(row.patent_id)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}

                              {/* Empty State */}
                              {compareFiles.length === 0 && (
                                <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                                  <div className="text-center">
                                    <img
                                      className="mb-2.5 ml-auto mr-auto"
                                      src={allImgPaths.searchIcon3}
                                      alt=""
                                    />
                                    <p className="font-interSemiBold text-base text-[#101828]">
                                      No Files found
                                    </p>
                                    <p className="font-interRegular text-sm text-[#475467]">
                                      Please add files to compare with <br />{" "}
                                      your source patent
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                        {/* URLs */}
                        {isActiveColumn === 2 && (
                          <>
                            <div className={"h-[calc(100vh_-_356px)] mt-4"}>
                              {compareURLs && compareURLs.length > 0 && (
                                <div className="rounded-bl-lg rounded-br-lg overflow-hidden">
                                  {/* Table Head */}
                                  {
                                    <div className="flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1]">
                                      <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <div className="relative leading-none">
                                            <input
                                              type="checkbox"
                                              id={"selectAllCompareUrls"}
                                              name="checkbox-group"
                                              checked={selectAllCompareUrls}
                                              onChange={
                                                handleSelectAllCompareUrls
                                              }
                                              className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer"
                                            />
                                            <svg
                                              className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            >
                                              <polyline points="4 8 7 11 12 5"></polyline>
                                            </svg>
                                          </div>
                                          <div className="flex items-center gap-x-1.5">
                                            <span className="font-interMedium text-sm text-[#0A090B]">
                                              URL
                                            </span>
                                            <img
                                              onClick={() =>
                                                handleSort(
                                                  "url",
                                                  "compareWithUrls"
                                                )
                                              }
                                              className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                                sortConfig["compareWithUrls"]
                                                  ?.url === "asc"
                                                  ? "rotate-0"
                                                  : "rotate-180"
                                              }`}
                                              src={allImgPaths.arrowUp3}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <span className="font-interMedium text-sm text-[#0A090B]">
                                            Last Update
                                          </span>
                                          <img
                                            onClick={() =>
                                              handleSort(
                                                "updatedAt",
                                                "compareWithUrls"
                                              )
                                            }
                                            className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                              sortConfig["compareWithUrls"]
                                                ?.updatedAt === "asc"
                                                ? "rotate-0"
                                                : "rotate-180"
                                            }`}
                                            src={allImgPaths.arrowUp3}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                        <div className="flex items-center gap-x-2 cursor-pointer">
                                          <span className="font-interMedium text-sm text-[#0A090B]">
                                            Action
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  {/* Table body */}
                                  <div className="max-h-[calc(100vh_-_332px)] overflow-y-auto rounded-bl-lg rounded-br-lg">
                                    {compareURLs.map(
                                      (row: any, rowIndex: number) => (
                                        <div key={rowIndex} className="h-auto">
                                          <div className="flex">
                                            <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex">
                                              <div className="flex items-center gap-x-2 cursor-pointer">
                                                <div className="relative">
                                                  <input
                                                    type="checkbox"
                                                    id={"item" + rowIndex}
                                                    name="checkbox-group"
                                                    checked={selectedCompareUrls.includes(
                                                      row._id
                                                    )}
                                                    onChange={(e) =>
                                                      handleSelectedCompareUrls(
                                                        e,
                                                        row
                                                      )
                                                    }
                                                    className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                                                                    checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                                                                    transition-colors cursor-pointer"
                                                  />
                                                  <svg
                                                    className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                  >
                                                    <polyline points="4 8 7 11 12 5"></polyline>
                                                  </svg>
                                                </div>
                                                <Link
                                                  to={row.url}
                                                  target="_blank"
                                                  className="font-interMedium inline-block"
                                                >
                                                  <div className=" font-interMedium text-sm pb-0.5 hover:text-[#113B98] text-[#1751D0] underline">
                                                    {row.url}
                                                  </div>
                                                </Link>
                                              </div>
                                            </div>

                                            <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                              <div>
                                                <p className="text-sm text-[#4F4D55] font-interRegular">
                                                  {" "}
                                                  {formatDate(row.updatedAt)}
                                                </p>
                                                <p className="text-sm text-[#ADACB0] font-interRegular">
                                                  {" "}
                                                  {row?.updated_by?.name}{" "}
                                                </p>
                                              </div>
                                            </div>

                                            <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                              <div className="flex items-center gap-x-2 justify-center">
                                                {/* {(row.patent_status ===
                                                  "not_started" ||
                                                  row.patent_status ===
                                                    "failed") && (
                                                  <div className="relative group">
                                                    <img
                                                      onClick={() =>
                                                        startIndivijualAnalyzation(
                                                          row
                                                        )
                                                      }
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.analyzeIcon
                                                      }
                                                      alt=""
                                                    />

                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                          Analyze
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}
                                                {row.patent_status ===
                                                  "completed" && (
                                                  <div className="relative group">
                                                    <img
                                                      onClick={() =>
                                                        handlePatentClick(row)
                                                      }
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.viewAnalysis
                                                      }
                                                      alt=""
                                                    />
                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0 whitespace-nowrap">
                                                          View Analysis
                                                        </li>                                                    
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )}
                                                {row.patent_status ===
                                                  "in_progress" && (
                                                  <div className="relative group">
                                                    <img
                                                      className="cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded"
                                                      src={
                                                        allImgPaths.analyzingIcon1
                                                      }
                                                      alt=""
                                                    />
                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                      <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                      <ul className="text-center">
                                                        <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                          Analyzing...
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                )} */}
                                                <img
                                                  className="cursor-pointer on-hover-bg-grey w-[28px] h-[28px] p-1 rounded"
                                                  src={allImgPaths.deleteIcon3}
                                                  alt=""
                                                  onClick={() =>
                                                    deleteUrl(row._id)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}

                              {/* Empty State */}
                              {compareURLs.length === 0 && (
                                <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                                  <div className="text-center">
                                    <img
                                      className="mb-2.5 ml-auto mr-auto"
                                      src={allImgPaths.searchIcon3}
                                      alt=""
                                    />
                                    <p className="font-interSemiBold text-base text-[#101828]">
                                      No URLs found
                                    </p>
                                    <p className="font-interRegular text-sm text-[#475467]">
                                      Please add URLs to compare with <br />{" "}
                                      your source patent
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}

          {/* Vijilizations */}
          {isActiveSection === 1 && (
            <div className="px-4 pb-4">
              {vijilizeLoader ? (
                <VijilizeShimmerLoader />
              ) : (
                <div className="bg-white h-[calc(100vh_-_165px)] rounded-lg">
                  {vijilizedData.length !== 0 && (
                    <div className="flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1] rounded-tl-lg rounded-tr-lg">
                      <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6">
                        <div className="flex items-center justify-start gap-x-2 pt-0.5">
                          {/* <Checkbox
                                            data={checkBoxdata}
                                            /> */}
                          <div className="flex items-center gap-x-1 cursor-pointer">
                            <span className="font-interMedium text-sm text-[#0A090B]">
                              Name
                            </span>
                            <img
                              onClick={() =>
                                handleSort("name", "vijilizations")
                              }
                              className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                sortConfig["vijilizations"]?.name === "asc"
                                  ? "rotate-0"
                                  : "rotate-180"
                              }`}
                              src={allImgPaths.arrowUp3}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6">
                        <div className="flex items-center justify-start gap-x-2 pt-0.5">
                          <span className="cursor-pointer font-interMedium text-sm text-[#0A090B]">
                            Last Update
                          </span>
                          <img
                            onClick={() =>
                              handleSort("updatedAt", "vijilizations")
                            }
                            className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                              sortConfig["vijilizations"]?.updatedAt === "asc"
                                ? "rotate-0"
                                : "rotate-180"
                            }`}
                            src={allImgPaths.arrowUp3}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6">
                        <div className="flex items-center justify-start gap-x-2 pt-0.5">
                          <span className="cursor-pointer font-interMedium text-sm whitespace-nowrap text-[#0A090B]">
                            Data Used
                          </span>
                        </div>
                      </div>
                      <div className="w-[10%] flex flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 justify-center">
                        <div className="flex items-center justify-start gap-x-2 pt-0.5">
                          <span className="cursor-pointer font-interMedium whitespace-nowrap text-sm text-[#0A090B]">
                            Status
                          </span>
                        </div>
                      </div>
                      {/* <div className="w-[10%] flex flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 justify-center">
                                                    <div className="font-interMedium text-sm text-[#0A090B] inline-block">
                                                        Action
                                                    </div>
                                                </div> */}
                    </div>
                  )}

                  <div className="max-h-[calc(100vh_-_205px)] overflow-y-auto">
                    {vijilizedData.map((data: any, index) => (
                      <div className="flex" key={index}>
                        <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 table-row !justify-start">
                          <div className="">
                            <label
                              htmlFor="item0"
                              className="flex items-center gap-x-2"
                            >
                              <div className="">
                                {/* <a
                                                                    className="font-interMedium inline-block smoothness"
                                                                    href={`project/${projectId}/vijilize`}
                                                                > */}
                                {data.status === "completed" && (
                                  <Link
                                    className="font-interMedium inline-block smoothness"
                                    state={{
                                      source: data.source,
                                      compare: data.compare,
                                    }}
                                    to={`/project/${projectId}/vijilize/${data._id}/heatmap`}
                                    onClick={() => setVijilizeData(data)}
                                  >
                                    <div className=" font-interMedium text-sm pb-0.5 hover:text-[#113B98] text-[#1751D0]">
                                      {data.name}
                                    </div>
                                  </Link>
                                )}
                                {data.status !== "completed" && (
                                  <div className=" font-interMedium text-sm pb-0.5 cursor-not-allowed hover:text-[#113B98] text-[#1751D0]">
                                    {data.name}
                                  </div>
                                )}

                                {/* </a> */}
                                <div
                                  title="dddd"
                                  className=" max-w-[24vw] truncate text-[#7F7D83] font-interMedium text-sm"
                                >
                                  {data.description}
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 flex items-center">
                          <div>
                            <p className="text-sm text-[#4F4D55] font-interRegular">
                              {formatDate(data.updatedAt)}
                            </p>
                            <p className="text-sm text-[#ADACB0] font-interRegular">
                              {data?.updated_by?.user_name}
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setIsShowSideBar(true);
                            setSrcRefData({
                              source: data.source,
                              compare: data.compare,
                            });
                          }}
                          className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 flex items-center gap-x-1"
                        >
                          <span className="font-interMedium group-hover:text-[#1E0AF5] text-sm text-[#006FEE] inline-block cursor-pointer">
                            {data?.source?.length} Source
                          </span>
                          <span className="font-interMedium group-hover:text-[#1E0AF5] text-sm text-[#006FEE] inline-block cursor-pointer">
                            {" "}
                            |{" "}
                          </span>
                          <span className="font-interMedium group-hover:text-[#1E0AF5] text-sm text-[#006FEE] inline-block cursor-pointer">
                            {data?.compare?.length} Reference
                          </span>
                        </div>
                        <div className="w-[10%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 flex items-center justify-center">
                          {data.status === "in_progress" && (
                            <span className="text-sm font-interMedium text-[#113B98] bg-[#EBF0FB] rounded px-2 py-1">
                              Running
                            </span>
                          )}

                          {data.status === "completed" && (
                            <span className="text-sm font-interMedium text-[#016626] bg-[#E1FAEA] rounded px-2 py-1">
                              Success
                            </span>
                          )}

                          {data.status === "failed" && (
                            <span className="text-sm font-interMedium text-[#961616] bg-[#FFE3E3] rounded px-2 py-1">
                              Failed
                            </span>
                          )}
                        </div>
                        {/* {data.status === 'completed' && <div className="w-[10%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-2 px-6 flex items-center justify-center">
                                                        <img
                                                            src={allImgPaths.downloadIcon2}
                                                            alt="download icon"
                                                            className="inline-block cursor-pointer on-hover-bg-grey w-7 p-1 rounded"
                                                        />
                                                    </div>} */}
                      </div>
                    ))}

                    {vijilizedData.length === 0 && (
                      <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                        <div className="text-center">
                          <img
                            className="mb-2.5 ml-auto mr-auto"
                            src={allImgPaths.searchIcon3}
                            alt=""
                          />
                          <p className="font-interSemiBold text-base text-[#101828]">
                            No vijilisation found
                          </p>
                          <p className="font-interRegular text-sm text-[#475467]">
                            Please add patents, files, URLs, publications to{" "}
                            <br /> compare with your source patent
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Pagination */}

              {false && (
                <div className="flex items-center justify-center gap-x-2 py-6">
                  <span className="text-sm text-[#4F4D55] font-interMedium">
                    Showing
                  </span>
                  <span className="text-[#0A090B] font-interSemiBold">1</span>
                  <span className="text-sm text-[#4F4D55] font-interMedium">
                    to
                  </span>
                  <span className="text-[#0A090B] font-interSemiBold">1</span>
                  <span className="text-sm text-[#4F4D55] font-interMedium">
                    of
                  </span>
                  <span className="text-[#0A090B] font-interSemiBold">1</span>
                  <span className="text-sm text-[#4F4D55] font-interMedium">
                    Results
                  </span>
                </div>
              )}
            </div>
          )}

          {/* Claim chart */}
          {isActiveSection === 2 && (
            <div className="px-4 pb-4">
              {
                <ClaimTable
                  claimData={claimChartData}
                  projectId={projectId || "0"}
                  isClaimTableLoading={isClaimTableLoading}
                  handleSort={handleSort}
                  sortConfig={sortConfig}
                />
              }
            </div>
          )}
        </div>
      </div>
      {openModal && (
        <AddSource
          isShow={openModal}
          setIsShow={setOpenModal}
          projectId={projectId || ""}
          setRefreshData={() => setRefreshData((prev) => !prev)}
        />
      )}
      {openComparisonFileModal && (
        <AddComparisonData
          isShow={openComparisonFileModal}
          setIsShow={setOpenComparisonFileModal}
          setToast={setToast}
          projectId={projectId || ""}
          setRefreshData={() => setRefreshData((prev) => !prev)}
          type="file"
        />
      )}
      {openComparisonPatentModal && (
        <AddComparisonData
          isShow={openComparisonPatentModal}
          setIsShow={setOpenComparisonPatentModal}
          setToast={setToast}
          projectId={projectId || ""}
          setRefreshData={() => setRefreshData((prev) => !prev)}
          type="patent"
        />
      )}
      {openComparisonUrlModal && (
        <AddComparisonData
          isShow={openComparisonUrlModal}
          setIsShow={setOpenComparisonUrlModal}
          setToast={setToast}
          projectId={projectId || ""}
          setRefreshData={() => setRefreshData((prev) => !prev)}
          type="url"
        />
      )}
      {vijilizeModalStatus && (
        <VijilizeModal
          isShow={vijilizeModalStatus}
          setIsShow={setVijilizeModalStatus}
          setRefreshData={() => setRefreshData((prev) => !prev)}
          selectedSource={selectedSource}
          selectedComparePatents={selectedComparePatents}
          selectedCompareFiles={selectedCompareFiles}
          selectedCompareUrls={selectedCompareUrls}
          projectId={projectId}
          sourcePatents={sourcePatents}
          comparePatents={comparePatents}
          compareFiles={compareFiles}
          compareUrls={compareURLs}
        />
      )}

      {/* Sidebar */}
      <div
        className={`w-full h-full fixed bg-[#07031154] z-[100] ${
          isShowSideBar ? "right-0" : "right-full"
        }`}
      >
        <div
          onClick={() => setIsShowSideBar(false)}
          className="h-full w-[calc(100%_-_360px)]"
        ></div>
        <div
          className={`border-l border-[#E4E7EC] w-[360px] h-screen fixed z-50 bg-white top-0 shadow-customShadow15 ${
            isShowSideBar ? "right-0" : "right-full"
          }`}
        >
          <div className="pb-6 px-6 pt-4">
            <div className="pb-0.5 flex items-center justify-between">
              <span className="text-lg font-interSemiBold text-[#101828] inline-block ">
                Data Used
              </span>
              <img
                onClick={() => setIsShowSideBar(false)}
                className="cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1"
                src={allImgPaths.closeIcon}
                alt=""
              />
            </div>

            <p className="text-[#475467] text-sm ">
              List of source and compare with data used for this vijilization.
            </p>
          </div>

          <div className="h-[calc(100vh_-_130px)]">
            <div className="">
              <div className="bg-[#F8F8F8] rounded-lg p-1.5 mx-6 mb-4">
                <div className="flex items-center gap-x-1">
                  <p
                    onClick={() => setToggledDataUsed(0)}
                    className={`text-sm font-interMedium rounded-lg py-1.5 px-2 flex-1 flex justify-center cursor-pointer ${
                      toggledDataUsed === 0
                        ? "bg-white shadow-customShadow12 text-[#0A090B]"
                        : "text-[#4F4D55]"
                    }`}
                  >
                    Source ({srcRefData?.source?.length})
                  </p>
                  <p
                    onClick={() => setToggledDataUsed(1)}
                    className={`text-sm font-interMedium rounded-lg py-1.5 px-2 flex-1 flex justify-center cursor-pointer ${
                      toggledDataUsed === 1
                        ? "bg-white shadow-customShadow12 text-[#0A090B]"
                        : "text-[#4F4D55]"
                    }`}
                  >
                    Compare With ({srcRefData?.compare?.length})
                  </p>
                </div>
              </div>
              <div className="overflow-y-auto h-[calc(100vh_-_180px)] px-6">
                {/* Source */}
                {toggledDataUsed === 0 && srcRefData?.source && (
                  <>
                    {srcRefData?.source.map((item: any, id: number) => {
                      return (
                        <>
                          <div key={id} className="border-b px-5 py-3">
                            <div className="flex items-center gap-x-4">
                              <Link
                                to={item.file_url}
                                target="_blank"
                                className="cursor-default w-full"
                              >
                                <p
                                  title={item.file_name}
                                  className="text-sm font-interSemiBold text-[#1751D0] pb-1 truncate max-w-[calc(100%_-_5px)] cursor-pointer w-fit"
                                >
                                  {item.file_name}
                                </p>
                              </Link>
                              {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                            </div>
                            {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                          </div>
                        </>
                      );
                    })}
                  </>
                )}

                {/* Compare with */}
                {toggledDataUsed === 1 && srcRefData?.compare && (
                  <>
                    {srcRefData?.compare.map((item: any, id: number) => {
                      let url = "";
                      let name = "";
                      if (srcRefData && item?.url) {
                        url = item.url;
                        name = item.url;
                      } else {
                        url = item.file_url;
                        name = item.file_name;
                      }
                      return (
                        <>
                          <div key={id} className="border-b px-5 py-3">
                            <div className="flex items-center gap-x-4">
                              <Link
                                to={url}
                                target="_blank"
                                className="cursor-default w-full"
                              >
                                <p
                                  title={name}
                                  className="text-sm font-interSemiBold text-[#1751D0] pb-1 truncate max-w-[calc(100%_-_5px)] cursor-pointer w-fit"
                                >
                                  {name}
                                </p>
                              </Link>
                              {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                            </div>
                            {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* <div className="">
                    <div className="flex gap-x-3 justify-end z-10 mt-4 px-5 py-4 border-t border-[#E4E7EC]">
                        <button onClick={() => setIsShowSideBar(false)} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide w-40 py-2.5 px-2 !text-base hover:bg-[#f2f4f7]'>Cancel</button>
                        <button className={`font-interSemiBold text-base text-[#667085] py-2 px-2 w-40 border border-[#1E0AF5] rounded-lg flex items-center justify-center cursor-pointer shadow-customShadow3 bg-white hover:bg-[#E8E6FE]`}>
                            <span className="text-[15px] text-[#1E0AF5] font-interSemiBold">Apply</span>
                        </button>
                    </div>
                </div> */}
        </div>
      </div>
      {/* Edit Project */}
      {isShowEdit && (
        <EditProject
          isShow={isShowEdit}
          setIsShow={() => setIsShowEdit(false)}
          setToast={setToast}
          setRefreshData={() => setRefreshData((prev) => !prev)}
          projectId={projectId || "0"}
          projectName={projectDetail.title}
          projectDesc={projectDetail.description}
        />
      )}

      {/* Delete Project */}
      {isShowDelete && (
        <DeleteModal
          isShow={isShowDelete}
          setIsShow={() => setIsShowDelete(false)}
          id={projectId || "0"}
          title={DELETE_MODAL_DATA.project.title}
          description={DELETE_MODAL_DATA.project.description}
          onDelete={handleDeleteProject}
        />
      )}
    </div>
  );
}
export default FindCoveredProducts;
