import allImgPaths from "assets/images";
import { useEffect, useState } from "react";

const SearchBar = ({
  img_url,
  placeholder,
  width,
  extra_class,
  value,
  onChange,
}: any) => {
  const [inputValue, setInputValue] = useState(value);
  const debounceDelay = 500;

  useEffect(() => {
    const handler = setTimeout(() => {
      onChange(inputValue);
    }, debounceDelay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, onChange, debounceDelay]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <div
        className={`border hover:border-[#1751D0] rounded-lg ${width} py-2 px-3.5 flex items-center gap-x-2 bg-white ${extra_class}`}
      >
        <img src={img_url} alt="search" />
        <input
          className="w-full"
          placeholder={placeholder}
          type="text"
          value={inputValue}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default SearchBar;
