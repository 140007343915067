/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import Sidebar from "components/common/Sidebar";
import Header from "components/common/Header";
import allImgPaths from "assets/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  deleteFolder,
  deleteMultipleFiles,
  deletePortfolio,
  getFolder,
  getPortfolioById,
} from "services/portfolio";
import SearchBar from "components/common/SearchBar";
import DeleteModal from "components/common/modals/DeleteModal";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import AddFileBlue from "components/common/AddFileBlue";
import UploadFileModal from "components/common/modals/UploadFile";
import { formatDate } from "utils/function";
import AddNewFolderModal from "components/common/modals/AddNewFolder";
import AddToProjectModal from "components/common/modals/AddToProject";
import { deleteFile } from "../../services/portfolio";
import Toast from "components/common/Toast";
import useAppState from "context/useAppState";
import { PortfolioDetailShimmerLoader } from "pages/loaders";
import { DELETE_MODAL_DATA } from "utils/constant";

function PortfolioDetails() {
  const [openAddToProjectModal, setOpenAddToProjectModal] = useState(false);
  const [isLoader, setIsLoader] = useState<boolean>(true);
  const [portfolioDetail, setPortfolioDetail] = useState<any>();
  const [search, setSearch] = useState("");
  const [isShowDropdown, setIsShowDropdown] = useState<any>(0);
  const [isShowFolderDropdown, setIsShowFolderDropdown] = useState<any>(null);
  const [isShowAddBlueDropdown, setIsShowAddBlueDropdown] =
    useState<any>(false);
  const [isShowDeletePortfolio, setIsShowDeletePortfolio] =
    useState<boolean>(false);
  const [isShowDeleteFolder, setIsShowDeleteFolder] = useState<boolean>(false);
  const [isShowDeleteFile, setIsShowDeleteFile] = useState<boolean>(false);
  const [isShowDeleteMultipleFile, setIsShowDeleteMultipleFile] =
    useState<boolean>(false);
  const [refreshData, setRefreshData] = useState(false);
  const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [openEditFolderModal, setOpenEditFolderModal] = useState(false);
  const [rootFiles, setRootFiles] = useState<any[]>([]);
  const [rootFolders, setRootFolders] = useState<any[]>([]);
  const [selectedRootFiles, setSelectedRootFiles] = useState<any[]>([]);
  const [selectAllRootFiles, setSelectAllRootFiles] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
  const [editFolderData, setEditFolderData] = useState<any>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<
    { id: string | null; name: string }[]
  >([{ id: null, name: "Root Folder" }]);
  const [subFolders, setSubFolders] = useState<any[]>([]);
  const [currentFiles, setCurrentFiles] = useState<any[]>([]);
  const [itemToDelete, setItemToDelete] = useState<any>(null);
  const initialSortState = {
    file_name: "asc",
    uploaded_at: "asc",
  };

  const [sortConfig, setSortConfig] = useState(initialSortState);
  const { setHeaderDataLocal } = useAppState("vijilize");

  const navigate = useNavigate();
  const { id: portfolioId } = useParams();
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setIsShowDropdown(false));

  const folderDropdownRef = useRef(null);
  useOnClickOutside(folderDropdownRef, () => setIsShowFolderDropdown(false));

  const addDropdownBlueRef = useRef(null);
  useOnClickOutside(addDropdownBlueRef, () => setIsShowAddBlueDropdown(false));

  useEffect(() => {
    fetchPortfolioDetails();
  }, [refreshData]);

  const fetchPortfolioDetails = async () => {
    try {
      setIsLoader(true);

      const detailResponse: any = await getPortfolioById(portfolioId || "0");
      const portfolioData = detailResponse?.data;
      setHeaderDataLocal((prev: any) => {
        return {
          ...prev,
          portfolioDetail: {
            title: portfolioData?.name,
            description: portfolioData?.description,
            id: portfolioData?._id,
          },
        };
      });
      setPortfolioDetail(portfolioData);
      if (!currentFolderId) {
        setRootFiles(portfolioData?.rootFiles || []);
        setRootFolders(portfolioData?.folders || []);
        setSubFolders(portfolioData?.folders || []);
        setCurrentFiles(portfolioData?.rootFiles || []);
      } else {
        const folderResponse: any = await getFolder(
          portfolioId,
          currentFolderId
        );
        setSubFolders(folderResponse?.data?.folders || []);
        setCurrentFiles(folderResponse?.data?.files || []);
      }
      setIsLoader(false);
    } catch (error) {
      console.error("Error while fetching portfolio details:", error);
    }
  };

  const handleFolderClick = (folder: any) => {
    setCurrentFolderId(folder._id);
    setBreadcrumbs((prev) => [...prev, { id: folder._id, name: folder.name }]);
    setRefreshData((prev) => !prev);
    setSelectedRootFiles([]);
    setSelectAllRootFiles(false);
  };

  const handleBreadcrumbClick = (folderId: string | null, index: number) => {
    setCurrentFolderId(folderId);
    setBreadcrumbs((prev) => prev.slice(0, index + 1));
    setRefreshData((prev) => !prev); // Trigger data fetch
    setSelectedRootFiles([]);
    setSelectAllRootFiles(false);
  };

  const handleSearchChange = (value: any) => {
    setSearch(value);
  };

  const handleSelectAllRootFiles = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectAllRootFiles(e.target.checked);
      if (e.target.checked) {
        const allFileIds = currentFiles.map((file) => file._id);
        setSelectedRootFiles(allFileIds);
      } else {
        setSelectedRootFiles([]);
      }
    },
    [currentFiles]
  );

  const handleSort = (key: string) => {
    setSortConfig((prevSortConfig: any) => {
      const currentDirection = prevSortConfig[key] || "asc";
      const newDirection = currentDirection === "asc" ? "desc" : "asc";

      let data: any[] = [...currentFiles]; // Create copy to avoid mutation

      data.sort((a: any, b: any) => {
        if (key === "file_name") {
          return newDirection === "asc"
            ? a[key].localeCompare(b[key])
            : b[key].localeCompare(a[key]);
        } else if (key === "uploaded_at") {
          return newDirection === "asc"
            ? new Date(a[key]).getTime() - new Date(b[key]).getTime()
            : new Date(b[key]).getTime() - new Date(a[key]).getTime();
        }
        return 0;
      });

      setCurrentFiles(data); // Update the rootFiles with sorted data

      return {
        ...prevSortConfig,
        [key]: newDirection,
      };
    });
  };

  const handleSelectedRootFiles = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    const isChecked = event.target.checked;
    setSelectedRootFiles((prev) => {
      const updatedSelection = isChecked
        ? [...prev, data._id]
        : prev.filter((item) => item !== data._id);

      setSelectAllRootFiles(updatedSelection.length === rootFiles.length);

      return updatedSelection;
    });
  };

  const onSuccessFullFileAttach = () => {
    setSelectAllRootFiles(false);
    setSelectedRootFiles([]);
  };

  const handleDeleteFile = async (fileId: any) => {
    try {
      await deleteFile(portfolioId, fileId);
      Toast.success("Success", "File deleted successfully");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.log("Error while deleting file:", error);
      Toast.error("Error", "Error while deleting file please try again later");
    }
  };

  const handleDeleteMultipleFiles = async () => {
    try {
      const payload = {
        fileIds: selectedRootFiles,
      };
      await deleteMultipleFiles(portfolioId, payload);
      Toast.success("Success", "Files deleted successfully");
      setRefreshData((prev) => !prev);
      setSelectedRootFiles([]);
      selectAllRootFiles && setSelectAllRootFiles(false);
    } catch (error) {
      console.log("Error while deleting files:", error);
      Toast.error("Error", "Error while deleting files please try again later");
    }
  };

  const handleDeletePortfolio = async (id: any) => {
    try {
      await deletePortfolio(id);
      Toast.success("Success", "Portfolio deleted successfully");
      setRefreshData((prev) => !prev);
      navigate("/portfolio");
    } catch (error) {
      console.log("Error while deleting portfolio:", error);
      Toast.error(
        "Error",
        "Error while deleting portfolio please try again later"
      );
    }
  };

  const handleDeleteFolder = async (folderId: string) => {
    try {
      await deleteFolder(portfolioId, folderId);
      Toast.success("Success", "Folder deleted successfully");
      setRefreshData((prev) => !prev);
    } catch (error) {
      console.log("Error while deleting folder:", error);
      Toast.error(
        "Error",
        "Error while deleting folder please try again later"
      );
    }
  };

  return (
    <div className="dashboard-wrapper flex">
      {/* Sidebar */}
      <Sidebar />

      {/* dashboard main */}
      <div className="w-[calc(100%_-_75px)]  ml-auto bg-[#f9fafb]">
        {/* Hedaer */}
        <Header
          title="Portfolio"
          count={0}
          isChildEle={true}
          type="portfolio"
        />
        <div className="h-[calc(100vh_-_70px)] overflow-hidden">
          <div className="bg-[#f9fafb]">
            <div className="flex justify-between py-4 px-4">
              <div className="flex items-start gap-4">
                <div className="flex items-start gap-x-2 pl-1">
                  <img
                    className="inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1"
                    src={allImgPaths.goBack}
                    alt="return"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <div className="">
                    <h2 className="text-[#0A090B] text-xl font-interSemiBold">
                      {portfolioDetail?.name}
                    </h2>
                    <p
                      title={portfolioDetail?.description}
                      className="text-sm text-[#7F7D83] font-interRegular truncate max-w-[calc(100vw_-_1100px)]"
                    >
                      {portfolioDetail?.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-x-2 mr-2">
                <div className="flex gap-x-[16px] items-center ">
                  <AddFileBlue
                    addDropdownRef={addDropdownBlueRef}
                    setOpenModal={setOpenUploadFileModal}
                    type={"portfolio"}
                    setOpenFolderModal={setOpenFolderModal}
                  />

                  <SearchBar
                    img_url={allImgPaths.searchIcon}
                    value={search}
                    onChange={handleSearchChange}
                    width={"w-64"}
                    placeholder={"Search"}
                  />
                  <div ref={dropdownRef} className="relative">
                    <img
                      onClick={() => setIsShowDropdown(!isShowDropdown)}
                      className="cursor-pointer on-hover-bg-grey w-6 h-6 p-1 rounded"
                      src={allImgPaths.threeVerticleDotsIcon}
                      alt=""
                    />
                    <div
                      className={`absolute bg-white w-36 top-0 right-full shadow-customShadow14 rounded-lg mt-8 -mr-5 z-[100] ${
                        isShowDropdown ? "block" : "hidden"
                      }`}
                    >
                      <ul>
                        <li className="flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer">
                          <img
                            className="w-[18px]"
                            src={allImgPaths.downloadIcon2}
                            alt=""
                          />
                          <span className="text-sm font-interMedium text-[#0A090B]">
                            Download
                          </span>
                        </li>
                        <li
                          className="flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer"
                          onClick={() => setIsShowDeletePortfolio(true)}
                        >
                          <img src={allImgPaths.deleteicon2} alt="" />
                          <span className="text-sm font-interMedium text-[#C53434]">
                            Delete
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoader ? (
            <PortfolioDetailShimmerLoader />
          ) : (
            <>
              {subFolders?.length === 0 &&
              currentFiles?.length === 0 &&
              !currentFolderId ? (
                <div className="h-[calc(100vh_-_160px)] flex justify-center items-center bg-white mx-8 rounded-lg">
                  <div className="">
                    <div className="text-center">
                      <img
                        className="mb-2.5 ml-auto mr-auto max-w-48"
                        src={allImgPaths.noPortfolioDetailData}
                        alt=""
                      />
                      <p className="font-interSemiBold text-base text-[#455468] pb-0.5">
                        No Data Found
                      </p>
                      <p className="font-interRegular text-sm mb-4 text-[#475467]">
                        Create a new folder or Upload files to get started
                      </p>
                      <AddFileBlue
                        addDropdownRef={addDropdownBlueRef}
                        setOpenModal={setOpenUploadFileModal}
                        type={"portfolio"}
                        setOpenFolderModal={setOpenFolderModal}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/* Breadcrumbs */}
                  <div className="flex items-center gap-x-1 mx-8 mb-4">
                    {breadcrumbs.map((crumb, index) => (
                      <React.Fragment key={crumb.id || "root"}>
                        {index === 0 && (
                          <>
                            <img
                              className="hover:bg-[#f2f4f7] cursor-pointer "
                              src={allImgPaths.folderIcon1}
                              alt=""
                              height={16}
                              width={16}
                            />
                          </>
                        )}
                        <span
                          className={`text-sm font-interMedium  text-[#7F7D83] ${
                            index === breadcrumbs.length - 1
                              ? ""
                              : "hover:underline cursor-pointer"
                          }`}
                          onClick={() =>
                            index < breadcrumbs.length - 1 &&
                            handleBreadcrumbClick(crumb.id, index)
                          }
                        >
                          {crumb.name}
                        </span>
                        {index < breadcrumbs.length - 1 && (
                          <span className="text-sm text-[#7F7D83] ">/</span>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                  {/* Folder Section */}
                  <div className={"mt-4 mx-8 px-4 py-4 bg-white rounded-lg"}>
                    <div className="text-lg font-interSemiBold text-[#4F4D55]">
                      Folders ({subFolders ? subFolders.length : 0})
                    </div>
                    <div
                      className={`flex items-center mt-2 ${
                        subFolders?.length === 0
                          ? "justify-center"
                          : "justify-start"
                      }`}
                    >
                      {subFolders &&
                        subFolders?.length > 0 &&
                        subFolders?.map((folder: any, index: number) => (
                          <>
                            <div
                              ref={folderDropdownRef}
                              className="flex gap-x-4 mb-4 px-1.5"
                              key={index + folder._id}
                            >
                              <div className="border border-[#D7DFE9] rounded-lg bg-[#F9FAFB] flex flex-col justify-center shadow-customShadow16 px-5 py-5 w-[250px] ml-2 relative">
                                <div className="flex justify-between items-start">
                                  <div
                                    className="w-full"
                                    onClick={() => handleFolderClick(folder)}
                                  >
                                    <p className="text-sm font-interSemiBold text-black pb-1.5">
                                      {folder?.name}
                                    </p>
                                    <p className="text-sm font-interRegular text-[#7F7D83] pb-1.5">
                                      {folder?.subFoldersCount || 0}{" "}
                                      {folder?.subFoldersCount > 1
                                        ? "Folders"
                                        : "Folder"}{" "}
                                      | {folder?.filesCount || 0}{" "}
                                      {folder?.filesCount > 1
                                        ? "Files"
                                        : "File"}
                                    </p>
                                  </div>
                                  <div className="relative">
                                    <img
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setIsShowFolderDropdown((prev: any) =>
                                          prev === folder._id
                                            ? null
                                            : folder._id
                                        );
                                      }}
                                      className="cursor-pointer on-hover-bg-grey w-6 h-6 p-1 rounded"
                                      src={allImgPaths.threeVerticleDotsIcon}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {/* Folder dropdown menu */}
                                <div
                                  ref={folderDropdownRef}
                                  className={`absolute bg-white w-36 left-56 top-8 shadow-customShadow14 rounded-lg z-[100] ${
                                    isShowFolderDropdown === folder._id
                                      ? "block"
                                      : "hidden"
                                  }`}
                                >
                                  <ul>
                                    <li
                                      className="flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer"
                                      onClick={() => {
                                        setOpenEditFolderModal(true);
                                        setEditFolderData({
                                          id: folder._id,
                                          name: folder.name,
                                        });
                                      }}
                                    >
                                      <img
                                        className="w-[18px]"
                                        src={allImgPaths.editIcon}
                                        alt="Edit"
                                      />
                                      <span className="text-sm font-interMedium text-[#0A090B]">
                                        Edit
                                      </span>
                                    </li>
                                    <li className="flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer">
                                      <img
                                        className="w-[18px]"
                                        src={allImgPaths.downloadIcon2}
                                        alt="Download"
                                      />
                                      <span className="text-sm font-interMedium text-[#0A090B]">
                                        Download
                                      </span>
                                    </li>
                                    <li
                                      className="flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer"
                                      onClick={() => {
                                        setItemToDelete(folder._id);
                                        setIsShowDeleteFolder(true);
                                      }}
                                    >
                                      <img
                                        src={allImgPaths.deleteicon2}
                                        alt="Delete"
                                      />
                                      <span className="text-sm font-interMedium text-[#C53434]">
                                        Delete
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}

                      {subFolders?.length === 0 && (
                        <div className="flex flex-col items-center justify-center py-8">
                          <div className="bg-white p-3 rounded-lg border-[#E4E7EC] border-[1px] mb-4">
                            <img
                              src={allImgPaths.noFolderIcon}
                              alt="No folders"
                              height={24}
                              width={24}
                            />
                          </div>
                          <p className="text-[#101828] font-interSemiBold mb-1">
                            No folders found
                          </p>
                          <p className="text-[#475467] text-sm">
                            Create a new folder to organize your files
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* File Section */}
                  <div
                    className={
                      "mt-4 mx-8 bg-white rounded-lg h-[calc(100vh_-_400px)] overflow-y-auto "
                    }
                  >
                    <div className="flex items-center justify-between px-4 py-1">
                      <div className="text-lg font-interSemiBold text-[#4F4D55]">
                        Files ({currentFiles ? currentFiles.length : 0})
                      </div>
                      <div className="flex items-center gap-x-4">
                        <p className="text-[#7F7D83] text-sm">
                          {selectedRootFiles.length} Selected
                        </p>

                        <button
                          className={`flex items-center gap-x-2 border mt-1 mb-1 rounded-md px-2 py-2 w-max $"border-[#E6E6E6]"} ${
                            selectedRootFiles.length === 0
                              ? "text-red-500 bg-[#F1F1F1] cursor-not-allowed"
                              : "hover:border-[#1751D0] cursor-pointer "
                          }`}
                          disabled={selectedRootFiles.length === 0}
                          onClick={() => {
                            {
                              /* Empty State */
                            }
                            {
                              /* {comparePatents.length === 0 &&
                                                                      <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                                                                          <div className="text-center">
                                                                              <img className="mb-2.5 ml-auto mr-auto" src={allImgPaths.searchIcon3} alt="" />
                                                                              <p className="font-interSemiBold text-base text-[#101828]">No data found</p>
                                                                              <p className="font-interRegular text-sm text-[#475467]">Please add patents, files, URLs, publications to <br /> compare with your source patent</p>
                                                                          </div>
                                                                      </div>
                                                                  } */
                            }
                            setOpenAddToProjectModal(true);
                          }}
                        >
                          <img
                            className={`hover:bg-[#f2f4f7] ${
                              selectedRootFiles.length === 0 ? "opacity-20" : ""
                            }`}
                            src={allImgPaths.folderPlusIcon}
                            alt=""
                            height={20}
                            width={24}
                          />
                          <span
                            className={`text-sm font-interMedium  ${
                              selectedRootFiles.length === 0
                                ? "text-[#C9C9CC]"
                                : " text-[#4F4D55]"
                            }`}
                          >
                            Add to Project
                          </span>
                        </button>
                        <div
                          className={`${
                            selectedRootFiles.length === 0
                              ? "bg-[#ECECED] opacity-50 p-2 rounded-lg border-[#E4E7EC] border-[1px] cursor-not-allowed"
                              : ""
                          }`}
                        >
                          <img
                            className="hover:bg-[#f2f4f7]"
                            src={allImgPaths.downloadIcon2}
                            alt=""
                            height={20}
                            width={24}
                          />
                        </div>

                        <div
                          className={`${
                            selectedRootFiles.length === 0
                              ? "bg-[#ECECED] opacity-50 p-2 rounded-lg border-[#E4E7EC] border-[1px] cursor-not-allowed"
                              : ""
                          }`}
                        >
                          <img
                            className="on-hover-bg-grey w-[28px] h-[28px] p-1 rounded"
                            src={allImgPaths.deleteIcon3}
                            alt="Delete"
                            onClick={() => {
                              selectedRootFiles.length !== 0 &&
                                setIsShowDeleteMultipleFile(true);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {currentFiles && currentFiles.length > 0 && (
                      <div className="rounded-bl-lg rounded-br-lg overflow-hidden">
                        {/* Table Head */}
                        {
                          <div className="flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1]">
                            <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                              <div className="flex items-center gap-x-2 cursor-pointer">
                                <div className="relative leading-none">
                                  <input
                                    type="checkbox"
                                    id={"selectAllRootFiles"}
                                    name="checkbox-group"
                                    checked={selectAllRootFiles}
                                    onChange={handleSelectAllRootFiles}
                                    className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer"
                                  />
                                  <svg
                                    className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <polyline points="4 8 7 11 12 5"></polyline>
                                  </svg>
                                </div>
                                <div className="flex items-center gap-x-1.5">
                                  <span className="font-interMedium text-sm text-[#0A090B]">
                                    Name
                                  </span>
                                  <img
                                    onClick={() => handleSort("file_name")}
                                    className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                      sortConfig?.file_name === "asc"
                                        ? "rotate-0"
                                        : "rotate-180"
                                    }`}
                                    src={allImgPaths.arrowUp3}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                              <div className="flex items-center gap-x-2 cursor-pointer">
                                <span className="font-interMedium text-sm text-[#0A090B]">
                                  Uploaded On
                                </span>
                                <img
                                  onClick={() => handleSort("uploaded_at")}
                                  className={`w-4 cursor-pointer transform transition-transform duration-300 ${
                                    sortConfig?.uploaded_at === "asc"
                                      ? "rotate-0"
                                      : "rotate-180"
                                  }`}
                                  src={allImgPaths.arrowUp3}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                              <div className="flex items-center gap-x-2 cursor-pointer">
                                <span className="font-interMedium text-sm text-[#0A090B]">
                                  Action
                                </span>
                              </div>
                            </div>
                          </div>
                        }

                        {/* Table body */}
                        <div className="max-h-[calc(100vh_-_332px)] overflow-y-auto rounded-bl-lg rounded-br-lg">
                          {currentFiles.map((row: any, rowIndex: number) => (
                            <div className="h-auto">
                              <div className="flex">
                                <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex">
                                  <div className="flex items-center gap-x-2 cursor-pointer">
                                    <div className="relative">
                                      <input
                                        type="checkbox"
                                        id={"item" + rowIndex}
                                        name="checkbox-group"
                                        checked={selectedRootFiles.includes(
                                          row._id
                                        )}
                                        onChange={(e) =>
                                          handleSelectedRootFiles(e, row)
                                        }
                                        className="peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                                                                    checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                                                                    transition-colors cursor-pointer"
                                      />
                                      <svg
                                        className="absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <polyline points="4 8 7 11 12 5"></polyline>
                                      </svg>
                                    </div>
                                    <Link
                                      to={row.file_url}
                                      target="_blank"
                                      className="font-interMedium inline-block"
                                    >
                                      <div className=" font-interMedium text-sm pb-0.5 hover:text-[#113B98] text-[#1751D0] underline">
                                        {row.file_name}
                                      </div>
                                    </Link>
                                  </div>
                                </div>

                                <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                  <div>
                                    <p className="text-sm text-[#4F4D55] font-interRegular">
                                      {" "}
                                      {formatDate(row.uploaded_at)}
                                    </p>
                                    <p className="text-sm text-[#ADACB0] font-interRegular">
                                      {" "}
                                      {row?.uploaded_by?.name}{" "}
                                    </p>
                                  </div>
                                </div>

                                <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                  <div className="flex items-center gap-x-2 justify-center">
                                    {/* {
                                                                                                (row.patent_status === 'not_started' || row.patent_status === 'failed') &&
                                                                                                <div className="relative group">
                                                                                                    <img onClick={() => startIndivijualAnalyzation(row)} className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.analyzeIcon} alt="" />

                                                                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                                                                        <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                                                                        <ul className="text-center">
                                                                                                            <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                                                                                Analyze
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            } */}
                                    {/* {
                                                                                                row.patent_status === 'completed' &&
                                                                                                <div className="relative group">
                                                                                                    <img onClick={() => handlePatentClick(row)} className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.viewAnalysis} alt="" />
                                                                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                                                                        <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                                                                        <ul className="text-center">
                                                                                                            <li className="text-xs font-interMedium text-white pb-1 last:pb-0 whitespace-nowrap">
                                                                                                                View Analysis
                                                                                                            </li>                                                                                                            
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            } */}
                                    {/* {
                                                                                                row.patent_status === 'in_progress' &&
                                                                                                <div className="relative group">
                                                                                                    <img className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.analyzingIcon1} alt="" />
                                                                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                                                                        <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                                                                        <ul className="text-center">
                                                                                                            <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                                                                                Analyzing...
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            } */}
                                    <img
                                      className="hover:bg-[#f2f4f7] cursor-pointer"
                                      src={allImgPaths.downloadIcon2}
                                      alt=""
                                      height={20}
                                      width={24}
                                    />

                                    <img
                                      className="cursor-pointer on-hover-bg-grey w-[28px] h-[28px] p-1 rounded"
                                      src={allImgPaths.deleteIcon3}
                                      alt=""
                                      onClick={() => {
                                        setItemToDelete(row._id);
                                        setIsShowDeleteFile(true);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {currentFiles?.length === 0 && (
                      <div className="flex flex-col items-center justify-center h-96">
                        <div className="bg-white p-3 rounded-lg border-[#E4E7EC] border-[1px] mb-4">
                          <img
                            src={allImgPaths.noFileIcon}
                            alt="No folders"
                            height={24}
                            width={24}
                          />
                        </div>
                        <p className="text-[#101828] font-interSemiBold mb-1">
                          No files found
                        </p>
                        <p className="text-[#475467] text-sm">
                          Upload files to get started
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {openAddToProjectModal && (
          <AddToProjectModal
            portfolioId={portfolioId}
            isShow={openAddToProjectModal}
            setIsShow={setOpenAddToProjectModal}
            selectedFiles={selectedRootFiles}
            onSuccess={onSuccessFullFileAttach}
          />
        )}
      </div>

      {/* Delete Portfolio */}
      {isShowDeletePortfolio && (
        <DeleteModal
          isShow={isShowDeletePortfolio}
          setIsShow={() => setIsShowDeletePortfolio(false)}
          id={portfolioId || "0"}
          title={DELETE_MODAL_DATA.portfolio.title}
          description={DELETE_MODAL_DATA.portfolio.description}
          onDelete={handleDeletePortfolio}
        />
      )}
      {/* Delete Folder */}
      {isShowDeleteFolder && (
        <DeleteModal
          isShow={isShowDeleteFolder}
          setIsShow={() => setIsShowDeleteFolder(false)}
          id={itemToDelete as string}
          title={DELETE_MODAL_DATA.portfolio_folder.title}
          description={DELETE_MODAL_DATA.portfolio_folder.description}
          onDelete={handleDeleteFolder}
        />
      )}
      {/* Delete File */}
      {isShowDeleteFile && (
        <DeleteModal
          isShow={isShowDeleteFile}
          setIsShow={() => setIsShowDeleteFile(false)}
          id={itemToDelete as string}
          title={DELETE_MODAL_DATA.portfolio_file.title}
          description={DELETE_MODAL_DATA.portfolio_file.description}
          onDelete={handleDeleteFile}
        />
      )}
      {/* Delete Multiple File */}
      {isShowDeleteMultipleFile && (
        <DeleteModal
          isShow={isShowDeleteMultipleFile}
          setIsShow={() => setIsShowDeleteMultipleFile(false)}
          id=""
          title={DELETE_MODAL_DATA.portfolio_multiple_file.title}
          description={DELETE_MODAL_DATA.portfolio_multiple_file.description}
          onDelete={handleDeleteMultipleFiles}
        />
      )}
      {openUploadFileModal && (
        <UploadFileModal
          isShow={openUploadFileModal}
          setIsShow={setOpenUploadFileModal}
          id={portfolioId || ""}
          setRefreshData={() => setRefreshData((prev) => !prev)}
          currentFolderId={currentFolderId}
        />
      )}

      {openFolderModal && (
        <AddNewFolderModal
          isShow={openFolderModal}
          setIsShow={setOpenFolderModal}
          portfolioId={portfolioId || ""}
          setRefreshData={() => setRefreshData((prev) => !prev)}
          currentFolderId={currentFolderId}
        />
      )}

      {openEditFolderModal && (
        <AddNewFolderModal
          isShow={openEditFolderModal}
          setIsShow={setOpenEditFolderModal}
          portfolioId={portfolioId || ""}
          setRefreshData={() => setRefreshData((prev) => !prev)}
          isEditMode={true}
          folderIdToEdit={editFolderData?.id}
          existingFolderName={editFolderData?.name}
        />
      )}
    </div>
  );
}
export default PortfolioDetails;
