import { useEffect,useState } from 'react';
import { Link,useNavigate,useLocation } from 'react-router-dom';

import useAppState from '../../context/useAppState';
// import CustomSelect from "./CustomSelect";

import allImgPaths from 'assets/images';
import CustomSelect from "./CustomSelect";

const Sidebar = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const { pathname } = location;
    const { retrieveUserTeams,hasRetrievedTeams,handleChangeTeam,selectedTeam,teamList,setTeamChange } = useAppState('selectTeam');
    const { handleClick,showSidebar } = useAppState('sidebar');

    useEffect(() => {
        // hasRetrievedTeams than no need to call api
        if (!hasRetrievedTeams) {
            retrieveUserTeams();
        }
    },[hasRetrievedTeams,selectedTeam]);

    useEffect(() => {
        setTimeout(() => {
            if (localStorage.getItem('add_new_team')) {
                retrieveUserTeams();
                localStorage.removeItem('add_new_team')
            }
        },0)
    });

    const handleChange = (value: any) => {
        if (value?.value == 'pendingInvitation') return;

        handleChangeTeam(value);
        if (value?.value != 'addNewTeam') {
            setTeamChange(true);
        }
        // updateUserEventCallApiFlag(true);
    }

    return (
        <div className={`w-60 border-r border-[#D0D5DD] ${!showSidebar ? 'collapsed-sidebar' : ''}  smoothness switch-to-overlay`}>
            {/* Logo */}
            <div className='py-[21px] px-[18px] relative collapsed-logo-wrapper smoothness cursor-pointer'>
                <div onClick={() => navigate('/')} className='w-[208px] gap-x-0.5 flex items-center collapsed-logo smoothness'>
                    {<img className='min-w-[40px] max-h-[35px] object-contain shrink-0 smoothness' src={allImgPaths.miniLogo} alt='main logo' />}
                    <span className='text-[#1e0af5] font-interSemiBold text-[30px] smoothness'>iPVijil</span>
                </div>
                <span className='absolute border border-[#D0D5DD] rounded-full w-6 h-6 flex items-center justify-center cursor-pointer -bottom-3 -right-3 z-10 bg-white collapsed-icon smoothness hover:border-[#1e0af5]'>
                    <img className='transform rotate-180 smoothness' src={allImgPaths.rightSideIcon2} alt='' onClick={handleClick} />
                </span>
            </div>
            <div className='pt-4 px-3 pb-4'>
                <ul className='sidebar-selectbox sidebar-dropdown-adjust disable-hover-on-focus'>
                    <CustomSelect
                        selectedValue={selectedTeam}
                        options={teamList}
                        onChange={(value: any) => handleChange(value)}
                        extendedOption={true}
                        className="on-hover-border-blue rounded"
                    />
                    <li className={`${pathname === '/' && 'active'} group relative flex items-center gap-x-3 py-2 hover:bg-[#F5F8FD] rounded-[5px] cursor-pointer mb-2 collapsed-list justify-start sidebar-menu`} onClick={() => navigate('/')}>
                        <img className='inline-block ml-3 w-5 on-hover-blue' src={allImgPaths.homeIcon2} alt='home' />
                        <Link className={`font-interMedium group-hover:text-[#1751D0] text-base text-[#344054] inline-block smoothness sidebar-link`} to='/'>
                            Home
                        </Link>
                        {/* <span className='group-hover:inline-block hidden absolute -left-3 w-[5px] h-10 bg-[#1E0AF5] rounded-t-none rounded-r-[3px] rounded-b-[3px] rounded-l-none sidebar-menu-blue-line'></span> */}
                    </li>

                    <li
                        className={`${pathname === '/project' && 'active'} group relative flex items-center gap-x-3 py-2 hover:bg-[#F5F8FD] rounded-[5px] cursor-pointer mb-2 collapsed-list justify-start sidebar-menu`}
                        onClick={() => navigate('/project')}>
                        <img className='inline-block ml-3 w-5 on-hover-blue' src={allImgPaths.boxIconGrey} alt='home' />
                        <Link className='font-interMedium group-hover:text-[#1751D0] text-base text-[#344054] inline-block smoothness sidebar-link' to='/project'>
                            Projects
                        </Link>
                        {/* <span className='group-hover:inline-block hidden absolute -left-3 w-[5px] h-10 bg-[#1E0AF5] rounded-t-none rounded-r-[3px] rounded-b-[3px] rounded-l-none sidebar-menu-blue-line'></span> */}
                    </li>

                    <li
                        className={`${pathname === '/portfolio' && 'active'} group relative flex items-center gap-x-3 py-2 hover:bg-[#F5F8FD] rounded-[5px] cursor-pointer mb-2 collapsed-list justify-start sidebar-menu`}
                        onClick={() => navigate('/portfolio')}>
                        <img className='inline-block ml-3 w-5 on-hover-blue' src={allImgPaths.folderIcon} alt='home' />
                        <Link className='font-interMedium group-hover:text-[#1751D0] text-base text-[#344054] inline-block smoothness sidebar-link' to='/portfolio'>
                            Portfolios
                        </Link>
                        {/* <span className='group-hover:inline-block hidden absolute -left-3 w-[5px] h-10 bg-[#1E0AF5] rounded-t-none rounded-r-[3px] rounded-b-[3px] rounded-l-none sidebar-menu-blue-line'></span> */}
                    </li>
                    <li
                        className={`${pathname === '/settings' && 'active'} group relative flex items-center gap-x-3 py-2 hover:bg-[#F5F8FD] rounded-[5px] cursor-pointer mb-2 collapsed-list justify-start sidebar-menu`}
                        onClick={() => navigate('/settings')}>
                        <img className='inline-block ml-3 w-5 on-hover-blue' src={allImgPaths.settingsIcon} alt='Settings' />
                        <Link className='font-interMedium group-hover:text-[#1751D0] text-base text-[#344054] inline-block smoothness sidebar-link' to='/settings'>
                            Settings
                        </Link>
                        {/* <span className='group-hover:inline-block hidden absolute -left-3 w-[5px] h-10 bg-[#1E0AF5] rounded-t-none rounded-r-[3px] rounded-b-[3px] rounded-l-none sidebar-menu-blue-line'></span> */}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
