import { useEffect, useState } from "react";
import allImgPaths from "assets/images";
import { cloneDeep } from "lodash";
import {
  addInviteCollaborator,
  getInviteTeamMemberList,
  getProjectInviteRoles,
} from "services/project";
import { Modal } from "./Modal";
import { HeaderModal } from "./Header";
import { Body } from "./Body";
import CustomCheckBox from "components/CustomCheckBox";
import CustomSelect from "../CustomSelect";
import { FooterModal } from "./Footer";
import useAppState from "context/useAppState";
import {
  addInviteCollaboratorForPortfolio,
  getInviteTeamMemberListForPortfolio,
  getPortfolioInviteRoles,
} from "services/portfolio";

interface props {
  setOpenInviteCollaborators: any;
  openInviteCollaborators: boolean;
  setRefreshData: any;
  selectedProjectId?: any;
  selectedPortfolioId?: any;
  type?: string;
}

const InviteCollaborator = ({
  setOpenInviteCollaborators,
  openInviteCollaborators,
  selectedProjectId = null,
  selectedPortfolioId = null,
  setRefreshData,
  type = "project",
}: props) => {
  const [error, setError] = useState("");
  const { fetchCollaborators } = useAppState("project");
  const { fetchCollaboratorsForPortfolios } = useAppState("portfolio");

  const [filteredData, setFilteredData] = useState<any>([]);
  const currentTeam = JSON.parse(
    localStorage.getItem("selectedTeam") as string
  );
  const [isApiCalled, setIsApiCalled] = useState(false);

  // const [selectedRolesCheckbox, setSelectedRolesCheckbox] = useState<any[]>([]);
  const [selectedRolesDropdown, setSelectedRolesDropdown] = useState<any[]>([]);
  const [emailText, setEmailText] = useState("");
  const [roleOptions, setRoleOptions] = useState<any>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [formData, setFormData] = useState<any>({
    selectedEmail: [],
    email: [],
  });

  useEffect(() => {
    //api call for filteredData to get
    (async () => {
      let teamMemberData: any = [];
      let roleData: any = [];
      if (type === "project" && selectedProjectId) {
        const { data } = await getInviteTeamMemberList(
          selectedProjectId,
          currentTeam.currentTeamId
        );
        teamMemberData = data;

        const projectRoleData = await getProjectInviteRoles();
        roleData = projectRoleData;
      } else if (type === "portfolio" && selectedPortfolioId) {
        const { data } = await getInviteTeamMemberListForPortfolio(
          selectedPortfolioId,
          currentTeam.currentTeamId
        );
        teamMemberData = data;

        const portfolioRoleData = await getPortfolioInviteRoles();
        roleData = portfolioRoleData;
      }

      const filterRecords = teamMemberData?.map((item: any, index: any) => ({
        id: item._id,
        index: index + 1,
        profile_image: item.profile_image,
        name: item.name,
        userName: `@${item.email.split("@")[0]}`,
        email: item.email,
        teams: {
          roleName: item.name,
        },
        selected: false,
      }));

      const filterRoleRecords = roleData?.data?.map(
        (item: any, index: any) => ({
          id: item._id,
          value: item.name,
          label: item.name,
          key: index + 1,
        })
      );
      setFilteredData(filterRecords);
      setRoleOptions(filterRoleRecords);
    })();
  }, [openInviteCollaborators]);

  const handleChangeRole = (index: any, selectedRole: any) => {
    const updatedRoles = [...selectedRolesDropdown];
    updatedRoles[index] = selectedRole;
    setSelectedRolesDropdown(updatedRoles);

    setFormData({ ...formData, email: updatedRoles });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setEmailText(value);
  };

  const handleAddEmail = () => {
    setError("");

    const trimmedText = emailText.replace(/[,\s]/g, "");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(trimmedText);

    if (!trimmedText || emails.includes(trimmedText)) {
      // If textarea is empty or email already exists, do not add it
      return;
    }
    if (trimmedText && isValid) {
      const updatedEmails = [...emails, trimmedText];
      setEmails([...emails, trimmedText]);
      setFormData({ ...formData, selectedEmail: updatedEmails });
      setEmailText("");
    } else {
      setError("Please enter valid email");
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddEmail();
    }
  };

  const handleInputChange = (event: any) => {
    const emailText = event.target.value;

    const trimmedText = emailText.replace(/[,\s]/g, "");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(trimmedText);

    if (!trimmedText || emails.includes(trimmedText)) {
      setError("");
      return;
    }
    if (trimmedText && isValid) {
      setEmailText("");
    } else {
      setError("Please enter valid email");
    }
  };

  const handleDelete = (index: number) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
    setFormData({ ...formData, selectedEmail: updatedEmails });
  };

  const handleChangeCheckbox = (index: number, isChecked: boolean) => {
    // Update the selectedRoles array based on checkbox change
    // const updatedRoles = [...selectedRolesCheckbox];
    // updatedRoles[index] = isChecked;
    // setSelectedRolesCheckbox(updatedRoles);

    const clonedFilteredData = cloneDeep(filteredData);
    clonedFilteredData[index]["selected"] = isChecked;
    setFilteredData(clonedFilteredData);
  };

  const constructPayload = () => {
    const PROJECT_ROLE_ID = "65c3475afc8bd3fa64491003";
    const PORTFOLIO_ROLE_ID = "65c3475afc8bd3fa64492003";
    const selectedEmails = filteredData
      // .filter((_: any, index: any) => selectedRolesCheckbox[index])
      .filter((member: any, index: any) => member.selected)
      .map((item: any, index: number) => {
        let roleFieldName: any;
        let roleId: any;
        if (type === "project") {
          roleFieldName = "project_role_id";
          roleId = PROJECT_ROLE_ID;
        } else if (type === "portfolio") {
          roleFieldName = "portfolio_role_id";
          roleId = PORTFOLIO_ROLE_ID;
        }

        return {
          email: item.email,
          [roleFieldName]: selectedRolesDropdown[index]?.id || roleId,
        };
      });

    const emailFromTextarea = emails.map((email) => {
      let roleFieldName: any;
      let roleId: any;
      if (type === "project") {
        roleFieldName = "project_role_id";
        roleId = PROJECT_ROLE_ID;
      } else if (type === "portfolio") {
        roleFieldName = "portfolio_role_id";
        roleId = PORTFOLIO_ROLE_ID;
      }
      return {
        email,
        [roleFieldName]: roleId,
      };
    });

    const allEmails = [...selectedEmails, ...emailFromTextarea];
    const uniqueEmails = Array.from(
      new Map(allEmails.map((member) => [member.email, member])).values()
    );

    const payload = {
      // user_details: [...selectedEmails, ...emailFromTextarea],
      user_details: uniqueEmails,
      team_id: currentTeam.currentTeamId,
    };

    return payload;
  };

  const postData = async () => {
    const payload = constructPayload();
    setIsApiCalled(true);

    try {
      if (type === "project") {
        await addInviteCollaborator(selectedProjectId, payload);
        fetchCollaborators(selectedProjectId);
      } else if (type === "portfolio") {
        await addInviteCollaboratorForPortfolio(selectedPortfolioId, payload);
        fetchCollaboratorsForPortfolios(selectedPortfolioId);
      }
      setEmails([]);
      setEmailText("");
      setOpenInviteCollaborators(false);
      setError("");
      setIsApiCalled(false);
      setRefreshData((prev: any) => !prev);
    } catch (error) {
      setIsApiCalled(false);
    }
  };

  const handleSubmit = (event: any) => {
    event?.preventDefault();
    const payload = constructPayload();
    postData();
  };

  const handleUserImageError = (e: any) => {
    e.target.src = allImgPaths.defaultProfile;
  };

  const cancel = () => {
    setEmailText("");
    setFormData({ email: [], selectedEmail: [] });
    setEmails([]);
    setOpenInviteCollaborators(false);
    setIsApiCalled(false);
    setError("");
  };

  const isFormValid = () => {
    const selectedEmails = filteredData.filter((item: any) => item.selected);
    // console.log({ error, emails, selectedEmails, isApiCalled })
    if (error || (!emails.length && !selectedEmails.length)) return false;
    return true;
  };

  return (
    <Modal
      className={"modal-wrapper max-w-[480px]"}
      isShow={openInviteCollaborators}
    >
      <form onSubmit={handleSubmit}>
        <HeaderModal
          title="Project Settings"
          isShowHeader={true}
          className="modal-header px-6 pb-5"
        >
          <div className="py-6">
            <img
              className="w-[120px] h-[56px] mx-auto"
              src={allImgPaths.inviteTeamProjectIcon}
              alt=""
            />
          </div>
          <div className="">
            <h2 className="text-lg text-[#101828] text-center font-interSemiBold leading-none pb-1.5">
              Add to your {type === "project" ? "project" : "portfolio"}
            </h2>
          </div>

          <p className="text-sm text-[#475467] text-center">
            Invite colleagues to collaborate on this{" "}
            {type === "project" ? "project" : "portfolio"}
          </p>

          <div
            onClick={() => setOpenInviteCollaborators(false)}
            className="absolute top-6 right-6 cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1"
          >
            <img src={allImgPaths.closeIcon} />
          </div>
        </HeaderModal>

        <Body title="" isShowBody={true} className="modal-body px-6">
          <div className="mb-5">
            <p className="text-sm text-[#344054] font-interMedium pb-2">
              Invite with Email
            </p>
            <div className="border border-[#D0D5DD] rounded-lg w-full py-3 px-3.5 flex flex-col-reverse h-24 overflow-y-auto hover:border-[#1E0AF5] focus-within:border-[#1E0AF5]">
              <textarea
                className="resize-none mb-auto min-h-8 placeholder:text-base placeholder:text-[#667085]"
                name="selectedEmail"
                id="selectedEmail"
                value={emailText}
                onChange={handleEmailChange}
                onKeyUp={handleKeyUp}
                onBlur={handleAddEmail}
                onInput={handleInputChange}
                placeholder="Enter email addresses"
              />

              <div className="chips-container">
                {emails.map((email: string, index: number) => (
                  <div key={index} className="chip">
                    {email}
                    <button onClick={() => handleDelete(index)}>&times;</button>
                  </div>
                ))}
              </div>
            </div>
            <p className="text-sm text-[#475467] pt-1">
              Invites sent out to non-team-members will have to be approved by
              the {type === "project" ? "project" : "portfolio"} owners
            </p>
            <div className="text-red-500 font-interSemiBold mt-3">{error}</div>
          </div>

          {filteredData.length > 0 && (
            <>
              <div className="flex justify-between items-center mb-3">
                <p className="text-[#344054] text-sm font-interMedium">
                  Select from Team Members
                </p>
              </div>

              <div className=" mb-4">
                {filteredData.map((member: any, index: number) => (
                  <div className="" key={index}>
                    <div className="flex justify-between mb-1">
                      <div className="flex w-[75%] items-center checkbox-alignment">
                        <span className="inline-block mr-3">
                          <CustomCheckBox
                            name={`checkBox-${index}`}
                            id={`checkBox-${index}`}
                            // checked={selectedRolesCheckbox[index] || false}
                            checked={member?.selected || false}
                            onChange={(name: string, isChecked: boolean) =>
                              handleChangeCheckbox(index, isChecked)
                            }
                          />
                        </span>

                        <img
                          className="w-10 h-10 rounded-full mr-3 border border-[#ccc]"
                          src={
                            member?.profile_image || allImgPaths.defaultProfile
                          }
                          alt=""
                          onError={handleUserImageError}
                        />
                        <div className="">
                          <h6 className="text-[#101828] text-sm font-interSemiBold">
                            {member.name}
                          </h6>
                          <p className="text-sm text-[#475467]">
                            {member.email}
                          </p>
                        </div>
                      </div>

                      <div className="w-[25%] modal-select-box -mr-2 ">
                        <CustomSelect
                          key={index}
                          selectedValue={selectedRolesDropdown[index]}
                          options={roleOptions}
                          defaultValue={roleOptions[1]}
                          onChange={(selectedRole) =>
                            handleChangeRole(index, selectedRole)
                          }
                          extendedOption={false}
                          className="border rounded-lg hover:border-[#1E0AF5] focus-within:border-[#1E0AF5] disable-hover-on-focus"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </Body>

        <FooterModal
          title=""
          isShowFooter={true}
          className="modal-footer pt-4 px-6 pb-4 border-t border-[#EAECF0]"
        >
          <div className="flex items-center gap-x-3 justify-end">
            <button
              onClick={cancel}
              className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base hover:bg-[#F2F4F7] w-40"
            >
              Cancel
            </button>
            <button
              className={`${
                !isFormValid() || isApiCalled ? "button-disabled" : ""
              } !text-base text-white bg-[#1E0AF5] hover:bg-[#120693] font-interSemiBold rounded-lg  px-3.5 py-2.5 w-40`}
            >
              Done
            </button>
          </div>
        </FooterModal>
      </form>
    </Modal>
  );
};

export default InviteCollaborator;
