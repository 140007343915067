import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEn from '../i18n/lang/en.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: "en", // default language
    resources: {
      en: {
        translation: translationEn,
      },
    },
    fallbackLng: "en", // if translation is not available
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
