import { useState, useEffect } from "react";
import Toast from "components/common/Toast";
import { twi } from "tw-to-css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const useClaimChartDownload = () => {
  const [showClaimChartDownload, setShowClaimChartDownload] = useState(false);
  const [isDownloadClicked, setIsDownloadClicked] = useState(false);
  const [newData, setNewData] = useState<any>(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [claimChartData, setClaimChartData] = useState<any>({});

  const loaderMessages = [
    "Preparing your document...",
    "Analyzing data...",
    "Extracting content from HTML...",
    "Processing images...",
    "Converting content to Word format...",
    "Optimizing formatting...",
    "Generating report...",
    "Finalizing your download...",
  ];

  const convertTailwindClassesToInlineStyles = (element: any) => {
    if (!(element instanceof HTMLElement)) {
      throw new Error("The provided argument must be an HTMLElement.");
    }

    const classes: any = element.className.trim();
    if (classes) {
      const css: any = twi(classes.split(/\s+/));
      element.setAttribute("style", css);
      element.className = "";
    }

    Array.from(element.children).forEach((child) => {
      convertTailwindClassesToInlineStyles(child);
    });

    return element.innerHTML;
  };

  const export2wordWithoutImage = (element: any) => {
    let htmlContent: any = document.getElementById(element) || "";

    const clonedElement = htmlContent.cloneNode(true) as HTMLElement;

    const pageBreaks = clonedElement.querySelectorAll(".page-break");
    pageBreaks.forEach((breakElement) => {
      const wordBreak = document.createElement("div");
      wordBreak.innerHTML = `
      <br clear="all" style="mso-special-character:line-break; page-break-before:always">
      <!--[if supportFields]><span style="mso-element:field-begin"></span>
      <span style="mso-spacerun:yes">&nbsp;</span>PAGE
      <span style="mso-element:field-separator"></span><![endif]-->
      <span style="mso-element:field-end"></span>
      `;
      breakElement.replaceWith(wordBreak);
    });
    htmlContent = convertTailwindClassesToInlineStyles(clonedElement);

    const styles = `
        <style>
          body {
            font-family: Arial, sans-serif;
            font-size: 12pt;
          }
          table {
            border-collapse: collapse;
            width: 100%;
          }
          table, th, td {
            border: 1px solid #ddd;
          }
          th, td {
            padding: 8px;
            text-align: left;
          }
          img {
            max-width: 100%;
            height: auto;
          }
        </style>
      `;

    const preHtml = `
        <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
          <head>
            <meta charset='utf-8'>
            <title>Export HTML To Doc</title>
            ${styles}
          </head>
          <body>
      `;

    const postHtml = "</body></html>";

    const fullHtml = preHtml + htmlContent + postHtml;

    const blob = new Blob(["\ufeff", fullHtml], { type: "application/msword" });

    const url =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(fullHtml);

    let filename = "wordDoc";
    filename = filename ? filename + ".doc" : "document.doc";

    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    const ng: any = navigator;
    if (ng.msSaveOrOpenBlob) {
      ng.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
    setIsDownloadClicked(false);
  };

  const exportPDF = async (
    elementId: string,
    filename: string = "document"
  ) => {
    const input = document.getElementById(elementId);
    if (!input) return;

    // Landscape dimensions for A4 (in points)
    const pdfWidth = 841.89; // A4 landscape width
    const pdfHeight = 595.28; // A4 landscape height

    // 1. Capture the HTML element
    const canvas = await html2canvas(input, {
      scale: 2, // Higher resolution
      logging: true, // Debug if needed
      useCORS: true, // For external images
    });

    // 2. Calculate dimensions
    const imgWidth = pdfWidth;
    const imgHeight = (canvas.height * pdfWidth) / canvas.width;

    // 3. Create PDF
    const pdf = new jsPDF("l", "pt", "a4"); // Landscape orientation

    // 4. Add content
    let position = 0;
    const pageHeight = imgHeight;

    // Add first page
    pdf.addImage(
      canvas.toDataURL("image/png"),
      "PNG",
      0, // X position
      position, // Y position
      imgWidth,
      imgHeight
    );

    // Handle multi-page content
    while (position - pageHeight > 0) {
      position -= pdfHeight;
      pdf.addPage();
      pdf.addImage(
        canvas.toDataURL("image/png"),
        "PNG",
        0,
        position,
        imgWidth,
        imgHeight
      );
    }

    // 5. Save PDF
    pdf.save(`${filename}.pdf`);
  };

  useEffect(() => {
    if (isDownloadClicked && imagesLoaded) {
      try {
        export2wordWithoutImage("claim-chart-download");
      } catch (error) {
        Toast.error("Error", "Failed to generate document");
        console.error("Document generation error:", error);
      }
      setShowClaimChartDownload(false);
      setIsDownloadClicked(false);
      setImagesLoaded(false);
    }
  }, [isDownloadClicked, imagesLoaded]);

  useEffect(() => {
    if (!isDownloadClicked) return;

    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex:number) =>
        prevIndex === loaderMessages.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [isDownloadClicked, loaderMessages.length]);

  const handleDownload = async (APIData: any, claimChartData: any) => {
    const processedData = {
      patents: APIData.patents.map((patent: any) => {
        const patentId = patent.patentId;
        const resultItem = APIData.summarized_similarity_results.find(
          (result: any) => result[patentId]
        );
        return {
          ...patent,
          summarized_similarity_results: resultItem?.[patentId] || null,
        };
      }),
    };

    setShowClaimChartDownload(true);
    setNewData(processedData);
    setClaimChartData(claimChartData);
    Toast.success("Success", "Generating Doc, please wait...");
    setIsDownloadClicked(true);
  };
  

  return {
    showClaimChartDownload,
    setShowClaimChartDownload,
    isDownloadClicked,
    setIsDownloadClicked,
    newData,
    imagesLoaded,
    setImagesLoaded,
    currentMessageIndex,
    setCurrentMessageIndex,
    loaderMessages,
    handleDownload,
    claimChartData
  };
};

export default useClaimChartDownload;
