/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, Suspense } from "react";
import Sidebar from "components/common/Sidebar";
import Header from "components/common/Header";
import allImgPaths from "assets/images";
import CreatePortfolioModal from "components/common/modals/CreatePortfolio";
import { useNavigate } from "react-router-dom";
import { deletePortfolio, fetchPortfolioList } from "services/portfolio";
import { formatDate } from "utils/function";
import SearchBar from "components/common/SearchBar";
import Toast from "components/common/Toast";
import Popover from "components/Popover";
import { PortfolioShimmerLoader } from "pages/loaders";
import DeleteModal from "components/common/modals/DeleteModal";
import { DELETE_MODAL_DATA } from "utils/constant";
import InviteCollaborator from "components/common/modals/InviteCollabrators";

function Portfolio() {
  const portfolioColumns = [
    {
      name: "Portfolio Name",
      ref: "portfolioName",
      hide: false,
      position: 1,
    },
    {
      name: "Contents",
      ref: "contents",
      hide: false,
      position: 2,
    },
    {
      name: "Last Update",
      ref: "lastUpdate",
      hide: false,
      position: 3,
    },
    {
      name: "Team",
      ref: "team",
      hide: false,
      position: 4,
    },
    {
      name: "Action",
      ref: "action",
      hide: false,
      position: 5,
    },
  ];

  const [isLoader, setIsLoader] = useState<boolean>(true);
  const [openCreatePortFolioModal, setOpenCreatePortFolioModal] =
    useState(false);
  const [showMenu, setshowMenu] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [arrowUp, setArrowUp] = useState(false);
  const [sortField, setSortField] = useState<any>(null);
  const [paginatedData, setPaginatedData] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    heading: "",
    message: "",
  });
  const [refreshData, setRefreshData] = useState(false);
  const [search, setSearch] = useState("");
  const [isShowDeletePortfolio, setIsShowDeletePortfolio] =
    useState<boolean>(false);
  const [deletePortfolioId, setDeletePortfolioId] = useState<any>(null);
  const [openInviteCollaborators, setOpenInviteCollaborators] = useState(false);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState(0);
  const userData: any = JSON.parse(localStorage.getItem("userData") as string);
  let selectedTeam: any = JSON.parse(
    localStorage.getItem("selectedTeam") as string
  );

  const Shimmer: React.FC = () => {
    return (
      <div className="h-10 w-[42px] flex gap-x-3">
        <div className="rounded-md w-[42px] bg-gray-100"></div>
      </div>
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoader(true);
    setTimeout(() => {
      (async () => {
        selectedTeam = JSON.parse(
          localStorage.getItem("selectedTeam") as string
        );
        let apiData: any = await portfolioAPIdata();
        setPaginatedData(apiData);
        setIsLoader(false);
        setSelectedItems([]);
        setSelectAll(false);
      })();
    }, 2000);
  }, [refreshData, search]);

  const portfolioAPIdata = async () => {
    let portfolioAPIdata = await fetchPortfolioList(
      selectedTeam?.currentTeamId,
      search
    );
    let apiPortfolioData: any = portfolioAPIdata.data;

    let filteredData = apiPortfolioData.map((data: any) => {
      return {
        id: data._id,
        portfolioName: data.name,
        description: data.description,
        lastUpdate: data.updatedAt ? formatDate(data.updatedAt) : null,
        lastUpdateBy: data?.updated_by?.name || "Andy",
        team: [...data.teams],
        folderCount: data.folderCount,
        fileCount: data.fileCount,
      };
    });

    return filteredData;
  };

  const handleSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectAll(e.target.checked);
      if (e.target.checked) {
        // Select all items in the current page
        const currentPageIds = paginatedData.map((_, index) => index);
        setSelectedItems(currentPageIds);
      } else {
        // Deselect all items
        setSelectedItems([]);
      }
    },
    [paginatedData]
  );

  const handleSelectItem = useCallback(
    (index: number) => {
      setSelectedItems((prev) => {
        const newSelected = prev.includes(index)
          ? prev.filter((i) => i !== index)
          : [...prev, index];

        // Update selectAll state based on if all items in current page are selected
        setSelectAll(newSelected.length === paginatedData.length);

        return newSelected;
      });
    },
    [paginatedData]
  );

  const handleSort = (type: string) => {
    const sortedData = [...paginatedData].sort((a, b) => {
      if (type === "lastUpdate") {
        const dateA: any = new Date(a.lastUpdate);
        const dateB: any = new Date(b.lastUpdate);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      } else if (type === "contents") {
        const fileCountA = Number(a.fileCount);
        const fileCountB = Number(b.fileCount);
        return sortOrder === "asc"
          ? fileCountA - fileCountB
          : fileCountB - fileCountA;
        // return sortOrder === "asc"
        //   ? a.fileCount.localeCompare(b.fileCount)
        //   : b.fileCount.localeCompare(a.fileCount);
      } else {
        return sortOrder === "asc"
          ? a[type].localeCompare(b[type])
          : b[type].localeCompare(a[type]);
      }
    });

    setPaginatedData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setArrowUp(!arrowUp);
    setSortField(type);
  };

  const createColumnClassName = (field: string) => {
    if (field === "portfolioName") {
      return "w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6";
    }
    if (field === "contents") {
      return "w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6";
    }
    if (field === "lastUpdate") {
      return "w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6";
    }
    if (field === "team") {
      return "w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6";
    }
    if (field === "action") {
      return "w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-1.5 px-6";
    }
  };

  const handleSearchChange = (value: any) => {
    setSearch(value);
  };

  const handleDeletePortfolio = async (portfolioId: any): Promise<any> => {
    try {
      await deletePortfolio(portfolioId);
      Toast.success("Success", "Portfolio deleted successfully");
      setRefreshData((prev) => !prev);
    } catch (error) {
      Toast.error(
        "Error",
        "Error while deleting portfolio please try again later"
      );
      console.log("Error while deleting portfolio", error);
    }
  };

  const openCreatePortfolioModal = () => {
    setOpenCreatePortFolioModal(true);
  };
  return (
    <div className="dashboard-wrapper flex">
      {/* Sidebar */}
      <Sidebar />

      {/* dashboard main */}
      <div className="w-[calc(100%_-_75px)] ml-auto">
        {/* Header */}
        <Header
          title="Portfolios"
          count={paginatedData?.length || 0}
          isChildEle={false}
          type="portfolio"
        />
        <div className="flex justify-between h-[80vh] w-full">
          <div className=" bg-white w-full">
            <div className="flex justify-between m-[20px] items-center">
              <SearchBar
                img_url={allImgPaths.searchIcon}
                value={search}
                onChange={handleSearchChange}
                width={"w-96"}
                placeholder={"Search portfolio"}
              />
              <div className="flex items-center justify-between  gap-x-[12px] ">
                {/* <img src={allImgPaths.rightCurvedIcon} alt='' /> */}
                {/* <button className='cancle-btn-blue flex gap-x-2' onClick={() => setOpenAddToProjectModal(true)}>
                                    <img src={allImgPaths.folderPlusIcon} alt='' /> Add to Project
                                </button> */}
                <Suspense fallback={<Shimmer />}>
                  <button
                    className="submit-btn-blue flex h-auto gap-x-2 !my-[15px] mx-[14px] !align-middle !items-center"
                    onClick={openCreatePortfolioModal}
                  >
                    <img src={allImgPaths.folderPlusWhiteIcon} alt="" /> Create
                    Portfolio
                  </button>
                </Suspense>
                {false && (
                  <div className="w-4 flex-shrink-0 flex items-center justify-center py-1.5 px-6 mr-0.5 mb-px">
                    <Popover
                      classes="!p-0"
                      trigger={
                        <span className="select-none hover:bg-status-brand/20 rounded-md cursor-pointer w-10 h-10 flex justify-center items-center duration-200 gap-x-1">
                          <img
                            className="hover:bg-[#f2f4f7] rounded w-7 h-7 p-1"
                            src={allImgPaths.verticleDotsGrey}
                            alt=""
                          />
                        </span>
                      }
                      content={
                        //
                        <></>
                      }
                    />
                  </div>
                )}
                {showMenu && <div></div>}
              </div>
            </div>

            <div className="w-full">
              <div className="">
                <div className="overflow-x-scroll bg-white">
                  {isLoader ? <PortfolioShimmerLoader /> : <></>}
                  {!isLoader ? (
                    <div>
                      {/* Tabs Head */}
                      <div className="flex flex-nowrap sticky top-0 z-10 checkbox-alignment2">
                        {paginatedData.length > 0 &&
                          portfolioColumns?.length > 0 &&
                          portfolioColumns.map(
                            (column: any, columnIndex: number) => (
                              <React.Fragment key={columnIndex}>
                                {
                                  <div
                                    className={createColumnClassName(
                                      column.ref
                                    )}
                                  >
                                    {/* To manage sort icon and column width */}
                                    {column.ref === "portfolioName" && (
                                      <span className="font-interSemiBold text-sm text-[#0A090B] flex items-center justify-start gap-x-[8px] w-[100%]">
                                        <div className="relative inline-block">
                                          <input
                                            type="checkbox"
                                            id={"select-all-" + columnIndex}
                                            name="checkbox-group"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            className="peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                            hover:border-indigo-600 
                                                                            checked:bg-indigo-600 checked:border-0 
                                                                            transition-colors cursor-pointer"
                                          />
                                          <svg
                                            className="absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                             inset-0 stroke-white mt-[1px] ml-[1px]"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          >
                                            <polyline points="4 8 7 11 12 5"></polyline>
                                          </svg>
                                        </div>
                                        {column.name}
                                        <img
                                          className={`w-4 cursor-pointer transform ${
                                            sortField === column.ref &&
                                            sortOrder === "asc"
                                              ? "rotate-0"
                                              : "rotate-180"
                                          }`}
                                          onClick={() => handleSort(column.ref)}
                                          src={allImgPaths.arrowUp3}
                                          alt=""
                                        />
                                      </span>
                                    )}

                                    {/* Contents */}
                                    {column.ref === "contents" && (
                                      <span className="font-interSemiBold text-sm text-[#0A090B] flex items-center justify-start gap-x-[8px] w-[100%]">
                                        {column.name}
                                        <img
                                          className={`w-4 cursor-pointer transform ${
                                            sortField === column.ref &&
                                            sortOrder === "asc"
                                              ? "rotate-0"
                                              : "rotate-180"
                                          }`}
                                          onClick={() => handleSort(column.ref)}
                                          src={allImgPaths.arrowUp3}
                                          alt=""
                                        />
                                      </span>
                                    )}

                                    {column.ref === "lastUpdate" && (
                                      <span className="font-interSemiBold text-sm text-[#0A090B] flex items-center justify-start gap-x-[8px] w-[100%]">
                                        {column.name}
                                        <img
                                          className={`w-4 cursor-pointer transform ${
                                            sortField === column.ref &&
                                            sortOrder === "asc"
                                              ? "rotate-0"
                                              : "rotate-180"
                                          }`}
                                          onClick={() => handleSort(column.ref)}
                                          src={allImgPaths.arrowUp3}
                                          alt=""
                                        />
                                      </span>
                                    )}

                                    {/* Column with no sort icon*/}
                                    {column.ref === "team" && (
                                      <span className="font-interSemiBold text-sm text-[#0A090B] flex items-center justify-start gap-x-[8px] w-[100%]">
                                        {column.name}
                                      </span>
                                    )}
                                    {column.ref === "action" && (
                                      <span className="font-interSemiBold text-sm text-[#0A090B] flex items-center justify-start gap-x-[8px] w-[100%]">
                                        {column.name}
                                      </span>
                                    )}
                                  </div>
                                }
                              </React.Fragment>
                            )
                          )}
                      </div>

                      {/* Body */}
                      {
                        <div
                          className={"overflow-y-auto h-[calc(100vh_-_203px)]"}
                        >
                          {paginatedData.length > 0 &&
                            paginatedData.map((row: any, rowIndex: number) => (
                              <React.Fragment key={rowIndex}>
                                <div className={`flex flex-nowrap`}>
                                  {portfolioColumns?.length > 0 &&
                                    portfolioColumns.map(
                                      (column: any, columnIndex: number) => (
                                        <React.Fragment key={columnIndex}>
                                          {column.ref === "portfolioName" && (
                                            <div
                                              className={`${createColumnClassName(
                                                column.ref
                                              )} table-row !justify-start`}
                                            >
                                              <span className="text-sm text-gray-600 flex items-start gap-x-2">
                                                <label
                                                  htmlFor={"item" + rowIndex}
                                                  className="flex gap-x-2 cursor-pointer"
                                                >
                                                  <div className="relative inline-block">
                                                    <input
                                                      type="checkbox"
                                                      id={"item" + rowIndex}
                                                      name="checkbox-group"
                                                      checked={selectedItems.includes(
                                                        rowIndex
                                                      )}
                                                      onChange={() =>
                                                        handleSelectItem(
                                                          rowIndex
                                                        )
                                                      }
                                                      className="peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                                                checked:bg-indigo-600 hover:border-indigo-600 checked:border-0 
                                                                                                transition-colors cursor-pointer"
                                                    />
                                                    <svg
                                                      className="absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                                                   inset-0 stroke-white mt-[1px] ml-[1px]"
                                                      viewBox="0 0 16 16"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth="2"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    >
                                                      <polyline points="4 8 7 11 12 5"></polyline>
                                                    </svg>
                                                  </div>
                                                  <div className="flex flex-col">
                                                    <span
                                                      className="text-gray-900 font-interSemiBold text-sm leading-5 tracking-tight"
                                                      onClick={() =>
                                                        navigate(
                                                          "/portfolio/" +
                                                            row.id +
                                                            "/details"
                                                        )
                                                      }
                                                    >
                                                      {row[column.ref]}
                                                    </span>
                                                    <span className="max-w-[24vw] truncate text-[#7F7D83] font-interSemiBold text-sm leading-5 tracking-tight">
                                                      {row.description}
                                                    </span>
                                                  </div>
                                                </label>
                                              </span>
                                            </div>
                                          )}

                                          {/* contents */}
                                          {column.ref === "contents" && (
                                            <div
                                              className={`${createColumnClassName(
                                                column.ref
                                              )} table-row !flex-col !items-start`}
                                            >
                                              <span className=" flex items-center gap-x-2 text-[#4F4D55] font-interRegular text-sm font-normal leading-5 tracking-tight">
                                                {" "}
                                                {row.fileCount} Files{" "}
                                              </span>
                                              <span className=" flex items-center gap-x-2  font-interRegular text-sm font-normal leading-5 tracking-tight text-[#ADACB0]">
                                                {" "}
                                                {row.folderCount} Folders{" "}
                                              </span>
                                            </div>
                                          )}

                                          {/* lastUpdate */}
                                          {column.ref === "lastUpdate" && (
                                            <div
                                              className={`${createColumnClassName(
                                                column.ref
                                              )} table-row !flex-col !items-start`}
                                            >
                                              <span className=" flex items-center gap-x-2 text-[#4F4D55] font-interRegular text-sm font-normal leading-5 tracking-tight">
                                                {" "}
                                                {row[column.ref]}{" "}
                                              </span>
                                              <span className=" flex items-center gap-x-2  font-interRegular text-sm font-normal leading-5 tracking-tight text-[#ADACB0]">
                                                {" "}
                                                {row.lastUpdateBy}{" "}
                                              </span>
                                            </div>
                                          )}
                                          {column.ref === "team" && (
                                            <div
                                              className={`${createColumnClassName(
                                                column.ref
                                              )} flex items-center`}
                                            >
                                              {row[column.ref]
                                                // .slice(0, 3)
                                                .map(
                                                  (
                                                    item: any,
                                                    index: number
                                                  ) => (
                                                    <div
                                                      key={index}
                                                      className="w-8 h-8 rounded-full -ml-2 border-2 border-[#fff] first:ml-0 relative group"
                                                    >
                                                      <img
                                                        key={index}
                                                        className="w-full h-full rounded-full on-hover-border-shadow"
                                                        src={
                                                          item.profile_image ||
                                                          allImgPaths.defaultProfile
                                                        }
                                                        alt=""
                                                      />
                                                      <div className="bg-[#0C111D] rounded-lg py-2 px-3 min-w-[122px] absolute z-10 top-full left-1/2 transform -translate-x-1/2 mt-2 hidden group-hover:block cursor-default">
                                                        <span className="arrow-top w-0 h-0 block absolute left-1/2 transform -translate-x-1/2 -top-2"></span>
                                                        <ul className="text-center">
                                                          <li
                                                            className="text-xs text-white pb-1 last:pb-0"
                                                            key={item?._id}
                                                          >
                                                            {item?.user_name ||
                                                              item?.user_email}
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              {/* {row[column.ref].length > 3 && (
                                                <div className="w-7 h-7 rounded-full -ml-3 bg-[#dfe2e5] flex items-center justify-center text-sm relative group cursor-pointer">
                                                  +
                                                  {
                                                    row.assigned_to?.slice(3)
                                                      .length
                                                  }
                                                </div>
                                              )} */}
                                              <img
                                                src={allImgPaths.plusIcon}
                                                key={columnIndex + "plus-team-"}
                                                onClick={() => {
                                                  setOpenInviteCollaborators(
                                                    true
                                                  );
                                                  setSelectedPortfolioId(
                                                    row.id
                                                  );
                                                }}
                                                alt=""
                                                className=" p-1 rounded-full cursor-pointer !border !border-dashed !border-[#ccc] on-hover-border-shadow h-6 w-6 -ml-2 bg-white"
                                              />
                                            </div>
                                          )}
                                          {column.ref === "action" && (
                                            <div
                                              className={`${createColumnClassName(
                                                column.ref
                                              )} flex  gap-x-2`}
                                            >
                                              {/* <img className='hover:bg-[#f2f4f7] cursor-pointer' src={allImgPaths.eyeIcon} alt='' height={20} width={24} /> */}
                                              <img
                                                className="hover:bg-[#f2f4f7] cursor-pointer"
                                                src={allImgPaths.downloadIcon2}
                                                alt=""
                                                height={20}
                                                width={24}
                                              />

                                              <img
                                                className="hover:bg-[#f2f4f7] cursor-pointer"
                                                src={allImgPaths.deleteicon2}
                                                alt=""
                                                height={20}
                                                width={24}
                                                onClick={() => {
                                                  setIsShowDeletePortfolio(
                                                    true
                                                  );
                                                  setDeletePortfolioId(row.id);
                                                }}
                                              />
                                            </div>
                                          )}
                                        </React.Fragment>
                                      )
                                    )}
                                </div>
                              </React.Fragment>
                            ))}
                          {paginatedData.length === 0 && (
                            <div className="">
                              <div className="flex justify-center items-center h-full mt-16">
                                <div className="text-center">
                                  <img
                                    className="mb-2.5 ml-auto mr-auto max-w-48"
                                    src={allImgPaths.noProjects}
                                    alt=""
                                  />
                                  <p className="font-interSemiBold text-base text-[#455468] pb-0.5">
                                    No portfolio found
                                  </p>
                                  <p className="font-interRegular text-sm mb-4 text-[#475467]">
                                    Create new to get started.
                                  </p>

                                  <button
                                    className="submit-btn-blue flex h-auto gap-x-2 !my-[15px] mx-[14px] !align-middle !items-center"
                                    onClick={openCreatePortfolioModal}
                                  >
                                    <img
                                      src={allImgPaths.folderPlusWhiteIcon}
                                      alt=""
                                    />{" "}
                                    Create Portfolio
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {openCreatePortFolioModal && (
          <CreatePortfolioModal
            isShow={openCreatePortFolioModal}
            setIsShow={setOpenCreatePortFolioModal}
            setToast={setToast}
            setRefreshData={setRefreshData}
          />
        )}
        {/* Delete Portfolio */}
        {isShowDeletePortfolio && (
          <DeleteModal
            isShow={isShowDeletePortfolio}
            setIsShow={() => setIsShowDeletePortfolio(false)}
            id={deletePortfolioId}
            title={DELETE_MODAL_DATA.portfolio.title}
            description={DELETE_MODAL_DATA.portfolio.description}
            onDelete={handleDeletePortfolio}
          />
        )}

        {openInviteCollaborators && (
          <InviteCollaborator
            setOpenInviteCollaborators={setOpenInviteCollaborators}
            openInviteCollaborators={openInviteCollaborators}
            selectedPortfolioId={selectedPortfolioId}
            setRefreshData={() => setRefreshData((prev) => !prev)}
            type="portfolio"
          />
        )}
      </div>
    </div>
  );
}
export default Portfolio;
