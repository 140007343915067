import { useReducer } from "react";
import { getCollaboratorsForPortfolio } from "services/portfolio";
const initialArgs = {
  searchQuery: "",
  initialPortfolioMembers: [],
  portfolioMembers: [],
  currentUserId: null,
};

const reducer = (state: any, action: any) => {
  return { ...state, ...action };
};

const usePortfolioHook = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const currentUser = JSON.parse(localStorage.getItem("userData") as string);
  if (currentUser && !state.currentUserId) {
    setState({
      ...state,
      currentUserId: currentUser.id,
    });
  }

  const fetchCollaboratorsForPortfolios = async (id: any) => {
    try {
      const { data } = await getCollaboratorsForPortfolio(
        id,
        state.searchQuery
      );

      const newTransformedData = data.map((item: any, index: any) => ({
        id: item._id,
        user_id: item.user_id,
        portfolioRoleId: item.portfolioRole._id,
        profileImage: "defaultProfile",
        name: item.user_name,
        email: item.user_email,
        teams: {
          roleName: item.portfolioRole.name,
          roleId: item.portfolioRole._id,
        },
      }));

      setState({
        ...state,
        portfolioMembers: newTransformedData,
        initialPortfolioMembers: newTransformedData,
      });
      return newTransformedData;
    } catch (error) {
      console.log("Error fetching access options:", error);
    }
  };

  return {
    fetchCollaboratorsForPortfolios,
    ...state,
  };
};
export default usePortfolioHook;
