import ClaimElement from "components/ClaimElement";
import ClaimGraphicsDownload from "components/ClaimGraphicsDownload";
import React, { useEffect, useState } from "react";
import { formatDate } from "utils/function";

const ClaimChartDownload = ({ newData, claimChartAPIData, imagesLoaded, setImagesLoaded }: any) => {
  const [todayDate, setTodayDate] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");

useEffect(() => {
    const loadImages = async () => {
        const images = Array.from(document.querySelectorAll('img'));
        await Promise.all(images.map(img => 
          new Promise((resolve) => {
                if (img.complete) resolve(true);
                img.onload = resolve;
                img.onerror = resolve;
            })
          ));
          setImagesLoaded(true);
    };
    
    loadImages();
}, []);

  const formatDownloadDate = (date: any) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const month = months[date.getMonth()]; // Get the month
    const day = date.getDate(); // Get the date
    const year = date.getFullYear(); // Get the year
    const dayOfWeek = days[date.getDay()]; // Get the day of the week

    // Get hours, minutes and determine AM/PM
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Pad minutes with leading zero if necessary
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${month} ${day} ${year}, ${dayOfWeek} ${hours}:${formattedMinutes} ${ampm}`;
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = formatDownloadDate(today);
    setTodayDate(formattedDate);
    const userData: any = JSON.parse(
      localStorage.getItem("userData") as string
    );

    setUserName(userData?.name || "");
    const headerData: any = JSON.parse(
      localStorage.getItem("headerData") as string
    );

    setProjectName(headerData?.projectDetail?.title || "");
  }, []);

  return (
    <div id="claim-chart-download" className="mb-10">
      <div id="cover-page">
        <div
        // style={{ borderCollapse: "collapse", border: "none", width: "100%" }}
        >
          <div className="px-6 py-6">
            <div className="">
              <img
                className="mb-1.5"
                src="https://demo-ipvijil-sample-images.s3.us-east-1.amazonaws.com/login_logo.svg"
                alt="mainLogo"
              />
              <p className="text-bse font-interSemiBold text-[#1A75FF] pb-2">
                Vijil Chart - For {claimChartAPIData?.name}
              </p>

              <div className="flex items-center gap-x-1.5">
                {/* <img
                src="https://demo-ipvijil-sample-images.s3.us-east-1.amazonaws.com/device-holder-icon.svg"
                alt="deviceHolderIcon"
              /> */}
                <span className="text-[#344051] text-xs font-interMedium">
                  Project : {projectName}
                </span>
              </div>
            </div>
            <div className="">
              <div className="">
                {/* <img
                src="https://demo-ipvijil-sample-images.s3.us-east-1.amazonaws.com/calendar-icon.svg"
                alt="calenderIcon"
              /> */}
                <span className="text-[#344051] text-xs font-interRegular">
                  Downloaded On : {todayDate}
                </span>
              </div>
              <div className="">
                {/* <img
                src="https://demo-ipvijil-sample-images.s3.us-east-1.amazonaws.com/user-profile-icon.svg"
                alt="userProfileIcon"
              /> */}
                <span className="text-[#344051] text-xs font-interRegular">
                  Downloaded By : {userName}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-10">
          <p className="text-xs italic text-[#7E7E7E] font-interRegular pb-2 mx-auto max-w-screen-lg text-center">
            ** All data has been generated using IPVijil’s AI Assist technology.
            It may need human verification before proceeding with legal
            action.**
          </p>

          <p className="text-center">
            <span className="text-[#1A75FF] font-interSemiBold text-xs">
              {" "}
              IPVijil.ai
            </span>
            <span className="text-[#7E8C9A] font-interRegular text-xs pl-2">
              {" "}
              AI powered IP Intelligence. © 2025 All Rights Reserved.
            </span>
          </p>
          {/* <p className="text-[#7E8C9A] font-interRegular text-xs">Page 1 of 3</p> */}
        </div>
      </div>

      <div className="page-break"></div>
      {newData.patents.map((patent: any) =>
        Object.entries(patent.claims).map(([claimKey, claim]: any) => (
          <>
            <div className="">
              <h3 className="text-lg font-interSemiBold text-[#000000] mb-2">
                {patent.patentNumber}
              </h3>
              <span className="text-lg font-interSemiBold text-[#000000]">
                Claim {claimKey.replace("claim", "")}
              </span>
            </div>

            <table className="">
              <tr
                key={`${patent.patentId}-${claimKey}`}
                className="claim w-full"
              >
                <th className="border border-[#CFD6DD] rounded-lg mb-3 pl-4 pr-3.5 w-[33%] align-top text-left font-normal">
                  <div className="mt-4 pr-3 pl-4">
                    <ClaimElement claim={claim} />
                  </div>
                </th>

                <th className="border border-[#CFD6DD] rounded-lg py-4 ml-2 mr-2 w-[33%] align-top text-left font-normal">
                  <div className="">
                    <div className="">
                      <ClaimGraphicsDownload claim={claim} />
                    </div>
                  </div>
                </th>

                <th className="px-4 py-4 border border-[#CFD6DD] rounded-lg w-[33%] align-top text-left font-normal">
                  <p className="text-base font-interSemiBold text-[#0A090B] pb-1">
                    Covered Patents
                  </p>
                  {patent.summarized_similarity_results &&
                  patent.summarized_similarity_results[claimKey] ? (
                    patent.summarized_similarity_results[claimKey].map(
                      (item: any, index: any) => (
                        <div
                          key={item.targetDocumentId + index}
                          className="mt-2 px-3 mb-4 last:mb-0"
                        >
                          <div className="border border-[#EAEDF0] rounded-sm">
                            <div className="px-3 py-3 relative">
                              <div className="relative flex items-center gap-x-2 mb-3">
                                <p className="text-sm text-[#71717A]">
                                  {item.targetDocumentId}
                                </p>
                              </div>
                              {item.matched_items &&
                                item.matched_items.length > 0 && (
                                  <>
                                    <p className="text-sm font-interBold text-[#71717A] mb-3">
                                      Matched Items :{" "}
                                    </p>
                                    {item.matched_items.map(
                                      (matchedItem: any, index: any) => (
                                        <p
                                          key={matchedItem + index}
                                          className="text-sm text-[#71717A] mb-3"
                                        >
                                          {matchedItem}
                                        </p>
                                      )
                                    )}
                                  </>
                                )}
                              {item.non_matched_items &&
                                item.non_matched_items.length > 0 && (
                                  <>
                                    <p className="text-sm font-interBold text-[#71717A] mb-3 mt-3">
                                      Unmatched Items :{" "}
                                    </p>
                                    {item.non_matched_items.map(
                                      (unmatchedItem: any, index: any) => (
                                        <p
                                          key={unmatchedItem + index}
                                          className="text-sm text-[#71717A] mb-3"
                                        >
                                          {unmatchedItem}
                                        </p>
                                      )
                                    )}
                                  </>
                                )}
                              <div className="bg-[#FAFAFA] pb-3 pt-1 px-2 mb-4">
                                <span className="text-xs text-[#7E8C9A] font-interMedium inline-block pb-3">
                                  Overlap score
                                </span>
                                <div className="flex items-center gap-x-3 justify-between">
                                  <div className="bg-[#E4E4E7] h-2 w-[97%] relative">
                                    <div
                                      className="bg-[#005BC4] h-full absolute top-0 left-0"
                                      style={{
                                        width: `${Math.round(item.score)}%`,
                                      }}
                                    ></div>
                                  </div>
                                  <span className="font-interSemiBold text-black text-sm">
                                    {Math.round(item.score)}%
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <p className="flex justify-center items-center h-full mx-auto text-sm text-[#71717A] mb-3">
                      No Covered Patents Found
                    </p>
                  )}
                </th>
              </tr>
            </table>

            <div className="page-break"></div>
          </>
        ))
      )}
      <div className="page-break"></div>

      <div className="px-6">
        <h4 className="text-lg font-interMedium text-[#141C24] pt-3">
          Appendix
        </h4>

        {claimChartAPIData?.source?.length > 0 && (
          <>
            <div className="">
              <h5 className="text-base font-interMedium text-[#637083] py-4">
                Source
              </h5>

              {claimChartAPIData?.source?.map((item: any, index: number) => (
                <div
                  key={index}
                  className="border-[#F1F1F1] border-b pb-3 pt-3 first-of-type:pt-0 px-6"
                >
                  <p className="items-center flex gap-x-3 pb-1">
                    <span className="text-sm font-interSemiBold text-[#1751D0]">
                      {item.original_file_name}
                    </span>
                    {/* <span className="text-sm font-interRegular text-[#4F4D55]">{data.date}</span> */}
                  </p>
                  {/* <p className="text-sm font-interSemiBold text-[#7F7D83]">{data.sub_heading}</p> */}
                  <p>
                    <span className="text-[#ADACB0] text-xs font-interRegular">
                      {formatDate(item?.updatedAt)}
                    </span>
                    <span className="text-[#ADACB0] text-xs font-interRegular">
                      {" by "}
                      {item?.user_id?.name}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </>
        )}

        {claimChartAPIData?.compare?.length > 0 && (
          <>
            <div className="">
              <h5 className="text-base font-interMsourceedium text-[#637083] py-4">
                Compare
              </h5>

              {claimChartAPIData?.compare?.map((item: any, index: number) => (
                <div
                  key={index}
                  className="border-[#F1F1F1] border-b pb-3 pt-3 first-of-type:pt-0 px-6"
                >
                  <p className="items-center flex gap-x-3 pb-1">
                    <span className="text-sm font-interSemiBold text-[#1751D0]">
                      {item.original_file_name}
                    </span>
                    {/* <span className="text-sm font-interRegular text-[#4F4D55]">{data.date}</span> */}
                  </p>
                  {/* <p className="text-sm font-interSemiBold text-[#7F7D83]">{data.sub_heading}</p> */}
                  <p>
                    <span className="text-[#ADACB0] text-xs font-interRegular">
                      {formatDate(item?.updatedAt)}
                    </span>
                    <span className="text-[#ADACB0] text-xs font-interRegular">
                      {" by "}
                      {item?.user_id?.name}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClaimChartDownload;
