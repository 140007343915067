import React, { useEffect, useRef, useState } from "react";
import Sidebar from "components/common/Sidebar";
import Header from "components/common/Header";
import allImgPaths from "assets/images";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ConfigureScoring from "components/common/modals/ConfigureScoring";
import { getPatentVijilizedDetails } from "services/project";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import { ClaimAnalysisShimmerLoader } from "pages/loaders";
import useAppState from "context/useAppState";
import CreateClaimChart from "components/common/modals/CreateClaimChart";
import CustomSelect from "components/common/CustomSelect";
import ClaimData from "components/ClaimData";
import CitationsSidebar from "components/CitationsSidebar";

interface Claim {
  elements: {
    description: string;
    figure: string;
    hex_code: string;
    legend: { [key: string]: string }[];
    figurePreSignedUrl: string;
  }[];
  similarityScore: number | null;
  confidenceScore: number | null;
}

interface Patent {
  patentId: string;
  patentNumber: string;
  claims: { [key: string]: Claim };
  structure: string;
}

interface APIData {
  patents: Patent[];
  similarity_results: { [key: string]: any[] };
  name: string;
  description: string;
}

interface ActiveIndex {
  patentId: string | null;
  claimIndex: number | null;
}

const ClaimAnalysisVijilize: React.FC = () => {
  const [toggle, setToggle] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<ActiveIndex>({
    patentId: null,
    claimIndex: null,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [showConfidence, setShowConfidence] = useState<boolean>(false);
  const navigate = useNavigate();
  const [selectedClaim, setSelectedClaim] = useState<Claim | null>(null);
  const [selectedSimilarity, setSelectedSimilarity] = useState<any[]>([]);
  const [tempData, setTempData] = useState<any[]>([]);
  const [claimSelectionLoader, setClaimSelectionLoader] =
    useState<boolean>(false);
  const [isShowSideBar, setIsShowSideBar] = useState<boolean>(false);
  const [isShowSideBarCitations, setIsShowSideBarCitations] =
    useState<boolean>(false);
  const [toggledDataUsed, setToggledDataUsed] = useState<any>(0);

  const [selectedSimilarityItems, setSelectedSimilarityItems] = useState<{
    [key: string]: boolean;
  }>({});

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [createClaimChartData, setCreateClaimChartData] = useState<any[]>([]);

  const [patentClaimPairs, setPatentClaimPairs] = useState<any>([]);

  const [persistedSelections, setPersistedSelections] = useState<{
    [claimKey: string]: {
      [checkboxId: string]: boolean;
    };
  }>({});

  const itemsRef = useRef<Map<string, HTMLInputElement>>(new Map());

  const [configurationobj, setConfigurationobj] = useState<any>({
    specification: 0,
    prosecution: 90,
    precedential: 50,
    dictionary: 55,
    articleAccuracy: 65,
  });
  const { getVijilizeParamsData, setHeaderDataLocal, clickedHeatmapData } =
    useAppState("vijilize");

  const filterRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(filterRef, () => {
    setShowConfidence(false);
    setIsOpen(false);
  });

  const [toast, setToast] = useState<{
    show: boolean;
    heading: string;
    message: string;
  }>({
    show: false,
    heading: "",
    message: "",
  });

  const [openConfigureScoreModal, setConfigureScoreModal] =
    useState<boolean>(false);
  const [openCreateClaim, setOpenCreateClaim] = useState<boolean>(false);

  const [APIData, setAPIData] = useState<any | null>(null);
  const [summarizedAPIData, setSummarizedAPIData] = useState<any | null>(null);
  const [claimsData, setClaimsData] = useState<{ [key: string]: Patent }>({});
  const [filteredClaimsData, setFilteredClaimsData] = useState<{
    [key: string]: Patent;
  }>({});
  const [sourceOptions, setSourceOptions] = useState<any[]>([]);
  const [claimOptions, setClaimOptions] = useState<any[]>([]);
  const [selectedSourceOption, setSelectedSourceOption] =
    useState<string>("all");
  const [selectedClaimOption, setSelectedClaimOption] = useState<string>("all");
  const [citationsData, setCitationsData] = useState<any>([]);
  const [selectedCitation, setSelectedCitation] = useState<any>(null);

  const location = useLocation();

  const setSummarizedSimilarityResultsData = (patentId: any, claimKey: any) => {
    const summarizedData = summarizedAPIData.find(
      (item: any) => item[patentId]
    );
    const tempDataValue =
      (summarizedData && summarizedData[patentId][claimKey]) || [];
    setTempData(tempDataValue);

    const filteredData = tempDataValue.filter((obj: any) => {
      return obj.score >= configurationobj.specification;
    });

    setSelectedSimilarity(filteredData);
  };
  const toggleAccordion = (
    patentId: string,
    claimIndex: number,
    claimKey: string
  ) => {
    const persistedSelectionsKey = `${patentId}_${claimKey}`;
    const storedSelections = persistedSelections[persistedSelectionsKey] || {};
    setSelectedSimilarityItems(storedSelections);

    const currentId = `${patentId}_id${claimKey}check`;
    const checkboxElement: any = itemsRef.current.get(currentId);

    if (checkboxElement) {
      if (!checkboxElement.checked) {
        checkboxElement.checked = !checkboxElement.checked;
      }

      itemsRef.current.forEach((element, key) => {
        const eleId = element.id;
        const elePid = eleId.split("_id")[0];
        const eleClaim = eleId.split("_id")[1].split("check")[0];
        const trueFlag = generateSelectedProd(eleClaim, elePid);

        if (key.startsWith(patentId) && key !== currentId && !trueFlag) {
          element.checked = false;
        }
      });
    }

    setActiveIndex((prev) => ({
      patentId:
        prev.patentId === patentId && prev.claimIndex === claimIndex
          ? null
          : patentId,
      claimIndex:
        prev.patentId === patentId && prev.claimIndex === claimIndex
          ? null
          : claimIndex,
    }));

    setClaimSelectionLoader(true);
    setSelectedClaim(claimsData[patentId]?.claims[claimKey]);

    setSummarizedSimilarityResultsData(patentId, claimKey);

    setTimeout(() => {
      setClaimSelectionLoader(false);
    }, 1000);

    // Clear selectedSimilarityItems when navigating away
    if (
      activeIndex.claimIndex !== null &&
      activeIndex.claimIndex !== claimIndex
    ) {
      setSelectedSimilarityItems({});
    }
  };

  // handleScoring =
  const handleScoring = (data: any) => {
    let filteredData = tempData.filter((obj: any) => {
      return obj.score >= data.specification;
    });
    setSelectedSimilarity(filteredData);
  };

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any
  ) => {
    event.stopPropagation();
    const isChecked = event.target.checked;
    const currentClaim = `${activeIndex.patentId}_claim${
      (activeIndex.claimIndex || 0) + 1
    }`;
    const checkboxId = `claim${(activeIndex.claimIndex || 0) + 1}cid${
      data.patentId
    }_id${data.targetDocumentId}check`;

    setSelectedSimilarityItems((prev) => ({
      ...prev,
      [checkboxId]: isChecked,
    }));

    const updatedSelectedItems = {
      ...selectedSimilarityItems,
      [checkboxId]: isChecked,
    };

    let tempPatentClaimPairs: any[] = [...patentClaimPairs];
    const existingPairIndex = tempPatentClaimPairs.findIndex(
      (pair) =>
        pair.patentId === activeIndex.patentId &&
        pair.claimKey === `claim${(activeIndex.claimIndex || 0) + 1}`
    );

    if (isChecked) {
      if (existingPairIndex !== -1) {
        tempPatentClaimPairs[existingPairIndex].targetDocumentId.push(
          data.targetDocumentId
        );
      } else {
        tempPatentClaimPairs.push({
          patentId: activeIndex.patentId,
          claimKey: `claim${(activeIndex.claimIndex || 0) + 1}`,
          targetDocumentId: [data.targetDocumentId],
        });
      }
    } else {
      if (existingPairIndex !== -1) {
        tempPatentClaimPairs[existingPairIndex].targetDocumentId =
          tempPatentClaimPairs[existingPairIndex].targetDocumentId.filter(
            (id: string) => id !== data.targetDocumentId
          );
        if (
          tempPatentClaimPairs[existingPairIndex].targetDocumentId.length === 0
        ) {
          tempPatentClaimPairs.splice(existingPairIndex, 1);
        }
      }
    }

    setPatentClaimPairs(tempPatentClaimPairs);
    setPersistedSelections((prev) => ({
      ...prev,
      [currentClaim]: updatedSelectedItems,
    }));
  };
  const handleDropdownSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "source" | "claim",
    data: any
  ) => {
    if (type === "source") {
      setSelectedSourceOption(data.value);
      if (data.value !== "all") {
        activeIndex.claimIndex = 0;
        activeIndex.patentId = data.value;
      }
      updateClaimOptions(data.value === "all" ? "all" : data.value, claimsData);
    } else if (type === "claim") {
      setSelectedClaimOption(data.value);
    }
  };

  const updateClaimOptions = (patentId: string, claimsData: any) => {
    if (patentId === "all") {
      setClaimOptions(createClaimOptions("all", claimsData));
    } else {
      setClaimOptions(createClaimOptions(patentId, claimsData));
    }
  };

  const filterClaimsData = (
    sourceOption: string,
    type: string,
    claimOption: string
  ) => {
    setClaimSelectionLoader(true);

    if (type === "source") {
      setSelectedClaimOption("all");
      if (sourceOption === "all") {
        setFilteredClaimsData(claimsData);
        if (Object.keys(claimsData).length > 0) {
          setActiveIndex((prev) => ({
            patentId: activeIndex.patentId,
            claimIndex: activeIndex.claimIndex,
          }));

          let patentId =
            (clickedHeatmapData && clickedHeatmapData?.sourceId) ||
            APIData?.patents[0]?.patentId;

          let claimKey =
            (clickedHeatmapData && clickedHeatmapData?.claimKey) ||
            Object.keys(APIData.patents[0]?.claims)[0];
          setSummarizedSimilarityResultsData(patentId, claimKey);

          setTimeout(() => {
            const element: any = document.getElementById(
              `${activeIndex.patentId}_${activeIndex.claimIndex}`
            );
            element && element?.scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      } else {
        const filtered = {
          [sourceOption]: claimsData[sourceOption],
        };
        setFilteredClaimsData(filtered);
        let claimKey = Object.keys(claimsData[sourceOption]?.claims)[0];
        setSummarizedSimilarityResultsData(sourceOption, claimKey);
        setTimeout(() => {
          const element: any = document.getElementById(
            `${selectedSourceOption}_0`
          );
          element && element?.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    } else if (type === "claim") {
      if (claimOption === "all") {
        filterClaimsData(selectedSourceOption, "source", selectedClaimOption);

        // setFilteredClaimsData(claimsData);
      } else {
        const claimOptionData: any = claimOption.split("_");
        const claimKey = claimOptionData && claimOptionData[1];
        const sourceId = claimOptionData && claimOptionData[0];

        if (claimKey && sourceId) {
          const filteredClaims: any = {};

          if (claimsData[sourceId]) {
            const patentClaims = claimsData[sourceId].claims;

            if (patentClaims[claimKey]) {
              filteredClaims[sourceId] = {
                claims: {
                  [claimKey]: patentClaims[claimKey],
                },
                patentId: claimsData[sourceId].patentId,
                patentNumber: claimsData[sourceId].patentNumber,
                structure: claimsData[sourceId].structure,
              };
            }
          }

          const claimIndex = 0;
          setActiveIndex((prev) => ({
            patentId: sourceId,
            claimIndex: claimIndex,
          }));
          setSelectedClaim(claimsData[sourceId]?.claims[claimKey]);
          setSummarizedSimilarityResultsData(sourceId, claimKey);

          setTimeout(() => {
            setFilteredClaimsData(filteredClaims);
            const element: any = document.getElementById(
              `${sourceId}_${claimIndex}`
            );
            element && element?.scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      }
      setTimeout(() => {
        setClaimSelectionLoader(false);
      }, 1000);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      let comparisonId;
      const pathName = location?.pathname;
      const regexMatch = pathName?.match(/\/vijilize\/([^\/]+)/);

      if (!regexMatch) {
        return;
      }

      if (regexMatch && regexMatch[1]) {
        comparisonId = regexMatch[1];

        let patentData: any = await getPatentVijilizedDetails(comparisonId);

        setAPIData(patentData);
        setCitationsData(patentData?.citations);
        setHeaderDataLocal((prev: any) => {
          return {
            ...prev,
            vijilize: { title: patentData?.name, id: patentData?._id },
          };
        });
        const claimsD = patentData?.patents?.reduce(
          (acc: { [key: string]: Patent }, patent: Patent) => {
            acc[patent.patentId] = patent;
            return acc;
          },
          {}
        );
        setClaimsData(claimsD);

        let patentId =
          (clickedHeatmapData && clickedHeatmapData?.sourceId) ||
          patentData?.patents[0]?.patentId;

        let claimKey =
          (clickedHeatmapData && clickedHeatmapData?.claimKey) ||
          Object.keys(patentData.patents[0]?.claims)[0];
        const claimIndex = Number(claimKey.split("claim")[1]) - 1;

        setActiveIndex({
          patentId: patentId,
          claimIndex: claimIndex,
        });
        setSummarizedAPIData(patentData?.summarized_similarity_results);

        const summarizedData = patentData?.summarized_similarity_results?.find(
          (item: any) => item[patentId]
        );
        setSelectedClaim(
          patentData?.patents?.find(
            (patent: Patent) => patent.patentId === patentId
          ).claims[claimKey]
        );
        const tempDataValue =
          (summarizedData && summarizedData[patentId][claimKey]) || [];

        setTempData(tempDataValue);

        let filteredData = tempDataValue?.filter((obj: any) => {
          return obj.score >= configurationobj.specification;
        });

        setSelectedSimilarity(filteredData);
        const sourceOptionsData = createSourceOptions(patentData.patents);
        setSourceOptions(sourceOptionsData);
        if (patentData.patents.length > 0) {
          updateClaimOptions(patentData.patents[0].patentId, claimsD);
        }
        setIsLoading(false);
        setClaimSelectionLoader(true);
        setPersistedSelections({
          [claimKey]: {},
        });
        setIsDataLoaded(true);

        setTimeout(() => {
          setClaimSelectionLoader(false);
          const element: any = document.getElementById(
            `${patentId}_${claimIndex}`
          );
          element && element?.scrollIntoView({ behavior: "smooth" });
        }, 1000);
      }
    })();
  }, []);

  // Second useEffect to handle refs after data is loaded
  useEffect(() => {
    if (!isDataLoaded || !APIData?.patents[0]?.patentId) return;

    const firstPatentId = APIData.patents[0].patentId;
    const firstCheckboxId = `${firstPatentId}_idclaim1check`;

    itemsRef.current.forEach((element, key) => {
      element.checked = key === firstCheckboxId;
    });

    setClaimSelectionLoader(false);
  }, [isDataLoaded, APIData]);

  useEffect(() => {
    if (activeIndex.claimIndex !== null) {
      const currentClaim = `${activeIndex.patentId}_claim${
        activeIndex.claimIndex + 1
      }`;
      const storedSelections = persistedSelections[currentClaim] || {};
      setSelectedSimilarityItems(storedSelections);
    }
  }, [activeIndex.claimIndex, persistedSelections]);

  useEffect(() => {
    filterClaimsData(selectedSourceOption, "source", selectedClaimOption);
  }, [claimsData, selectedSourceOption]);

  useEffect(() => {
    filterClaimsData(selectedSourceOption, "claim", selectedClaimOption);
  }, [claimsData, selectedClaimOption]);

  const handleCreateClaimChart = async (e: any) => {
    const vijilizeParamsData = await getVijilizeParamsData();
    let compareId = vijilizeParamsData?.compare_id || "0";

    patentClaimPairs.map((item: any) => {
      return (item.compareId = compareId);
    });

    setCreateClaimChartData(patentClaimPairs);
    setOpenCreateClaim(true);
  };
  const setRef = (id: string) => (element: HTMLInputElement | null) => {
    if (element) {
      itemsRef.current.set(id, element);
    } else {
      itemsRef.current.delete(id);
    }
  };

  const generateSelectedProd = (claimKey: string, patentId: string) => {
    if (!patentClaimPairs.length) {
      return 0;
    }
    const matchingPair = patentClaimPairs.find(
      (pair: any) => pair.patentId === patentId && pair.claimKey === claimKey
    );

    if (!matchingPair || !matchingPair.targetDocumentId) {
      return 0;
    }

    return matchingPair.targetDocumentId.length;
  };

  const createSourceOptions: any = (data: any) => {
    const patentOptions = data.map((item: any) => ({
      value: item.patentId,
      label: item.patentNumber,
    }));

    const allOption = { value: "all", label: "All" };

    return [allOption, ...patentOptions];
  };

  const createClaimOptions = (patentId: any, claimsData: any) => {
    const claimOptions = [];

    claimOptions.push({
      value: "all",
      label: "All",
    });

    if (patentId === "all") {
      const allClaimsData: any = Object.values(claimsData).flatMap(
        (patent) => patent
      );
      allClaimsData.forEach((patent: any) => {
        Object.keys(patent.claims).forEach((claimKey) => {
          claimOptions.push({
            value: `${patent.patentId}_${claimKey}`,
            label: claimKey,
          });
        });
      });
    } else {
      if (Object.keys(claimsData).length > 0) {
        const selectedPatentClaims = claimsData[patentId]?.claims;
        Object.keys(selectedPatentClaims).forEach((claimKey) => {
          claimOptions.push({
            value: `${patentId}_${claimKey}`,
            label: claimKey,
          });
        });
      }
    }

    return claimOptions;
  };

  const extractFirstBracketedNumber = (data: any[]) => {
    const bracketedNumberRegex = /\[\[?(\d+)\]?\]/;

    for (const obj of data) {
      for (const item of obj.matched_items) {
        const match = item.match(bracketedNumberRegex);
        if (match) {
          return match[1];
        }
      }

      if (obj.matched_items.length === 0) {
        for (const item of obj.non_matched_items) {
          const match = item.match(bracketedNumberRegex);
          if (match) {
            return match[1];
          }
        }
      }
    }

    return null;
  };

  const handleViewCitations = (patentId: any, claimsKey: any) => {
    const summarizedData = summarizedAPIData?.find(
      (item: any) => item[patentId]
    );
    let filteredData = summarizedData[patentId][claimsKey]?.filter(
      (obj: any) => {
        return obj.score >= configurationobj.specification;
      }
    );
    const citationNumber = extractFirstBracketedNumber(filteredData);
    citationNumber &&
      setSelectedCitation({
        value: citationNumber,
        label: `${citationNumber}`,
      });
    setIsShowSideBarCitations(true);
  };

  const handleClaimClick = (
    event: any,
    item: any,
    index: number,
    claimKey: string
  ) => {
    if (event.target.id === "viewCitations") {
      handleViewCitations(item.patentId, claimKey);
    } else {
      toggleAccordion(item.patentId, index, claimKey);
    }
  };

  const onClaimChartCreated = () => {
    setPatentClaimPairs([]);
    setPersistedSelections({});
    setSelectedSimilarityItems({});
  };

  return (
    <div className="dashboard-wrapper flex">
      <Sidebar />
      <div className="w-[calc(100%_-_72px)] ml-auto">
        <Header title="Vijilize" count={0} isChildEle={true} type="project" />
        {isLoading ? <ClaimAnalysisShimmerLoader /> : null}
        {!isLoading ? (
          <div className="pt-4 px-4 h-[calc(100vh_-_69px)] overflow-y-auto scrollbar-adjust bg-[#f9fafb]">
            <div className="flex justify-between items-center w-full pb-4">
              <div className="flex items-start gap-x-2 pl-1">
                <img
                  className="inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1"
                  src={allImgPaths.goBack}
                  alt="return"
                  onClick={() => navigate(-1)}
                />
                <div className="">
                  <h2 className="text-[#101828] text-xl font-interSemiBold">
                    {APIData?.name}
                  </h2>
                  <p className="text-sm text-[#4F4D55] font-interRegular">
                    {APIData?.description}
                  </p>
                </div>
              </div>
              <div className="flex gap-x-4 items-center">
                {/* <p className='text-sm text-[#344051] font-interRegular'> */}
                <div
                  onClick={() => {
                    setIsShowSideBar(true);
                  }}
                  className="w-fit flex-shrink-0 flex items-center gap-x-1"
                >
                  <span className="font-interMedium group-hover:text-[#113B98] text-sm text-[#1751D0] inline-block cursor-pointer">
                    {APIData?.source?.length} Source
                  </span>
                  <span className="font-interMedium group-hover:text-[#113B98] text-sm text-[#1751D0] inline-block cursor-pointer">
                    {" "}
                    |{" "}
                  </span>
                  <span className="font-interMedium group-hover:text-[#113B98] text-sm text-[#1751D0] inline-block cursor-pointer">
                    {APIData?.compare?.length} Reference
                  </span>
                </div>

                <button
                  disabled={patentClaimPairs.length === 0}
                  onClick={handleCreateClaimChart}
                  className={`!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5 ${
                    patentClaimPairs.length === 0 && "button-disabled"
                  }`}
                >
                  <img
                    className={`${
                      patentClaimPairs.length === 0 && "contrast-50 w-5"
                    }`}
                    src={allImgPaths.saveClaimChartIcon}
                    alt=""
                  />
                  Save Vijil Chart
                </button>

                {/* <button disabled={patentClaimPairs.length === 0} onClick={handleCreateClaimChart} className={`!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5 ${patentClaimPairs.length === 0 && 'button-disabled'}`}>
                                    <img className={`${patentClaimPairs.length === 0 && 'contrast-50'}`} src={allImgPaths.saveClaimChartIcon} alt='' />
                                    Save Claim Chart
                                </button> */}

                <button
                  className={`px-3.5 py-2.5 rounded-md border border-[#E6E6E6] group bg-white`}
                  onClick={() => {
                    setConfigureScoreModal(true);
                  }}
                >
                  <span
                    className={`text-sm font-interMedium text-[#4F4D55] group-hover:text-[#000] flex items-center gap-x-2 ${
                      isOpen ? "text-[#000]" : ""
                    }`}
                  >
                    <img src={allImgPaths.configure} alt="" />
                    Configure Scoring
                  </span>
                </button>
              </div>
            </div>

            <div className="py-2 px-3 flex gap-x-4 bg-white mb-2">
              {sourceOptions.length > 0 && (
                <div className="">
                  <span className="text-[#2D2B32] text-sm font-interMedium block pb-1">
                    Source
                  </span>
                  <div className="modal-select-box fete-settings-modals">
                    <CustomSelect
                      defaultValue={sourceOptions[0]}
                      options={sourceOptions}
                      onChange={(event: any) =>
                        handleDropdownSelect(
                          event,
                          "source",
                          sourceOptions.find(
                            (option) => option.value === event.value
                          )
                        )
                      }
                      extendedOption={false}
                      className="w-80"
                    />
                  </div>
                </div>
              )}

              <div className="">
                <span className="text-[#2D2B32] text-sm font-interMedium block pb-1">
                  Claim
                </span>
                <div className="modal-select-box fete-settings-modals">
                  <CustomSelect
                    isDisabled={selectedSourceOption === "all"}
                    defaultValue={claimOptions[0]}
                    options={claimOptions}
                    onChange={(event: any) =>
                      handleDropdownSelect(
                        event,
                        "claim",
                        claimOptions.find(
                          (option) => option.value === event.value
                        )
                      )
                    }
                    extendedOption={false}
                    className={`w-80 ${
                      selectedSourceOption === "all" ? "dropdown-disabled" : ""
                    }`}
                  />
                </div>
              </div>
            </div>
            {/* main div */}
            <div className="">
              <ClaimData
                type="vijilize"
                APIData={APIData}
                filteredClaimsData={filteredClaimsData}
                activeIndex={activeIndex}
                handleClaimClick={handleClaimClick}
                summarizedAPIData={summarizedAPIData}
                generateSelectedProd={generateSelectedProd}
                claimSelectionLoader={claimSelectionLoader}
                toggle={toggle}
                selectedClaim={selectedClaim}
                selectedSimilarity={selectedSimilarity}
                selectedSimilarityItems={selectedSimilarityItems}
                handleSelect={handleSelect}
                setIsShowSideBarCitations={setIsShowSideBarCitations}
                setSelectedCitation={setSelectedCitation}
              />
            </div>

            {openConfigureScoreModal && (
              <ConfigureScoring
                selectedSimilarity={selectedSimilarity}
                isShow={openConfigureScoreModal}
                configurationobj={configurationobj}
                setConfigurationobj={setConfigurationobj}
                setIsShow={setConfigureScoreModal}
                setToast={setToast}
                setScoringValue={handleScoring}
              />
            )}
            {openCreateClaim && (
              <CreateClaimChart
                isShow={openCreateClaim}
                setIsShow={setOpenCreateClaim}
                setToast={setToast}
                projectId={id + ""}
                createClaimChartData={createClaimChartData}
                onClaimChartCreated={onClaimChartCreated}
                APIData={APIData}
              />
            )}
          </div>
        ) : null}
      </div>
      {/* Sidebar */}
      <div
        className={`w-full h-full fixed bg-[#07031154] z-[100] ${
          isShowSideBar ? "right-0" : "right-full"
        }`}
      >
        <div
          onClick={() => setIsShowSideBar(false)}
          className="h-full w-[calc(100%_-_360px)]"
        ></div>
        <div
          className={`border-l border-[#E4E7EC] w-[360px] h-screen fixed z-50 bg-white top-0 shadow-customShadow15 ${
            isShowSideBar ? "right-0" : "right-full"
          }`}
        >
          <div className="pb-6 px-6 pt-4">
            <div className="pb-0.5 flex items-center justify-between">
              <span className="text-lg font-interSemiBold text-[#101828] inline-block ">
                Data Used
              </span>
              <img
                onClick={() => setIsShowSideBar(false)}
                className="cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1"
                src={allImgPaths.closeIcon}
                alt=""
              />
            </div>

            <p className="text-[#475467] text-sm ">
              List of source and compare with data used for this vijilization.
            </p>
          </div>

          <div className="h-[calc(100vh_-_130px)]">
            <div className="">
              <div className="bg-[#F8F8F8] rounded-lg p-1.5 mx-6 mb-4">
                <div className="flex items-center gap-x-1">
                  <p
                    onClick={() => setToggledDataUsed(0)}
                    className={`text-sm font-interMedium rounded-lg py-1.5 px-2 flex-1 flex justify-center cursor-pointer ${
                      toggledDataUsed === 0
                        ? "bg-white shadow-customShadow12 text-[#0A090B]"
                        : "text-[#4F4D55]"
                    }`}
                  >
                    Source ({APIData?.source?.length})
                  </p>
                  <p
                    onClick={() => setToggledDataUsed(1)}
                    className={`text-sm font-interMedium rounded-lg py-1.5 px-2 flex-1 flex justify-center cursor-pointer ${
                      toggledDataUsed === 1
                        ? "bg-white shadow-customShadow12 text-[#0A090B]"
                        : "text-[#4F4D55]"
                    }`}
                  >
                    Compare With ({APIData?.compare?.length})
                  </p>
                </div>
              </div>
              <div className="overflow-y-auto h-[calc(100vh_-_180px)] px-6">
                {/* Source */}
                {toggledDataUsed === 0 && APIData?.source && (
                  <>
                    {APIData?.source.map((item: any, id: number) => {
                      return (
                        <>
                          <div
                            key={id + item.file_url}
                            className="border-b px-5 py-3"
                          >
                            <div className="flex items-center gap-x-4">
                              <Link
                                to={item.file_url}
                                target="_blank"
                                className="cursor-default w-full"
                              >
                                <p
                                  title={item.file_name}
                                  className="text-sm font-interSemiBold text-[#1751D0] pb-1 truncate max-w-[calc(100%_-_5px)] cursor-pointer w-fit"
                                >
                                  {item.file_name}
                                </p>
                              </Link>
                              {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                            </div>
                            {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                          </div>
                        </>
                      );
                    })}
                  </>
                )}

                {/* Compare with */}
                {toggledDataUsed === 1 && APIData?.compare && (
                  <>
                    {APIData?.compare.map((item: any, id: number) => {
                      let url = "";
                      let name = "";
                      if (APIData && item?.url) {
                        url = item.url;
                        name = item.url;
                      } else {
                        url = item.file_url;
                        name = item.file_name;
                      }
                      return (
                        <>
                          <div key={id} className="border-b px-5 py-3">
                            <div className="flex items-center gap-x-4">
                              <Link
                                to={url}
                                target="_blank"
                                className="cursor-default w-full"
                              >
                                <p
                                  title={name}
                                  className="text-sm font-interSemiBold text-[#1751D0] pb-1 truncate max-w-[calc(100%_-_5px)] cursor-pointer w-fit"
                                >
                                  {name}
                                </p>
                              </Link>
                              {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                            </div>
                            {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sidebar Citations */}
      <CitationsSidebar
        isShowSideBarCitations={isShowSideBarCitations}
        setIsShowSideBarCitations={setIsShowSideBarCitations}
        citationsData={citationsData}
        selectedCitation={selectedCitation}
        setSelectedCitation={setSelectedCitation}
      />
    </div>
  );
};
export default ClaimAnalysisVijilize;
