import useAppState from "context/useAppState";
import allImgPaths from "assets/images";
import React, { useEffect, useState } from "react";
import {
  acceptPendingInvitations,
  getPendingInvitations,
} from "services/auth/auth";

const SidebarPendingInvitations = () => {
  const [pendingInvitations, setPendingInvitations] = useState<any>([]);
  const { setHasRetrievedTeams } = useAppState("selectTeam");

  useEffect(() => {
    fetchPendingInvitations();
  }, []);

  const fetchPendingInvitations = async () => {
    const { data } = await getPendingInvitations();
    if (data?.length) {
      setPendingInvitations(data);
    }
  };

  const setPendingInvitationStatus = async (
    invitationId: any,
    status: string
  ) => {
    const payload = {
      invitation_id: invitationId,
      is_accepted: status === "accept",
    };
    await acceptPendingInvitations(payload);

    if (status === "accept") {
      setHasRetrievedTeams(false);
    }
  };

  return (
    <div className="">
      {pendingInvitations?.length > 0 && (
        <>
          <p className="text-[#667085] text-base pb-3">
            Pending Invitations{" "}
            <span className="text-[#667085] text-sm">
              ({pendingInvitations.length})
            </span>
          </p>
          {pendingInvitations?.map((pi: any, index: any) => (
            <React.Fragment key={index}>
              <div className="flex items-center gap-x-3">
                <img
                  className="w-[32px] h-[32px] rounded-full object-cover"
                  src={pi?.team_id?.logo || allImgPaths.defaultProfile}
                  alt=""
                />
                <span className="text-base text-[#344054] font-interSemiBold">
                  {pi?.team_id?.name}
                </span>
              </div>

              <div className="pl-12 flex items-center gap-x-5 pending-invitation-wrapper">
                <div
                  className="flex items-center gap-x-2 cursor-pointer"
                  onClick={() => setPendingInvitationStatus(pi?._id, "accept")}
                >
                  <img src={allImgPaths.RightCheckGreenIcon} alt="" />
                  <span className="text-sm font-interSemiBold text-[#067647]">
                    Accept
                  </span>
                </div>

                <div
                  className="flex items-center gap-x-2 cursor-pointer"
                  onClick={() => setPendingInvitationStatus(pi?._id, "reject")}
                >
                  <img src={allImgPaths.closeIconRed} alt="" />
                  <span className="text-sm font-interSemiBold text-[#D92D20]">
                    Reject
                  </span>
                </div>
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </div>
  );
};

export default SidebarPendingInvitations;
