import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendOtp, verifyCodeService } from "../../services/auth/auth";
import { otpSchema } from "../../schema/validation";

const useVerifyHook = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string | null>(null);
  const [msg, setMsg] = useState({ success: "", error: "" });

  const userEmail = localStorage.getItem("userEmail") as string;
  const userId = localStorage.getItem("userId") as string;

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    otpSchema
      .validate({ otp })
      .then((data: any) => {
        submitOtp(data.otp);
      })
      .catch((err: any) => {
        console.log("err =>", err);
        if (err.path === "otp") {
          setMsg({ ...msg, error: err.message });
        }
      });

    const submitOtp = async (otp: string) => {
      // api call
      try {
        const response = await verifyCodeService(userId, otp);
        const userData = {
          accessToken: response.data.accessToken,
          name: response.data.name,
          refreshToken: response.data.refreshToken,
          role: response.data.role,
          id: response.data._id,
          profile_image: response.data.profile_image,
        };
        localStorage.setItem("userData", JSON.stringify(userData));
        if (response) {
          navigate("/team-setup");
        }
      } catch (error: any) {
        console.log("error while submit the otp =", error);
        if (error.statusCode === 403) {
          setMsg({ ...msg, error: error.message });
        }
      }
    };
  };

  const handleOtpChange = (code: string) => {
    setOtp(code);
    setMsg({ error: "", success: "" });
  };

  const resendOtp = async () => {
    try {
      const response: any = await sendOtp(userId);
      setMsg({ ...msg, success: response.message });
    } catch (err: any) {
      console.log("error while resend otp =", err);
      setMsg({ ...msg, error: err.message });
    }
  };

  return {
    handleOtpChange,
    handleFormSubmit,
    resendOtp,
    userEmail,
    msg,
  };
};
export default useVerifyHook;
