import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Modal } from "./Modal";

import { HeaderModal } from "./Header";
import { FooterModal } from "./Footer";
import { Body } from "./Body";
import allImgPaths from "assets/images";
import FileExplorer from "../Explorer";
import FileUploadProgress from "../FileUploadProgress";
import {
  createPatent,
  createPatentPresignedUrl,
  createUrl,
  patentUploadStatus,
  s3PutUrlWithProgress,
} from "services/project";
import Toast from "../Toast";
import { InputTextField } from "components/InputField";
import { PROJECT_CONSTANTS } from "utils/constant";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  setToast: any;
  projectId: string;
  setRefreshData: Dispatch<SetStateAction<boolean>>;
  type: string;
}

function AddComparisonData({
  isShow,
  setIsShow,
  setToast,
  projectId,
  setRefreshData,
  type,
}: Iprops) {
  const [toggle, setToggle] = useState(2);
  const [option, setOption] = useState("external upload");
  const [previewSrc, setPreviewSrc] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [filePreviews, setFilePreviews] = useState<any[]>([]);
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [url, setUrl] = useState("");
  const [urlTouched, setUrlTouched] = useState(false);

  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>(
    {}
  );
  const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);

  useEffect(() => {
    if (type === "file" || type === "patent") {
      setToggle(2);
    } else if (type === "url") {
      setToggle(3);
    }
  }, []);

  const isUrlValid = useMemo(() => {
    try {
      new URL(url.trim());
      return url.trim().length > 0;
    } catch {
      return false;
    }
  }, [url]);

  const handleUrlChange = (e: any) => {
    setUrl(e.target.value);
    setUrlTouched(true);
  };

  const onCloseModal = () => {
    setIsShow(false);
    setSelectedFiles([]);
    setFilePreviews([]);
    setIsAPICalled(false);
    setUrl("");
    setUrlTouched(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let recievedFiles: any[] = (e.target.files || []) as any[];
    const files = Array.from(recievedFiles);
    handleFiles(files);
  };
  const handleFiles = async (files: any[]) => {
    const allowedTypes = ["application/pdf"];
    let validFiles = files.filter((file) => allowedTypes.includes(file.type));

    if (validFiles.length !== files.length) {
      Toast.error("Error", "Only PDF files are allowed");
    }

    const previewPromises: Promise<string>[] = [];
    validFiles.forEach((file) => {
      const promise = new Promise<string>((resolve) => {
        displayPreview(file, (preview) => {
          resolve(preview);
        });
      });
      previewPromises.push(promise);
    });

    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);

    Promise.all(previewPromises).then((previews) => {
      setFilePreviews((prevFiles) => [...prevFiles, ...previews]);
    });
  };

  const resetModal = () => {
    setIsShow(false);
    setSelectedFiles([]);
    setFilePreviews([]);
    setIsAPICalled(false);
    setFileProgress({});
    setUploadingFiles(false);
    setUrl("");
  };

  const displayPreview = (file: File, callback: (preview: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        callback(reader.result as string);
      } else {
        callback("");
      }
    };
  };

  const submit = async (e: any) => {
    const userData: any = JSON.parse(
      localStorage.getItem("userData") as string
    );
    let selectedTeam: any = JSON.parse(
      localStorage.getItem("selectedTeam") as string
    );

    e?.preventDefault();
    setIsAPICalled(true);
    setUploadingFiles(true);

    if (
      (toggle === 2 && selectedFiles.length > 0 && type === "file") ||
      type === "patent"
    ) {
      try {
        const uploadPromises = selectedFiles.map(async (currentFile) => {
          // Step 1: Create patent
          let payload = { patent_id: currentFile.name.split(".pdf")[0] };
          const { data, status } = await createPatent(payload);

          if (!data) {
            throw new Error(
              `Failed to create patent for file ${currentFile.name}`
            );
          }

          // Step 2: Get presigned URL
          let payload2: any = {
            team_id: selectedTeam.currentTeamId,
            project_id: projectId,
            patent_id: data._id,
            file_name: currentFile.name,
            content_type: currentFile.type,
            patent_category: PROJECT_CONSTANTS.COMPARE,
          };

          if (type === "file") {
            payload2.patent_type = PROJECT_CONSTANTS.EXTERNAL;
          } else if (type === "patent") {
            payload2.patent_type = PROJECT_CONSTANTS.PATENT;
          }

          const step2Response: any = await createPatentPresignedUrl(payload2);
          if (!step2Response?.url) {
            throw new Error(
              `Failed to get presigned URL for file ${currentFile.name}`
            );
          }

          // Step 3: Upload to S3
          const step3payload: any = {
            url: step2Response.url,
            file: currentFile,
            type: currentFile.type,
          };

          const response: any = await s3PutUrlWithProgress(
            step3payload,
            (progress) => {
              setFileProgress((prev) => ({
                ...prev,
                [currentFile.name]: Math.round(progress),
              }));
            }
          );

          // Step 4: Update upload status
          const fileStatus = response.status === 200 ? "uploaded" : "pending";
          const step4response = await patentUploadStatus({
            file_id: step2Response.file_id,
            file_status: fileStatus,
          });

          return { success: true, fileName: currentFile.name };
        });

        const results = await Promise.all(uploadPromises);
        const allSuccessful = results.every((result) => result.success);

        if (allSuccessful) {
          let successMessage: any;
          if (type === "file") {
            successMessage =
              results.length > 1
                ? `All files added successfully`
                : `File added successfully`;
          } else if (type === "patent") {
            successMessage =
              results.length > 1
                ? `All patents added successfully`
                : `Patent added successfully`;
          }
          Toast.success("Success", successMessage);
          setRefreshData(true);
          resetModal();
        } else {
          Toast.error("Error", "Some files failed to upload");
          setUploadingFiles(false);
        }
      } catch (error) {
        console.error("Upload error:", error);
        Toast.error("Error", "Failed to upload files");
        setUploadingFiles(false);
      }
    } else if (toggle === 3 && type === "url") {
      try {
        if (!isUrlValid) {
          return;
        }
        let payload: any = {
          url,
          project_id: projectId,
          team_id: selectedTeam.currentTeamId,
        };

        await createUrl(payload);
        setToggle(2);
        setIsShow(false);
        setRefreshData((prev) => !prev);
        Toast.success("Success", "URL added successfully");
      } catch (error) {
        console.error("Error while creating url", error);
        Toast.error("Error", "Error while creating url please try again later");
      }
    }
  };

  return (
    <>
      <Modal className={"modal-wrapper max-w-[700px]"} isShow={isShow}>
        <form onSubmit={submit}>
          {/* Modal bg Shade */}

          <HeaderModal
            title={"test"}
            isShowHeader={true}
            className="modal-header pt-6 px-6"
          >
            <div className="flex items-center justify-between pb-3">
              <img
                className="cursor-pointer"
                src={allImgPaths.addPatentLogo}
                alt="remove"
              />
              {/* Close Icon */}
              <img
                className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4"
                id="modal-toggle"
                src={allImgPaths.closeIcon}
                onClick={onCloseModal}
                alt="close icon"
              />
            </div>

            <h2 className="text-base text-[#0A090B] font-interSemiBold pb-1">
              Add Comparison Data
            </h2>
            <p className="text-sm text-[#4F4D55]">
              {type !== "url" &&
                `Add ${
                  type === "file" ? "Files" : type === "patent" && "Patents"
                } to compare with the source patent`}
              {type === "url" && "Add URLs to be crawled and compared"}
            </p>
          </HeaderModal>

          <Body title="" isShowBody={true} className="modal-body px-6 pt-3">
            <div className="modal-container mt-3">
              {toggle == 0 && (
                <div className="">
                  <div className="text-[#2D2B32] font-interMedium text-left  pb-1.5 flex gap-1">
                    Enter Patent #
                    <img src={allImgPaths.questionCircle} alt="help" />
                  </div>
                  <div className="">
                    <input
                      className="placeholder:text-[#7F7D83] placeholder:text-base text-base text-[#1D1C20] w-full  px-3 py-2 bg-[#FFFFFF] border border-[#E6E6E6] rounded-lg"
                      name={"title"}
                      placeholder={"Enter patent numbers separated by commas"}
                    />
                  </div>
                </div>
              )}

              {toggle == 1 && (
                <div className="">
                  <div className="w-full">
                    <FileExplorer />
                  </div>
                </div>
              )}

              {toggle == 2 && (
                <div className="bg-[#F9FAFB] px-3 pl-4 py-3 rounded-md">
                  <div
                    className="relative border border-[#C9C9CC] hover:border-gray-400 border-dashed rounded-lg p-2 w-[99%]"
                    id="dropzone"
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={(e) => e.preventDefault()}
                    onDragLeave={() => {}}
                    onDrop={handleDrop}
                  >
                    <input
                      id="photo-dropbox"
                      type="file"
                      multiple
                      accept=".pdf"
                      className="absolute inset-0 w-full h-full opacity-0 z-50"
                      onChange={handleFileChange}
                    />
                    <label
                      className="flex cursor-pointer gap-1 w-full px-3 py-2 bg-[#F9FAFB] rounded-lg"
                      htmlFor="photo-dropbox"
                    >
                      <input
                        id="photo-dropbox"
                        type="file"
                        multiple
                        accept=".pdf"
                        className="sr-only"
                        onChange={(e) => console.log(e.target.files)}
                      />
                      <img src={allImgPaths.uploadIcon} alt="upload.svg" />
                      <span className="px-2">
                        Drag & drop patent file or{" "}
                        <span className="text-[#1751D0]">browse files</span>
                      </span>
                    </label>
                  </div>

                  {selectedFiles.length > 0 && (
                    <FileUploadProgress
                      files={selectedFiles}
                      progress={fileProgress}
                    />
                  )}
                </div>
              )}
              {toggle == 3 && (
                <div className="">
                  <div className="text-[#2D2B32] font-interMedium text-left  pb-1.5 flex gap-1">
                    URL
                    <img src={allImgPaths.questionCircle} alt="help" />
                  </div>
                  <div className="">
                    <InputTextField
                      className="placeholder:text-[#7F7D83] placeholder:text-[15px] text-base text-[#344054] w-full"
                      name={"url"}
                      placeholder={"Enter Url"}
                      onChange={handleUrlChange}
                      value={url}
                      err={
                        urlTouched && !isUrlValid
                          ? "Please enter a valid URL (e.g., https://example.com)"
                          : undefined
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </Body>
          <FooterModal
            title=""
            isShowFooter={true}
            className="modal-footer pt-8 px-6 pb-4"
          >
            <div className="flex items-center gap-x-3 justify-center">
              <button
                onClick={onCloseModal}
                className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]"
              >
                Cancel
              </button>
              <button
                className={`!text-base text-white bg-[#1751D0] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${
                  (
                    type === "file" || type === "patent"
                      ? selectedFiles.length === 0 || uploadingFiles
                      : !isUrlValid || uploadingFiles
                  )
                    ? "button-disabled"
                    : ""
                }`}
                type="submit"
                disabled={
                  type === "file" || type === "patent"
                    ? selectedFiles.length === 0 || uploadingFiles
                    : !isUrlValid
                }
              >
                {(type === "file" || type === "patent") && "Upload"}
                {type === "url" && "Add"}
              </button>
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
}
export default AddComparisonData;
