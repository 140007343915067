import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Modal } from "./Modal";
import { HeaderModal } from "./Header";
import { Body } from "./Body";
import { FooterModal } from "./Footer";
import closeIcon from "assets/images/close-icon.svg";
import modalBgShade from "../../../assets/images/bg-modal-shade.svg";
import allImgPaths from "assets/images";
import CustomSelect from "../CustomSelect";
import { otherMemberItemType, removeUserType } from "types";
import { getOtherTeamMemberList, removeTeamMember } from "services/team";
import useAppState from "context/useAppState";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  selectedMemberId: string;
  setToast: any
}

type optionType = {
  value: string;
  label: string;
};

const RemoveMember = ({ isShow, setIsShow, selectedMemberId, setToast }: Iprops) => {
  const teamId: any = JSON.parse(localStorage.getItem("selectedTeam") || "");
  const [options, setOptions] = useState<optionType[]>([]);
  const [formData, setFormData] = useState({ selectedOptions: "" });
  const { getTeamDetails } = useAppState("settings");

  useEffect(() => {
    if (selectedMemberId) {
      const fetchData = async () => {
        const { data } = await getOtherTeamMemberList({
          team_id: teamId.currentTeamId,
          user_id: selectedMemberId,
        });

        const modifiedArray = data.map((item: otherMemberItemType) => ({
          value: item._id,
          label: item.name,
        }));

        setOptions(modifiedArray);
      };
      fetchData();
    }
  }, [selectedMemberId]);

  const handleChange = (data: optionType) => {
    setFormData({ ...formData, selectedOptions: data.value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (formData.selectedOptions) {
      const payLoad: removeUserType = {
        team_id: teamId.currentTeamId,
        user_id: selectedMemberId,
        new_user_id: formData.selectedOptions,
      };

      const response = await removeTeamMember(payLoad);
      await getTeamDetails();
      setIsShow(false);
      setToast({ show: true, heading: 'Success', message: 'Member removed successfully' })
    }
  };

  const onCloseModal = () => {
    setIsShow(false);
  };
  return (
    <>
      <Modal className={"modal-wrapper max-w-[400px]"} isShow={isShow}>
        <form onSubmit={handleSubmit}>
          {/* Modal bg Shade */}
          <img
            className="absolute top-0.5 left-0 object-contain -z-10"
            src={modalBgShade}
            alt="shade"
          />

          <HeaderModal title={"test"} isShowHeader={true} className="modal-header pt-6 px-6">
            <img
              className="w-12 h-12 mb-4"
              src={allImgPaths.removeUserIcon5}
              alt="remove"
            />
            <div className="flex items-center justify-between">
              <h2 className="text-lg text-[#101828] font-interSemiBold leading-none pb-1.5">
                Transfer Project / Portfolio
              </h2>
              {/* Close Icon */}
              <img
                className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 -mt-[130px]"
                id="modal-toggle"
                src={closeIcon}
                onClick={onCloseModal}
                alt="close icon"
              />
            </div>

            <p className="text-sm text-[#475467]">
              Are you sure you want to remove this user? All the projects/portfolios created by this
              user will have to be transferred to a user to ensure seamless function of the team.
            </p>
          </HeaderModal>
          <Body title="" isShowBody={true} className="modal-body px-6 pt-5">
            <p className="text-[15px] text-[#344054] font-interMedium pb-1.5">Transfer Project / Portfolio to</p>
            <div className="modal-select-box fete-settings-modals">
              {options.length > 0 ? (
                <CustomSelect options={options} onChange={handleChange} extendedOption={false} />
              ) : (
                <p>Loading</p>
              )}
              {/* <p className='text-[#D92D20] text-sm pt-0.5'>error</p> */}
            </div>
          </Body>

          <FooterModal title="" isShowFooter={true} className="modal-footer pt-8 px-6 pb-4">
            <div className="flex items-center gap-x-3 justify-center">
              <button
                onClick={onCloseModal}
                className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]"
              >
                Cancel
              </button>
              <button
                className={`!text-base text-white bg-[#D92D20] hover:bg-[#B42318] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${!formData?.selectedOptions ? 'button-disabled' : ''}`}>
                Remove user
              </button>
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
};

export default RemoveMember;
