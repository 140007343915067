import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Modal } from "./Modal";

import { HeaderModal } from "./Header";
import { Body } from "./Body";
import { FooterModal } from "./Footer";

import allImgPaths from "assets/images";
import { sendInvitation } from "services/user";
import { SendInvitationPayload } from "types";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  setToast: any
}

function AddMember({ isShow, setIsShow, setToast }: Iprops) {
  const [emailText, setEmailText] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [emails, setEmails] = useState<string[]>([]);
  const [formData, setFormData] = useState<any>({ selectedEmail: [] });


  const handleEmailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setEmailText(value);
    setError("")
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddEmail();
    }
  };

  const handleAddEmail = () => {
    setError("");

    const trimmedText = emailText.replace(/[,\s]/g, "");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(trimmedText);

    if (!trimmedText) {
      // If textarea empty, do not add it
      setError("");
      return
    }

    if (emails.includes(trimmedText)) {
      // If textarea email already exists, do not add it
      setError("Email already exist!")
      return;
    }
    if (trimmedText && isValid) {
      const updatedEmails = [...emails, trimmedText];
      setEmails([...emails, trimmedText]);
      setFormData({ ...formData, selectedEmail: updatedEmails });
      setEmailText("");
    } else {
      setError("Please enter valid email");
    }
  };

  const handleDelete = (index: number) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
    setFormData({ ...formData, selectedEmail: updatedEmails });
  };

  const onCloseModal = () => {
    setIsShow(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const teamId: any = JSON.parse(localStorage.getItem('selectedTeam') || "")

    const payLoad: SendInvitationPayload = {
      emails: formData.selectedEmail,
      team_id: teamId.currentTeamId
    }

    const response = await sendInvitation(payLoad);
    setEmails([]);
    setEmailText("");
    setError("");
    onCloseModal();
    setToast({ show: true, heading: 'Success', message: 'Invitation sent to new members' })
  }

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const handleFocus = () => {
    setIsFocused(true);
    console.log('test');
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  return (
    <>
      <Modal className={"modal-wrapper max-w-[400px]"} isShow={isShow}>
        <form onSubmit={handleSubmit}>
          {/* Modal bg Shade */}
          <img
            className="absolute top-0.5 left-0 object-contain -z-10"
            src={allImgPaths.modalBgShade}
            alt="shade"
          />

          <HeaderModal title={"test"} isShowHeader={true} className="modal-header pt-6 px-6">
            <img
              className="w-12 h-12 mb-4"
              src={allImgPaths.addMemberIcon}
              alt="remove"
            />
            <div className="flex items-center justify-between">
              <h2 className="text-lg text-[#101828] font-interSemiBold leading-none pb-1.5">
                Add Member
              </h2>
              {/* Close Icon */}
              <img
                className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 -mt-[130px]"
                id="modal-toggle"
                src={allImgPaths.closeIcon}
                onClick={onCloseModal}
                alt="close icon"
              />
            </div>

            <p className="text-sm text-[#475467]">Invite colleagues to collaborate on this team.</p>
          </HeaderModal>

          <Body title="" isShowBody={true} className="modal-body px-6 pt-5">
            <div className="flex items-center gap-x-3 mt-3 mb-6">
              <img src={allImgPaths.featuredRoundShadeIcon} alt="" />
              <div className="">
                <h4 className="font-interSemiBold text-sm text-[#344054]">
                  New team members need approval.
                </h4>
                <p className="text-[#475467] text-sm">
                  Invites sent out will have to be approved by the team owner.
                </p>
              </div>
            </div>

            <div className="">
              <p className="text-sm text-[#344054] font-interMedium pb-0.5">Email address</p>
              <div className={`border hover:border-[#1E0AF5] rounded-lg w-full py-3 px-3.5 flex flex-col-reverse h-24 overflow-y-auto ${isFocused ? "border-[#1e0af5]" : "border-[#D0D5DD]"}`}>
                <textarea
                  className="resize-none mb-auto min-h-8 placeholder:text-base placeholder:text-[#667085]"
                  name="selectedEmail"
                  id="selectedEmail"
                  value={emailText}
                  onChange={handleEmailChange}
                  onKeyUp={handleKeyUp}
                  onBlur={() => {
                    handleAddEmail();
                    handleBlur();
                  }}
                  placeholder="Enter email addresses"
                  onFocus={handleFocus}
                />
                <div className="chips-container">
                  {emails.map((email: string, index: number) => (
                    <div key={index} className="chip">
                      {email}
                      <button onClick={() => handleDelete(index)}>&times;</button>
                    </div>
                  ))}
                </div>
              </div>
              <p className="text-sm text-[#475467] pt-1">
                Enter one or multiple email separated by commas
              </p>
              <p className='text-[#D92D20] text-sm pt-0.5'>{error}</p>
            </div>
          </Body>

          <FooterModal title="" isShowFooter={true} className="modal-footer pt-8 px-6 pb-4">
            <div className="flex items-center gap-x-3 justify-center">
              <button
                onClick={onCloseModal}
                className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]"
              >
                Cancel
              </button>
              <button
                className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#120693] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${!formData?.selectedEmail?.length ? 'button-disabled' : ''}`}
                type="submit">
                Send invites
              </button>
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
}

export default AddMember;
