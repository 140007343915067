import { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import {
  getProjectInvitations,
  getPortfolioInvitations,
} from "../../services/user";
import {
  FILE_MAX_SIZE,
  FILE_SIZE_TEXT,
  FILE_TYPE_TEXT,
  teamNameLengthText,
} from "../../utils/constant";
import { updateTeamValidation } from "../../schema/validation";
import { UpdateTeamFields } from "../../types";
import { uploadFileToS3 } from "../../services/auth/auth";
import {
  generateTeamPresignedUrl,
  getTeamLogo,
  getTeamMembers,
  updateTeamDetails as updateTeamInfo,
} from "services/team";

const initialArgs = {
  teamDetails: null,
  searchTerm: "",
  fileData: { file_name: "", content_type: "", category: "" },
  imageUrl: "",
  imageValidation: "",
  pendingFeteInvitations: [],
  pendingFeteInvitationsLength: 0,
  teamDetailsLoading: true,
};

const reducer = (state: any, action: any) => {
  return { ...state, ...action };
};

let updatedFileName = "";

const useSettingsHook = () => {
  const [state, setState] = useReducer(reducer, initialArgs);
  const location = useLocation();

  const currentTeam = JSON.parse(
    localStorage.getItem("selectedTeam") as string
  );

  const resetStateToDefaults = () => {
    setState({ ...state, teamDetails: null, searchTerm: "" });
  };

  useEffect(() => {
    resetStateToDefaults();
  }, [location.pathname]);

  const getTeamDetails = async (query = "") => {
    try {
      const { data } = await getTeamMembers({
        page: 1,
        perPage: 20,
        sort: "asc",
        sortBy: "name",
        query,
        team_id: currentTeam?.currentTeamId,
      });
      setState({ ...state, teamDetails: data, teamDetailsLoading: false });
    } catch (error) {
      console.log("error while fetching the team members =", error);
    }
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setState({ ...state, searchTerm: value });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const teamName = e.target.value;
    if (teamName.length > 50) {
      setState({ ...state, teamNameValidation: teamNameLengthText });
    } else {
      setState({ ...state, teamNameValidation: null });
      setState({
        ...state,
        teamDetails: { ...state.teamDetails, name: teamName },
      });
    }
  };

  const updateTeamDetails = async (payload: UpdateTeamFields) => {
    try {
      await updateTeamInfo(payload);
    } catch (error: any) {
      console.log("Error while updating team =", error);
    }
  };

  const handleFileChange = async (event: any) => {
    const fileData = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

    if (!allowedTypes.includes(fileData.type)) {
      setState({ ...state, imageValidation: FILE_TYPE_TEXT });
    } else if (fileData.size > FILE_MAX_SIZE) {
      setState({ ...state, imageValidation: FILE_SIZE_TEXT });
    } else {
      setState({ ...state, imageValidation: "" });
      try {
        setState({
          ...state,
          fileData: {
            file_name: fileData.name,
            content_type: fileData.type,
            category: "team-logo",
          },
        });
        const { data } = await generateTeamPresignedUrl({
          file_name: fileData.name,
          content_type: fileData.type,
          category: "team_profile",
          team_id: currentTeam?.currentTeamId,
        });

        const payload = {
          url: data.url,
          file: fileData,
          type: fileData.type,
        };
        if (data) {
          updatedFileName = data?.fileName || "";

          const response = await uploadFileToS3(payload);

          if (response.status === 200) {
            const getUrl: any = await getTeamLogo({
              category: "team_profile",
              file_name: data.fileName,
            });
            //   setImageUrl(getUrl);
            setState({ ...state, imageUrl: getUrl });
          }
        }
      } catch (error) {
        console.log("Error while generating the presigned url =", error);
      }
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return updateTeamValidation
      .validate({ name: state.teamDetails.name })
      .then((data: any) => {
        const payload = {
          name: data.name,
          team_id: currentTeam?.currentTeamId,
          ...(updatedFileName && { logo: updatedFileName || "" }),
          // ...(state.fileData && { logo: state.fileData.file_name })
        };

        updateTeamDetails(payload);
        updatedFileName = "";
        return payload;
      })
      .catch((err: any) => {
        if (err.path === "name") {
          setState({ ...state, teamNameValidation: err.message });
        }
        return false;
      });
  };

  const getProjectInvitationList = async () => {
    try {
      const { data, total }: any = await getProjectInvitations(
        currentTeam?.currentTeamId
      );
      return { data, total };
    } catch (error) {
      console.log("error while fetching the team members =", error);
    }
  };

  const getPortfolioInvitationList = async () => {
    try {
      const { data, total }: any = await getPortfolioInvitations(
        currentTeam?.currentTeamId
      );
      return { data, total };
    } catch (error) {
      console.log("error while fetching the team members =", error);
    }
  };

  return {
    handleInputChange,
    getTeamDetails,
    handleNameChange,
    handleFileChange,
    handleFormSubmit,
    currentTeam,
    getProjectInvitationList,
    getPortfolioInvitationList,
    ...state,
  };
};
export default useSettingsHook;
