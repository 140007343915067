import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Modal } from "./Modal";

import { HeaderModal } from "./Header";
import { FooterModal } from "./Footer";

import folderPlus from "assets/images/folder-plus-blue.svg";
import close from "assets/images/close-icon.svg";

import { Body } from "./Body";
import CustomSelect from "../CustomSelect";
import { getUserProjects } from "services/project";
import { attachToProject } from "services/portfolio";
import Toast from "../Toast";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  portfolioId: any;
  selectedFiles: any;
  onSuccess: any;
}

const AddToProjectModal = ({
  isShow,
  setIsShow,
  portfolioId,
  selectedFiles,
  onSuccess,
}: Iprops) => {
  const [option, setOption] = useState("source");
  const [formattedList, setFormattedList] = useState<any[]>([]);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [isAddClicked, setIsAddClicked] = useState<boolean>(false);

  useEffect(() => {
    getProjectData();
  }, []);

  const resetStateToDefault = () => {
    setOption("source");
    setSelectedProject(null);
    setIsShow(false);
    setIsAddClicked(false);
  };

  const getProjectData = async () => {
    try {
      let selectedTeam: any = JSON.parse(
        localStorage.getItem("selectedTeam") as string
      );

      const projects = await getUserProjects(selectedTeam.currentTeamId);
      const formattedOptions = projects?.data.map((project: any) => ({
        label: project.name,
        value: project._id,
      }));
      setFormattedList(formattedOptions);
    } catch (error) {
      console.log("error while fetching the projects", error);
    }
  };

  const onCloseModal = () => {
    setIsShow(false);
  };

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setIsAddClicked(true);
      let selectedTeam: any = JSON.parse(
        localStorage.getItem("selectedTeam") as string
      );

      const payload = {
        portfolio_id: portfolioId,
        project_id: selectedProject.value,
        team_id: selectedTeam.currentTeamId,
        file_ids: selectedFiles,
        category: option,
      };

      await attachToProject(payload);

      Toast.success(
        "Success",
        `${selectedFiles.length} ${
          selectedFiles.length === 1 ? "File" : "Files"
        } added to the project successfully`
      );
      onSuccess();
      resetStateToDefault();
    } catch (error) {
      console.log("error while adding files to the project", error);
      Toast.error(
        "Error",
        "Error while adding files to the project please try again later"
      );
    }
  };
  return (
    <>
      <Modal className={"modal-wrapper max-w-[480px]"} isShow={isShow}>
        <form onSubmit={handleSubmit}>
          <HeaderModal
            title={"test"}
            isShowHeader={true}
            className="modal-header pt-6 px-6"
          >
            <div className="flex items-center justify-between pb-7">
              <img className="h-10 w-10" src={folderPlus} alt="remove" />
              {/* Close Icon */}
              <img
                className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4"
                id="modal-toggle"
                src={close}
                onClick={onCloseModal}
                alt="close icon"
              />
            </div>

            <h2 className="text-base text-[#0A090B] font-interSemiBold pb-1">
              Add to Portfolio
            </h2>
            <p className="text-sm text-[#4F4D55] font-interRegular">
              Add selected portfolios to your existing projects
            </p>
          </HeaderModal>

          <Body title="" isShowBody={true} className="modal-body pt-2">
            <div className="modal-container flex flex-col mx-[24px] my-[16px]">
              <div className="pb-5">
                <label
                  htmlFor=""
                  className="text-[#2D2B32] text-sm font-interMedium pb-2 flex"
                >
                  Project
                </label>
                <div className="modal-select-box fete-settings-modals">
                  <CustomSelect
                    options={formattedList}
                    onChange={(selectedOption) => {
                      setSelectedProject(selectedOption);
                    }}
                    extendedOption={false}
                    selectedValue={selectedProject}
                  />
                </div>
              </div>

              {/* Top Radio Part */}
              <h5 className="text-[#4F4D55] text-sm font-interRegular pb-1">
                Add as
              </h5>
              <div className="flex justify-between items-center">
                <label className="flex  cursor-pointer justify-start items-center">
                  <div className="relative flex items-center justify-center mt-1">
                    <div
                      className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${
                                          option === "source"
                                            ? "border-[#1751D0] bg-[#1751D0]"
                                            : "border-gray-300 bg-white group-hover:border-gray-400"
                                        }`}
                    >
                      {option === "source" && (
                        <div className="w-2 h-2 rounded-full bg-white mx-auto mt-[2px]" />
                      )}
                    </div>
                    <input
                      type="radio"
                      name=""
                      className="sr-only"
                      onClick={(e) => {
                        setOption("source");
                      }}
                      checked={option === "source"}
                    />
                  </div>
                  <h5 className="text-base text-[#0A090B] font-interMedium leading-[24px] text-left px-2">
                    Source
                  </h5>
                </label>

                <label className="flex  cursor-pointer justify-start items-center">
                  <div className="relative flex items-center justify-center mt-1">
                    <div
                      className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${
                                          option === "compare"
                                            ? "border-[#1751D0] bg-[#1751D0]"
                                            : "border-gray-300 bg-white group-hover:border-gray-400"
                                        }`}
                    >
                      {option === "compare" && (
                        <div className="w-2 h-2 rounded-full bg-white mx-auto mt-[2px]" />
                      )}
                    </div>
                    <input
                      type="radio"
                      name=""
                      className="sr-only"
                      onClick={(e) => {
                        setOption("compare");
                      }}
                      checked={option === "compare"}
                    />
                  </div>
                  <h5 className="text-base text-[#0A090B] font-interMedium leading-[24px] text-left px-2 mr-4">
                    Compare with Data
                  </h5>
                </label>
              </div>
            </div>
          </Body>

          <FooterModal
            title=""
            isShowFooter={true}
            className="modal-footer pt-5 px-6 pb-6"
          >
            <div className="flex items-center gap-x-3 justify-center">
              <button
                onClick={onCloseModal}
                className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]"
              >
                Cancel
              </button>
              <button
                className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${
                  !selectedProject || isAddClicked ? "button-disabled" : ""
                }`}
                disabled={!selectedProject || isAddClicked}
                type="submit"
              >
                Add
              </button>
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
};

export default AddToProjectModal;
