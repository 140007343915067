import { yupResolver } from "@hookform/resolvers/yup";
import useAppState from "context/useAppState";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { addPromptSchema } from "schema/validation";

const AddPrompt = ({showAddPrompt}: any) => {

    const { addPrompt, updatePromptApiFlag } = useAppState('promptHook')

    const [name, setName] = useState("");
    const [prompt, setPrompt] = useState("");
    
    const {
		setValue,
		handleSubmit,
		formState: { errors },
        clearErrors,
        reset,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			name: "",
			prompt: ""
		},
		resolver: yupResolver(addPromptSchema),
	});

    const handleClearForm = () => {
        reset(); // Reset the form values
        setName(""); // Clear local state
        setPrompt("");
    };

    const onSubmit = async (data:any) => {
        await addPrompt(data)
        handleClearForm();
        updatePromptApiFlag(true);
	};

    const handleNameChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setName(newValue);
        setValue('name', newValue);
        clearErrors('name');
    };

    const handlePromptChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setPrompt(newValue);
        setValue('prompt', newValue);
        clearErrors('prompt');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={` ${showAddPrompt ? "block" : "hidden"}`}>
                <div className="flex gap-x-5 items-center mb-3 mt-1">
                    <h2 className="text-[#101828] text-xl font-interSemiBold pb-2">Add Prompt</h2>
                    <button type="submit" className="submit-btn-blue !px-4 !py-2">Add</button>
                    {errors?.name && (
                        <div className='text-red-600'>{errors?.name.message}</div>
                    )}
                    {errors?.prompt && (
                        <div className='text-red-600'>{errors?.prompt.message}</div>
                    )}
                </div>

                {/* Add Prompt row */}
                <div className="flex gap-x-5">
                    <textarea
                        id=""
                        name="name"
                        value={name}
                        onChange={handleNameChange}
                        className="placeholder:text-[#344054] placeholder:text-base text-base text-[#344054] border border-[#D0D5DD] rounded-lg py-2.5 px-2.5 resize-none w-[17.24%] overflow-y-auto h-[calc(100vh_-_750px)] flex-shrink-0"
                        placeholder="Prompt Name"
                    />

                    <textarea
                        id=""
                        name="prompt"
                        value={prompt}
                        onChange={handlePromptChange}
                        className="placeholder:text-[#344054] placeholder:text-base text-base text-[#344054]  border border-[#D0D5DD] rounded-lg py-2.5 px-2.5 resize-none w-full overflow-y-auto h-[calc(100vh_-_750px)]"
                        placeholder="New Prompt"
                    />
                </div>
            </div>
        </form>
    )
}

export default AddPrompt;