import useAppState from "context/useAppState";
import allImgPaths from "assets/images";
import React, { useEffect, useState } from "react";
import { getProjectInvitations, updateApprovalStatus } from "services/user";
import { getPendingInvitations } from "services/auth/auth";
import Toast from "components/common/Toast";
import { ExistingSettingMemberLoader } from "pages/loaders";
import { capitalize } from "lodash";

interface Iprops {
  setToast: any;
}

const PendingInvitations = ({ setToast }: Iprops) => {
  const [pendingInvitations, setPendingInvitations] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { getProjectInvitationList, getPortfolioInvitationList } =
    useAppState("settings");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const { data: projectData } = await getProjectInvitationList();
    const { data: portfolioData } = await getPortfolioInvitationList();

    setPendingInvitations([...projectData, ...portfolioData]);
    setLoading(false);
  };

  const currentTeam = JSON.parse(
    localStorage.getItem("selectedTeam") as string
  );

  const updateCardStatus = async (data: any, status: boolean) => {
    const payload = {
      is_approved: status,
      email: data?.user_email,
      team_id: currentTeam?.currentTeamId,
      type: data?.type,
    };

    const collaboratorId = data?._id;

    await updateApprovalStatus(collaboratorId, payload);

    setToast({
      show: true,
      heading: "Success",
      message: "Approval status updated successfully",
    });
    fetchData();
  };

  return (
    <div className="">
      {loading && <ExistingSettingMemberLoader />}
      {!loading &&
        pendingInvitations &&
        pendingInvitations?.length > 0 && (
          <React.Fragment>
            <div className="custom-table-grid-pending-list bg-[#F9FAFB] rounded-tl-lg rounded-tr-lg border-b-0 border border-[#EAECF0]">
              <div>
                <span className="font-interMedium text-xs text-[#475467] py-3 px-6 inline-block">
                  Name
                </span>
              </div>
              <div>
                <span className="font-interMedium text-xs text-[#475467] py-3 px-6 inline-block">
                  Invited by
                </span>
              </div>
              <div>
                <span className="font-interMedium text-xs text-[#475467] py-3 px-6 inline-block">
                  Type
                </span>
              </div>
              <div>
                <span className="font-interMedium text-xs text-[#475467] py-3 px-6 inline-block">
                  Type name
                </span>
              </div>
              {/* <div>
                            <span className="opacity-0">test</span>
                        </div> */}
            </div>

            <div className="border border-[#EAECF0] rounded-bl-lg rounded-br-lg overflow-y-auto max-h-[246px]">
              {pendingInvitations.map((item: any) => (
                <React.Fragment key={item._id}>
                  <div className="custom-table-grid-pending-list border-b border-[#EAECF0]">
                    <div className="flex items-center gap-x-3 py-2 px-6">
                      <img
                        className="w-7 h-7 rounded-full"
                        src={
                          item?.profile_image
                            ? item.profile_image
                            : allImgPaths.defaultProfile
                        }
                        alt=""
                      />
                      <div className="">
                        <h6 className="text-[#101828] text-sm font-interMedium">
                          {item?.user_name ? item?.user_name : "-"}
                        </h6>
                        <span className="text-sm text-[#475467]">
                          {item?.user_email}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center gap-x-3 py-2 px-6">
                      <img
                        className="w-7 h-7 rounded-full"
                        src={
                          item?.invited_by_profile_image
                            ? item.invited_by_profile_image
                            : allImgPaths.defaultProfile
                        }
                        alt=""
                      />
                      <div className="">
                        <h6 className="text-[#101828] text-sm font-interMedium">
                          {item.invited_by_name}
                        </h6>
                        <span className="text-sm text-[#475467]">
                          {item.invited_by_email}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center py-4 px-6">
                      <div className="">
                        <h6 className="text-[#101828] text-sm font-interMedium">
                          {capitalize(item.type)}
                        </h6>
                      </div>
                    </div>

                    <div className="flex items-center py-4 px-6">
                      <div className="">
                        <h6 className="text-[#101828] text-sm font-interMedium">
                          {item.type === "project" && item.project}{" "}
                          {item.type === "portfolio" && item.portfolio}
                        </h6>
                      </div>
                    </div>

                    <div className="flex items-center gap-x-3 py-4 px-6">
                      <span
                        className="text-[#D92D20] text-sm font-interSemiBold flex items-center gap-x-2 cursor-pointer hover:bg-[#fef3f2] px-2 py-1 rounded"
                        onClick={() => updateCardStatus(item, false)}
                      >
                        <img src={allImgPaths.closeIconRed} alt="" />
                        Reject
                      </span>

                      <span
                        className="text-[#067647] text-sm font-interSemiBold flex items-center gap-x-2 cursor-pointer hover:bg-[#DCFAE6] px-2 py-1 rounded"
                        onClick={() => updateCardStatus(item, true)}
                      >
                        <img src={allImgPaths.RightCheckGreenIcon} alt="" />
                        Approve
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </React.Fragment>
        )}
      {!loading && !pendingInvitations?.length && (
        <div className="ml-3">
          <h3>No Pending Invitations</h3>
        </div>
      )}
    </div>
  );
};

export default PendingInvitations;
