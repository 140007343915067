import React from 'react';

const ClaimsTableShimmerLoader = () => {
  // Mock columns to match your table structure
  const columns = [
    { ref: 'name',width: 'w-[40%]' },
    { ref: 'createdBy',width: 'w-[25%]' },
    { ref: 'updatedAt',width: 'w-[25%]' },
    { ref: 'action',width: 'w-[10%]' }
  ];

  return (
    <div className="rounded-br-2xl overflow-hidden">
      <div className="bg-white">
        {/* Header */}
        <div className="flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1]">
          {columns.map((column,index) => (
            <div
              key={index}
              className={`${column.width} flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6`}
            >
              <div className="h-5 bg-gray-200 rounded animate-pulse w-24"></div>
            </div>
          ))}
        </div>

        {/* Body */}
        <div className="h-[calc(100vh_-_200px)]">
          {[1,2,3,4,5].map((row) => (
            <div key={row} className="flex flex-nowrap border-b border-[#F1F1F1]">
              {/* Name Column */}
              <div className="w-[40%] flex-shrink-0 px-6 py-4">
                <div className="h-5 bg-gray-200 rounded animate-pulse w-48"></div>
              </div>

              {/* Created By Column */}
              <div className="w-[25%] flex-shrink-0 px-6 py-4">
                <div className="space-y-2">
                  <div className="h-5 bg-gray-200 rounded animate-pulse w-32"></div>
                  <div className="h-4 bg-gray-100 rounded animate-pulse w-24"></div>
                </div>
              </div>

              {/* Updated At Column */}
              <div className="w-[25%] flex-shrink-0 px-6 py-4">
                <div className="space-y-2">
                  <div className="h-5 bg-gray-200 rounded animate-pulse w-32"></div>
                  <div className="h-4 bg-gray-100 rounded animate-pulse w-24"></div>
                </div>
              </div>

              {/* Action Column */}
              <div className="w-[10%] flex-shrink-0 px-6 py-4 flex justify-center">
                <div className="h-8 w-8 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClaimsTableShimmerLoader;