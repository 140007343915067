const PortfolioDetailShimmerLoader = () => {
  return (
    <div className="w-full p-4 animate-pulse">
      {/* Breadcrumbs Shimmer */}
      <div className="flex gap-2 mb-4">
        <div className="h-4 w-20 bg-gray-200 rounded"></div>
        <div className="h-4 w-4 bg-gray-200 rounded"></div>
        <div className="h-4 w-32 bg-gray-200 rounded"></div>
      </div>

      {/* Folders Section Shimmer */}
      <div className="mb-8">
        <div className="h-6 w-48 bg-gray-200 rounded mb-4"></div>
        <div className="flex gap-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="w-64 h-32 bg-gray-100 rounded-lg p-4">
              <div className="h-5 w-3/4 bg-gray-200 rounded mb-2"></div>
              <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
            </div>
          ))}
        </div>
      </div>

      {/* Files Section Shimmer */}
      <div>
        <div className="h-6 w-48 bg-gray-200 rounded mb-4"></div>
        <div className="space-y-2">
          {/* Table Header */}
          <div className="flex gap-4 border-b pb-2">
            <div className="w-1/12 h-5 bg-gray-200 rounded"></div>
            <div className="w-4/12 h-5 bg-gray-200 rounded"></div>
            <div className="w-3/12 h-5 bg-gray-200 rounded"></div>
          </div>

          {/* Table Rows */}
          {[1, 2, 3, 4, 5].map((i) => (
            <div key={i} className="flex gap-4 py-2">
              <div className="w-1/12 h-5 bg-gray-200 rounded"></div>
              <div className="w-4/12 h-5 bg-gray-200 rounded"></div>
              <div className="w-3/12 h-5 bg-gray-200 rounded"></div>
              <div className="w-2/12 h-5 bg-gray-200 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailShimmerLoader;
