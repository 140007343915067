import { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "./Modal";
import { HeaderModal } from "./Header";
import { Body } from "./Body";
import { FooterModal } from "./Footer";


import { InputTextField } from "components/InputField";
import { changePassword } from "services/user";
import Toast from "../Toast";
import { PasswordRulesComponent } from "components/Auth";
import { checkAllFieldSame, passwordValidation } from "utils/function";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  setFetchNewData: any;
}

let updatedFileName = "";

const ChangePassword = ({ isShow, setIsShow, setFetchNewData }: Iprops) => {
  const initialShowEyeOptions = {
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  };
  const initialChangePasswordDetails = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [formData, setFormData] = useState(initialChangePasswordDetails);
  const [error, setError] = useState<any>(initialChangePasswordDetails);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValidState, setIsFormValidState] = useState(false);

  const [showEyeBtn, setShowEyeButton] = useState(initialShowEyeOptions);
  const [passwordRule, setPasswordRule] = useState<boolean[]>([]);
  const [showPasswordRule, setShowPasswordRule] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const resetStateToDefaults = () => {
    setFormData(initialChangePasswordDetails);
    setError(initialChangePasswordDetails);
    setErrMsg("");
    setShowEyeButton(initialShowEyeOptions);
    setPasswordRule([]);
    setShowPasswordRule(false);
    setIsShow(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedForm = { ...prevData, [name]: value };
      validateForm(updatedForm, name);
      return updatedForm;
    });

    setError((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = (updatedForm: any, name: any) => {
    let newErrors: any = {};

    if (!updatedForm.currentPassword)
      newErrors.currentPassword = "Current password is required.";

    if (name === "newPassword") {
      setShowPasswordRule(true);
      const results = passwordValidation(updatedForm.newPassword);

      if (passwordRule.length > 0 && checkAllFieldSame(passwordRule)) {
        setShowPasswordRule(false);
      } else {
        setShowPasswordRule(true);
      }
      setPasswordRule(results);
    } else {
      setShowPasswordRule(false);
    }

    if (!updatedForm.confirmPassword)
      newErrors.confirmPassword = "Please confirm your new password.";
    else if (updatedForm.newPassword !== updatedForm.confirmPassword)
      newErrors.confirmPassword = "Passwords do not match.";

    setError(newErrors);
    setIsFormValidState(Object.keys(newErrors).length === 0);
  };

  const submitForm = async (event: any) => {
    event.preventDefault();

    if (!isFormValidState) return;

    setIsSubmitting(true);

    try {
      await changePassword({
        old_password: formData.currentPassword,
        new_password: formData.newPassword,
      });

      Toast.success("Success", "Password updated successfully!");
      setFetchNewData(true);
      resetStateToDefaults();
    } catch (error: any) {
      console.error(error);
      setErrMsg(error.message);
      //   Toast.error("Error", "Failed to update password. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEyeBtn = (fieldType: string) => {
    setShowEyeButton((prev: any) => ({
      ...prev,
      [fieldType]: !prev[fieldType],
    }));
  };

  const handleShowPassWordRule = () => {
    setShowPasswordRule(!showPasswordRule);
  };

  return (
    <>
      <Modal className={"modal-wrapper max-w-[480px]"} isShow={isShow}>
        <form onSubmit={submitForm}>
          <HeaderModal
            title={"test"}
            isShowHeader={true}
            className="modal-header pt-6 px-6"
          >
            <div className="pb-5">
              <h2 className="text-lg text-[#101828] font-interSemiBold leading-none pb-1.5">
                Change Password
              </h2>
              <p className="text-sm text-[#475467]">
                {" "}
                Please enter your current password to change your password.
              </p>
            </div>
          </HeaderModal>
          <Body title="" isShowBody={true} className="modal-body px-6">
            <div className="">
              <div className="mb-5">
                <InputTextField
                  label="Current Password"
                  name="currentPassword"
                  placeholder="Current Password"
                  type={showEyeBtn.currentPassword ? "text" : "password"}
                  onChange={handleChange}
                  value={formData.currentPassword}
                  style={error.currentPassword ? "border-red-500" : ""}
                  iButton={true}
                  err={error.currentPassword}
                  handleShowEyeBtn={() => {
                    handleEyeBtn("currentPassword");
                  }}
                  showEyeBtn={showEyeBtn.currentPassword}
                />
              </div>

              <div className="mb-4">
                <InputTextField
                  label="New Password"
                  name="newPassword"
                  placeholder="New Password"
                  type={showEyeBtn.newPassword ? "text" : "password"}
                  onChange={handleChange}
                  value={formData.newPassword}
                  style={error.newPassword ? "border-red-500" : ""}
                  onBlur={() => setShowPasswordRule(false)}
                  iButton={true}
                  err={error.newPassword}
                  handleShowEyeBtn={() => {
                    handleEyeBtn("newPassword");
                  }}
                  showEyeBtn={showEyeBtn.newPassword}
                  onClick={handleShowPassWordRule}
                />
                <PasswordRulesComponent
                  passwordRule={passwordRule}
                  showPasswordRule={showPasswordRule}
                  setShowPasswordRule={setShowPasswordRule}
                  isChangePasswordPopUpShow={true}
                />
              </div>

              <div className="mb-6">
                <InputTextField
                  label="Confirm New Password"
                  name="confirmPassword"
                  placeholder="Confirm New Password"
                  type={showEyeBtn.confirmPassword ? "text" : "password"}
                  onChange={handleChange}
                  value={formData.confirmPassword}
                  style={error.confirmPassword ? "border-red-500" : ""}
                  iButton={true}
                  err={error.confirmPassword}
                  handleShowEyeBtn={() => {
                    handleEyeBtn("confirmPassword");
                  }}
                  showEyeBtn={showEyeBtn.confirmPassword}
                />
              </div>
              <p className="text-[#D92D20] text-sm pt-0.5">{errMsg}</p>
            </div>
          </Body>

          <FooterModal
            title=""
            isShowFooter={true}
            className="modal-footer pt-6 px-6 pb-6 border-[#E4E7EC] border-t "
          >
            <div className="flex items-center gap-x-3 justify-end">
              <button
                onClick={resetStateToDefaults}
                className="text-[#344054] font-interSemiBold border border-[#D0D5DD] hover:border-[#1E0AF5] rounded-lg tracking-wide py-2.5 px-4 !text-base"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isFormValidState || isSubmitting}
                className={`!text-base text-white font-interSemiBold rounded-lg tracking-wide px-5 py-2.5 ${
                  !isFormValidState || isSubmitting
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-[#1E0AF5] hover:bg-[#120693]"
                }`}
              >
                {isSubmitting ? "Updating..." : "Update Password"}
              </button>
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
};

export default ChangePassword;
