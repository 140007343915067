import React, { Dispatch, SetStateAction, useState, useMemo } from "react";
import { Modal } from "./Modal";
import { HeaderModal } from "./Header";
import { FooterModal } from "./Footer";
import close from "assets/images/close-icon.svg";
import { Body } from "./Body";
import allImgPaths from "assets/images";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  id: string;
  title: string;
  description: string;
  onDelete: (id: string) => Promise<void>;
}

const DeleteModal = ({
  isShow,
  setIsShow,
  id,
  title,
  description,
  onDelete,
}: Iprops) => {
  const onCloseModal = () => {
    setIsShow(false);
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    await onDelete(id);
    setIsShow(false);
  };

  return (
    <>
      <Modal className={"modal-wrapper max-w-[420px]"} isShow={isShow}>
        <form onSubmit={handleSubmit}>
          <HeaderModal
            title={"test"}
            isShowHeader={true}
            className="modal-header pt-6 px-6"
          >
            <div className="flex items-center justify-between pb-5">
              <img
                className="h-10 w-10"
                src={allImgPaths.deletIconModal}
                alt="remove"
              />
              <img
                className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4"
                id="modal-toggle"
                src={close}
                onClick={onCloseModal}
                alt="close icon"
              />
            </div>

            <h2 className="text-base text-[#0A090B] font-interSemiBold pb-4">
              {title}
            </h2>
            <p className="text-sm text-[#4F4D55] font-interRegular">
              {description}
            </p>
          </HeaderModal>

          <Body title="" isShowBody={true} className="modal-body">
            <></>
          </Body>

          <FooterModal
            title=""
            isShowFooter={true}
            className="modal-footer pt-5 px-6 pb-6"
          >
            <div className="flex items-center gap-x-3 justify-center">
              <button
                onClick={onCloseModal}
                className="text-[#4F4D55] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]"
              >
                Cancel
              </button>
              <button
                className={`!text-base text-white font-interSemiBold bg-[#E12121] hover:bg-[#B42318] rounded-lg px-3.5 py-2.5 flex-1`}
                type="submit"
              >
                Yes, Delete
              </button>
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
};

export default DeleteModal;
