// import { City, Country } from "country-state-city";

export const signupValidation = {
  email: "Please enter your email",
  name: "Please enter name",
  password: "Please enter your password",
  confirmPassword: "Please enter confirm Password",
};

export const signupValidationText = {
  nameText: "Please enter a valid name",
  emailValidation: "Please enter your email",
  emailText: "Please enter a valid email address",
  emailRequired: "Email address is required.",
  minPasswordLength: "Password must be at least 8 characters",
  maxPasswordLength: "Password must be at most 32 characters",
  passwordText: "Please enter your password",
  validPassword:
    "Password should contain minimum 8 character, at least one Lower Case letter, one number, one Upper Case letter and one special character",
  confirmPasswordText: "Please enter confirm password",
  confirmPassword: "Password mismatch",
};

export const teamNameLengthText = "You can add up to only 50 characters.";
export const MAP_API_KEY = "AIzaSyA468Fyil6tABBGD8ye3exch-kFjLEQGFU";

export const otpValidation = {
  otpRequired: "Please enter a verification code it should not be empty.",
  sixDigitOtp: "Verification code must be of 6 digits.",
};

export const roleValidation = {
  roleRequired: "Please select a role.",
};

export const USER_TYPE = {
  individual: "Individual",
  organization: "Organization",
};

export const teamValidation = {
  emailValidationText: "Please enter correct email",
  inviteSelfEmailText: "You cannot send an invite to yourself.",
  emailExistText: "Email has already been added",
  emailLengthText: "Emails field must have at least 1 items",
  teamNameText: "Please enter Team Name",
  teamNameValidation: "Please enter valid team name",
};

export const USER_NOT_ACTIVE = "User is not verified";
export const ORGANIZATION_SET_ERROR = "Please set your organization type first";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const teamNameValidationText = "Please enter valid team name";
export const PLANNER_TEXT = "Please select planner";
export const TEAM_TEXT = "Please select team member";
export const EMAIL_VALIDATION = "Please select email address";
export const TASK_VALIDATION = "Please select task";

export const FILE_SIZE_TEXT =
  "Please select a file that is less than 1MB in size.";
export const FILE_TYPE_TEXT = "You can select only .jpeg, .png, or .jpg files.";

export const FILE_MAX_SIZE = 1048576;
export const DEBOUNCE_TIME = 500;

export const sliderSetting = {
  dots: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: false,
};

export const ROLES = {
  ADMIN: "Admin",
  PLANNER: "Planner",
  OWNER: "Owner",
};

export const CARD_STATUS = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};

export const TASK_STATUS = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};

export const FETE_ACCESS_OPTION = [
  {
    label: "Restricted",
    value: "restricted",
    extraText: "only team admin and people invited to the fete can view",
  },
  {
    label: "Public",
    value: "public",
    extraText: "all members of the team can view",
  },
];

export const CUSTOM_ROLES = [
  { label: "Admin", value: "Admin" },
  { label: "Owner", value: "Owner" },
  { label: "Member", value: "Member" },
];

export const FETE_FILTERS = [
  { label: "All", value: "All" },
  { label: "In-Person", value: "In-Person" },
  { label: "Online", value: "Online" },
  { label: "Hybrid", value: "Hybrid" },
];

export const RFP_FILTERS = [
  { label: "Venue", value: "Venue" },
  { label: "Decor", value: "decor" },
  { label: "F&B", value: "f&b" },
];

export const RANGE = {
  ATTENDEES: { MIN: 0, MAX: 5000 },
  BUDGET: { MIN: 100, MAX: 5_00_000 },
};

export const getAbrivationTimeZone = (data) => {
  const firstMatchingTimezones = new Map();
  // Iterate over the timezones array
  data.forEach((timezone) => {
    // Check if the gmtOffset already exists in the map
    if (!firstMatchingTimezones.has(timezone.abbreviation)) {
      const index = timezone.tzName.indexOf(" (");
      let result = timezone.tzName;
      if (index !== -1) {
        result = timezone.tzName.substring(0, index);
      }
      // Concatenate abbreviation with tzName
      const tzName = `${result} (${timezone.abbreviation})`;
      firstMatchingTimezones.set(timezone.abbreviation, {
        zoneName: timezone.zoneName,
        gmtOffset: timezone.gmtOffset,
        gmtOffsetName: timezone.gmtOffsetName,
        abbreviation: timezone.abbreviation,
        tzName: tzName,
      });
    }
  });

  // Convert the map values back to an array
  const uniqueTimezones = Array.from(firstMatchingTimezones.values());
  return uniqueTimezones;
};

// export const getAllCountryAbbreviation = () => {
//   const allCountries = [Country.getCountryByCode('US'), Country.getCountryByCode('CA')]

//   const allTimezones = Object.values(allCountries).flatMap((country) => country.timezones);

//   return { timezones: allTimezones };
// };

// export const getAllCities = () => {
//   const codes = ['US', 'CA'];
//   let allCities = []
//   codes.map(cd => {
//     const country = Country.getCountryByCode(cd);
//     const cities = City.getCitiesOfCountry(country.isoCode);
//     allCities.push(...cities)
//   })

//   return allCities
// }

export const FETE_FORMATS = [
  { id: 1, label: "In-Person" },
  { id: 2, label: "Hybrid" },
  { id: 3, label: "Online" },
];

export const CHIP_DISPLAY_LINE = {
  FIRST_LINE: "line1",
  SECOND_LINE: "line2",
  THIRD_LINE: "line3",
};

export const EVENT_RANGE_FIELDS = ["event_budget", "event_number_of_attendees"];

export const API_ROUTE_PREFIX = "/v1";

export const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}${API_ROUTE_PREFIX}`;

export const DEFAULT_BLOCK_IMAGES_BASE_URL =
  process.env.REACT_APP_BLOCK_IMAGES_S3_URL;

// export const LLM_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const LLM_BASE_URL = 'https://api.dev.ipvijil.ai';
export const LLM_BASE_URL = process.env.REACT_APP_LLM_BASE_URL;

export const DOCUMENTS_BUCKET_NAME = "dev-ipvijil-files";

export const DOCUMENT_ROUTE_PREFIX = "documents";

export const LLM_INTENTS = {
  CREATE_EVENT: "CREATE_EVENT",
  SKIP_EXTRACTION: "SKIP_EXTRACTION",
};

export const LLM_USERNAME = process.env.REACT_APP_LLM_USERNAME;
export const LLM_PASSWORD = process.env.REACT_APP_LLM_PASSWORD;

export const EVENT_PROGRAM_TITLE = "Event Program";

export const EVENT_PER_PAGE = 5;

export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

export const PROFILE_IMAGE_MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

export const ALLOWED_FILE_MIME_TYPES = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/csv",
  "text/plain",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const ALLOWED_IMAGE_MIME_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
];

export const timeZoneWithStates = [
  {
    timezone: "Eastern Standard Time (EST)",
    states: [
      "CT",
      "DE",
      "GA",
      "ME",
      "MD",
      "MA",
      "MI",
      "NH",
      "NJ",
      "NY",
      "NC",
      "OH",
      "PA",
      "RI",
      "SC",
      "VT",
      "VA",
      "WA",
      "WV",
      "FL",
      "IN",
      "KY",
      "TN",
      "ON",
      "QC",
    ],
    value: "America/New_York",
  },
  {
    timezone: "Central Standard Time (CST)",
    states: [
      "AL",
      "AR",
      "IL",
      "IA",
      "LA",
      "MN",
      "MS",
      "MO",
      "NE",
      "OK",
      "WI",
      "FL",
      "IN",
      "KY",
      "TN",
      "KS",
      "NE",
      "ND",
      "SD",
      "TX",
      "MB",
      "SK",
      "ON",
      "NU",
    ],
    value: "America/Chicago",
  },
  {
    timezone: "Mountain Standard Time (MST)",
    states: [
      "AZ",
      "CO",
      "MT",
      "NM",
      "UT",
      "WY",
      "KS",
      "NE",
      "ND",
      "SD",
      "TX",
      "ID",
      "OR",
      "AB",
      "BC",
      "NT",
      "NU",
      "SK",
    ],
    value: "America/Denver",
  },
  {
    timezone: "Pacific Standard Time (PST)",
    states: ["CA", "NV", "ID", "OR", "BC"],
    value: "America/Los_Angeles",
  },
  {
    timezone: "Alaska Standard Time (AKST)",
    states: ["AK", "YT"],
    value: "America/Anchorage",
  },
  {
    timezone: "Hawaii–Aleutian Standard Time (HST)",
    states: ["HI"],
    value: "Pacific/Honolulu",
  },
  {
    timezone: "Atlantic Standard Time (AST)",
    states: ["NS", "NB", "PE"],
    value: "America/Halifax",
  },
  {
    timezone: "Newfoundland Standard Time (NST)",
    states: ["NL"],
    value: "America/St_Johns",
  },
];

export const ACTUAL_CARD_NAMES = {
  LOGISTICS_AND_OTHERS: "Logistics",
  THEMES: "Themes",
  BUDGET: "Budget",
  FETE_PROGRAM: "Event Program",
  ATTENDEES: "Attendees",
  SPECIAL_GUESTS_AND_SPEAKERS: "Special Guests & Speakers",
  VENDOR: "Vendor",
  CONTRACTS: "Contracts",
  INVOICES: "Invoices",
  PRINT_AND_MEDIA: "Print & Media",
  SOCIAL_NETWORKS: "Social Networks",
  PRE_FETE: "Pre-Fete",
  POST_FETE: "Post-Fete",

  // New Cards
  BRAINSTORM: "Brainstorm",
  DRAFT: "Draft",
  EXPLORE: "Explore",
  UPLOAD: "Upload",
  ADD: "Add",
  DRAFT_ATTENDEES: "Draft/Attendees",
  DRAFT_SPECIAL_GUESTS: "Draft/Special Guests",
  VENDOR_RECOMMENDATION: "Vendor Recommendation",
  ADD_LOGISTICS: "Add/Logistics",
  DRAFT_RFP: "Draft/RFP",
  DRAFT_CONTRACTS: "Draft/Contracts",
  ANALYZE: "Analyze",
};

export const ACTUAL_TASK_NAMES = {
  LOGISTICS_AND_OTHERS: {
    FINALIZE_LOGISTICS: "Finalize Logistics",
  },
  THEMES: {
    FETE: "Event Themes",
    DECOR: "Decor Themes",
    VENUE: "Venue Styles",
  },
  BUDGET: {
    ALLOCATION: "Budget Allocation",
  },
  FETE_PROGRAM: {
    DRAFT: "Draft Event Program",
  },
  ATTENDEES: {
    UPLOAD_ATTENDEE_LIST: "Upload attendee list",
    CREATE_ATTENDEE_LIST: "Create attendee list",
    DRAFT_INVITATIONS: "Draft Invitations",
    CREATE_FETE_REGISTRATION: "Create Fete Registration",
  },
  SPECIAL_GUESTS_AND_SPEAKERS: {
    ADD_SPECIAL_GUESTS: "Add special guests",
    DRAFT_INVITATIONS: "Draft Invitations",
    DRAFT_GUEST_AGREEMENTS: "Draft Guest Agreements",
  },
  VENDOR: {
    VENUE: "Venue",
    DECOR: "Decor",
    CATERING: "Catering",
    ENTERTAINMENT: "Entertainment",
  },
  CONTRACTS: {
    FINAL_CONTRACTS: "Final Contracts",
    DOCUSIGN_INTEGRATION: "Docusign Integration",
  },
  LOGISTICS: {
    PERMITS_AND_INSURANCE: "Permits & Insurance",
    EMERGENCY_SERVICES: "Emergency Services",
    ACCESIBILITY_MEASURES: "Accessibility Measures",
    SECURITY: "Security",
    STORAGE: "Storage",
    LOAD_IN_OUT_SCHEDULE: "Load-in/Load-out Schedule",
  },
  // INVOICES: {
  //   INVOICES: 'Invoices'
  // },
  PRINT_AND_MEDIA: {
    CREATE_PRESS_RELEASE: "Create Press Release",
    CONTACT_MEDIA_OUTLETS: "Contact Media Outlets",
    DESIGN_PROMOTIONAL_MATERIAL: "Design Promotional Material",
    PRINT_AND_PUBLISH: "Print and Publish",
  },
  SOCIAL_NETWORKS: {
    CONTENT_MANAGEMENT: "Content Management",
    KNOW_YOUR_TARGET_AUDIENCE: "Know Your Target Audience",
  },
  PRE_FETE: {
    KNOW_YOUR_TARGET_AUDIENCE: "Know Your Target Audience",
    KEEP_THE_ATTENDEES_ENGAGED: "Keep The Attendees Engaged",
  },
  POST_FETE: {
    IDEATION: "Ideation",
    DRAFT_POLLS_AND_SURVEY: "Draft Polls & Survey",
  },

  // New Tasks
  EVENT_THEMES: "Event Themes",
  DECOR_THEMES: "Décor Themes",
  VENUE_STYLES: "Venue Styles",
  GIFTS_SWAGS: "Gifts/Swags",
  ACTIVITIES: "Activities",
  EVENT_PROGRAM: "Event Program",
  EVENT_AGENDA: "Event Agenda",
  BRAND_VOICE: "Brand Voice",
  BRAND_KIT: "Brand Kit",
  EMAIL_SIGNARURES_AND_TEMPLATES: "Email Signatures and Templates",
  PRESENTATION_DECK_TEMPLATES: "Presentation Deck Templates",
  BUDGET_ALLOCATION: "Budget Allocation",
  BRAND_LOGO: "Brand Logo",
  BRAND_DOCUMENT: "Brand Document",
  INSPIRATION_IMAGES: "Inspiration Images",
  ATTENDEE_LIST: "Attendee List",
  SPONSOR_LOGOS: "Sponsor Logos",
  SPECIAL_GUESTS: "Special Guests",
  INVITATION: "Invitation",
  EVENT_REMINDER: "Event Reminder",
  OTHER_COMMINCATION: "Other Communication",
  AGGREMENTS: "Agreements",
  VENUE: "Venue",
  FB: "F&B",
  DECOR: "Décor",
  ENTERTAINMENT: "Entertainment",
  TECHNOLOGY: "Technology",
  MEETING_SPACE: "Meeting Space",
  ACCOMODATION: "Accomodation",
  TRANSPORTATION: "Transportation",
  DIETARY_REQUIREMENTS: "Dietary Requirements",
  ACCESSIBILITY: "Accessibility",
  EMERGENCY_HEALTH_SERVICES: "Emergency & Health Services",
  SECURITY: "Security",
  LEGAL_COMPLIANCE_SUSTAINABILITY: "Legal, Compliance and Sustainability",
  INVOICES: "Invoices",
  EVENT_PROMOTION: "Event Promotion",
  DRAFT: "Draft",
  EMAIL: "Email",
  PRESS_RELEASE: "Press Release",
  ADS: "Ads",
  DRAFT_SOCIAL_MEDIA_ADS: "Draft/Social Media Ads",
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  LINKEDIN: "LinkedIn",
  X: "X",
  PINTEREST: "Pinterest",
  EVENT_APP: "Event App",
  PRE_EVENT_SURVEY: "Pre-Event Survey",
  POST_EVENT_SURVEY: "Post-Event Survey",
  PRE_EVENT_QUESTIONAIRE: "Pre-Event Questionnaire",
  POST_EVENT_QUESTIONAIRE: "Post-Event Questionnaire",
  PRE_EVENT_MAIL: "Pre-Event Mail",
  POST_EVENT_MAIL: "Post-Event Mail",
  GIFTS: "Gifts",
  EVENT_PHOTOGRAPHY_VIDEOGRAPHY_GUIDELINES:
    "Event Photography/Videography Guidelines",
  SPONSORSHIP_PACKAGES: "Sponsorship Packages",
  SPEAKER_PRESENTATIONS: "Speaker Presentations",
  SPONSOR_INFORMATION: "Sponsor Information",
  PANEL_QUESTIONS_MATERIALS: "Panel Questions/Materials",
  SPONSOR_CONTRACTS: "Sponsor Contracts",
  VENUE_SELECTION: "Venue Selection",
  FB_SELECTION: "F&B Selection",
  TECH_AV_VENDORS: "Tech/AV Vendors",
  TRANSPORTATION_VENDOR_SELECTION: "Transportation Vendor Selection",
  EVENT_WEBSITE: "Event Website",
  PLUS_ONE_TRACKING: "Plus-One Tracking",
  TICKETING_FOR_LARGE_SOCIAL_EVENTS: "Ticketing for Large Social Events",
};

export const ACTUAL_TASK_INDENTATION = {
  LOGISTICS_AND_OTHERS: {
    FINALIZE_LOGISTICS: "finalize_logistics",
  },
  THEMES: {
    FETE: "event_themes",
    DECOR: "decor_themes",
    VENUE: "venue_styles",
  },
  BUDGET: {
    ALLOCATION: "budget_allocation",
  },
  FETE_PROGRAM: {
    DRAFT: "draft_event_program",
  },
  ATTENDEES: {
    UPLOAD_ATTENDEE_LIST: "upload_attendee_list",
    CREATE_ATTENDEE_LIST: "create_attendee_list",
    DRAFT_INVITATIONS: "draft_invitations",
    CREATE_FETE_REGISTRATION: "create_fete_registration",
  },
  SPECIAL_GUESTS_AND_SPEAKERS: {
    ADD_SPECIAL_GUESTS: "add_special_guests",
    DRAFT_INVITATIONS: "draft_invitations",
    DRAFT_GUEST_AGREEMENTS: "draft_guest_agreements",
  },
  VENDOR: {
    VENUE: "venue",
    DECOR: "decor",
    CATERING: "catering",
    ENTERTAINMENT: "entertainment",
  },
  CONTRACTS: {
    FINAL_CONTRACTS: "final_contracts",
    DOCUSIGN_INTEGRATION: "docusign_integration",
  },
  LOGISTICS: {
    PERMITS_AND_INSURANCE: "permits_&_insurance",
    EMERGENCY_SERVICES: "emergency_services",
    ACCESIBILITY_MEASURES: "accessibility_measures",
    SECURITY: "security",
    STORAGE: "storage",
    LOAD_IN_OUT_SCHEDULE: "load-in/load-out_schedule",
  },
  // INVOICES: {
  //   INVOICES: 'invoices'
  // },
  PRINT_AND_MEDIA: {
    CREATE_PRESS_RELEASE: "create_press_release",
    CONTACT_MEDIA_OUTLETS: "contact_media_outlets",
    DESIGN_PROMOTIONAL_MATERIAL: "design_promotional_material",
    PRINT_AND_PUBLISH: "print_and_publish",
  },
  SOCIAL_NETWORKS: {
    CONTENT_MANAGEMENT: "content_management",
    KNOW_YOUR_TARGET_AUDIENCE: "know_your_target_audience",
  },
  PRE_FETE: {
    KNOW_YOUR_TARGET_AUDIENCE: "know_your_target_audience",
    KEEP_THE_ATTENDEES_ENGAGED: "keep_the_attendees_engaged",
  },
  POST_FETE: {
    IDEATION: "ideation",
    DRAFT_POLLS_AND_SURVEY: "draft_polls_&_survey",
  },

  // New Tasks Indents
  EVENT_THEMES: "event_themes",
  DECOR_THEMES: "décor_themes",
  VENUE_STYLES: "venue_styles",
  GIFTS_SWAGS: "gifts_swags",
  ACTIVITIES: "activities",
  EVENT_PROGRAM: "event_program",
  EVENT_AGENDA: "event_agenda",
  BRAND_VOICE: "brand_voice",
  BRAND_KIT: "brand_kit",
  EMAIL_SIGNARURES_AND_TEMPLATES: "email_signatures_and_templates",
  PRESENTATION_DECK_TEMPLATES: "presentation_deck_templates",
  BUDGET_ALLOCATION: "budget_allocation",
  BRAND_LOGO: "brand_logo",
  BRAND_DOCUMENT: "brand_document",
  INSPIRATION_IMAGES: "inspiration_images",
  ATTENDEE_LIST: "attendee_list",
  SPONSOR_LOGOS: "sponsor_logos",
  SPECIAL_GUESTS: "special_guests",
  INVITATION: "invitation",
  EVENT_REMINDER: "event_reminder",
  OTHER_COMMINCATION: "other_communication",
  AGGREMENTS: "agreements",
  VENUE: "venue",
  FB: "f_&_b",
  DECOR: "décor",
  ENTERTAINMENT: "entertainment",
  TECHNOLOGY: "technology",
  MEETING_SPACE: "meeting_space",
  ACCOMODATION: "accomodation",
  TRANSPORTATION: "transportation",
  DIETARY_REQUIREMENTS: "dietary_requirements",
  ACCESSIBILITY: "accessibility",
  EMERGENCY_HEALTH_SERVICES: "emergency_&_health_services",
  SECURITY: "security",
  LEGAL_COMPLIANCE_SUSTAINABILITY: "legal_compliance_and_sustainability",
  INVOICES: "invoices",
  EVENT_PROMOTION: "event_promotion",
  DRAFT: "draft",
  EMAIL: "email",
  PRESS_RELEASE: "press_release",
  ADS: "ads",
  DRAFT_SOCIAL_MEDIA_ADS: "draft_social_media_ads",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  LINKEDIN: "linkedIn",
  X: "x",
  PINTEREST: "pinterest",
  EVENT_APP: "event_app",
  PRE_EVENT_SURVEY: "pre_event_survey",
  POST_EVENT_SURVEY: "post_event_survey",
  PRE_EVENT_QUESTIONAIRE: "pre_event_questionnaire",
  POST_EVENT_QUESTIONAIRE: "post_event_questionnaire",
  PRE_EVENT_MAIL: "pre_event_mail",
  POST_EVENT_MAIL: "post_event_mail",
  GIFTS: "gifts",
  EVENT_PHOTOGRAPHY_VIDEOGRAPHY_GUIDELINES:
    "event_photography_videography_guidelines",
  SPONSORSHIP_PACKAGES: "sponsorship_packages",
  SPEAKER_PRESENTATIONS: "speaker_presentations",
  SPONSOR_INFORMATION: "sponsor_information",
  PANEL_QUESTIONS_MATERIALS: "panel_questions_materials",
  SPONSOR_CONTRACTS: "sponsor_contracts",
  VENUE_SELECTION: "venue_selection",
  FB_SELECTION: "f_&_b_selection",
  TECH_AV_VENDORS: "tech_av_vendors",
  TRANSPORTATION_VENDOR_SELECTION: "transportation_vendor_selection",
  EVENT_WEBSITE: "event_website",
  PLUS_ONE_TRACKING: "plus_one_tracking",
  TICKETING_FOR_LARGE_SOCIAL_EVENTS: "ticketing_for_large_social_events",
};

export const EVENT_DEFAULTS = {
  title: "Your Awesome Fete Title Here",
};

export const REVIEW_SPACE_ACTIONS = [
  {
    card: ACTUAL_CARD_NAMES.LOGISTICS_AND_OTHERS,
    tasks: [
      {
        name: ACTUAL_TASK_NAMES.LOGISTICS_AND_OTHERS.FINALIZE_LOGISTICS,
        indent: ACTUAL_TASK_INDENTATION.LOGISTICS_AND_OTHERS.FINALIZE_LOGISTICS,
        showDelete: true,
        showView: true,
        showEdit: true,
        showDownload: false,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: false,
      },
    ],
  },
  {
    card: ACTUAL_CARD_NAMES.THEMES,
    tasks: [
      {
        name: ACTUAL_TASK_NAMES.THEMES.FETE,
        indent: ACTUAL_TASK_INDENTATION.THEMES.FETE,
        showDelete: true,
        showView: false,
        showEdit: false,
        showDownload: false,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: false,
      },
      {
        name: ACTUAL_TASK_NAMES.THEMES.DECOR,
        indent: ACTUAL_TASK_INDENTATION.THEMES.DECOR,
        showDelete: true,
        showView: false,
        showEdit: true,
        showDownload: false,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: false,
      },
      {
        name: ACTUAL_TASK_NAMES.THEMES.VENUE,
        indent: ACTUAL_TASK_INDENTATION.THEMES.VENUE,
        showDelete: true,
        showView: false,
        showEdit: true,
        showDownload: false,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: false,
      },
    ],
  },
  {
    card: ACTUAL_CARD_NAMES.FETE_PROGRAM,
    tasks: [
      {
        name: ACTUAL_TASK_NAMES.FETE_PROGRAM.DRAFT,
        indent: ACTUAL_TASK_INDENTATION.FETE_PROGRAM.DRAFT,
        showDelete: true,
        showView: true,
        showEdit: true,
        showDownload: true,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: true,
      },
    ],
  },
  {
    card: ACTUAL_CARD_NAMES.BUDGET,
    tasks: [
      {
        name: ACTUAL_TASK_NAMES.BUDGET.ALLOCATION,
        indent: ACTUAL_TASK_INDENTATION.BUDGET.ALLOCATION,
        showDelete: false,
        showView: true,
        showEdit: true,
        showDownload: true,
        showFavorite: false,
        showCheckbox: false,
        showFinalDecision: false,
      },
    ],
  },
  {
    card: ACTUAL_CARD_NAMES.ATTENDEES,
    tasks: [
      {
        name: ACTUAL_TASK_NAMES.ATTENDEES.UPLOAD_ATTENDEE_LIST,
        indent: ACTUAL_TASK_INDENTATION.ATTENDEES.UPLOAD_ATTENDEE_LIST,
        showDelete: true,
        showView: true,
        showEdit: true,
        showDownload: true,
        showFavorite: false,
        showCheckbox: false,
        showFinalDecision: false,
      },
      {
        name: ACTUAL_TASK_NAMES.ATTENDEES.DRAFT_INVITATIONS,
        indent: ACTUAL_TASK_INDENTATION.ATTENDEES.DRAFT_INVITATIONS,
        showDelete: true,
        showView: true,
        showEdit: true,
        showDownload: true,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: true,
      },
      {
        name: ACTUAL_TASK_NAMES.ATTENDEES.CREATE_FETE_REGISTRATION,
        indent: ACTUAL_TASK_INDENTATION.ATTENDEES.CREATE_FETE_REGISTRATION,
        showDelete: false,
        showView: false,
        showEdit: false,
        showDownload: false,
        showFavorite: false,
        showCheckbox: false,
        showFinalDecision: false,
      },
    ],
  },
  {
    card: ACTUAL_CARD_NAMES.SPECIAL_GUESTS_AND_SPEAKERS,
    tasks: [
      {
        name: ACTUAL_TASK_NAMES.SPECIAL_GUESTS_AND_SPEAKERS.ADD_SPECIAL_GUESTS,
        indent:
          ACTUAL_TASK_INDENTATION.SPECIAL_GUESTS_AND_SPEAKERS
            .ADD_SPECIAL_GUESTS,
        showDelete: true,
        showView: false,
        showEdit: false,
        showDownload: false,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: false,
      },
      {
        name: ACTUAL_TASK_NAMES.SPECIAL_GUESTS_AND_SPEAKERS.DRAFT_INVITATIONS,
        indent:
          ACTUAL_TASK_INDENTATION.SPECIAL_GUESTS_AND_SPEAKERS.DRAFT_INVITATIONS,
        showDelete: true,
        showView: true,
        showEdit: true,
        showDownload: true,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: false,
      },
      {
        name: ACTUAL_TASK_NAMES.SPECIAL_GUESTS_AND_SPEAKERS
          .DRAFT_GUEST_AGREEMENTS,
        indent:
          ACTUAL_TASK_INDENTATION.SPECIAL_GUESTS_AND_SPEAKERS
            .DRAFT_GUEST_AGREEMENTS,
        showDelete: true,
        showView: true,
        showEdit: true,
        showDownload: true,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: true,
      },
    ],
  },
  {
    card: ACTUAL_CARD_NAMES.VENDOR,
    tasks: [
      {
        name: ACTUAL_TASK_NAMES.VENDOR.VENUE,
        indent: ACTUAL_TASK_INDENTATION.VENDOR.VENUE,
        showDelete: true,
        showView: true,
        showEdit: false,
        showDownload: false,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: true,
      },
      {
        name: "RFP",
        indent: "rfp",
        showDelete: true,
        showView: true,
        showEdit: true,
        showDownload: true,
        showFavorite: false,
        showCheckbox: false,
        showFinalDecision: true,
      },
      {
        name: ACTUAL_TASK_NAMES.VENDOR.DECOR,
        indent: ACTUAL_TASK_INDENTATION.VENDOR.DECOR,
        showDelete: true,
        showView: true,
        showEdit: true,
        showDownload: true,
        showFavorite: false,
        showCheckbox: false,
        showFinalDecision: true,
      },
      {
        name: ACTUAL_TASK_NAMES.VENDOR.CATERING,
        indent: ACTUAL_TASK_INDENTATION.VENDOR.CATERING,
        showDelete: true,
        showView: true,
        showEdit: false,
        showDownload: false,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: true,
      },
      {
        name: ACTUAL_TASK_NAMES.VENDOR.ENTERTAINMENT,
        indent: ACTUAL_TASK_INDENTATION.VENDOR.ENTERTAINMENT,
        showDelete: true,
        showView: true,
        showEdit: false,
        showDownload: false,
        showFavorite: true,
        showCheckbox: false,
        showFinalDecision: true,
      },
    ],
  },
  {
    card: ACTUAL_CARD_NAMES.CONTRACTS,
    tasks: [
      {
        name: ACTUAL_TASK_NAMES.CONTRACTS.FINAL_CONTRACTS,
        name: ACTUAL_TASK_INDENTATION.CONTRACTS.FINAL_CONTRACTS,
        showDelete: false,
        showView: false,
        showEdit: false,
        showDownload: false,
        showFavorite: false,
        showCheckbox: false,
        showFinalDecision: false,
      },
    ],
  },
];

export const PROMPT_DEFAULTS = {
  NOTES:
    '- For update action reply like "Sure, i have updated event budget to 50k".\n- Brainstorming\n    --  Do not include saved/selected items in brainstorming.\n    --  Must not use or include any data from the prompt.\n    --  Must only return four number of items in result.\n    --  Must use Available Venues for brainstorming venues for event.\n    --  For brainstorming event venues, provide options only from the Available Venues with matching city locations under the Event Details given above and if you do not find any matching venues with the selected locations then you must respond with "Sorry we do not have any venues available at the moment with the selected locations.".\n- Current/Saved/Selected Items\n    --  Do not make up things on your own.\n    --  Always return selected : true for current/saved/selected items.',
  JSON_FORMATS:
    'Purpose: "Use this for event themes"\nFormat: {{"taskId":"task id according to task title from mentioned tasks","heading":"Task title or heading here","items":[{{"title":"Item 1 title","description":"Description for item 1.", "selected": false}}]}}\n\nPurpose: "Use this for event program, invitations for guests, invitations for attendees, agreements for guests, and invoices"\nFormat: {{"isRichTextContent": true, "heading":"Task title or heading here", "taskId":"task id according to task title from mentioned tasks", "content":"html content here"}}\n\nPurpose: "Use this for venue styles, and decor themes"\nFormat: {{"renderImages":true, "heading":"Task title or heading here","taskId":"task id according to task title from mentioned tasks","items":[{{"title":"Item 1 title","tags":["Tag1","Tag2","Tag3","Tag4"],"imageDescription":"Description of the image to create for item 1.", selected: false}}]}}\n\nPurpose: "Use this for event decor, venue, catering, and entertainment"\nFormat: {{"renderImages":true, "heading":"Task title or heading here","taskId":"task id according to task title from mentioned tasks","items":[{{"title":"Item 1","description":"Item 1 description","imageDescription":"Description of the image to create for item 1", selected: false}}]}}',
};

export const DEFAULT_LLM_MODEL_CONFIG = {
  CHAT_PROVIDER: "openai",
  CHAT_MODEL: process.env.REACT_APP_ENV !== "uat" ? "gpt-4o" : "gpt-4o",
  INSTRUCT_MODEL: "gpt-4o",
  IMAGE_MODEL: process.env.REACT_APP_ENV !== "uat" ? "dall-e-2" : "dall-e-3",
};

// export const DEFAULT_LLM_MODEL_CONFIG = {
//   CHAT_PROVIDER: 'openai',
//   CHAT_MODEL: process.env.REACT_APP_ENV !== 'uat' ? 'gpt-4-turbo-preview' : 'gpt-4-turbo-preview',
//   INSTRUCT_MODEL: 'gpt-4-turbo-preview',
//   IMAGE_MODEL: process.env.REACT_APP_ENV !== 'uat' ? 'dall-e-2' : 'dall-e-3',
// }

export const ACTION_TYPES = {
  CONTENT_DRAFTING: "Content Drafting",
  SELECTION: "Selection",
  CARDS_AND_BLOCKS: "Cards & Blocks",
  TASK_MANAGEMENT: "Task Management",
  FILE_MANAGEMENT: "File Management",
  FETE_MANAGEMENT: "Fete Management",
  USER_MANAGEMENT: "User Management",
  COMMENT: "Comment",
  NOTE: "Note",
};

export const TAG_LINES = [
  {
    card: "Themes",
    content: "Fete theme unclear?  Your FeteBuddy is here. ",
    chatText: "Pick a command, or let's chat !!",
  },
  {
    card: "Event Program",
    content: "Event Program a mess, feeling stressed? ",
    chatText: "Pick a command or chat, put FeteBuddy to the test!",
  },
  {
    card: "Budget",
    content: "Budget tight, vision bright? FeteBuddy's your light, ",
    chatText: "command or chat, make it right!",
  },
  {
    card: "Attendees",
    content: "RSVPs unclear, attendance a fear? ",
    chatText: "Command or chat, FeteBuddy makes guest lists crystal clear!",
  },
  {
    card: "Special Guests & Speakers",
    content: "Guest & speaker plight? FeteBuddy sets things right! ",
    chatText: "Chat or command, connections ignite!",
  },
  {
    card: "Vendor",
    content: "Vendor hunt got you in a stew? ",
    chatText: "Pick a command or chat, FeteBuddy connects you!",
  },
  {
    card: "Logistics",
    content: "Don't let logistics make you frown, ",
    chatText: "FeteBuddy's chat or command puts the crown",
  },
];

export const EMAIL_TEMPLATE_CATEGORIES = {
  EMAIL_TEMPLATE: "email_template",
  EVENT_PROGRAM: "event_program",
  RFP: "rfp",
};

export const EMAIL_CATEGORIES = {
  ATTENDEE_INVITATION: "attendee_invitation",
  VENDOR_QUOTE: "vendor_quote",
};

export const COMPARE_KEYS = {
  services: "Services",
  rating: "Rating",
  venue_availability: "Venue Availability",
  capacity_layout: "Capacity Layout",
  costs_fees: "Costs Fees",
  facilities_amenities: "Facilities Amenities",
  catering_food_services: "Catering Food Services",
  technical_requirements: "Technical Requirements",
  decor_setup: "Decor Setup",
  parking_transportation: "Parking Transportation",
  security_safety: "Security Safety",
  accommodations: "Accomodations",
  sustainability_green_practices: "Sustainability Green Practices",
  contract_terms: "Contract Terms",
  cancellation_policy: "Cancellation Policy",
  force_majeure_clause: "Force Majeure Clause",
  insurance_liability: "Insurance Liability",
  compliance_laws: "Compliance Laws",
  promotional_considerations: "Promotional Considerations",
};

export const LOGISTICS_AND_OTHERS_ACTIONS_TABS = {
  meeting_spaces: "Meeting Spaces",
  accomodation: "Accommodation",
  transportation: "Transportation",
  dietary_requirements: "Dietary Requirements",
  accessibility: "Accessibility",
  emergency_health_services: "Emergency & Health Services",
  security: "Security",
  legal_compliance_sustainability: "Legal, Compliance and Sustainability",
};

export const LOGISTICS_AND_OTHERS_ACTIONS = [
  {
    name: LOGISTICS_AND_OTHERS_ACTIONS_TABS.meeting_spaces,
  },
  {
    name: LOGISTICS_AND_OTHERS_ACTIONS_TABS.accomodations,
  },
  {
    name: LOGISTICS_AND_OTHERS_ACTIONS_TABS.transportation,
  },
  {
    name: LOGISTICS_AND_OTHERS_ACTIONS_TABS.dietary_requirements,
  },
  {
    name: LOGISTICS_AND_OTHERS_ACTIONS_TABS.accessibility,
  },
  {
    name: LOGISTICS_AND_OTHERS_ACTIONS_TABS.emergency_health_services,
  },
  {
    name: LOGISTICS_AND_OTHERS_ACTIONS_TABS.security,
  },
  {
    name: LOGISTICS_AND_OTHERS_ACTIONS_TABS.legal_compliance_sustainability,
  },
];

export const RFP_VENDOR_TYPES = {
  ACCOMODATION: "Accomodation",
  DECOR_AND_RENTAL: "Decor & Rental",
  EVENT_VENUE: "Event Venue",
  ENTERTAINMENT: "Entertainment",
  FOOD_AND_BEVERAGE: "Food & Beverage",
  GIFTING: "Gifting",
  MARKETING_AND_RENTAL: "Marketing & Promotion",
  SECURITY_AND_STAFFING: "Security & Staffing",
  TECHNOLOGY_PARTNER: "Technology Partner",
  TRANSPORTATION: "Transportation",
};

export const VENDOR_OPTIONS = [
  {
    label: RFP_VENDOR_TYPES.ACCOMODATION,
    value: RFP_VENDOR_TYPES.ACCOMODATION,
  },
  {
    label: RFP_VENDOR_TYPES.DECOR_AND_RENTAL,
    value: RFP_VENDOR_TYPES.DECOR_AND_RENTAL,
  },
  {
    label: RFP_VENDOR_TYPES.ENTERTAINMENT,
    value: RFP_VENDOR_TYPES.ENTERTAINMENT,
  },
  { label: RFP_VENDOR_TYPES.EVENT_VENUE, value: RFP_VENDOR_TYPES.EVENT_VENUE },
  {
    label: RFP_VENDOR_TYPES.FOOD_AND_BEVERAGE,
    value: RFP_VENDOR_TYPES.FOOD_AND_BEVERAGE,
  },
  { label: RFP_VENDOR_TYPES.GIFTING, value: RFP_VENDOR_TYPES.GIFTING },
  {
    label: RFP_VENDOR_TYPES.MARKETING_AND_RENTAL,
    value: RFP_VENDOR_TYPES.MARKETING_AND_RENTAL,
  },
  {
    label: RFP_VENDOR_TYPES.SECURITY_AND_STAFFING,
    value: RFP_VENDOR_TYPES.SECURITY_AND_STAFFING,
  },
  {
    label: RFP_VENDOR_TYPES.TECHNOLOGY_PARTNER,
    value: RFP_VENDOR_TYPES.TECHNOLOGY_PARTNER,
  },
  {
    label: RFP_VENDOR_TYPES.TRANSPORTATION,
    value: RFP_VENDOR_TYPES.TRANSPORTATION,
  },
];

export const BOARD_API_ENDPOINTS = {
  draft_event_program: "draft_event_program",
  draft_invitations: null,
  draft_email: "draft_email",
  draft_save_the_date_email: "draft_save_the_date_email",
  draft_pre_event_reminder_email: "draft_pre_event_reminder_email",
  draft_thank_you_email: "draft_thank_you_email",
  draft_social_media_engagement_email: "draft_social_media_engagement_email",
  draft_onsite_welcome_email: "draft_onsite_welcome_email",
  draft_event_agenda_update_email: "draft_event_agenda_update_email",
  draft_speaker_invitation_email: "draft_speaker_invitation_email",
  draft_speaker_confirmation_email: "draft_speaker_confirmation_email",
  draft_speaker_pre_event_briefing_email:
    "draft_speaker_pre_event_briefing_email",
  draft_speaker_thankyou_email: "draft_speaker_thankyou_email",
  search_venue: "search_venue",
};

export const NEXT_BEST_ACTIONS_VALUES = {
  draft_event_program: "Draft event program",
  draft_invitations: "Draft attendee invitation",
  draft_save_the_date_email: "Draft save the date email",
  draft_pre_event_reminder_email: "Draft pre-event reminder email",
  draft_thank_you_email: "Draft thank you email",
  draft_social_media_engagement_email: "Draft social media engagement email",
  draft_onsite_welcome_email: "Draft on-site welcome email",
  draft_event_agenda_update_email: "Draft agenda update email",
  draft_speaker_invitation_email: "Draft speaker invitation email",
  draft_speaker_confirmation_email: "Draft speaker confirmation email",
  draft_speaker_pre_event_briefing_email:
    "Draft speaker pre event briefing email",
  draft_speaker_thankyou_email: "Draft speaker thankyou email",
  recommend_venues: "Recommend venues",
  recommend_fb_venues: "Recommend F&B vendor",
  recommend_decor_vendor: "Recommend decor vendor",
  recommend_gift_vendor: "Recommend gift vendor",
  recommend_entertainment_vendor: "Recommend entertainment vendor",
};

export const NEXT_BEST_ACTIONS_TYPES = {
  upload: {
    attendee_list: "upload-attendee-list",
    attendee_sample_invitation: "upload-attendee-sample-invitation",
    special_guest_sample_invitation: "upload-special-guest-sample-invitation",
    special_guest_sample_agreement: "upload-special-guest-sample-agreement",
  },
  modal: {
    add_speaker: "modal-add-speaker",
    add_special_guest: "modal-add-special-guest",
    logistics: "modal-logistics",
  },
};

export const EMAIL_TYPES = {
  save_the_date: "Save The Date",
  onsite_welcome: "Onsite Welcome",
  thank_you: "Thankyou",
  pre_event_reminder: "Pre-Event Reminder",
  pre_event_briefing: "Pre-Event Briefing",
  event_agenda_update: "Event Agenda Update",
  social_media_engagement: "Social Media Engagement",
  invitation: "Invitation",
  confirmation: "Confirmation",
  proposal: "Proposal",
  onsite_support: "Onsite Support",
  dynamic_email: "Dynamic Email",
};

export const TARGET_AUDIENCE = {
  attendee: "Attendee",
  speaker: "Speaker",
  sponsor: "Sponsor",
  special_guest: "Special Guest",
};

export const emptyReviewSpaceCardTexts = [
  {
    block: "Theme & Design",
    card: ACTUAL_CARD_NAMES.THEMES,
    fontMessage: "No themes found",
    textMessage: "Let's brainstorm together!!",
    buttons: [
      {
        text: "Event Themes",
        actionText: "Brainstorm event themes",
        promptKey: "FB_THEMES_BRAINSTORM",
      },
      {
        text: "Decor Themes",
        actionText: "Brainstorm decor themes",
        promptKey: "FB_THEMES_BRAINSTORM",
      },
      {
        text: "Venue Styles",
        actionText: "Brainstorm venue styles",
        promptKey: "FB_THEMES_BRAINSTORM",
      },
    ],
  },
  {
    block: "Theme & Design",
    card: ACTUAL_CARD_NAMES.FETE_PROGRAM,
    fontMessage: "No event program found",
    textMessage: "Let's draft event program!!",
    buttons: [
      {
        text: "Draft event program",
        actionText: "Draft event program",
        promptKey: "FB_EVENT_PROGRAM_DRAFT",
        endpoint: "draft_event_program",
      },
    ],
  },
  {
    block: "Theme & Design",
    card: ACTUAL_CARD_NAMES.BUDGET,
    fontMessage: "Budget Allocation pending",
    textMessage:
      "Let's discuss and strategize budget allocation for event categories",
    buttons: [
      {
        text: "Allocate budget",
        actionText: "Brainstorm budget allocation",
        promptKey: "FB_BUDGET_BUDGET_ALLOCATION",
      },
    ],
  },
  {
    block: "Guest Management",
    card: ACTUAL_CARD_NAMES.ATTENDEES,
    fontMessage: "Attendee management tasks haven't been initiated ",
    textMessage: "Let's start planning for attendees!!",
    buttons: [
      {
        text: ACTUAL_TASK_NAMES.ATTENDEES.UPLOAD_ATTENDEE_LIST,
        actionText: ACTUAL_TASK_NAMES.ATTENDEES.UPLOAD_ATTENDEE_LIST,
        promptKey: "",
      },
      {
        text: "Draft invitations",
        actionText: "Draft invitations",
        promptKey: "FB_ATTTENDEES_DRAFT",
        endpoint: "draft_email",
        target_audience: TARGET_AUDIENCE.attendee,
        email_type: EMAIL_TYPES.invitation,
      },
      {
        text: "Create event registration",
        actionText: "Create event registration",
        promptKey: "",
      },
    ],
  },
  {
    block: "Guest Management",
    card: ACTUAL_CARD_NAMES.SPECIAL_GUESTS_AND_SPEAKERS,
    fontMessage: "Guest management tasks haven't been initiated",
    textMessage: "Let's start planning for guests and speakers!!",
    buttons: [
      {
        text: ACTUAL_TASK_NAMES.SPECIAL_GUESTS_AND_SPEAKERS.ADD_SPECIAL_GUESTS,
        actionText:
          ACTUAL_TASK_NAMES.SPECIAL_GUESTS_AND_SPEAKERS.ADD_SPECIAL_GUESTS,
      },
      {
        text: "Draft invitations",
        actionText: "Draft invitations",
        promptKey: "FB_SPECIAL_GUESTS_SPEAKERS_DRAFT",
        endpoint: "draft_email",
        target_audience: TARGET_AUDIENCE.speaker,
        email_type: EMAIL_TYPES.invitation,
      },
      { text: "Draft guest agreements", actionText: "Draft guest agreements" },
    ],
  },
  {
    block: "Vendor Management",
    card: ACTUAL_CARD_NAMES.LOGISTICS_AND_OTHERS,
    fontMessage: "No logistics found",
    textMessage: "Let's finalize together!!",
    buttons: [
      {
        text: "Finalize Logistics",
        actionText: "Finalize Logistics",
        promptKey: "FB_LOGISTICS_FINALIZE",
      },
    ],
  },
  {
    block: "Vendor Management",
    card: ACTUAL_CARD_NAMES.VENDOR,
    fontMessage: "No vendors selected yet",
    textMessage: "Let's select vendors!!",
    buttons: [
      {
        text: "Venue",
        actionText: "Recommend venues",
        promptKey: "FB_VENDOR_BRAINSTORM_VENUES",
        endpoint: BOARD_API_ENDPOINTS.search_venue,
      },
      {
        text: "Decor",
        actionText: "Recommend decor vendor",
        promptKey: "FB_VENDOR_BRAINSTORM_VENUES",
        endpoint: BOARD_API_ENDPOINTS.search_venue,
      },
      {
        text: "Catering",
        actionText: "Recommend catering vendor",
        promptKey: "FB_VENDOR_BRAINSTORM_VENUES",
        endpoint: BOARD_API_ENDPOINTS.search_venue,
      },
      {
        text: "Entertainment",
        actionText: "Recommend entertainment vendor",
        promptKey: "FB_VENDOR_BRAINSTORM_VENUES",
        endpoint: BOARD_API_ENDPOINTS.search_venue,
      },
    ],
  },
  {
    block: "Vendor Management",
    card: ACTUAL_CARD_NAMES.CONTRACTS,
    fontMessage: "No contracts found",
    textMessage: "Signed contracts will show here!!",
    buttons: [
      { text: "Final contracts", actionText: "", promptKey: "" },
      { text: "Docusign integration", actionText: "", promptKey: "" },
    ],
  },
  {
    block: "Vendor Management",
    card: ACTUAL_CARD_NAMES.LOGISTICS,
    fontMessage: "No logistics available",
    textMessage: "",
    buttons: [
      { text: "Button 1", actionText: "", promptKey: "" },
      { text: "Button 2", actionText: "", promptKey: "" },
      { text: "Button 3", actionText: "", promptKey: "" },
      { text: "Button 4", actionText: "", promptKey: "" },
      { text: "Button 5", actionText: "", promptKey: "" },
      { text: "Button 6", actionText: "", promptKey: "" },
    ],
  },
  {
    block: "Vendor Management",
    card: ACTUAL_CARD_NAMES.INVOICES,
    fontMessage: "No invoices available",
    textMessage: "",
    buttons: [{ text: "Button 1", actionText: "", promptKey: "" }],
  },
  {
    block: "Marketing",
    card: ACTUAL_CARD_NAMES.PRINT_AND_MEDIA,
    fontMessage: "Not available",
    textMessage: "",
    buttons: [
      { text: "Button 1", actionText: "", promptKey: "" },
      { text: "Button 2", actionText: "", promptKey: "" },
      { text: "Button 3", actionText: "", promptKey: "" },
      { text: "Button 4", actionText: "", promptKey: "" },
    ],
  },
  {
    block: "Marketing",
    card: ACTUAL_CARD_NAMES.SOCIAL_NETWORKS,
    fontMessage: "Not available",
    textMessage: "",
    buttons: [
      { text: "Button 1", actionText: "", promptKey: "" },
      { text: "Button 2", actionText: "", promptKey: "" },
    ],
  },
  {
    block: "Polls & Survey",
    card: ACTUAL_CARD_NAMES.PRE_FETE,
    fontMessage: "No pre-fete available",
    textMessage: "",
    buttons: [
      { text: "Button 1", actionText: "", promptKey: "" },
      { text: "Button 2", actionText: "", promptKey: "" },
    ],
  },
  {
    block: "Polls & Survey",
    card: ACTUAL_CARD_NAMES.POST_FETE,
    fontMessage: "No post-fete available",
    textMessage: "",
    buttons: [
      { text: "Button 1", actionText: "", promptKey: "" },
      { text: "Button 2", actionText: "", promptKey: "" },
    ],
  },
];

export const emptyBoardCardTexts = [
  {
    block: "Theme & Design",
    fontMessage: "Your ipvijil is lonely!",
    textMessage: "Let's pin some themes and vision for this fete.",
    buttons: [
      {
        text: "Brainstorm",
        promptKey: "FB_THEMES_BRAINSTORM",
        actionText: "Brainstorm event themes",
        card: ACTUAL_CARD_NAMES.THEMES,
        isBrainstorm: true,
      },
      {
        text: "Review Selected",
        promptKey: "",
        actionText: "",
        card: ACTUAL_CARD_NAMES.THEMES,
        isBrainstorm: false,
      },
    ],
  },
  {
    block: "Guest Management",
    fontMessage: "Guest management tasks haven't been initiated ",
    textMessage: "Let's start planning for guests!!",
    buttons: [
      {
        text: "Brainstorm",
        promptKey: "FB_ATTTENDEES_DRAFT",
        actionText: "Draft invitations",
        card: ACTUAL_CARD_NAMES.ATTENDEES,
        isBrainstorm: true,
      },
      {
        text: "Review Selected",
        promptKey: "",
        actionText: "",
        card: ACTUAL_CARD_NAMES.ATTENDEES,
        isBrainstorm: false,
      },
    ],
  },
  {
    block: "Vendor Management",
    fontMessage: "No vendors selected yet",
    textMessage: "Let's pin some vendors!!",
    buttons: [
      {
        text: "Brainstorm",
        promptKey: "FB_VENDOR_BRAINSTORM_VENUES",
        actionText: "Show venues",
        card: ACTUAL_CARD_NAMES.VENDOR,
        isBrainstorm: true,
      },
      {
        text: "Review Selected",
        promptKey: "",
        actionText: "",
        card: ACTUAL_CARD_NAMES.VENDOR,
        isBrainstorm: false,
      },
    ],
  },
  {
    block: "Marketing",
    fontMessage: "Not available",
    textMessage: "Nothing pinned yet",
    buttons: [
      {
        text: "Brainstorm",
        promptKey: "",
        actionText: "",
        card: ACTUAL_CARD_NAMES.PRINT_AND_MEDIA,
        isBrainstorm: true,
      },
      {
        text: "Review Selected",
        promptKey: "",
        actionText: "",
        card: ACTUAL_CARD_NAMES.PRINT_AND_MEDIA,
        isBrainstorm: false,
      },
    ],
  },
  {
    block: "Polls & Survey",
    fontMessage: "No pre or post event available",
    textMessage: "Nothing pinned yet",
    buttons: [
      {
        text: "Brainstorm",
        promptKey: "",
        actionText: "",
        card: ACTUAL_CARD_NAMES.PRE_FETE,
        isBrainstorm: true,
      },
      {
        text: "Review Selected",
        promptKey: "",
        actionText: "",
        card: ACTUAL_CARD_NAMES.PRE_FETE,
        isBrainstorm: false,
      },
    ],
  },
];

export const ALL_STATUS = ["Complete", "In-Progress", "On-Hold", "To-Do"];

export const ALL_TASK_STATUS = {
  TODO: "To-Do",
  INPROGRESS: "In-Progress",
  COMPLETE: "Complete",
  ONHOLD: "On-Hold",
  INVALID: "Invalid",
};

export const DEFAULT_CARD_TYPES = {
  BRAINSTORM: ACTUAL_CARD_NAMES.BRAINSTORM,
  EXPLORE: ACTUAL_CARD_NAMES.EXPLORE,
  ADD: ACTUAL_CARD_NAMES.ADD,
  UPLOAD: ACTUAL_CARD_NAMES.UPLOAD,
  DRAFT: ACTUAL_CARD_NAMES.DRAFT,
  ANALYZE: ACTUAL_CARD_NAMES.ANALYZE,
};

export const EVENT_TYPES = {
  conferencesseminars: "Conferences & Seminars",
  seminars: "Seminars",
  workshopstraining: "Workshops & Training",
  productfocusedevents: "Product Focused Events",
  tradeshows: "Trade Shows",
  teambuilding: "Team Building",
  corporateretreats: "Corporate Retreats",
  executiveretreats: "Executive Retreats",
  BoardInternalMeetings: "Board & Internal Meetings",
  incentiveprograms: "Incentive Programs",
  AwardsRecognition: "Awards & Recognition",
  annualgeneralmeetings: "Annual General Meetings (AGMs)",
  NetworkingEvents: "Networking Events",
  PressMediaEvents: "Press & Media Events",
  FundraisingGala: "Fundraising & Gala",
  HolidaySocialEvents: "Holiday & Social Events",
  ClientCustomerEvents: "Client & Customer Events",
  townhallmeetings: "Town Hall Meetings",
  traininganddevelopmentsessions: "Training and Development Sessions",
  HackathonsInnovation: "Hackathons & Innovation",
  CulturalCSREvents: "Cultural & CSR Events",
  SalesMarketingEvents: "Sales & Marketing Events",
  TeamCompanyRetreats: "Team & Company Retreats",
  default: "Default",
};

export const CARD_USE_TYPE = {
  BRAINSTORM: "Brainstorm",
  EXPLORE: "Explore",
  ADD: "Add",
  UPLOAD: "Upload",
  DRAFT: "Draft",
  ANALYZE: "Analyze",
  CHAT: "Chat",
};

export const DELETE_MODAL_DATA = {
  project: {
    title: "Delete Project",
    description:
      "Are you sure you want to delete this project? Once deleted, it will be permanently removed from the system, and all associated data, including claim charts, will be lost forever.",
  },
  portfolio: {
    title: "Delete Portfolio",
    description:
      "Are you sure you want to delete this portfolio? Once deleted, it will be permanently removed from the system, and all associated data, including files and folders, will be lost forever.",
  },
  portfolio_folder: {
    title: "Delete Folder",
    description:
      "Are you sure you want to delete this folder? Once deleted, it will be permanently removed from the system, and all data uploaded to this folder will be lost forever.",
  },
  portfolio_file: {
    title: "Delete File",
    description:
      "Are you sure you want to delete this file? Once deleted, it will be permanently removed from the system and cannot be recovered.",
  },
  portfolio_multiple_file: {
    title: "Delete Files",
    description:
      "Are you sure you want to delete these files? Once deleted, they will be permanently removed from the system and cannot be recovered.",
  },
};

export const PATENT_FILE_CONSTANTS = {
  NOT_STARTED: "not_started",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  FAILED: "failed",
};

export const PROJECT_CONSTANTS = {
  SOURCE: "source",
  COMPARE: "compare",
  PATENT: "patent",
  EXTERNAL: "external",
};
