import React from 'react';

const VijilizeShimmerLoader = () => {
    return (
        <div className="w-full h-[calc(100vh_-_165px)] bg-white">
            {/* Header */}
            <div className="flex bg-white sticky top-0 z-10 border-t border-gray-100">
                <div className="w-1/4 px-6 py-4 border-b">
                    <div className="h-5 bg-gray-200 rounded animate-pulse w-20"></div>
                </div>
                <div className="w-1/4 px-6 py-4 border-b">
                    <div className="h-5 bg-gray-200 rounded animate-pulse w-24"></div>
                </div>
                <div className="w-[30%] px-6 py-4 border-b">
                    <div className="h-5 bg-gray-200 rounded animate-pulse w-28"></div>
                </div>
                <div className="w-[10%] px-6 py-4 border-b flex justify-center">
                    <div className="h-5 bg-gray-200 rounded animate-pulse w-16"></div>
                </div>
                <div className="w-[10%] px-6 py-4 border-b flex justify-center">
                    <div className="h-5 bg-gray-200 rounded animate-pulse w-16"></div>
                </div>
            </div>

            {/* Rows */}
            {[1,2,3,4,5].map((item) => (
                <div key={item} className="flex border-b hover:bg-gray-50">
                    {/* Name column */}
                    <div className="w-1/4 px-6 py-4">
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded animate-pulse w-32"></div>
                            <div className="h-4 bg-gray-100 rounded animate-pulse w-48"></div>
                        </div>
                    </div>

                    {/* Details column */}
                    <div className="w-1/4 px-6 py-4">
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded animate-pulse w-24"></div>
                            <div className="h-4 bg-gray-100 rounded animate-pulse w-32"></div>
                        </div>
                    </div>

                    {/* Data Used column */}
                    <div className="w-[30%] px-6 py-4 flex items-center">
                        <div className="flex items-center space-x-2">
                            <div className="h-4 bg-gray-200 rounded animate-pulse w-16"></div>
                            <div className="h-4 bg-gray-200 rounded animate-pulse w-2"></div>
                            <div className="h-4 bg-gray-200 rounded animate-pulse w-16"></div>
                        </div>
                    </div>

                    {/* Status column */}
                    <div className="w-[10%] px-6 py-4 flex justify-center items-center">
                        <div className="h-6 bg-gray-200 rounded-full animate-pulse w-20"></div>
                    </div>

                    {/* Action column */}
                    <div className="w-[10%] px-6 py-4 flex justify-center items-center">
                        <div className="h-7 w-7 bg-gray-200 rounded animate-pulse"></div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VijilizeShimmerLoader;