import React, { Dispatch, SetStateAction, useState } from "react";

import { Modal } from "./Modal";

import { HeaderModal } from "./Header";
import { FooterModal } from "./Footer";
import { Body } from "./Body";
import allImgPaths from "assets/images";
import Toast from "../Toast";
import FileUploadProgress from "../FileUploadProgress";
import {
  createFilePresignedUrl,
  fileUploadStatus,
  s3PutUrlWithProgressPortfolioFile,
} from "services/portfolio";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  id: string;
  setRefreshData: Dispatch<SetStateAction<boolean>>;
  currentFolderId?: string | null;
}

function UploadFileModal({
  isShow,
  setIsShow,
  id,
  setRefreshData,
  currentFolderId = null,
}: Iprops) {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [filePreviews, setFilePreviews] = useState<any[]>([]);
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>(
    {}
  );
  const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);

  const onCloseModal = () => {
    setIsShow(false);
    setSelectedFiles([]);
    setFilePreviews([]);
    setIsAPICalled(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let recievedFiles: any[] = (e.target.files || []) as any[];
    const files = Array.from(recievedFiles);
    handleFiles(files);
  };
  const handleFiles = async (files: any[]) => {
    // Filter files if needed (e.g., specific file types)
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"]; // Example file types
    let validFiles = files;

    // Update state with new files
    const previewPromises: Promise<string>[] = [];
    validFiles.forEach((file) => {
      const promise = new Promise<string>((resolve) => {
        displayPreview(file, (preview) => {
          resolve(preview);
        });
      });
      previewPromises.push(promise);
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    setBtnDisabled(false);
    Promise.all(previewPromises).then((previews) => {
      setFilePreviews((prevFiles) => [...prevFiles, ...previews]);
    });
  };
  const resetModal = () => {
    setIsShow(false);
    setSelectedFiles([]);
    setFilePreviews([]);
    setIsAPICalled(false);
    setFileProgress({});
    setUploadingFiles(false);
  };

  const displayPreview = (file: File, callback: (preview: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        callback(reader.result as string);
      } else {
        callback("");
      }
    };
  };

  const submit = async (e: any) => {
    const userData: any = JSON.parse(
      localStorage.getItem("userData") as string
    );
    let selectedTeam: any = JSON.parse(
      localStorage.getItem("selectedTeam") as string
    );

    e?.preventDefault();
    setIsAPICalled(true);
    setUploadingFiles(true);

    if (selectedFiles.length > 0) {
      try {
        const uploadPromises = selectedFiles.map(async (currentFile) => {
          // Step 1: Get presigned URL
          let payload: any = {
            team_id: selectedTeam.currentTeamId,
            portfolio_id: id,
            file_name: currentFile.name,
            content_type: currentFile.type,
          };

          if (currentFolderId) {
            payload.portfolio_folder_id = currentFolderId;
          }

          const step1Response: any = await createFilePresignedUrl(payload);
          if (!step1Response?.url) {
            throw new Error(
              `Failed to get presigned URL for file ${currentFile.name}`
            );
          }

          // Step 2: Upload File to S3
          const payload2: any = {
            url: step1Response.url,
            file: currentFile,
            type: currentFile.type,
          };

          const step2response: any = await s3PutUrlWithProgressPortfolioFile(
            payload2,
            (progress) => {
              setFileProgress((prev) => ({
                ...prev,
                [currentFile.name]: Math.round(progress),
              }));
            }
          );

          // Step 3: Update upload status
          const fileStatus =
            step2response.status === 200 ? "uploaded" : "pending";
          const step3response = await fileUploadStatus({
            file_id: step1Response.file_id,
            file_status: fileStatus,
          });

          return { success: true, fileName: currentFile.name };
        });

        const results = await Promise.all(uploadPromises);
        const allSuccessful = results.every((result) => result.success);

        if (allSuccessful) {
          Toast.success("Success", "All Files Uploaded successfully");
          setRefreshData(true);
          resetModal();
        } else {
          Toast.error("Error", "Some files failed to upload");
          setUploadingFiles(false);
        }
      } catch (error) {
        console.error("Upload error:", error);
        Toast.error("Error", "Failed to upload files");
        setUploadingFiles(false);
      }
    }
  };

  return (
    <>
      <Modal className={"modal-wrapper max-w-[700px]"} isShow={isShow}>
        <form onSubmit={submit}>
          {/* Modal bg Shade */}

          <HeaderModal
            title={"test"}
            isShowHeader={true}
            className="modal-header pt-6 px-6"
          >
            <div className="flex items-center justify-between">
              <img
                className="cursor-pointer"
                src={allImgPaths.addPatentLogo}
                alt="remove"
              />
              {/* Close Icon */}
              <img
                className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4"
                id="modal-toggle"
                src={allImgPaths.closeIcon}
                onClick={onCloseModal}
                alt="close icon"
              />
            </div>
          </HeaderModal>

          <Body title="" isShowBody={true} className="modal-body px-6 pt-3">
            <h2 className="text-base text-[#0A090B] font-interSemiBold pb-1">
              Add File
            </h2>
            <p className="text-sm text-[#4F4D55]">
              Upload files to the portfolio
            </p>

            <div className="modal-container">
              {
                <div className="bg-[#F9FAFB] px-3 pl-4 py-3 mt-6 rounded-md">
                  <div
                    className="relative border border-[#C9C9CC] hover:border-gray-400 border-dashed rounded-lg p-2 w-[99%] cursor-pointer"
                    id="dropzone"
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={(e) => e.preventDefault()}
                    onDragLeave={() => {}}
                    onDrop={handleDrop}
                  >
                    <input
                      id="photo-dropbox"
                      type="file"
                      multiple
                      accept=".pdf"
                      className="absolute inset-0 w-full h-full opacity-0 z-50 cursor-pointer"
                      onChange={handleFileChange}
                    />
                    <label
                      className="flex cursor-pointer gap-1 w-full px-3 py-2 bg-[#F9FAFB] rounded-lg"
                      htmlFor="photo-dropbox"
                    >
                      <input
                        id="photo-dropbox"
                        multiple
                        type="file"
                        accept=".pdf"
                        className="sr-only"
                        onChange={(e) => console.log(e.target.files)}
                      />
                      <img src={allImgPaths.uploadIcon} alt="upload.svg" />
                      <span className="px-2 text-[#0A090B] font-interMedium text-sm">
                        Drag & drop patent file or{" "}
                        <span className="text-[#1751D0] font-interMedium text-sm">
                          browse files
                        </span>
                      </span>
                    </label>
                  </div>
                  {selectedFiles.length > 0 && (
                    <FileUploadProgress
                      files={selectedFiles}
                      progress={fileProgress}
                    />
                  )}
                </div>
              }
            </div>
          </Body>

          <FooterModal
            title=""
            isShowFooter={true}
            className="modal-footer pt-8 px-6 pb-4"
          >
            <div className="flex items-center gap-x-3 justify-center">
              <button
                onClick={onCloseModal}
                className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]"
              >
                Cancel
              </button>
              {!isAPICalled && (
                <button
                  className={`!text-base text-white bg-[#1751D0] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${
                    btnDisabled || uploadingFiles ? "button-disabled" : ""
                  }`}
                  type="submit"
                >
                  Upload
                </button>
              )}
              {isAPICalled && (
                <>
                  <button
                    disabled={true}
                    className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1  ${
                      btnDisabled || uploadingFiles ? "button-disabled" : ""
                    }`}
                    type="submit"
                  >
                    Upload
                  </button>
                  <br />
                </>
              )}
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
}
export default UploadFileModal;
