import React from 'react'

const ExistingSettingMember = () => {
  return (
    <div className="max-w-[660px] animate-pulse">
    <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-200 w-48 mb-5"></div>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 max-w-[660px] mb-4"></div>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 mb-4"></div>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 max-w-[630px] mb-4"></div>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 max-w-[570px] mb-4"></div>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 max-w-[550px] mb-4"></div>
	<div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 mb-4"></div>
    <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 max-w-[570px] mb-4"></div>
</div>
  )
}

export default ExistingSettingMember