import React, { useEffect, useRef, useState } from "react";
import Sidebar from "components/common/Sidebar";
import Header from "components/common/Header";
import allImgPaths from "assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { getPatentNameById, getPatentVijilizedDetails } from "services/project";
import { ClaimAnalysisShimmerLoader } from "pages/loaders";

import useAppState from "context/useAppState";
import ReactApexChart from "react-apexcharts";

interface Claim {
  elements: {
    description: string;
    figure: string;
    hex_code: string;
    legend: { [key: string]: string }[];
    figurePreSignedUrl: string;
  }[];
  similarityScore: number | null;
  confidenceScore: number | null;
}

interface Patent {
  patentId: string;
  patentNumber: string;
  claims: { [key: string]: Claim };
  structure: string;
}

interface APIData {
  patents: Patent[];
  similarity_results: { [key: string]: any[] };
  name: string;
  description: string;
}

interface ActiveIndex {
  patentId: string | null;
  claimIndex: number | null;
}

const ClaimAnalysisHeatmap: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setHeaderDataLocal, setClickedHeatmapData, setVijilizeParamsData } =
    useAppState("vijilize");

  const [APIData, setAPIData] = useState<any | null>(null);
  const [series, setSeries] = useState<any>([]);
  const [matchedAbove, setMatchedAbove] = useState<number>(0);
  const location = useLocation();

  const sampleHeatmapData = [
    {
      name: "US9895202",
      data: [
        {
          x: "US9895202.pdf",
          y: 20,
          claim: "claim1",
        },
        {
          x: "1736523020787",
          y: 65,
          claim: "claim1",
        },
      ],
    },
    {
      name: "Niraj",
      data: [
        {
          x: "US9895202.pdf",
          y: 20,
          claim: "claim1",
        },
        {
          x: "1736523020787",
          y: 65,
          claim: "claim1",
        },
        {
          x: "1736523020787",
          y: 65,
          claim: "claim1",
        },
        {
          x: "1736523020787",
          y: 65,
          claim: "claim1",
        },
        {
          x: "1736523020787",
          y: 65,
          claim: "claim1",
        },
      ],
    },
    {
      name: "US9895204",
      data: [
        {
          x: "US9895202.pdf",
          y: 55,
          claim: "claim1",
        },
        {
          x: "1736523020787",
          y: 100,
          claim: "claim1",
        },
      ],
    },
    {
      name: "pk",
      data: [
        {
          x: "US9895202.pdf",
          y: 55,
          claim: "claim1",
        },
        {
          x: "1736523020787",
          y: 100,
          claim: "claim1",
        },
      ],
    },
  ];

  const options: any = {
    chart: {
      //   height: 350,
      type: "heatmap",
      events: {
        click: function (event: any, chartContext: any, opts: any) {
          const { seriesIndex, dataPointIndex } = opts;
          const series = opts.config.series;
          const finalData = {
            x: series[seriesIndex]?.data[dataPointIndex]?.x,
            y: series[seriesIndex]?.data[dataPointIndex]?.y,
            claim: series[seriesIndex]?.data[dataPointIndex]?.claim,
          };

          if (dataPointIndex >= 0 && seriesIndex >= 0) {
            const newRoute = location?.pathname.split("/heatmap")[0];

            navigate(newRoute);

            setClickedHeatmapData({
              source: series[seriesIndex]?.name,
              sourceId: series[seriesIndex]?.id,
              claimKey: series[seriesIndex]?.data[dataPointIndex]?.claim,
            });
          }
        },
      },
    },

    plotOptions: {
      heatmap: {
        // enableShades:true,
        shadeIntensity: 0,
        radius: 0,
        useFillColorAsStroke: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 40,
              color: "#F2F5FF",
              name: "0-40",
              foreColor: "#455468",
            },
            {
              from: 41,
              to: 60,
              color: "#AEBFFF",
              name: "41-60",
              foreColor: "#455468",
            },
            {
              from: 61,
              to: 90,
              color: "#1B4DFF",
              name: "61-90",
            },
            {
              from: 91,
              to: 100,
              color: "#113997",
              name: "91-100",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        return `${val}%`;
      },
      style: {
        fontSize: "2px",
        fontWeight: "bold",
      },
    },
    legend: {
      show: false,
    },
    // title: {
    //   text: "HeatMap Chart with Color Range",
    // },
    // colors: ['#F2F5FF', '#AEBFFF', '#1B4DFF', '#113997']
  };

  const setPatentId = async (heatmapData: any) => {
    return Promise.all(
      heatmapData.map(async (item: any) => {
        const response = await getPatentNameById(item.name);

        if (response && response.data) {
          item.name = response.data.patent_id;
          item.id = response.data._id;
        }

        return item;
      })
    );
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      let comparisonId;
      const pathName = location?.pathname;
      const regexMatch = pathName?.match(/\/vijilize\/([^\/]+)/);

      if(!regexMatch){
        return;
      }

      if (regexMatch && regexMatch[1]) {
        comparisonId = regexMatch[1];
      
      let patentData:any =
        (await getPatentVijilizedDetails(comparisonId));
        setHeaderDataLocal((prev: any) => {
          return {
            ...prev,
            vijilize: { title: patentData?.name, id: patentData?._id },
          };
        });
        const patent_ids = patentData?.source.map((item: any) => item.patent_id);
        const compare_id = patentData?._id;
        setVijilizeParamsData({ patent_ids, compare_id });

      if (patentData?.heatmap_data) {
        const updatedHeatmapData = await setPatentId(patentData?.heatmap_data);
        setSeries(updatedHeatmapData);
      } else {
        // setSeries(sampleHeatmapData)
      }

      setAPIData(patentData);

      const countHighScores = (data: any, matchPercentage: number) => {
        let count = 0;

        data?.forEach((item: any) => {
          Object.values(item)?.forEach((claims: any) => {
            Object.values(claims)?.forEach((claim: any) => {
              claim?.forEach((detail: any) => {
                if (detail?.score > matchPercentage) {
                  count++;
                }
              });
            });
          });
        });

        return count;
      };

      const highScoreCount = countHighScores(
        patentData?.summarized_similarity_results,
        80
      );
      setMatchedAbove(highScoreCount);
      setIsLoading(false);
    }
    })();
  }, []);

  return (
    <div className="dashboard-wrapper flex">
      <Sidebar />
      <div className="w-[calc(100%_-_72px)] ml-auto">
        <Header
          title="Vijilize Heatmap"
          count={0}
          isChildEle={true}
          type="project"
        />
        {isLoading ? <ClaimAnalysisShimmerLoader /> : null}
        {!isLoading ? (
          <div className="pt-4 px-4 h-[calc(100vh_-_69px)] overflow-y-auto scrollbar-adjust">
            <div className="flex justify-between items-center w-full pb-8">
              <div className="flex items-start gap-x-2 pl-1">
                <img
                  className="inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1"
                  src={allImgPaths.goBack}
                  alt="return"
                  onClick={() => navigate(-1)}
                />
                <div className="">
                  <h2 className="text-[#101828] text-xl font-interSemiBold">
                    {(APIData && APIData?.name) || "Test"}
                  </h2>
                  <p className="text-sm text-[#4F4D55] font-interRegular">
                    {(APIData && APIData?.description) ||
                      "Description of the vijilization comes here"}
                  </p>
                </div>
              </div>
              {/* <div className='flex gap-x-4 items-center'>
                                <button className={`px-3.5 py-2.5 rounded-md border border-[#E6E6E6] group`} onClick={() => { setConfigureScoreModal(true); console.log(tempData,selectedSimilarity,'RESET'); setSelectedSimilarity(tempData); }}>
                                    <span className={`text-sm font-interMedium text-[#4F4D55] group-hover:text-[#000] flex items-center gap-x-2 ${isOpen ? 'text-[#000]' : ''}`}>
                                        <img src={allImgPaths.configure} alt='' />
                                        Configure Scoring
                                    </span>
                                </button>
                            </div> */}
            </div>

            <div className="flex gap-x-4 mb-4 px-1.5">
              <div className="border border-[#E6E6E6] rounded-lg bg-white flex flex-col items-center justify-center shadow-customShadow16 px-5 py-5 w-[300px]">
                <p className="text-sm font-interRegular text-[#667085] pb-1.5">
                  Source
                </p>
                <p className="text-2xl font-interBold text-[#101828]">
                  {APIData?.source.length}
                </p>
              </div>
              <div className="border border-[#E6E6E6] rounded-lg bg-white flex flex-col items-center justify-center shadow-customShadow16 px-5 py-5 w-[300px]">
                <p className="text-sm font-interRegular text-[#424855] pb-1.5">
                  Compare With
                </p>
                <p className="text-2xl font-interBold text-[#101828]">
                  {APIData?.compare.length}
                </p>
              </div>
              <div className="border border-[#E6E6E6] rounded-lg bg-white flex flex-col items-center justify-center shadow-customShadow16 px-5 py-5 w-[300px]">
                <p className="text-sm font-interRegular text-[#667085] pb-1.5">
                  Match above 80%
                </p>
                <p className="text-2xl font-interBold text-[#101828]">
                  {matchedAbove}
                </p>
              </div>
              {/* <div className="border border-[#E6E6E6] rounded-lg bg-white flex flex-col items-center justify-center shadow-customShadow16 px-5 py-5 w-[300px]">
                                <p className='text-sm font-interRegular text-[#667085] pb-1.5'>Active Patents</p>
                                <p className='text-2xl font-interBold text-[#101828]'>1</p>
                            </div> */}
            </div>

            <div className="flex items-center justify-between px-1.5">
              <p className="flex items-center gap-x-2 text-base font-interMedium text-[#272E35]">
                Comparison Heatmap{" "}
                <span className="text-sm font-interRegular text-[#7E8C9A]">
                  (Click a box to view in depth analysis)
                </span>
              </p>
              <div className="flex gap-x-4 justify-items-end items-center">
                <button
                  className={``}
                  onClick={() => {
                    navigate(location?.pathname.split("/heatmap")[0]);
                  }}
                >
                  <span
                    className={`text-sm font-interMedium text-[#1751D0] group-hover:text-[#000] flex items-center gap-x-2 `}
                  >
                    <img src={allImgPaths.arrowRightContained} alt="" />
                    Skip Selection & Continue
                  </span>
                </button>
              </div>
            </div>

            {APIData?.heatmap_data?.length > 0 ? (
              <div className="heatmap-chart-styling">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="heatmap"
                  height={550}
                />
              </div>
            ) : (
              <>
                <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                  <div className="text-center">
                    <img
                      className="mb-2.5 ml-auto mr-auto"
                      src={allImgPaths.heatmapNotFound}
                      alt=""
                    />
                    <p className="font-interSemiBold text-base text-[#101828] pb-2">
                      Couldn’t load the comparsion matrix
                    </p>
                    <p className="font-interRegular text-sm text-[#4F4D55] max-w-md mx-auto">
                      There was some issue loading the comparison matrix. You
                      can click next to proceed and use filters in the next
                      screen to view your desired comparison.
                    </p>
                    <div className=" flex justify-center items-center gap-x-4 mt-7">
                      {/* <button className={`px-20 py-3 rounded-md border border-[#E6E6E6] group`} >
                                                <span className={`text-sm font-interMedium text-[#4F4D55] group-hover:text-[#000] flex items-center gap-x-2 ${isOpen ? 'text-[#000]' : ''}`}>
                                                    Retry
                                                </span>
                                            </button> */}

                      <button
                        className={`px-20 py-3 text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg`}
                        onClick={() => {
                          navigate(location?.pathname.split("/heatmap")[0]);
                        }}
                      >
                        <span
                          className={`text-sm font-interMedium text-white group-hover:text-[#000] flex items-center gap-x-2`}
                        >
                          Skip to Next Screen
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ClaimAnalysisHeatmap;
