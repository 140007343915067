import { ChangeEvent } from "react";

interface CheckboxProps {
	label?: string;
	id: string;
	name: string;
	checked?: boolean;
	onChange?: (string: string,isChecked:boolean) => void;
	disabled?: boolean;
	customLabelStyle?: string;
	customStyle?: string;
	title?: string;
	truntTitle?: string;
}

function CustomCheckBox({
	label,
	id,
	name,
	title,
	checked = false,
	onChange,
	disabled = false,
	customLabelStyle,
	customStyle,
	truntTitle
}: CheckboxProps) {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		onChange?.(event.target.name,isChecked);
	};
	return (
		<div className={`custom-checkbox ${customStyle}`}>
			<input
				type="checkbox"
				id={id}
				name={name}
				onChange={handleChange}
				checked={checked}
			/>
			<label
				title={truntTitle}
				htmlFor={name}
				className={`font-interMedium ${disabled ? '' : ''}`}
			>
				<span title={title} className={`custom-checkbox-label font-interMedium ${customLabelStyle}`}>{label}</span>
			</label>
		</div>
	);
}

export default CustomCheckBox;