import { useEffect, useRef } from "react";
// import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import useAppState from "../../context/useAppState";

import { useState } from "react";
import allImgPaths from "assets/images";

// Common components
import Sidebar from "../../components/common/Sidebar";
import Header from "../../components/common/Header";
import { ROLES } from "../../utils/constant";
import { useDebounce } from "hooks/common/useDebounce";
import AddMember from "components/common/modals/AddMember";
import LeaveMember from "components/common/modals/LeaveMember";
import TransferMember from "components/common/modals/TransferMember";
import RemoveMember from "components/common/modals/RemoveMember";
import PendingInvitations from "./PendingInvitations";
import { ExistingSettingMemberLoader } from "pages/loaders";

import { cloneDeep, isEqual } from "lodash";
import { useLocation } from "react-router-dom";

const Settings = () => {
  const { t } = useTranslation();
  const isInit = useRef(true);
  const {
    teamDetails,
    getTeamDetails,
    handleNameChange,
    teamNameValidation,
    handleFormSubmit,
    currentTeam,
    imageValidation,
    imageUrl,
    handleFileChange,
    getProjectInvitationList,
    teamDetailsLoading,
    getPortfolioInvitationList,
  } = useAppState("settings");

  const { updateCurrentTeam } = useAppState("selectTeam");

  const [toggle, setToggle] = useState(0);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  const [totalCount, setTotalCount] = useState(0);
  const [showRemoveMemberModal, setShowRemoveMemberModal] =
    useState<boolean>(false);
  const [removeSelectedMember, setRemoveSelectedMember] = useState<string>("");
  const [showTransferModal, setShowTransferModal] = useState<boolean>(false);

  const userRole = JSON.parse(localStorage.getItem("selectedTeam") || "");

  const [searchValue, setSearchValue] = useState("");

  const useDebounceTerm = useDebounce(searchValue);
  const location = useLocation();

  const [toast, setToast] = useState({
    show: false,
    heading: "",
    message: "",
  });

  const [formData, setFormData] = useState<any>({});
  const actualFormData = useRef<any>({});
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

  useEffect(() => {
    const data = {
      name: teamDetails?.name,
      logo: teamDetails?.logo,
    };
    setFormData({ ...formData, ...data });
    if (isInit.current && teamDetails) {
      actualFormData.current = cloneDeep(data);
      isInit.current = false;
    }
  }, [teamDetails]);

  useEffect(() => {
    areArraysEqual(formData, actualFormData.current);
  }, [formData]);

  useEffect(() => {
    getTeamDetails(useDebounceTerm);
  }, [useDebounceTerm]);

  useEffect(() => {
    if (userRole.currentRole === "Admin") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    setFormData({ ...formData, logo: imageUrl });
  }, [imageUrl]);

  useEffect(() => {
    let timeoutId: any;
    if (toast.show) {
      timeoutId = setTimeout(() => {
        setToast({ ...toast, show: false, heading: "", message: "" });
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("tab") === "pending") {
      setToggle(1);
    }
  }, [location]);

  const areArraysEqual = (original: any, cloned: any) => {
    const isSame = isEqual(original, cloned);
    setSaveBtnDisabled(isSame);
  };

  const fetchData = async () => {
    const { total: projectTotal } = await getProjectInvitationList();
    const { total: portfolioTotal } = await getPortfolioInvitationList();

    setTotalCount(projectTotal + portfolioTotal);
  };

  const handleButtonClick = (event: any) => {
    event?.preventDefault();
    document.getElementById("file-input")?.click();
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const submitForm = async (data: any) => {
    const response = await handleFormSubmit(data);
    if (response) {
      setTimeout(() => {
        const response: any = JSON.parse(
          localStorage.getItem("selectedTeam") || ""
        );
        if (response) {
          updateCurrentTeam(response.currentTeamId);
          setToast({
            show: true,
            heading: "Success",
            message: "Profile Updated Successfully",
          });
        }
        setSaveBtnDisabled(true);
        actualFormData.current = {
          ...actualFormData.current,
          name: teamDetails?.name,
        };
      }, 2000);
    }
  };

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleTeamNameChange = (e: any) => {
    if (e.target.value) {
      setFormData({ ...formData, name: e.target.value });
      handleNameChange(e);
    }
  };

  return (
    <div className="dashboard-wrapper flex">
      {/* Sidebar */}
      <Sidebar />

      {/* dashboard main */}
      <div className="w-[calc(100%_-_72px)] ml-auto">
        {/* Header */}
        <Header type={"settings"} />

        {/* Main */}
        <form
          onSubmit={(data: any) => {
            submitForm(data);
          }}
        >
          <div className="pt-6 px-8 h-[calc(100vh_-_125px)] overflow-y-scroll scrollbar-adjust">
            <h2 className="text-[#101828] text-3xl font-semibold pb-5">
              {t("settings.heading")}
            </h2>

            {/* Switcher */}
            <ul className="flex items-center mb-5 gap-x-4">
              <li className="block text-sm font-interSemiBold text-[#1E0AF5] hover:text-[#1E0AF5] hover:bg-[#E8E6FE] rounded-md bg-[#E8E6FE] py-2 px-4 cursor-pointer">
                {t("settings.switcherTeam")}
              </li>
              {currentTeam?.currentRole === ROLES.ADMIN && (
                <>
                  <li className="block text-sm font-interSemiBold py-2 px-4 cursor-pointer hover:text-[#1E0AF5] hover:bg-[#E8E6FE] rounded-md text-[#667085]">
                    {t("settings.switcherBilling")}
                  </li>
                  <li className="block text-sm font-interSemiBold py-2 px-4  cursor-pointer hover:text-[#1E0AF5] hover:bg-[#E8E6FE] rounded-md text-[#667085]">
                    {t("settings.switcherIntegration")}
                  </li>
                </>
              )}
            </ul>

            {/* Team Info */}
            {currentTeam?.currentRole === ROLES.ADMIN && (
              <>
                <div className="flex items-center justify-between mb-5">
                  <div>
                    <h4 className="text-lg font-interSemiBold text-[#101828] pb-0.5">
                      {t("settings.infoHeading")}
                    </h4>
                    <p className="text-sm text-[#475467]">
                      {t("settings.infoDescription")}
                    </p>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <button className="cancle-btn-blue !px-[15px] !text-sm hover:bg-[#F2F4F7]">
                      Cancel
                    </button>
                    <button
                      className={`submit-btn-blue !px-[15px] !text-sm hover:bg-[#120693] ${
                        saveBtnDisabled ? "button-disabled" : ""
                      }`}
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </div>

                <div className="w-full h-px bg-[#EAECF0]"></div>

                {/* Name and logo */}
                <div className="mt-5 mb-6">
                  <div className="flex gap-x-8">
                    <div className="flex items-center gap-x-4">
                      <span className="text-sm text-[#344054] font-interSemiBold">
                        {t("settings.name")}
                      </span>
                      <input
                        className={
                          teamNameValidation
                            ? "input-field border border-[#D0D5DD] hover:border-[#1e0af5] block w-full max-w-[512px] focus:border-[#1e0af5]"
                            : "input-field border border-[#D0D5DD] hover:border-[#1e0af5] focus:border-[#1e0af5] block w-full max-w-[512px]"
                        }
                        type="text"
                        name="name"
                        defaultValue={teamDetails?.name}
                        onChange={handleNameChange}
                        // onChange={handleTeamNameChange}
                      />
                    </div>

                    <div className="flex items-center gap-x-1">
                      <div className="w-[276px]">
                        <span className="text-sm text-[#344054] font-interSemiBold w-[280px] flex gap-x-0.5">
                          {t("settings.teamlogo")}
                          {/* <img src={questionMarkIcon} alt="icon" /> */}
                        </span>
                        <span className="text-sm text-[#475467]">
                          {t("settings.teamlogoDescription")}
                        </span>
                      </div>

                      <div className="">
                        <div className="flex items-center gap-x-5">
                          <img
                            className="w-16 h-16 border border-[#00000014] object-contain"
                            src={
                              imageUrl ||
                              teamDetails?.logo ||
                              allImgPaths.TeamPlaceholder64
                            }
                            alt="default "
                          />
                          <input
                            className="flex items-center gap-x-1.5 border border-[#D0D5DD] hover:border-[#1e0af5] rounded-lg py-2.5 px-3.5"
                            type="file"
                            id="file-input"
                            accept=".jpg,.jpeg,.png"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                          <button
                            className="flex items-center gap-x-1.5 border border-[#D0D5DD] hover:border-[#1e0af5] rounded-lg py-2.5 px-3.5"
                            onClick={handleButtonClick}
                          >
                            <img
                              src={allImgPaths.uploadIcon2}
                              alt="upload Iocn"
                            />
                            <span className="text-sm font-interSemiBold text-[#344054]">
                              Upload photo
                            </span>
                          </button>
                        </div>
                        <p className="text-[#D92D20] text-sm pt-0.5">
                          {imageValidation}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Members */}
            <div className="flex gap-x-4">
              <div className="w-1/2">
                <h4 className="text-lg font-interSemiBold text-[#101828] pb-0.5">
                  {t("settings.member")}
                </h4>
                <p className="text-sm text-[#475467]">
                  {currentTeam?.currentRole === ROLES.ADMIN
                    ? t("settings.memberDescription")
                    : t("settings.memberDescriptionForPlanner")}
                </p>
              </div>
              <div
                className={`w-1/2 flex items-center justify-end gap-x-4 ${
                  currentTeam?.currentRole === ROLES.ADMIN ? "" : "mb-6"
                }`}
              >
                {currentTeam?.currentRole === ROLES.ADMIN && (
                  <button
                    className="submit-btn-blue !px-[15px] !text-sm hover:bg-[#120693]"
                    onClick={() => {
                      setShowAddMemberModal(true);
                    }}
                  >
                    Add Member
                  </button>
                )}
                <div
                  className={`border hover:border-[#1E0AF5] rounded-lg w-80 py-2 px-3.5 flex items-center gap-x-2 ${
                    isFocused ? "border-[#1e0af5]" : "border-[#D0D5DD]"
                  }`}
                >
                  <img src={allImgPaths.searchIcon4} alt="search" />
                  <input
                    className="w-full"
                    placeholder="Search"
                    type="text"
                    onChange={handleChange}
                    value={searchValue}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </div>

            {currentTeam?.currentRole === ROLES.ADMIN && (
              <ul className="flex items-center my-5 gap-x-4">
                <li
                  onClick={(e) => {
                    setToggle(0);
                    fetchData();
                  }}
                  className={`hover:text-[#1E0AF5] hover:bg-[#E8E6FE] rounded-md font-interSemiBold text-sm px-3 py-2 cursor-pointer ${
                    toggle === 0
                      ? "text-[#1E0AF5] bg-[#E8E6FE] pointer-none"
                      : "text-[#667085]"
                  }`}
                >
                  Existing
                </li>
                <li
                  onClick={(e) => setToggle(1)}
                  className={`hover:text-[#1E0AF5] hover:bg-[#E8E6FE] rounded-md font-interSemiBold text-sm  px-3 py-2 cursor-pointer ${
                    toggle === 1
                      ? "text-[#1E0AF5] bg-[#E8E6FE] pointer-none"
                      : "text-[#667085]"
                  }`}
                >
                  Pending Invitations{" "}
                  {toggle == 0 && totalCount > 0 && (
                    <span className="ml-2 text-xs font-interMedium text-[#344054] border border-[#bbbec4] px-3 py-1.5 rounded-full">
                      {totalCount}
                    </span>
                  )}
                </li>
              </ul>
            )}
            {/* Table for Existing */}

            {toggle === 0 &&
              (teamDetailsLoading ? (
                <ExistingSettingMemberLoader />
              ) : (
                <div className="">
                  <div className="custom-table-grid bg-[#F9FAFB] rounded-tl-lg rounded-tr-lg border-b-0 border border-[#EAECF0]">
                    <div>
                      <span className="font-interMedium text-sm text-[#475467] py-3 px-6 inline-block">
                        Name
                      </span>
                    </div>
                    <div>
                      <span className="font-interMedium text-sm text-[#475467] py-3 px-6 inline-block">
                        Email
                      </span>
                    </div>
                    <div>
                      <span className="font-interMedium text-sm text-[#475467] py-3 px-6 inline-block">
                        Role
                      </span>
                    </div>
                    <div>
                      <span className="font-interMedium text-sm text-[#475467] py-3 px-6 inline-block">
                        Action
                      </span>
                    </div>
                  </div>
                  <div
                    className={`border border-[#EAECF0] rounded-bl-lg rounded-br-lg overflow-y-auto ${
                      currentTeam?.currentRole === ROLES.ADMIN
                        ? "max-h-[342px]"
                        : "max-h-[584px]"
                    } `}
                  >
                    {teamDetails &&
                      teamDetails.members &&
                      teamDetails.members.map((member: any, index: number) => (
                        <div
                          className="custom-table-grid border-b border-[#EAECF0]"
                          key={index}
                        >
                          <div className="flex items-center gap-x-3 py-2 px-6">
                            <img
                              className="inline-block w-7 h-7 rounded-full"
                              src={
                                member.profile_image ||
                                allImgPaths.defaultProfile
                              }
                              alt=""
                            />
                            <div className="">
                              <h6 className="text-[#101828] text-xs font-interMedium">
                                {member.name}
                              </h6>
                              {/* <p className="text-sm text-[#475467]">{member.teams.roleName === ROLES.ADMIN ? 'Admin' : ''}</p> */}
                            </div>
                          </div>

                          <div className="flex items-center py-2 px-6">
                            <span className="text-xs text-[#475467]">
                              {member.email}
                            </span>
                          </div>

                          <div className="flex items-center py-2 px-6">
                            <span className="text-sm text-[#475467]">
                              <span className="text-xs text-[#475467]">
                                {member.teams.roleName}
                              </span>
                            </span>
                          </div>

                          {currentTeam?.currentRole === ROLES.ADMIN && (
                            <div className="flex items-center gap-x-3 py-2 px-6">
                              {member?.teams?.roleName === ROLES.ADMIN ? (
                                <>
                                  <span
                                    className="text-sm text-[#475467] font-interSemiBold w-7 h-7 p-0.5 hover:bg-[#f2f4f7] rounded flex items-center justify-center"
                                    onClick={() => {
                                      setRemoveSelectedMember(member._id);
                                      setShowTransferModal(true);
                                    }}
                                  >
                                    <img
                                      className="cursor-pointer w-5 h-5"
                                      src={allImgPaths.RefreshIcon}
                                      alt="transfer"
                                      title="transfer"
                                    />
                                  </span>
                                </>
                              ) : (
                                <span
                                  className="text-sm text-[#475467] font-interSemiBold w-7 h-7 p-0.5 hover:bg-[#FEE4E2] rounded flex items-center justify-center"
                                  onClick={() => {
                                    setRemoveSelectedMember(member._id);
                                    setShowRemoveMemberModal(true);
                                  }}
                                >
                                  <img
                                    className="cursor-pointer w-5 h-5"
                                    src={allImgPaths.removeUserIcon2}
                                    alt="remove"
                                    title="remove"
                                  />
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    {/* END LOOP */}
                  </div>
                </div>
              ))}

            {/* Table for Pending Invitations */}
            {userRole.currentRole === "Admin" && toggle === 1 && (
              <PendingInvitations setToast={setToast} />
            )}
          </div>
        </form>

        {showAddMemberModal && (
          <AddMember
            isShow={showAddMemberModal}
            setIsShow={setShowAddMemberModal}
            setToast={setToast}
          />
        )}
        <LeaveMember />
        {showTransferModal && (
          <TransferMember
            isShow={showTransferModal}
            setIsShow={setShowTransferModal}
            selectedMemberId={removeSelectedMember}
            setToast={setToast}
          />
        )}
        {showRemoveMemberModal && (
          <RemoveMember
            isShow={showRemoveMemberModal}
            setIsShow={setShowRemoveMemberModal}
            selectedMemberId={removeSelectedMember}
            setToast={setToast}
          />
        )}

        {/* {toast?.show && (
					<Toast heading={toast?.heading} message={toast?.message} />
				)} */}

        {/* Footer */}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Settings;
