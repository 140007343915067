import React, { useEffect, useRef, useState } from "react";

import Sidebar from "components/common/Sidebar";
import Header from "components/common/Header";
import allImgPaths from "assets/images";
import { useNavigate, useParams } from "react-router-dom";
import { getClaimChartById } from "services/project";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import ClaimChartDownload from "./ClaimChartDownload";
import useAppState from "context/useAppState";
import ClaimData from "components/ClaimData";
import ClaimChartDownloadLoader from "pages/loaders/ClaimChartDownloadLoader";

interface Claim {
  elements: {
    description: string;
    figure: string;
    hex_code: string;
    legend: { [key: string]: string }[];
    figurePreSignedUrl: string;
  }[];
  similarityScore: number | null;
  confidenceScore: number | null;
}

interface Patent {
  patentId: string;
  patentNumber: string;
  claims: { [key: string]: Claim };
  structure: string;
}

interface APIData {
  patents: Patent[];
  similarity_results: { [key: string]: any[] };
  name: string;
  description: string;
}

interface ActiveIndex {
  patentId: string | null;
  claimIndex: any;
}

const ClaimChart = () => {
  const [toggle, setToggle] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<ActiveIndex>({
    patentId: null,
    claimIndex: null,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { projectId, claimChartId } = useParams<{
    projectId: string;
    claimChartId: string;
  }>();

  const navigate = useNavigate();
  const [selectedClaim, setSelectedClaim] = useState<Claim | null>(null);
  const [selectedSimilarity, setSelectedSimilarity] = useState<any[]>([]);
  const [tempData, setTempData] = useState<any[]>([]);
  const [claimSelectionLoader, setClaimSelectionLoader] =
    useState<boolean>(false);

  const [claimChartAPIData, setClaimChartAPIData] = useState<any>({});

  const [patentClaimPairs, setPatentClaimPairs] = useState<any>([]);

  const { setHeaderDataLocal } = useAppState("vijilize");
  const {
    showClaimChartDownload,
    isDownloadClicked,
    newData,
    imagesLoaded,
    setImagesLoaded,
    handleDownload,
    currentMessageIndex,
    loaderMessages,
    claimChartData,
  } = useAppState("claimChartDownload");

  const filterRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(filterRef, () => {
    setIsOpen(false);
  });

  const [toast, setToast] = useState<{
    show: boolean;
    heading: string;
    message: string;
  }>({
    show: false,
    heading: "",
    message: "",
  });

  const [APIData, setAPIData] = useState<any | null>(null);
  const [summarizedAPIData, setSummarizedAPIData] = useState<any | null>(null);

  const [claimsData, setClaimsData] = useState<{ [key: string]: Patent }>({});

  const toggleAccordion = (
    patentId: string,
    claimIndex: number,
    claimKey: any
  ) => {
    setActiveIndex((prev) => ({
      patentId:
        prev.patentId === patentId && prev.claimIndex === claimIndex
          ? null
          : patentId,
      claimIndex:
        prev.patentId === patentId && prev.claimIndex === claimIndex
          ? null
          : claimIndex,
    }));

    let payload = {
      patentId: patentId,
      claimKey: claimKey,
    };
    setPatentClaimPairs((prev: any) => [...prev, payload]);

    setClaimSelectionLoader(true);
    setSelectedClaim(claimsData[patentId]?.claims[claimKey]);

    const summarizedData = summarizedAPIData.find(
      (item: any) => item[patentId]
    );
    setTempData(summarizedData[patentId][claimKey] || []);

    setSelectedSimilarity(summarizedData[patentId][claimKey]);
    setTimeout(() => {
      setClaimSelectionLoader(false);
    }, 1000);
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const claimChartDataRes = await getClaimChartById(claimChartId || "0");
      let claimChartData: any = claimChartDataRes.data;
      setClaimChartAPIData(claimChartData);

      setHeaderDataLocal((prev: any) => {
        return {
          ...prev,
          claimChart: { title: claimChartData?.name, id: claimChartData?._id },
        };
      });
      let patentData = claimChartData.claim_chart_data;
      setAPIData(patentData);
      setSummarizedAPIData(patentData?.summarized_similarity_results);
      setClaimsData(
        patentData.patents.reduce(
          (acc: { [key: string]: Patent }, patent: Patent) => {
            acc[patent.patentId] = patent;
            return acc;
          },
          {}
        )
      );
      let patentId = patentData?.patents[0]?.patentId;
      let claimKey = Object.keys(patentData.patents[0]?.claims)[0];

      setActiveIndex({
        patentId: patentId,
        claimIndex: 0,
      });
      const summarizedData = patentData?.summarized_similarity_results?.find(
        (item: any) => item[patentId]
      );

      setSelectedClaim(patentData.patents[0]?.claims[claimKey]);

      setTempData(summarizedData[patentId][claimKey]);
      setSelectedSimilarity(summarizedData[patentId][claimKey]);

      setIsLoading(false);
      setClaimSelectionLoader(true);
      setTimeout(() => {
        setClaimSelectionLoader(false);
      }, 1000);
    })();
  }, []);

  const handleDownloadClick = (e: any) => {
    handleDownload(APIData, claimChartAPIData);
  };

  const handleClaimClick = (
    event: any,
    item: any,
    index: number,
    claimKey: string
  ) => {
    toggleAccordion(item.patentId, index, claimKey);
  };

  return (
    <div className="dashboard-wrapper flex">
      {/* Sidebar */}
      <Sidebar />
      <div className="w-[calc(100%_-_75px)] h-[100vh] ml-auto">
        {/* Hedaer */}
        <Header
          count={0}
          title="Claim Chart"
          isChildEle={true}
          type="project"
        />
        <div className="bg-[#f9fafb]">
          {/* Page Header Part */}
          <div className="flex items-center justify-between p-[16px]">
            <div className="flex items-start gap-x-2 pl-1">
              <img
                className="inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1"
                src={allImgPaths.goBack}
                alt="return"
                onClick={() => navigate(-1)}
              />
              <div className="flex flex-col">
                <h3 className="text-[#101828] text-xl font-interSemiBold">
                  {claimChartAPIData?.name}
                </h3>
                <span className="text-[#7F7D83] font-light text-sm">
                  View / edit Vijil chart
                </span>
              </div>
            </div>

            <div className="flex items-center gap-4 bg-white">
              <button
                onClick={handleDownloadClick}
                className={`flex items-center gap-1.5 px-3 py-2 cancle-btn-blue ${
                  isDownloadClicked ? "button-disable" : ""
                }`}
                disabled={isDownloadClicked}
              >
                <img src={allImgPaths.downloadIcon} alt="" />
                <span className="text-sm font-interMedium text-[#4F4D55] group-hover:text-[#000] flex items-center gap-x-2 ">
                  Download
                </span>
                {/* <img src={allImgPaths.downIcon3} alt="" /> */}
              </button>
            </div>
          </div>

          <div className="px-5 pb-4" id="claimData">
            <ClaimData
              type="claimChart"
              APIData={APIData}
              filteredClaimsData={claimsData}
              activeIndex={activeIndex}
              handleClaimClick={handleClaimClick}
              summarizedAPIData={summarizedAPIData}
              claimSelectionLoader={claimSelectionLoader}
              toggle={toggle}
              selectedClaim={selectedClaim}
              selectedSimilarity={selectedSimilarity}
            />
          </div>

          {showClaimChartDownload && (
            <div
              className="absolute top-[-9999px] left-[-9999px]"
              aria-hidden="true"
              id="hidden-download-container"
            >
              <ClaimChartDownload
                newData={newData}
                claimChartAPIData={claimChartData}
                imagesLoaded={imagesLoaded}
                setImagesLoaded={setImagesLoaded}
              />
            </div>
          )}
        </div>
      </div>

      {isDownloadClicked && (
        <ClaimChartDownloadLoader
          loaderMessages={loaderMessages}
          currentMessageIndex={currentMessageIndex}
        />
      )}
    </div>
  );
};

export default ClaimChart;
