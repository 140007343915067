import { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { createPrompt, searchPrompts, updatePrompt } from "services/llm";
import { CreatePromptPayload, UpdatePromptPayload } from "types/prompt.types";

const initialArgs = {
  // promptList: [],
  isPromptCallApi: true
};

const reducer = (state: any, action: any) => {
  return { ...state, ...action };
};

const usePromptHook = () => {
  const [state, setState] = useReducer(reducer, initialArgs);
  const [promptList, setPromptList] = useState([])
  const location = useLocation();
  const resetStateToDefaults = () => {
    setState({ ...initialArgs })
  }

  useEffect(() => {
    resetStateToDefaults();
  }, [location.pathname]);

  const updatePromptApiFlag = (flag: boolean) => {
    setState({ ...state, isPromptCallApi: flag });
  }

  const addPrompt = async (promptData: CreatePromptPayload) => {
    try {
      await createPrompt(promptData);
    } catch (error) {
      console.log('Add Prompt error >>>', error);
    }
  }

  const getPrompts = async () => {
    try {
      const { data } = await searchPrompts()
      if (data && data?.results) {
        const promptData = data.results;
        promptData.sort((a: any, b: any) => a.name.localeCompare(b.name))
        // setPromptList(data?.results)
        setPromptList(promptData)
      }
      setState({ ...state, isPromptCallApi: false })
    } catch (error) {
      console.log('Add Prompt error >>>', error);
    }
  }

  const savePromptDetails = async (id: string, payload: UpdatePromptPayload) => {
    try {
      await updatePrompt(id, payload)
    } catch (error) {
      console.log('Add Prompt error >>>', error);
    }
  }

  return {
    setState,
    getPrompts,
    promptList,
    addPrompt,
    savePromptDetails,
    updatePromptApiFlag,
    ...state,
  };
};

export default usePromptHook;
