import allImgPaths from "assets/images";
import React, { useState } from "react";
const ClaimGraphicsDownload = ({ claim, setClaimFiguresTotal = () => { } }: any) => {
    const figures = claim?.figures || [];
    const elements = claim?.elements || [];
    const [loadingStates, setLoadingStates] = useState<boolean[]>(Array(figures.length + elements.reduce((acc: any, element: any) => acc + (element.figures?.length || 0), 0)).fill(true));
    const transformLegend = (figure: string) => {
        const legendData = claim?.legends.find((legend: any) => Object.keys(legend)[0] === figure);
        if (legendData) {
            return legendData[figure].map((item: any) => {
                const key = Object.keys(item)[0];
                return {
                    number: key,
                    text: item[key],
                };
            });
        }
        for (const element of elements) {
            const elementLegends = element.legends || [];
            const found = elementLegends.find((legend: any) => Object.keys(legend)[0] === figure);
            if (found) {
                return found[figure].map((item: any) => {
                    const key = Object.keys(item)[0];
                    return {
                        number: key,
                        text: item[key],
                    };
                });
            }
        }
        return [];
    };
    const handleImageLoad = (index: number) => {
        setLoadingStates((prev) => {
            const newLoadingStates = [...prev];
            newLoadingStates[index] = false;
            return newLoadingStates;
        });
    };
    // Function to deduplicate images and legends
    const getUniqueFiguresAndLegends = () => {
        const uniqueFigures = new Set();
        const uniqueEntries: any[] = [];
        figures.forEach((figure: any, index: any) => {
            const legendItems = transformLegend(figure);
            const imageUrl = claim?.figurePreSignedUrls[index];
            if (!uniqueFigures.has(figure)) {
                uniqueFigures.add(figure);
                uniqueEntries.push({ figure, legendItems, imageUrl });
            }
        });
        elements.forEach((element: any) => {
            const elementFigures = element.figures || [];
            elementFigures.forEach((figure: any, figureIndex: any) => {
                const legendItems = transformLegend(figure);
                const imageUrl = element.figurePreSignedUrls?.[figureIndex];
                if (!uniqueFigures.has(figure)) {
                    uniqueFigures.add(figure);
                    uniqueEntries.push({ figure, legendItems, imageUrl });
                }
            });
        });
        return uniqueEntries;
    };
    if (figures.length === 0 && elements.length === 0) {
        return null;
    }
    // Get unique figures and their corresponding legends
    const uniqueEntries = getUniqueFiguresAndLegends();
    setClaimFiguresTotal(uniqueEntries.length);
    return (
        <table border={0} className="mb-5 border-none border-collapse">
            {/* Loop through unique entries to display their images and legends */}
            {uniqueEntries.map(({ figure, legendItems, imageUrl }, entryIndex) => (
                <>
                    <tr key={figure} className="w-full border-none text-center">
                        <th className="text-center">
                            {loadingStates[entryIndex] && (
                                <div className="w-[348px] rounded-lg p-4 animate-pulse">
                                    <div className="space-y-4">
                                        <div className="h-56 bg-gray-200 rounded"></div>
                                    </div>
                                </div>
                            )}
                            <img
                                src={imageUrl}
                                width={300}
                                height={300}
                                onLoad={() => handleImageLoad(entryIndex)}
                                onError={(e) => {
                                    e.currentTarget.onerror = null;
                                    e.currentTarget.src = allImgPaths.claimFigureNotFound; // Fallback image
                                }}
                                alt={`Patent figure ${figure}`}
                                className={` mx-auto ${loadingStates[entryIndex] ? "hidden" : "block"}`}
                            />
                        </th>
                    </tr>
                    <tr>
                        {legendItems.map((item: any) => (
                            <p
                                key={item.number}
                                className="text-sm text-gray-900 font-interRegular pl-0 mb-1"
                            >
                                {item.number}. {item.text}
                            </p>
                        ))}
                    </tr>
                    
                    {/* Figure caption below */}
                    <tr className="mt-4 bg-[#F9FAFB] py-2 px-3 text-center w-11/12 mx-auto">
                        <span className="text-sm text-[#272E35] font-interMedium">
                            Fig. {figure.replace("FIG_", "")}
                        </span>
                    </tr>
                    <tr style={{'border':'none'}}><th style={{'border':'none'}}></th></tr>
                </>
            ))}
        </table>
    );
};
export default ClaimGraphicsDownload;