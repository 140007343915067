import {Link} from 'react-router-dom';

import alertIcon from 'assets/images/alert-icon.svg';
import {useRef} from 'react';
import useOnClickOutside from '../hooks/common/useOnClickOutside';

interface PopUpType {
    isPopUpShow: boolean;
    handleDismiss: () => void;
    handleLoginRedirect: () => void;
    isChangePasswordPopUpShow?: boolean;
}

export const AccountExistPopUp = ({isPopUpShow, handleDismiss, handleLoginRedirect}: PopUpType) => {
    return (
        <>
            {isPopUpShow && (
                <div className='rounded-xl border border-[#D0D5DD] pt-4 pr-4 pb-4 pl-2 flex items-start gap-x-2 mb-6'>
                    <img className='relative -top-2' src={alertIcon} alt='alrt icon' />
                    <div className=''>
                        <p className='font-interSemiBold text-sm text-[#344054] pb-1'>Account already exists</p>
                        <p className='text-sm text-[#475467]'>An account already exists with this email id. Please try with a new email id or login instead.</p>
                        <div className='flex gap-x-3 items-center mt-2.5'>
                            <Link className='text-[#475467] text-sm font-interSemiBold' to='' onClick={handleDismiss}>
                                Dismiss
                            </Link>
                            <Link className='text-[#4B3BF7] text-sm font-interSemiBold' to='/login' onClick={handleLoginRedirect}>
                                Log in
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

// password validation component
export const PasswordRulesComponent = ({passwordRule, showPasswordRule, setShowPasswordRule, isChangePasswordPopUpShow = false}: any) => {
    const popRef = useRef(null);
    useOnClickOutside(popRef, () => setShowPasswordRule(false));
    return (
        <>
            {showPasswordRule && (
                <div ref={popRef} className={`px-3 py-3 rounded-lg border border-[#D0D5DD] bg-white z-10 absolute shadow-customShadow2 left-0 w-full ${isChangePasswordPopUpShow ? "top-[20px]":"-top-[127px]"}`}>
                    <p className='text-[#101828] text-xs font-semibold pb-1.5'>Password rules</p>
                    <ul>
                        <li className={`font-interMedium ${!passwordRule.length && 'text-[#667085]'} text-xs pb-[3px] ${passwordRule.length > 0 && (passwordRule[0] ? 'text-[#079455]' : 'text-[#D92D20]')}`}>Must be between 8-15 characters</li>
                        <li className={`font-interMedium ${!passwordRule.length && 'text-[#667085]'} text-xs pb-[3px] ${passwordRule.length > 0 && (passwordRule[1] ? 'text-[#079455]' : 'text-[#D92D20]')}`}>
                            {'Must contain at least one lowercase letter'}
                        </li>
                        <li className={`font-interMedium ${!passwordRule.length && 'text-[#667085]'} text-xs pb-[3px] ${passwordRule.length > 0 && (passwordRule[2] ? 'text-[#079455]' : 'text-[#D92D20]')}`}>
                            {'Must contain at least one uppercase letter'}
                        </li>
                        <li className={`font-interMedium ${!passwordRule.length && 'text-[#667085]'} text-xs pb-[3px] ${passwordRule.length > 0 && (passwordRule[3] ? 'text-[#079455]' : 'text-[#D92D20]')}`}>{'Must contain one number'}</li>
                        <li className={`font-interMedium ${!passwordRule.length && 'text-[#667085]'} text-xs pb-[3px] ${passwordRule.length > 0 && (passwordRule[4] ? 'text-[#079455]' : 'text-[#D92D20]')}`}>{'Must contain one special character'}</li>
                        <li className={`font-interMedium ${!passwordRule.length && 'text-[#667085]'} text-xs ${passwordRule.length > 0 && (passwordRule[5] ? 'text-[#079455]' : 'text-[#D92D20]')}`}>{'Must not be an email address'}</li>
                    </ul>
                </div>
            )}
        </>
    );
};
