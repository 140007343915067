import axios from "axios";
import { CreatePromptPayload, UpdatePromptPayload } from "types/prompt.types";
import { LLM_BASE_URL } from "utils/constant";

const getLLMAxiosConfig = () => {
    return {}
    // const basicAuth = 'Basic ' + btoa(LLM_USERNAME + ':' + LLM_PASSWORD);
    // return {
    //     headers: {
    //         'Authorization': basicAuth,
    //         'Content-Type': 'application/json'
    //     }
    // }
}

export const searchPrompts = async (query?: string) => {
    try {
        return await axios.get(
            `${LLM_BASE_URL}/IPVigil/llm/prompt-template/search/v1${query ? '?query' + query : ''}`,
            getLLMAxiosConfig()
        );
    } catch (error) {
        throw error;
    }
}
export const createPrompt = async (payload: CreatePromptPayload) => {
    try {
        return await axios.post(
            `${LLM_BASE_URL}/IPVigil/llm/prompt-template/create/v1`,
            payload,
            getLLMAxiosConfig()
        );
    } catch (error) {
        throw error;
    }
}

export const updatePrompt = async (id: string, payload: UpdatePromptPayload) => {
    try {
        return await axios.put(
            `${LLM_BASE_URL}/IPVigil/llm/prompt-template/update/${id}/v1`,
            payload,
            getLLMAxiosConfig()
        );
    } catch (error) {
        throw error;
    }
}
