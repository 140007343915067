import allImgPaths from 'assets/images';

import AddSource from 'components/common/modals/AddSource';
import Toast from 'components/common/Toast';
import useAppState from 'context/useAppState';
import { usePagination } from 'hooks/paginate/usePagination';
import { ClaimsTableShimmerLoader } from 'pages/loaders';
import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getClaimChartById } from 'services/project';
import { formatDate } from 'utils/function';
import ClaimChartDownload from './ClaimChartDownload';
import ClaimChartDownloadLoader from 'pages/loaders/ClaimChartDownloadLoader';

function ClaimTable({ claimData, projectId, isClaimTableLoading, handleSort, sortConfig }: { claimData: any[], projectId: string, isClaimTableLoading: boolean, handleSort:any, sortConfig:any }) {
    const {
        showClaimChartDownload,
        isDownloadClicked,
        newData,
        imagesLoaded,
        setImagesLoaded,
        handleDownload,
        claimChartData,
        currentMessageIndex,
        loaderMessages
      } = useAppState('claimChartDownload');

    const claimColumns: any[] = [
        {
            name: 'Vijil Chart Name',
            ref: 'name',
            hide: false,
            position: 1,
        },
        {
            name: 'Created by',
            ref: 'createdBy',
            hide: false,
            position: 2,
        },
        {
            name: 'Last Update',
            ref: 'updatedAt',
            hide: false,
            position: 3,
        },
        {
            name: 'Actions',
            ref: 'action',
            hide: false,
            position: 4,
        },
    ];

    const [paginatedData, setPaginatedData] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [arrowUp, setArrowUp] = useState(false);


    const navigate = useNavigate();
    const totalItems = claimData.length;
    const itemsPerPage = 5;
    const { currentPage, totalPages, startItem, endItem, nextPage, prevPage, currentData } = usePagination({
        totalItems,
        itemsPerPage,
    });
    const createColumnClassName = (field: string) => {
        return 'bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
    }

    const handleSelectAll = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectAll(e.target.checked);
            if (e.target.checked) {
                console.log('hit all');

                // Select all items in the current page
                const currentPageIds = paginatedData.map((_, index) => index);
                setSelectedItems(currentPageIds);
            } else {
                // Deselect all items
                setSelectedItems([]);
            }
        },
        [paginatedData]
    );

    const handleSelectItem = useCallback(
        (index: number) => {
            setSelectedItems((prev) => {
                const newSelected = prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index];

                // Update selectAll state based on if all items in current page are selected
                setSelectAll(newSelected.length === paginatedData.length);

                return newSelected;
            });
        },
        [paginatedData]
    );

    const handlePrev = () => {
        prevPage();
        setPaginatedData(currentData(claimData));
    };
    const handleNext = () => {
        nextPage();
        setPaginatedData(currentData(claimData));
    };

    useEffect(() => {
        const newData = currentData(claimData);
        setPaginatedData(newData);
        setSelectedItems([]);
        setSelectAll(false);
    }, [currentPage]);


    const handleDownloadClick = async (claimChartId: string) => {
        try {
          const response = await getClaimChartById(claimChartId);
          const claimChartData = response.data;
          const APIData = claimChartData.claim_chart_data;
          handleDownload(APIData, claimChartData);
        } catch (error) {
          Toast.error("Error", "Failed to download document");
          console.error("Download error:", error);
        }
      };

    return (

        <div className='bg-white h-[calc(100vh_-_165px)] rounded-lg'>
            {isClaimTableLoading ? <ClaimsTableShimmerLoader /> :
                <>
                    {/* Tabs Head */}
                    <div className='flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1] rounded-tl-lg rounded-tr-lg'>
                        {claimColumns?.length > 0 && claimData.length > 0 &&
                            claimColumns.map((column: any, columnIndex: number) => (
                                <React.Fragment key={columnIndex}>
                                    {
                                        <>
                                            {/* ProjectName */}
                                            {column.ref === 'name' && (
                                                <div className='w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6'>
                                                    <div className='flex items-center gap-x-1 cursor-pointer'>
                                                        <span className='font-interMedium text-sm text-[#0A090B]'>
                                                            {column.name}
                                                        </span>
                                                        <img
                                                                onClick={() => handleSort('name', 'vijilChart')}
                                                                className={`w-4 cursor-pointer transform transition-transform duration-300 ${sortConfig['vijilChart']?.name === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                src={allImgPaths.arrowUp3}
                                                                alt=""
                                                            />
                                                    </div>
                                                </div>
                                            )}
                                            {/* Type */}
                                            {column.ref === 'createdBy' && (
                                                <div className='w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6'>
                                                    <div className='flex items-center justify-start gap-x-2 pt-0.5'>
                                                        <span className='cursor-pointer font-interMedium text-sm text-[#0A090B]' >
                                                            {column.name}
                                                        </span>
                                                        <img
                                                                onClick={() => handleSort('createdAt', 'vijilChart')}
                                                                className={`w-4 cursor-pointer transform transition-transform duration-300 ${sortConfig['vijilChart']?.createdAt === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                src={allImgPaths.arrowUp3}
                                                                alt=""
                                                            />
                                                    </div>
                                                </div>
                                            )}
                                            {/* Last Update */}
                                            {column.ref === 'updatedAt' && (
                                                <div className='w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6'>
                                                    <div className="flex items-center justify-start gap-x-2 pt-0.5">
                                                        <span className='cursor-pointer font-interMedium text-sm text-[#0A090B]'>
                                                            {column.name}
                                                        </span>
                                                        <img
                                                                onClick={() => handleSort('updatedAt', 'vijilChart')}
                                                                className={`w-4 cursor-pointer transform transition-transform duration-300 ${sortConfig['vijilChart']?.updatedAt === 'asc' ? 'rotate-0' : 'rotate-180'}`}
                                                                src={allImgPaths.arrowUp3}
                                                                alt=""
                                                            />
                                                    </div>
                                                </div>
                                            )}
                                            {/* Action */}
                                            {column.ref === 'action' &&
                                                <div className='w-[10%] flex flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 justify-center'>
                                                    <div className='font-interMedium text-sm text-[#0A090B] inline-block'>{column.name}</div>
                                                </div>
                                            }
                                        </>
                                    }
                                </React.Fragment>
                            ))}
                    </div>

                    {/* Body */}
                    {true && (
                        <div className={'max-h-[calc(100vh_-_205px)] overflow-y-auto'}>
                            {claimData.length > 0 &&
                                claimData.map((row: any, rowIndex: number) => (
                                    <React.Fragment key={rowIndex}>
                                        <div className={`flex`}>
                                            {claimColumns?.length > 0 &&
                                                claimColumns.map((column: any, columnIndex: number) => (
                                                    <React.Fragment key={columnIndex}>
                                                        {column.ref == 'name' && (
                                                            <div className={`w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2 pb-2 px-6 table-row !justify-start`}>
                                                                <Link to={`/project/${projectId}/claim-chart/${row['_id']}`}>
                                                                    <span className='font-interMedium text-sm pb-0.5 hover:text-[#113B98] text-[#1751D0]'>
                                                                        {row[column.ref]}
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        )}

                                                        {column.ref == 'createdBy' && (
                                                            <div className={`w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2 pb-2 px-6 table-row !justify-start`}>
                                                                <p className='text-sm text-[#4F4D55] font-interRegular'> {formatDate(row['createdAt'])} </p>
                                                                <p className='text-sm text-[#ADACB0] font-interRegular'> {row['created_by']['name']} </p>
                                                            </div>
                                                        )}

                                                        {column.ref == 'updatedAt' && (
                                                            <div className={`w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2 pb-2 px-6 table-row !justify-start`}>
                                                                <p className='text-sm text-[#4F4D55] font-interRegular'> {formatDate(row['updatedAt'])} </p>
                                                                <p className='text-sm text-[#ADACB0] font-interRegular'> {row['updated_by']['name']} </p>
                                                            </div>
                                                        )}

                                                        {column.ref === 'action' && (
                                                            <div className={`w-[10%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-2 px-6 flex items-center justify-center`}>
                                                                <img
                                                                    src={allImgPaths.downloadIcon2}
                                                                    alt="download icon"
                                                                    className="inline-block cursor-pointer on-hover-bg-grey w-7 p-1 rounded"
                                                                    onClick={() => handleDownloadClick(row['_id'])}
                                                                />

                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    </React.Fragment>
                                ))}
                            {claimData.length === 0 && <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                                <div className="text-center">
                                    <img className="mb-2.5 ml-auto mr-auto" src={allImgPaths.searchIcon3} alt="" />
                                    <p className="font-interSemiBold text-base text-[#101828]">No data found</p>
                                    <p className="font-interRegular text-sm text-[#475467]">Please add patents, files, URLs, publications to <br /> compare with your source patent</p>
                                </div>
                            </div>}
                        </div>
                    )}
                </>
            }

        {showClaimChartDownload && (
            <div 
            className="absolute top-[-9999px] left-[-9999px]"
            aria-hidden="true"
            id="hidden-download-container"
          >
            <ClaimChartDownload
              newData={newData}
              claimChartAPIData={claimChartData}
              imagesLoaded={imagesLoaded}
              setImagesLoaded={setImagesLoaded}
            />
          </div>
          )}

      {isDownloadClicked && <ClaimChartDownloadLoader loaderMessages={loaderMessages} currentMessageIndex={currentMessageIndex} />}
        </div>
    );
}
export default ClaimTable;
