import { useEffect, useRef, useState } from "react";
import bellIcon from "../../assets/images/bell-icon.svg";
import profileIcon from "../../assets/images/profile-icon.png";
import allImgPaths from "assets/images";
import useAppState from "context/useAppState";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import UserProfile from "./modal/UserProfile";
import { getUserProfile } from "services/user";
import UserProfile from "./modals/UserProfile";
// import ChangePassword from "./modal/ChangePassword";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import ChangePassword from "./modals/ChangePassword";
export interface Props {
  title?: string;
  count?: number;
  isChildEle?: boolean;
  type?: any;
}

const Header = ({
  title = "",
  count = 0,
  isChildEle = false,
  type = null,
}: Props) => {
  const [userProfile, setUserProfile] = useState<any>({});
  const [showUserProfile, setShowUserProfile] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  // const { setHasRetrievedTeams } = useAppState('selectTeam');
  const navigate = useNavigate();

  const [fetchNewData, setFetchNewData] = useState(false);

  const [isShow, setisShow] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const { headerData } = useAppState("vijilize");

  const handleProfileMenu = () => {
    setisShow(!isShow);
  };
  const handleNotificationMenu = () => {
    setIsShowNotification(!isShowNotification);
  };
  const handleLogout = () => {
    localStorage.clear();
    // setHasRetrievedTeams(false);
    navigate("/login");
  };

  // useEffect(() => {
  // 	const userName = JSON.parse(localStorage.getItem('userData') as string)
  // 	setName(userName.name);

  // 	const userData = {
  // 		name: userName?.name,
  // 		profile_image: userName?.profile_image,
  // 		email: localStorage.getItem('userEmail') || ''
  // 	}
  // 	setUserProfile(userData)
  // }, [showUserProfile])

  useEffect(() => {
    fetchUserData();
  }, [fetchNewData]);

  const fetchUserData = async () => {
    const { data } = await getUserProfile();

    const userData = {
      name: data?.name,
      profile_image: data?.profile_image,
      email: data?.email,
    };

    setUserProfile(userData);

    let updatedUserData = JSON.parse(
      localStorage.getItem("userData") as string
    );
    updatedUserData = {
      ...updatedUserData,
      name: data.name,
      profile_image: data.profile_image,
    };
    localStorage.setItem("userData", JSON.stringify(updatedUserData));

    setFetchNewData(false);
  };

  const notificationData = [
    {
      type: "userNotification",
      img: "assets/images/default-user.png",
      notification: "Orlando Diggs",
      msg: "Invited Alisa Hester to the team.",
      time: "1 min ago",
      notStatus: true,
      attachment: false,
    },
  ];

  const location = useLocation();

  const breadcrumbItems: any[] = [];

  if (isChildEle) {
    if (headerData) {
      const {
        projectDetail,
        claimAnalysis,
        vijilize,
        claimChart,
        portfolioDetail,
      } = headerData;
      // breadcrumbItems.push({ name: 'Home', url: '/' });
      if (type === "project") {
        if (projectDetail && projectDetail?.title && projectDetail?.id) {
          breadcrumbItems.push({
            name: `Project / ${projectDetail?.title} `,
            url: `/project/${projectDetail?.id}/find-covered-product`,
          });
        }
        if (vijilize && vijilize?.title && vijilize?.id) {
          breadcrumbItems.push({
            name: `/ Vijilize / ${vijilize?.title}`,
            url: `/project/${projectDetail?.id}/vijilize/${vijilize?.id}`,
          });
        } else if (claimAnalysis && claimAnalysis?.title && claimAnalysis?.id) {
          breadcrumbItems.push({
            name: `/ Claim Analysis / ${claimAnalysis?.title}`,
            url: `/project/${projectDetail?.id}/patent/${claimAnalysis?.id}/claim-analysis`,
          });
        } else if (claimChart && claimChart?.title && claimChart?.id) {
          breadcrumbItems.push({
            name: `/ Vijil Chart / ${claimChart?.title}`,
            url: `/project/${projectDetail?.id}/claim-chart/${claimChart?.id}`,
          });
        }
      } else if (type === "portfolio" && portfolioDetail) {
        breadcrumbItems.push({
          name: `Portfolio / ${portfolioDetail?.title} `,
          url: `/portfolio/${portfolioDetail?.id}/details`,
        });
      }
    }
  }

  const handleImageError = (e: any) => {
    e.target.src = ""; //allImgPaths.defaultProfileImage
  };

  const profileRef = useRef(null);
  const notiRef = useRef(null);
  useOnClickOutside(profileRef, () => setisShow(false));
  useOnClickOutside(notiRef, () => setIsShowNotification(false));

  return (
    <div
      id="mah"
      className="header-bar flex items-center justify-between px-6 py-3.5 border-b border-[#E6E6E6]"
    >
      {type && type === "settings" && (
        <span className="text-2xl text-[#091E42] font-interSemiBold">
          Welcome, {userProfile.name}
        </span>
      )}
      {type != "settings" && (
        <>
          {isChildEle && (
            <div className="flex justify-start ">
              <ol className="flex items-center cursor-default">
                {/* <li className='px-1'>
                            <Link to='/' className='text-[#7F7D83] text-sm font-interMedium flex items-center gap-x-1 hover:text-[#113B98] on-hover-blue-child-img'>
                                <img className='' src={allImgPaths.breadCrumbHomeIcon} alt='' />
                                Home
                            </Link>
                        </li> */}
                {breadcrumbItems.map((item, index) => (
                  <li key={item.url} className="flex items-center px-0.5">
                    <Link
                      to={item.url}
                      className={`text-[#7F7D83] hover:text-[#113B98] text-sm font-interMedium ${
                        index === 0 &&
                        "flex items-center gap-x-1 on-hover-blue-child-img"
                      }`}
                    >
                      {index === 0 && (
                        <img
                          className=""
                          src={allImgPaths.boxIconGrey}
                          alt=""
                        />
                      )}
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ol>
            </div>
          )}
          {!isChildEle && (
            <div className="">
              <div className="flex items-center gap-x-1">
                <img src={allImgPaths.breadCrumbHomeIcon} alt="" />
                <span className="text-[#7F7D83] font-interMedium text-sm">
                  {title}
                </span>
              </div>
              <div className="flex gap-x-2 items-center">
                <h1 className="text-[#0A090B] font-interSemiBold text-xl">
                  {title}
                </h1>

                <span className="bg-[#EBF0FB] text-[#113B98] rounded-lg py-1 px-2 text-sm font-interMedium">
                  {count} {title}
                </span>
              </div>
            </div>
          )}
        </>
      )}

      <div className="flex items-center gap-x-6">
        {/* <div ref={notiRef} className="relative">
          <img
            onClick={handleNotificationMenu}
            className={`inline-block cursor-pointer on-hover-bg-grey w-7 h-7 p-1 rounded ${
              isShowNotification === false ? "" : "on-hover-bg-grey"
            }`}
            src={bellIcon}
            alt="bell icon"
          />

          <div
            className={`${
              isShowNotification === false ? "hidden" : "inline-block"
            } absolute bg-white right-0 top-full shadow-customShadow8 rounded-lg z-30 overflow-hidden`}
          >
            <div className="w-[400px] rounded-lg">
              <div className="px-6 pt-5 mb-6 flex justify-between">
                <div className="">
                  <p className="text-xl font-interSemiBold text-[#101828] pb-0.5">
                    Notifications
                  </p>
                  <p className="text-[#475467] text-sm">
                    Keep track of team and project updates.
                  </p>
                </div>
                <img
                  onClick={handleNotificationMenu}
                  className="cursor-pointer relative -top-3 on-hover-bg-grey w-6 h-6 p-1.5 rounded"
                  src={allImgPaths.closeIcon}
                  alt=""
                />
              </div>

              <div className="border-b border-[#EAECF0]">
                {notificationData.map((data, ind) => (
                  <div className="px-6 flex justify-between mb-6" key={ind}>
                    <div className="flex gap-x-3">
                      {data.type === "generalNotification" && (
                        <img
                          className="w-12 h-12 rounded-full"
                          src={data.img}
                          alt=""
                        />
                      )}

                      {data.type === "userNotification" && (
                        <div className="relative">
                          <img
                            className="w-12 h-12 rounded-full"
                            src=""
                            alt=""
                          />
                          <span className="w-3.5 h-3.5 border-2 border-white rounded-full bg-[#17B26A] inline-block absolute bottom-2 -right-0.5"></span>
                        </div>
                      )}
                      <div>
                        <div className="mb-3">
                          <p className="text-sm font-interMedium text-[#344054] pb-0.5">
                            {data.notification}{" "}
                            <span className="text-[#475467] text-sm">
                              {data.time}
                            </span>
                          </p>
                          <p className="text-sm text-[#475467]">{data.msg}</p>
                        </div>
                      
                      </div>
                    </div>
                    <span
                      className={`w-2.5 h-2.5 rounded-full ${
                        data.notStatus ? "bg-[#17B26A]" : "bg-[#ddd]"
                      } ] inline-block relative top-1`}
                    ></span>
                  </div>
                ))}
              </div>

              <div className="py-6 flex justify-center items-center">
                <div className="on-hover-blue cursor-pointer w-fit flex gap-x-1.5">
                  <img src={allImgPaths.settingsIcon} alt="" />
                  <span className="text-sm font-interSemiBold text-[#475467]">
                    Settings
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div ref={profileRef} className="relative flex-shrink-0">
          <img
            onError={handleImageError}
            onClick={handleProfileMenu}
            className={`inline-block w-10 h-10 rounded-full cursor-pointer on-hover-border-shadow ${
              isShow === false ? "" : "on-hover-border-shadow"
            }`}
            src={userProfile?.profile_image || allImgPaths.defaultProfile}
            alt="profile"
          />

          <div
            className={`${
              isShow === false ? "hidden" : "inline-block"
            } absolute bg-white right-0 top-full shadow-customShadow8 rounded-lg z-30 overflow-hidden`}
          >
            <div className="w-60 rounded-lg">
              <div className="px-4 py-3 flex gap-x-3 border-b border-[#EAECF0]">
                <div className="relative flex-shrink-0">
                  <img
                    className="inline-block w-10 h-10 rounded-full cursor-pointer"
                    src={
                      userProfile?.profile_image || allImgPaths.defaultProfile
                    }
                    alt=""
                  />
                  <span className="w-3.5 h-3.5 border-2 border-white rounded-full bg-[#17B26A] inline-block absolute -bottom-px -right-px"></span>
                </div>
                <div className="">
                  <h4 className="font-interSemiBold text-sm text-[#344054]">
                    {userProfile?.name}
                  </h4>
                  <p
                    title={userProfile?.email}
                    className="text-[#475467] text-sm truncate max-w-40"
                  >
                    {userProfile?.email}
                  </p>
                </div>
              </div>

              <div className="py-3 px-4 border-b border-[#EAECF0]">
                <div className="flex items-center gap-x-2 cursor-pointer w-fit on-hover-blue">
                  {/* <img className="inline-block w-7 h-7 rounded-full" src={userProfile?.profile_image || allImgPaths.defaultProfileImage} alt="" /> */}
                  <img
                    className="w-4 h-4"
                    src={allImgPaths.profileImgNew}
                    alt="profile img"
                  />
                  <h4
                    onClick={() => {
                      setShowUserProfile(true);
                    }}
                    className="text-sm font-interMedium text-[#344054] on-hover-blue cursor-pointer"
                  >
                    Profile
                  </h4>
                </div>
              </div>

              <div className="">
                {/* <div className="bg-[#f9fafb] pt-3">
									<h3 className="text-[#98A2B3] text-sm pb-4 pl-3.5">SWITCH ACCOUNTS</h3>
									<div className="flex gap-x-2 border-b border-[#dcdcdc] pb-3 first:pt-0 pl-4 pr-3">
										<span className="uppercase text-sm text-[#363F72] font-interSemiBold border border-[#D6BBFB] bg-[#F9F5FF] rounded-full w-8 h-8 flex items-center justify-center -mt-1.5">OR</span>
										<div className="">
											<h6 className="text-sm font-interMedium text-[#344054] pb-1.5">Olivia Rhye</h6>
											<p className="text-xs text-[#98A2B3]">Olivia@eventsprite.com</p>
										</div>
									</div>
								</div>


								<div className="flex gap-x-2 pt-5 pb-5 first:pt-0 pl-4 pr-3 bg-[#f9fafb]">
									<span className="uppercase text-sm text-[#E62E05] font-interSemiBold border border-[#F7B27A] bg-[#FEF6EE] rounded-full w-8 h-8 flex items-center justify-center -mt-1.5">OR</span>
									<div className="">
										<h6 className="text-sm font-interMedium text-[#344054] pb-1.5">Olivia Rhye</h6>
										<p className="text-xs text-[#98A2B3]">Olivia@eventsprite.com</p>
									</div>
								</div> */}

                {/* <div className="px-4 border-b border-[#EAECF0] pb-4 bg-[#f9fafb]">
									<div className="flex gap-x-2 cursor-pointer w-fit on-hover-blue">
										<img src={allImgPaths.plusIcon6} alt="" />
										<h4 className="font-interMedium text-sm text-[#344054]">Add account</h4>
									</div>
								</div> */}

                <div className="py-3 px-4 border-b border-[#EAECF0]">
                  <div className="flex gap-x-2 cursor-pointer w-fit on-hover-blue">
                    <img
                      className="w-4 h-4"
                      src={allImgPaths.EditIcon}
                      alt=""
                    />
                    <h4
                      onClick={() => {
                        setShowChangePassword(true);
                      }}
                      className="font-interMedium text-sm text-[#344054]"
                    >
                      Change Password
                    </h4>
                  </div>
                </div>

                <div className="py-3 px-4 flex gap-x-3 rounded-bl-xl rounded-br-xl overflow-hidden">
                  <div
                    className="flex gap-x-2 cursor-pointer w-fit on-hover-blue"
                    onClick={handleLogout}
                  >
                    <img src={allImgPaths.logOutIcon} alt="" />
                    <h4 className="font-interMedium text-sm text-[#344054]">
                      Logout
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TODO:  */}
      {showUserProfile && (
        <UserProfile
          isShow={showUserProfile}
          setIsShow={setShowUserProfile}
          setFetchNewData={setFetchNewData}
        />
      )}

      {showChangePassword && (
        <ChangePassword
          isShow={showChangePassword}
          setIsShow={setShowChangePassword}
          setFetchNewData={setFetchNewData}
        />
      )}
    </div>
  );
};

export default Header;
