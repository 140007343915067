import allImgPaths from "assets/images";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import React, { useState } from "react";

const AddFileWhite = ({
  addDropdownRef,
  setOpenFileModal,
  setOpenPatentModal = {},
  type,
  setOpenUrlModal = {},
}: {
  addDropdownRef: React.MutableRefObject<null>;
  setOpenFileModal: any;
  setOpenPatentModal?: any;
  type: string;
  setOpenUrlModal?: any;
}) => {
  const [isShowAddDropdown, setisShowAddDropdown] = useState<any>(false);

  useOnClickOutside(addDropdownRef, () => setisShowAddDropdown(false));

  return (
    <div ref={addDropdownRef} className="relative">
      <div
        onClick={() => setisShowAddDropdown(!isShowAddDropdown)}
        className={`flex items-center gap-x-2 border rounded-md px-2 py-2 cursor-pointer hover:border-[#1751D0] w-max ${
          isShowAddDropdown ? "border-[#1751D0]" : "border-[#E6E6E6]"
        }`}
      >
        <img src={allImgPaths.plusIcon2} alt="" />

        <span className="text-sm font-interMedium text-[#4F4D55]">Add</span>
        <img
          className={`-ml-1 transform ${
            isShowAddDropdown ? "rotate-180" : "rotate-0"
          }`}
          src={allImgPaths.downIcon3}
          alt=""
        />
      </div>
      <div
        className={`absolute bg-white w-36 top-full left-1/2 -translate-x-1/2 shadow-customShadow14 rounded-lg mt-1 z-20 block ${
          isShowAddDropdown ? "block" : "hidden"
        }`}
      >
        <ul>
          <li className="cursor-default pointer-events-none opacity-50 flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] text-sm font-interMedium text-[#0A090B]">
            With patent #
          </li>
          <li className="cursor-default pointer-events-none opacity-50 flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] text-sm font-interMedium text-[#0A090B]">
            From Portfolio
          </li>
          <li
            onClick={() => setOpenFileModal(true)}
            className="flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]"
          >
            External {type === "source" ? "Upload" : "File"}
          </li>
          {type === "compare" && (
            <>
              <li
                onClick={() => setOpenPatentModal(true)}
                className="flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]"
              >
                External Patent
              </li>

              <li
                onClick={() => setOpenUrlModal(true)}
                className="flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]"
              >
                Add URL
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AddFileWhite;
