import React, { Dispatch, SetStateAction, useMemo, useState } from "react";

import { Modal } from "./Modal";

import { HeaderModal } from "./Header";
import { FooterModal } from "./Footer";

import folderPlus from "assets/images/folder-plus-blue.svg";
import close from "assets/images/close-icon.svg";

import { Body } from "./Body";
import allImgPaths from "assets/images";
import Toast from "../Toast";
import { createPortfolio } from "services/portfolio";
import { InputTextField } from "components/InputField";
import Textarea from "../Textarea";

interface Iprops {
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  setToast: any;
  setRefreshData: Dispatch<SetStateAction<boolean>>;
}

const CreatePortfolioModal = ({
  isShow,
  setIsShow,
  setToast,
  setRefreshData,
}: Iprops) => {
  const [toggle, setToggle] = useState(0);
  const [option, setOption] = useState("prior art");
  const [isOpen, setIsOpen] = useState(false);
  const [portfolioName, setPortfolioName] = useState("");
  const [portfolioDescription, setPortfolioDescription] = useState("");

  const selectedTeam: any = JSON.parse(
    localStorage.getItem("selectedTeam") as string
  );

  // Validation check for portfolio name
  const isPortfolioNameValid = useMemo(() => {
    return portfolioName.trim().length > 0;
  }, [portfolioName]);

  const onCloseModal = () => {
    setIsShow(false);
  };

  const handlePortfolioNameChange = (e: any) => {
    setPortfolioName(e.target.value);
  };

  const handlePortfolioDescriptionChange = (e: any) => {
    setPortfolioDescription(e.target.value);

    let getTextArea = document.getElementById("portfolio-desc");
    if (getTextArea) {
      if (e.target.value.length === 0) {
        e.target.style.height = "24px";
      } else {
        e.target.style.height = "auto";
        e.target.style.height = e.target.scrollHeight + "px";
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();

    if (!isPortfolioNameValid) {
      return;
    }

    const payload = {
      name: portfolioName.trim(),
      description: portfolioDescription.trim(),
      team_id: selectedTeam.currentTeamId,
    };

    const { status } = await createPortfolio(payload);

    setPortfolioName("");
    setPortfolioDescription("");
    setToggle(0);
    setIsShow(false);
    setRefreshData((prev) => !prev);
    Toast.success("Success", "Portfolio created successfully");
  };

  return (
    <>
      <Modal className={"modal-wrapper max-w-[480px]"} isShow={isShow}>
        <form onSubmit={handleSubmit}>
          <HeaderModal
            title={"test"}
            isShowHeader={true}
            className="modal-header pt-6 px-6"
          >
            <div className="flex items-center justify-between pb-7">
              <img className="h-10 w-10" src={folderPlus} alt="remove" />
              {/* Close Icon */}
              <img
                className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4"
                id="modal-toggle"
                src={close}
                onClick={onCloseModal}
                alt="close icon"
              />
            </div>

            <h2 className="text-base text-[#0A090B] font-interSemiBold pb-1">
              New Portfolio
            </h2>
            <p className="text-sm text-[#4F4D55] font-interRegular">
              Enter the details of the portfolio.
            </p>
          </HeaderModal>

          <Body title="" isShowBody={true} className="modal-body px-6 pt-7">
            <div className="pb-5">
              <label
                htmlFor=""
                className="text-[#2D2B32] text-sm font-interRegular pb-2 flex"
              >
                Portfolio Name
                <img src={allImgPaths.questionCircle} alt="help" />
              </label>
              <InputTextField
                className="placeholder:text-[#7F7D83] placeholder:text-[15px] text-base text-[#344054] w-full"
                name={"name"}
                placeholder={"Enter Portfolio Name"}
                onChange={handlePortfolioNameChange}
                value={portfolioName}
              />
            </div>
            <div className="flex flex-col gap-y-[5px] gap-x-6 ">
              <div className="flex  flex-col gap-y-[5px]">
                <div className="flex justify-between">
                  <label
                    htmlFor=""
                    className="text-[#2D2B32] text-sm font-interRegular flex"
                  >
                    Description
                    <img src={allImgPaths.questionCircle} alt="help" />
                  </label>
                  <label
                    htmlFor=""
                    className="font-interRegular text-xs text-[#7F7D83]"
                  >
                    Optional
                  </label>
                </div>
                <Textarea
                  name="portfolio-desc"
                  placeholder="Enter description"
                  value={portfolioDescription}
                  onChange={handlePortfolioDescriptionChange}
                  id="portfolio-desc"
                />
              </div>
            </div>
          </Body>

          <FooterModal
            title=""
            isShowFooter={true}
            className="modal-footer pt-5 px-6 pb-6"
          >
            <div className="flex items-center gap-x-3 justify-center">
              <button
                onClick={onCloseModal}
                className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]"
              >
                Cancel
              </button>
              <button
                className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${
                  isPortfolioNameValid ? "" : "button-disabled"
                }`}
                type="submit"
                disabled={!isPortfolioNameValid}
              >
                Create
              </button>
            </div>
          </FooterModal>
        </form>
      </Modal>
    </>
  );
};

export default CreatePortfolioModal;
