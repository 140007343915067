import allImgPaths from "assets/images";
import CustomSelect from "./common/CustomSelect";
import { useEffect, useState } from "react";

const CitationsSidebar = ({
  isShowSideBarCitations,
  setIsShowSideBarCitations,
  citationsData,
  selectedCitation,
  setSelectedCitation,
}: any) => {
  const [activeCitation, setActiveCitation] = useState<any>(0);
  const [citationAccordionData, setCitationAccordionData] = useState<any[]>([]);

  const citationOptions = citationsData.map((citation: any) => ({
    value: citation.number,
    label: `${citation.number}`,
  }));
  const handleCitationClick = (index: any) => {
    index === 0 && setActiveCitation(index);
  };

  const handleCitationChange = (selectedOption: any) => {
    setSelectedCitation(selectedOption);
  };

  const formatPrimaryHeading = (data: any) => {
    const parts = [];

    // Page number formatting
    if (data.starting_page_number && data.ending_page_number) {
      const pageLabel =
        data.starting_page_number === data.ending_page_number
          ? `Page ${data.starting_page_number}`
          : `Pages ${data.starting_page_number}-${data.ending_page_number}`;
      parts.push(pageLabel);
    }

    // Column number formatting
    if (data.starting_column_number && data.ending_column_number) {
      const columnLabel =
        data.starting_column_number === data.ending_column_number
          ? `Column ${data.starting_column_number}`
          : `Columns ${data.starting_column_number}-${data.ending_column_number}`;
      parts.push(columnLabel);
    }

    // Line number formatting
    if (data.starting_line_number && data.ending_line_number) {
      const lineLabel =
        data.starting_line_number === data.ending_line_number
          ? `Line ${data.starting_line_number}`
          : `Lines ${data.starting_line_number}-${data.ending_line_number}`;
      parts.push(lineLabel);
    }

    return parts.length > 0
      ? `Specification at ${parts.join(", ")}`
      : data.file_name;
  };

  const formatFileUrl = (data: any) => {
    return data.starting_page_number
      ? `${data.file_url}#page=${data.starting_page_number}`
      : data.file_url;
  };

  useEffect(() => {
    if (!selectedCitation) return;

    const selectedCitationData =
      selectedCitation?.value &&
      citationsData.find(
        (citation: any) => citation.number === Number(selectedCitation.value)
      );

    if (selectedCitationData) {
      const primary_heading = formatPrimaryHeading(selectedCitationData);
      const file_url = formatFileUrl(selectedCitationData);
      setCitationAccordionData([
        {
          label: "Specification",
          primary_heading,
          desc: selectedCitationData.description,
          file_url,
        },
        {
          label: "Prosecutional",
        },
        {
          label: "Precedential",
        },
      ]);
    } else {
      setCitationAccordionData([]);
    }
  }, [selectedCitation, citationsData]);

  return (
    <div
      className={`w-full h-full fixed bg-[#07031154] z-[100] ${
        isShowSideBarCitations ? "right-0" : "right-full"
      }`}
    >
      <div
        onClick={() => setIsShowSideBarCitations(false)}
        className="h-full w-[calc(100%_-_360px)]"
      ></div>
      <div
        className={`border-l border-[#E4E7EC] w-[360px] h-screen fixed z-50 bg-white top-0 shadow-customShadow15 ${
          isShowSideBarCitations ? "right-0" : "right-full"
        }`}
      >
        <div className="pb-4 px-5 pt-4">
          <div className="pb-0.5 flex items-center justify-between">
            <span className="text-lg font-interSemiBold text-[#101828] inline-block ">
              Citation
            </span>
            <img
              onClick={() => setIsShowSideBarCitations(false)}
              className="cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1"
              src={allImgPaths.closeIcon}
              alt=""
            />
          </div>

          <p className="text-[#475467] text-sm ">
            Reference to the source file
          </p>
        </div>

        <div className="px-5">
          <p className="text-sm font-interMedium text-[#2D2B32] pb-2">
            Viewing for Citation #{" "}
          </p>
          <div className="modal-select-box fete-settings-modals mb-6">
            <CustomSelect
              options={citationOptions}
              onChange={handleCitationChange}
              extendedOption={false}
              selectedValue={selectedCitation}
              // defaultValue={citationOptions.length > 0 && citationOptions[0]}
            />
          </div>
        </div>

        <div className="overflow-y-auto h-[calc(100vh_-_197px)] px-5">
          {citationAccordionData.map((item, index) => (
            <div
              className={`mb-2 last:mb-0 ${
                index === activeCitation ? "" : "accordion-hide"
              }`}
              key={index}
            >
              <div
                className="accordion-heading bg-[#F2F5FF] rounded-sm py-2 pl-2 pr-3 flex items-center justify-between cursor-pointer"
                onClick={() => handleCitationClick(index)}
              >
                <span className="text-sm font-interMedium text-[#101828]">
                  {item.label}
                </span>
                <img
                  className="accordion-arrow cursor-pointer hover:bg-[#f2f4f7] rounded w-5"
                  src={allImgPaths.dropdownArrow}
                  alt=""
                />
              </div>
              <div className="accordion-content px-2">
                <p
                  className="text-sm font-interMedium text-[#1A75FF] pb-2.5 pt-2.5 cursor-pointer hover:text-blue-700 hover:underline "
                  onClick={() => window.open(item.file_url, "_blank")}
                >
                  {item.primary_heading}
                </p>
                <p className="text-sm font-interRegular text-[#000000] pb-2">
                  {item.desc}
                </p>
                {/* <img className='mx-auto' src={allImgPaths.dummyImg} alt="" /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CitationsSidebar;
