import { ProjectSettingPayload } from "types/project.types";
import { axiosInstance } from "./axios";

export const fetchProjectList = async (
  page: number,
  limit: number,
  team_id: string,
  search: string
) => {
  try {
    // &team_id=${team_id}
    return await axiosInstance.get(
      `project/list?page=${page}&perPage=${limit}&team_id=${team_id}&search=${search}`
    );
  } catch (error) {
    throw error;
  }
};

export const addInviteCollaborator = async (id: string, data: any) => {
  try {
    return await axiosInstance.post(`/project/${id}/invite`, data);
  } catch (error) {
    throw error;
  }
};

export const addProject = async (data: any) => {
  try {
    return await axiosInstance.post(`/project/`, data);
  } catch (error) {
    throw error;
  }
};

export const createPatent = async (data: any) => {
  try {
    return await axiosInstance.post(`/patent/create`, data);
  } catch (error) {
    throw error;
  }
};

export const getPatentDetails = async (patentId: string) => {
  try {
    return await axiosInstance.get(`/patent/${patentId}`);
  } catch (error) {
    throw error;
  }
};

export const getPatentNameById = async (id: string) => {
  try {
    return await axiosInstance.get(`/patent/patentName/${id}`);
  } catch (error) {
    throw error;
  }
};
export const getPatentVijilizedDetails = async (compareId: string) => {
  try {
    return await axiosInstance.post(`/patent/vijilize-details`, {
      compare_id: compareId,
    });
  } catch (error) {
    throw error;
  }
};

// to get presign url to upload file
export const createPatentPresignedUrl = async (data: any) => {
  try {
    return await axiosInstance.post(
      `/patent/create-patent-presigned-url`,
      data
    );
  } catch (error) {
    throw error;
  }
};

export const uploadFileToS3 = async (imageData: any) => {
  try {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": imageData.type,
      },
      body: imageData.file,
    };

    const response = await fetch(imageData.url, requestOptions);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return response;
  } catch (error) {
    console.log("error while uploading to s3 =>", error);
    throw error;
  }
};

export const s3PutUrlWithProgress = async (
  imageData: any,
  onProgress?: (percentage: number) => void
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // Upload progress event
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable && onProgress) {
        const percentage = Math.round((event.loaded / event.total) * 100);
        onProgress(percentage);
      }
    };

    // Load completion event
    xhr.onload = () => {
      // Create a response object similar to fetch Response
      const response = {
        ok: xhr.status >= 200 && xhr.status < 300,
        status: xhr.status,
        statusText: xhr.statusText,
        headers: xhr
          .getAllResponseHeaders()
          .split("\r\n")
          .reduce((acc: any, current) => {
            const [name, value] = current.split(": ");
            if (name) acc[name.toLowerCase()] = value;
            return acc;
          }, {}),
        body: xhr.response,
        text: () => Promise.resolve(xhr.responseText),
        json: () => Promise.resolve(JSON.parse(xhr.responseText)),
        xhr: xhr, // Include original XHR object if needed
      };

      if (response.ok) {
        resolve(response);
      } else {
        reject(new Error(`Upload failed with status: ${xhr.status}`));
      }
    };

    // Error event
    xhr.onerror = () => {
      reject(new Error("Network error occurred during upload"));
    };

    try {
      // Open and send the request
      xhr.open("PUT", imageData.url);
      xhr.setRequestHeader("Content-Type", imageData.type);
      xhr.send(imageData.file);
    } catch (error) {
      console.log("error while uploading to s3 =>", error);
      reject(error);
    }
  });
};

export const patentUploadSuccess = async (data: any) => {
  try {
    return await axiosInstance.post(`/patent/patent-upload-success`, data);
  } catch (error) {
    throw error;
  }
};

// call after s3 upload url
export const patentUploadStatus = async (payload: any) => {
  try {
    return await axiosInstance.post(`/patent/patent-upload-status`, payload);
  } catch (error) {
    throw error;
  }
};

// get project details
export const getProjectDetails = async (projectId: string, search: string) => {
  try {
    return await axiosInstance.get(`/project/${projectId}?search=${search}`);
  } catch (error) {
    throw error;
  }
};

export const deleteProject = async (projectId: any) => {
  try {
    return await axiosInstance.delete(`/project/${projectId}`);
  } catch (error) {
    throw error;
  }
};
export const editProject = async (projectId: string, payload: any) => {
  try {
    return await axiosInstance.put(`/project/${projectId}`, payload);
  } catch (error) {
    throw error;
  }
};
export const deletePatentById = async (patentId: string) => {
  try {
    return await axiosInstance.delete(`/patent/${patentId}`);
  } catch (error) {
    throw error;
  }
};

// vijilize patent
export const startVijilize = async (payload: any) => {
  try {
    return await axiosInstance.post(`/patent/vijilize`, payload);
  } catch (error) {
    throw error;
  }
};

export const getVijilization = async (projectId: string, search?: string) => {
  try {
    return await axiosInstance.get(
      `/project/vijilizations/${projectId}?search=${search}`
    );
  } catch (error) {
    throw error;
  }
};

export const getClaimChartByProjectId = async (
  projectId: string,
  search?: string
) => {
  try {
    let url = `/project/claim-charts/${projectId}`;
    if (search) {
      url = `${url}?search=${search}`;
    }
    return await axiosInstance.get(url);
  } catch (error) {
    throw error;
  }
};

export const getClaimChartById = async (claimChartId: string) => {
  try {
    return await axiosInstance.get(`/project/claim-chart/${claimChartId}`);
  } catch (error) {
    throw error;
  }
};

export const createNewClaimChart = async (payload: any) => {
  try {
    return await axiosInstance.post(`/project/claim-chart`, payload);
  } catch (error) {
    throw error;
  }
};
export const createToExistingClaimChart = async (
  existingClaimChartId: string,
  payload: any
) => {
  try {
    return await axiosInstance.post(
      `/project/claim-chart/${existingClaimChartId}/add`,
      payload
    );
  } catch (error) {
    throw error;
  }
};

export const getInviteTeamMemberList = async (
  projectId: string,
  teamId: string
) => {
  try {
    return await axiosInstance.get(
      `/project/${projectId}/frequent-team-members/${teamId}`
    );
  } catch (error) {
    throw error;
  }
};

export const getProjectInviteRoles = async () => {
  try {
    return await axiosInstance.get(`/project/roles`);
  } catch (error) {
    throw error;
  }
};

export const getCollaborators = async (id: any, searchTerm: string) => {
  try {
    return await axiosInstance.post(`/project/${id}/collaborators`, {
      search: searchTerm,
    });
  } catch (error) {
    throw error;
  }
};

export const updateProjectSettings = async (
  projectId: string,
  projectSettingPayload: ProjectSettingPayload
) => {
  try {
    return await axiosInstance.put(
      `/project/${projectId}/settings`,
      projectSettingPayload
    );
  } catch (error) {
    throw error;
  }
};

export const leaveUserService = async (
  id: string | undefined,
  payload: any
) => {
  try {
    return await axiosInstance.put(`project/${id}/leave`, payload);
  } catch (error) {
    throw error;
  }
};

export const removeUserService = async (
  id: string | undefined,
  payload: any
) => {
  try {
    return await axiosInstance.put(`project/${id}/remove-user`, payload);
  } catch (error) {
    throw error;
  }
};

export const transferUserService = async (
  id: string | undefined,
  payload: any
) => {
  try {
    return await axiosInstance.put(`project/${id}/transfer-ownership`, payload);
  } catch (error) {
    throw error;
  }
};

export const getUserProjects = async (teamId: string) => {
  try {
    return await axiosInstance.get(`/project/user-projects?team_id=${teamId}`);
  } catch (error) {
    throw error;
  }
};

export const createUrl = async (data: any) => {
  try {
    return await axiosInstance.post(`/project/url/`, data);
  } catch (error) {
    throw error;
  }
};

export const updatePatentStatus = async (patentId: string) => {
  try {
    return await axiosInstance.post(`/patent/update-status/${patentId}`);
  } catch (error) {
    throw error;
  }
};

export const deleteUrlById = async (urlId: any) => {
  try {
    return await axiosInstance.delete(`/project/url/${urlId}`);
  } catch (error) {
    throw error;
  }
};

export const getPatentsStatusByProjectId = async (projectId: any) => {
  try {
    return await axiosInstance.get(`/project/patents-status/${projectId}`);
  } catch (error) {
    throw error;
  }
};
