import React, { useEffect, useState } from "react";
import Header from "components/common/Header";
import Sidebar from "components/common/Sidebar";
import { InputTextField } from "components/InputField";
import allImgPaths from "assets/images";
// import Footer from "components/Footer";
import useAppState from "context/useAppState";
import AddPrompt from "./Add";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { updatePromptSchema } from "schema/validation";


const Prompts = () => {
	const [showAddPrompt, setshowAddPrompt] = useState(false);
	const [currentSelectedPrompt, setCurrentSelectedPrompt] = useState<any>({});
	const [selectedPromptIndex, setSelectedPromptIndex] = useState<number | null>(null);
	const [newPrompt, setNewPrompt] = useState('');
    const { isPromptCallApi, promptList, getPrompts, savePromptDetails, updatePromptApiFlag } = useAppState('promptHook');
	const [updateStatus, setUpdateStatus] = useState<string>("");

	const {
		setValue,
		handleSubmit,
		formState: { errors },
        clearErrors,
        reset,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			newPrompt: ""
		},
		resolver: yupResolver(updatePromptSchema),
	});

    useEffect(() => {
        if(isPromptCallApi){
            getPrompts()
        }
    }, [isPromptCallApi])

	const handlePromptClick = (prompt: any,index:number) => {
		setCurrentSelectedPrompt(prompt);
		setSelectedPromptIndex(index);
	};

	const handlePromptChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setNewPrompt(newValue);
        setValue('newPrompt', newValue);
        clearErrors('newPrompt');
    };

	const handleClearForm = () => {
        reset(); // Reset the form values
        setNewPrompt("");
    };

	const onSubmit = async (data:any) => {
        await savePromptDetails(currentSelectedPrompt?._id, { prompt: data.newPrompt })
        handleClearForm();
		updatePromptApiFlag(true);
		setCurrentSelectedPrompt({...currentSelectedPrompt, prompt: data.newPrompt })
		setUpdateStatus("Prompt has been updated successfully!");

        setTimeout(() => {
            setUpdateStatus("");
        }, 3000);
	};

	return (
		<div className="flex">
			{/* Sidebar */}
			<Sidebar />

			<div className="w-[calc(100%_-_72px)] ml-auto">
				{/* Header */}
				<Header type = {'settings'} />

				{/* Main Wrapper */}
				<div className="pt-6 px-6 h-[calc(100vh_-_127px)] overflow-y-auto">
					{/* Update Prompt */}
					<div className="mb-4">
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="flex gap-x-5 items-center mb-4">
								<h2 className="text-[#101828] text-xl font-interSemiBold">Prompt Repo</h2>
								<button 
									className="submit-btn-blue !px-4 !py-2"
									type="submit"
								> 
									Update
								</button>
								<img onClick={() => setshowAddPrompt(!showAddPrompt)} className="cursor-pointer" src={allImgPaths.plusIcon2} title="Add Prompt" alt="plus icon" />
								<p>{updateStatus}</p>
							</div>
						</form>
						{/* Update Prompt row */}
						<div className="pr-2">
							<div className="flex gap-x-5 items-stretch mb-5 last:mb-0">
								<div className="text-base text-[#344054] border border-[#D0D5DD] rounded-lg py-2.5 px-5 w-[720px] overflow-y-auto h-[calc(100vh_-_485px)]">
									<ul>
									{promptList && promptList.length > 0 && promptList.map((prompt: any, index: number) => (
										<li
											key={index}
											className={`text-base ${selectedPromptIndex === index ? 'text-[#1E0AF5]' : 'text-[#344054]'} mb-2 last:mb-0 cursor-pointer`}
											onClick={() => handlePromptClick(prompt,index)}
										>
											{prompt.name}
										</li>
									))}
									</ul>
								</div>

								<textarea
									id=""
									name="currentsavedprompt"
									value={currentSelectedPrompt?.prompt}
          							readOnly
									className="placeholder:text-[#344054] placeholder:text-base text-base text-[#344054] w-full border border-[#D0D5DD] rounded-lg py-2.5 px-2.5 resize-none overflow-y-auto h-[calc(100vh_-_485px)]"
									placeholder="Current saved Prompt"
								/>

								<textarea
									id=""
									name="currentsavedprompt"
									value={newPrompt}
                        			onChange={handlePromptChange}
									className="placeholder:text-[#344054] placeholder:text-base text-base text-[#344054] w-full border border-[#D0D5DD] rounded-lg py-2.5 px-2.5 resize-none overflow-y-auto h-[calc(100vh_-_485px)]"
									placeholder="New Prompt"
								/>
							</div>
						</div>

					</div>

					{/* Add Prompt */}
					<AddPrompt showAddPrompt={showAddPrompt}/>

				</div>

				{/* Footer */}
				{/* <Footer /> */}
			</div>
		</div>
	);
};

export default Prompts;