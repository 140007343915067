import React, { useState } from 'react'
import { Modal } from './Modal'
import { HeaderModal } from './Header'
import { Body } from './Body'
import { FooterModal } from './Footer'
import closeIcon from 'assets/images/close-icon.svg';
import modalBgShade from "../../../assets/images/bg-modal-shade.svg";
import allImgPaths from "assets/images";
import CustomSelect from '../CustomSelect'

function LeaveMember() {
	const [isShow, setisShow] = useState(false);
	const onCloseModal = () => {
		setisShow(false);
	}
	return (
		<>
			<Modal className={"modal-wrapper max-w-[400px]"} isShow={isShow}>
				<form>
					{/* Modal bg Shade */}
					<img className="absolute top-0.5 left-0 object-contain -z-10" src={modalBgShade} alt="shade" />

					<HeaderModal title={"test"} isShowHeader={true} className="modal-header pt-6 px-6">
						<img className="w-12 h-12 mb-4" src={allImgPaths.leaveTeamIcon} alt="remove" />
						<div className="flex items-center justify-between">
							<h2 className="text-lg text-[#101828] font-interSemiBold leading-none pb-1.5">Leave Team</h2>
							{/* Close Icon */}
							<img
								className="modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 -mt-[130px]"
								id="modal-toggle"
								src={closeIcon}
								onClick={onCloseModal}
								alt="close icon"
							/>
						</div>

						<p className="text-sm text-[#475467]">
							Are you sure you want to leave this team? You will lose access to all your data associated with this team.
						</p>

					</HeaderModal>
					<Body title="" isShowBody={true} className="modal-body px-6 pt-5">
						<p className="text-[15px] text-[#344054] font-interMedium pb-1.5">Select team member</p>
						<div className="modal-select-box fete-settings-modals">
							<CustomSelect
								options={
									[
										{ label: "value 1", value: "value 1" },
										{ label: "value 2", value: "value 2" }
									]
								}
								onChange={() => { }}
								extendedOption={false}
							/>
							{/* <p className='text-[#D92D20] text-sm pt-0.5'>error</p> */}
						</div>
					</Body>

					<FooterModal title="" isShowFooter={true} className="modal-footer pt-8 px-6 pb-4">
						<div className="flex items-center gap-x-3 justify-center">
							<button onClick={onCloseModal} className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]">Cancel</button>
							<button className="!text-base text-white bg-[#D92D20] hover:bg-[#B42318] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1">Leave</button></div>
					</FooterModal>
				</form>

			</Modal>
		</>
	)
}

export default LeaveMember
