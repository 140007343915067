import { useReducer } from "react";
import {
  getCollaborators,
} from "services/project";

const initialArgs = {
  searchQuery: "",
  initialProjectMembers: [],
  projectMembers: [],
  currentUserId: null,
};

const reducer = (state: any, action: any) => {
  return { ...state, ...action };
};

const useProjectHook = () => {
  const [state, setState] = useReducer(reducer, initialArgs);

  const currentUser = JSON.parse(localStorage.getItem("userData") as string);
  if (currentUser && !state.currentUserId) {
    setState({
      ...state,
      currentUserId: currentUser.id,
    });
  }

  const fetchCollaborators = async (id: any) => {
    try {
      const { data } = await getCollaborators(id, state.searchQuery);

      const newTransformedData = data.map((item: any, index: any) => ({
        id: item._id,
        user_id: item.user_id,
        projectRoleId: item.projectRole._id,
        profileImage: "defaultProfile",
        name: item.user_name,
        email: item.user_email,
        teams: {
          roleName: item.projectRole.name,
          roleId: item.projectRole._id,
        },
      }));

      setState({
        ...state,
        projectMembers: newTransformedData,
        initialProjectMembers: newTransformedData,
      });
      return newTransformedData;
    } catch (error) {
      console.log("Error fetching access options:", error);
    }
  };

  return {
    fetchCollaborators,
    setState,
    ...state,
  };
};
export default useProjectHook;
